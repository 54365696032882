import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getEducationOptions } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/appointmentSlice";

const UseFetchOptions = (type) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getFetchOptions", type],
    queryFn: () => getEducationOptions(type),
    enabled: !!type,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },

    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseFetchOptions;
