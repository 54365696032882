import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  appointmentTableCell,
  appointmentTableHead,
} from "../../style/FutureAppointmentStatusStyle";
import { headingStyle, tableRowLab } from "../../style/LapPageStyle";
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import { getAppointmentCountDetail } from "../../connections/getApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const AppointmentDetail = ({ appointments = [], onClose ,propAppointmentType}) => {
  
  // console.log(propAppointmentType)
  const [appointmentType, setAppointmentType] = useState(propAppointmentType);
  const[appointmentPage,setAppointmentPage] =useState(1);
  const[appointmentPerPage,setAppointmentPerPage] =useState(10);
  const[appointmentsList, setAppointmentsList]=useState( appointments || [])
  const[totalRecords,setTotalRecords]=useState("")
  const [type,setType]=useState(propAppointmentType || '')

  const handleGetAppointmentDetail = async (value) => {
    // console.log(value,'value')// appointment type
    const res = await getAppointmentCountDetail(appointmentPage, appointmentPerPage, type);
    if (res.status?.status === "SUCCESS") {
      setAppointmentsList(res?.payload?.appointments);
      setTotalRecords(res?.meta?.total_count || res?.payload?.appointments?.length);
    }
  };

  useEffect(()=>{
    handleGetAppointmentDetail(appointmentPage, appointmentPerPage, propAppointmentType);
  } ,[appointmentPage])
   
  const handlePrevious = () => {
    if (appointmentPage > 1) setAppointmentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (appointmentPage < totalPage) setAppointmentPage((prev) => prev + 1);
  // setAppointmentPage((prev) => prev + 1);
  };

  const totalPage =Math.ceil(totalRecords / appointmentPerPage);
  //  console.log(appointmentsList)
  return (


    <Box sx={{ minWidth: "70%" }}> 
     
     <Box 
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        // width="100%"
         mb={2}
      >
        <Box></Box>
  

         <Box sx={{display:'flex', justifyContent:'center' , alignItems:'center' }} >
        <EditCalendarRoundedIcon sx={{color:'neutral.navyBlue' , marginBottom:"4px" , marginRight:'8px'}} /> 
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={headingStyle}
        >
        Appointments
       </Typography>
       </Box>
        <IconButton  onClick={onClose}>
          <CloseIcon size="small"  sx={{color:"red"}}/>
        </IconButton>
      </Box> 



      <TableContainer >
        <Table >
          <TableHead >
            <TableRow>
              <TableCell sx={{...appointmentTableCell ,  textAlign: "center" }}>Sr No.</TableCell>
              <TableCell sx={{...appointmentTableCell ,  textAlign: "center" }}>Date</TableCell>
              <TableCell sx={{...appointmentTableCell ,  textAlign: "center" }}>Visit Time</TableCell>
              <TableCell sx={{...appointmentTableCell ,  textAlign: "center" }}>Name</TableCell>
              <TableCell sx={{...appointmentTableCell ,  textAlign: "center" }}>PatientID</TableCell>
              <TableCell sx={{...appointmentTableCell ,  textAlign: "center" }}>Doctor</TableCell>
            </TableRow>

          </TableHead>
          <TableBody >
            {appointmentsList?.length === 0 && (
              <TableCell
                rowSpan={3}
                colSpan={7}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                No Data
              </TableCell>
            )}
            {appointmentsList?.map((appointmentsList, index) => {
              // const [date, time] = appointment.schedule.split("  ");
              return (
                <TableRow key={appointmentsList.id} sx={{...tableRowLab , borderTop:"2px solid white" , borderBottom:"8px solid white"} }>
                  <TableCell sx={{ p: "10px",  paddingX:"12px", fontSize: "14px" , textAlign:"center" , borderTopLeftRadius:"20px" , borderBottomLeftRadius:"20px" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{ p: "10px",  paddingX:"12px", fontSize: "14px", color: "neutral.black",   textAlign:"center" }}
                  >
                    {appointmentsList?.schedule}
                  </TableCell>
                  <TableCell
                    sx={{ p: "10px",  paddingX:"12px", fontSize: "14px", color: "neutral.black" ,  textAlign:"center" }}
                  >
                    {appointmentsList?.schedule}
                  </TableCell>
                  <TableCell
                    sx={{ p: "10px",  paddingX:"12px", fontSize: "14px", color: "neutral.black",  textAlign:"center" }}
                  >
                    {appointmentsList?.patient_name}
                  </TableCell>
                  <TableCell
                    sx={{ p: "10px",  paddingX:"12px", fontSize: "14px", color: "neutral.black",  textAlign:"center" }}
                  >
                    {appointmentsList?.patient_uid}
                  </TableCell>
                  <TableCell
                    sx={{ p: "10px", paddingX:"12px", fontSize: "14px",color: "neutral.black" ,  textAlign:"center" ,  borderTopRightRadius:"20px" , borderBottomRightRadius:"20px" }}
                  >
                    {appointmentsList?.doctor_name}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>


      <Box 
        container
        display={"flex"}
        
        justifyContent={"space-between"}
        sx={{ zIndex: "10" }}
        lg={12}
        marginBottom={'5px'}
        paddingX={'20px'}
      >
        
        <Grid item>
         
        </Grid>


        <Grid item  sx={{display:'flex' , gap:'20px' , alignItems:'center' , justifyContent:'center' }} >
          
          <Box sx={{display:'flex' , gap:'20px' , alignItems:'center' , justifyContent:'center' , marginTop:'16px' }}>
            <Typography sx={{ marginTop:'16px'}}>

             Displaying  {appointmentPage} {' '} - {' '} {totalPage} of   {totalRecords}  in total
            </Typography>

           {/* pagination buttons  start  */}

           <Box
        mt={2}
        gap={0}
        justifyContent="end"
        display="flex"
        alignItems="center"
      >
        <Button
        onClick={handlePrevious}
          startIcon={<ArrowBackIcon sx={{ fontSize: "14px !important" }} />}
          sx={{
            fontSize: "12px",
            textTransform: "capitalize",
            border: 1? "none" : "1px solid rgba(0, 0, 0, 0.2)",
            color: appointmentPage===1? "grey" : "#336cfb",
          }}
        
        >
          Previous
        </Button>
  
            <Button
             
              sx={{
                fontSize: "12px",
                minWidth: "24px",
                minHeight: "24px",
                padding: "2px",
                borderRadius: "4px",
                border: 2     
                    ? "none"
                    : "1px solid rgba(0, 0, 0, 0.2)",
                 color:  "#336cfb",
                // backgroundColor:
                //   appointmentPage === currentPage ? "rgba(0, 0, 0, 0.1)" : "inherit",
              }}
             
            >  
            {appointmentPage}
            </Button>
        
        <Button
         onClick={handleNext}
          endIcon={<ArrowForwardIcon sx={{ fontSize: "14px !important" }} />}
          sx={{
            fontSize: "12px",
            textTransform: "capitalize",
            border: 1
                ? "none"
                : "1px solid rgba(0, 0, 0, 0.2)",
            color: appointmentPage===totalPage ? "grey" : "#336cfb",
          }}       
        >
          Next
        </Button>
           </Box>

            {/* pagination buttons  end  */}
          </Box>

        </Grid>

      </Box>
    </Box>
  );
};

export default AppointmentDetail;
