export const slotChip = {
  // border: "1px solid #f58633",
  color: "#3A3A3A",
  fontSize: "12px",
  p: "1px 2px",
  m: 0,
  height: "18px",
  cursor: "pointer",
  backgroundColor:"#EAF2F4",
  fontFamily:'Outfit',
  "&:hover": {

    // backgroundColor: "neutral.main !important",  
    // backgroundColor: "#f58633 !important",  
    backgroundColor: "#0067FF !important",  // blue
    color: "white !important",          
    // border: "1px solid #f58633 !important",   
    transition: "background-color 0.4s", 
  },
};

export const activeChip={
  // border: "1px solid #f58633",
  color: "neutral.white",
  fontSize: "12px",  
  p: "1px 6px",
  m: 0,
  height: "18px",
  border :'none',
  fontFamily:'Outfit',
  "&:hover": {
    backgroundColor: "#0067FF ! important", 
    cursor: "pointer",
    color:'white',
  },
  cursor: "pointer",
  // backgroundColor: "neutral.main",
  // backgroundColor: "#f58633",
  backgroundColor: "#0067FF", 
}

export const textfieldTextPrep = {
  //  padding: "10px 0px",
   fontSize: "14px",
   fontFamily:'Outfit',
   transition: "all 0.4s ease-in-out",
  "& .MuiOutlinedInput-root": {
    fontFamily:'Outfit',
    borderRadius:'15px',
    height: "38px",
    padding: "9px !important",
    "& input": {
      padding: "4px",
      fontSize: "14px",
    },
  },  
  "& .MuiInputBase-root": {
    backgroundColor: "neutral.white",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "neutral.white",   
    boxShadow:
      // "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
    'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },     
};


export const textfieldTextBlueBorder = {
  //  padding: "10px 0px",
   fontSize: "14px",
  "& .MuiOutlinedInput-root": {
    fontFamily:"Outfit",
    borderRadius:'10px',
    height: "40px",
    padding: "9px !important",
    "& input": {
      padding: "4px",
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "neutral.white",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "neutral.white",   
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
  // "& fieldset": {
  //   border: "none", // Remove default border
  // },
  // "&:hover fieldset": {
  //   border: "none", // Remove border on hover
  // },     
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0067FF", // Blue border color
    borderWidth: "1px", // Border thickness
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0067FF", // Blue border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0067FF", // Blue border color when focused
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0067FF", // Keep the blue border even when disabled
    // opacity: 1, // Optional: Adjust opacity for a muted effect
  },
 "& fieldset": {
        borderColor: "#0067FF", // Blue border color
      },
      // Additional styles for hover and focus states
      "&:hover fieldset": {
        borderColor: "#0067FF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0067FF",
      },
      // Ensure disabled border remains blue
      "&.Mui-disabled fieldset": {
        borderColor: "#0067FF",
      },
};

export const selectFieldPrep = {
  padding: "9px 0px",
  fontSize: "14px",
  fontFamily:'Outfit',
  borderRadius:'15px',
  backgroundColor: "white",
  "& .MuiSelect-select": {
    height: "100%",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "&.Mui-focused": {
    backgroundColor: "white",
    // border:'1px solid grey',
    boxShadow:
    // "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  "&.MuiSelect-select.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
    // "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },
};



export const dateFiledPrep = {
  marginTop: "2px",
  "& .MuiOutlinedInput-root": {
    height: "38px",
    fontFamily:'Outfit',
    borderRadius:'15px',
    fontSize: "14px",
    "& input": {
      padding: "8px",
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },
}; 

export const primaryBtnPrep = {
  backgroundColor: "neutral.main",
  p: 0.5,
  m: 0,
  fontSize: "12px",
  textTransform: "capitalize",
  fontFamily:'Outfit',
  "&:hover": {
    backgroundColor: "neutral.main",
    color: "neutral.black",
  },
};
export const dialogBoxSaveBtn = {
  textTransform: "capitalize",
  p: "2px",
  backgroundColor: "neutral.white",
  mt: "2px",
  color: "neutral.black",
  "&:hover": {
    backgroundColor: "neutral.white",
    color: "neutral.black",
  },
};

export const primaryBtnBlue = {
  backgroundColor: "neutral.navyBlue",
  boxShadow:'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  height:'26px',
  p: 2.4,
  fontFamily:'Outfit',
  m: 0, 
  borderRadius:'20px',
  textTransform: "capitalize",
  transition: "all 0.4s ease-in-out", 
  "&:hover": {
    backgroundColor: "#055ee3", 
    color: "neutral.white",
    // fontSize:"14.6px",
    // boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px',
    // boxShadow: 'rgba(0, 0, 0, 0.4) 0px 30px 90px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',

  },
  "&:active": {
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 12px 18px -4px, rgba(0, 0, 0, 0.08) 0px 6px 8px -3px',
    backgroundColor: "#10459c", 
  },
};


export const mailBtn = {
  color: "neutral.white",
  backgroundColor: "neutral.navyBlue",
  borderRadius:'15px',
  padding: "6px",
  height: "1.6rem",
  width: "1.6rem",
  "&:hover": {
    backgroundColor: "#055ee3", 
    color: "neutral.white",
    fontSize:"15px",
    // boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px',
    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 30px 90px',
  }, 
  "&:active": {
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 12px 18px -4px, rgba(0, 0, 0, 0.08) 0px 6px 8px -3px',
    backgroundColor: "#10459c", 
  },
};

export const blueLightBtn = {
  backgroundColor: "neutral.blue1",
  "&:hover": { backgroundColor: "neutral.blue1" },
};
 
export const orangeButton = {
  backgroundColor: "neutral.orange",
  padding:'0px',
   borderRadius:'20%',
  "&:hover": { backgroundColor: "neutral.orange" },
};

    