import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  patientData: [],
  prescriptionData: [],
  appointmentData:{},
  patientDetailsData: {},  
  doctorOptions: [],
  isLoading: false,
  isError: false,
  renderPrescriptionTable:false,

};  
 
const prescriptionSlice = createSlice({
  name: "prescription",
  initialState,
  reducers: {
    setSearchPatient: (state, action) => {
      state.patientData = action.payload;
    },
    setPrescriptionData: (state, action) => {
      state.prescriptionData = action.payload;
    },
    setPatientDetailsData: (state, action) => {
      state.patientDetailsData = action.payload;
    },
    setAppointmentData: (state, action) => {
      state.appointmentData = action.payload;
    },
    setDoctorOption: (state, action) => {
      state.doctorOptions = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
    setRenderPrescriptionTable: (state, action) => {
      state.renderPrescriptionTable = !state.renderInvoice;
    },
  },
});

export const { setSearchPatient , setLoading, setError, setPrescriptionData , setPatientDetailsData , setAppointmentData , setDoctorOption , setRenderPrescriptionTable} = prescriptionSlice.actions;
export default prescriptionSlice.reducer;
