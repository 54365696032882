import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  Popper,
  Skeleton,
} from "@mui/material";
import {
  collectBtn,
  headingStyle,
  labPageButton,
  tableCellLab,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  dateFiled,
  menuItemStyle,
  newAppointmentSearchTextField,
  primaryBtn,
  selectField,
  textfieldText,
} from "../../style/AppointmentStyle";
import SearchIcon from "@mui/icons-material/Search";
import { drTableRow } from "../../style/DrApotComponentStyle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  addBtn,
  pharmacyPageBlueBtn,
  yellowBgBtn,
} from "../../style/PharmacyStyle";
import {
  getPriceByQuantity,
  getPriceByQuantityForReturn,
  reprintInvoice,
  refundInvoice,
  getMedicineByName,
} from "../../connections/getApi";
import { generateBill } from "../../connections/postApi";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/UseDebounce";
import UseGetMedicineList from "../../helper/UseGetMedicineList";
import UseMedicineByName from "../../helper/UseMedicineByName";
import UseGetMedicineDetail from "../../helper/UseGetMedicineDetail";
import UseGetInvoice from "../../helper/UseGetInvoice";
import { useReactToPrint } from "react-to-print";
import PharmacyInvoice from "../PrintCompoents/PharmacyInvoice";
import { showToast } from "../../utils/toastUtil";
import { Link } from "react-router-dom";
import UseGetAllMedicineList from "../../helper/UseGetAllMedicineList";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { orangeButton, primaryBtnBlue } from "../../style/PatientDetailsStyle";
import { drTableRowBlueBg } from "../../style/PatinetFormStyle";

const PastBillPharmacyByInvoice = ({
  id,
  onClose,
  setOpenBillByInvoice,
  refetch,
  showBtn,
}) => {
  const [formState, setFormState] = useState({
    full_name: "",
    uid: "",
    mobile: "",
    email: "",
    dob: "",
    gender: "",
    patient_id: "",
    prescription_id: "",
    invoice_id: "",
  });
  const [medicines, setMedicines] = useState([
    {
      id: "",
      name: "",
      quantity: "",
      batchId: null,
      batch: "",
      batchArray: [],
      returned: false,
      stock: "",
      packing: "",
      unitMrp: null,
      mrp: null,
      discount: null,
      gst: null,
      amount: null,
    },
  ]);
  const [total, setTotal] = useState(0);
  const [select, setSelect] = useState(null);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [showButton, setShowButton] = useState(true);
  const [printInvoiceData, setPrintInvoiceData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMedicines, setFilteredMedicines] = useState([]);
  const [openMedicineList, setOpenMedicines] = useState(false);

  const dispatch = useDispatch();

  const { data: invoiceDetails , isLoading } = UseGetInvoice(id);
  const { data: medicineList } = UseGetAllMedicineList();
  const { data: medicineDetail } = UseGetMedicineDetail(selectedMedicine?.id);

  // const { patientData } = useSelector((state) => state.pharmacy);
  // const { patientDetailsData } = useSelector((state) => state.pharmacy);

  useEffect(() => {
    // Simulating fetching all data on component mount and storing it in local storage or state.
    if (
      medicineList?.payload?.medicines &&
      medicineList?.payload?.medicines?.length > 0
    ) {
      localStorage.setItem(
        "allMedicines",
        JSON.stringify(medicineList?.payload?.medicines)
      );
    }
  }, [medicineList]);
  
  useEffect(() => {
    if (invoiceDetails) {
      setFormState({
        full_name: invoiceDetails.patient_info?.name || "",
        uid: invoiceDetails.patient_info?.uid || "",
        mobile: invoiceDetails.patient_info?.mobile || "",
        dob: invoiceDetails.patient_info?.dob || "",
        gender: invoiceDetails.patient_info?.gender || "",
        age:
          invoiceDetails.patient_info?.age >= 0
            ? invoiceDetails.patient_info?.age
            : "",
        patient_id: invoiceDetails?.patient_id || "",
        prescription_id: invoiceDetails?.prescription_id || "",
        invoice_id: invoiceDetails?.id || "",
      });

      setMedicines(
        invoiceDetails?.medicines?.map((medicine) => ({
          id: medicine?.id,
          name: medicine?.name || "",
          // quantity: medicine.qty || "",
          batchId: medicine?.medicine_batches[0]?.id || null,
          batch: medicine.batch || "",
          batchArray: medicine?.medicine_batches || [],
          stock: medicine?.medicine_batches[0]?.stock_left,
          packing: medicine?.medicine_batches[0]?.packing || "",
          unitMrp: medicine.mrp || 0,
          mrp: medicine.mrp || 0,
          discount: `${medicine.discount || 0}%`,
          gst: (medicine.cgst || 0) + (medicine.sgst || 0),
          amount: medicine?.grand_total || 0,
          expiry_date: medicine?.medicine_batches[0]?.expiry_date,
          quantity: medicine?.quantity || 1,
          returned: medicine?.returned,
        }))
      );
    }
  }, [invoiceDetails]);

  //handle form change
  // const handleFocusMedicineName = (e) => {
  //   const { name, value } = e.target;
  //   setFormState((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const handleAddRow = () => {
    setMedicines([
      ...medicines,
      {
        name: "",
        quantity: "",
        batch: "",
        return: false,
        stock: "",
        packing: "",
        UnitMRP: null,
        MRP: null,
        GST: null,
        amount: null,
      },
    ]);
  };

   // handle key down with  enter  logic
   const handleChange = (index, field, value) => {
    const newMedicines = medicines.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );
    setMedicines(newMedicines);
  };

  const handleSearch = (event) => {
    // console.log(event.target.value);
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    const storedMedicines =
      JSON.parse(localStorage.getItem("allMedicines")) || [];

    // Filter medicines based on search query
    const filteredMedicineList = storedMedicines.filter((medicine) =>
      medicine[1].toLowerCase().startsWith(query)
    );

    // Limit the results to top 10 and sort
    const limitedResults = filteredMedicineList.slice(0, 10);

    setFilteredMedicines(limitedResults);
  };

  const updateNameAndId=(index, value)=>{
    const newMedicines = [...medicines]; 
      newMedicines[index] = { ...newMedicines[index], id: value[0] , name:value[1] };
      setMedicines(newMedicines); 
  }


//  console.log(showBtn)
  const handleKeyDown = (e, index, field) => {
    if (e.key === "Enter" && field === "name") {
      e.preventDefault();
  
      if (!medicines[index]?.name) {
        setTimeout(() => document.getElementById(`submit`).focus(), 0);
        return;
      }
  
      const currentInput = medicines[index]?.name?.toLowerCase() || "";
  
      const topOption = filteredMedicines.find((medicine) =>
        (medicine[1] || "").toLowerCase().startsWith(currentInput)
      );     
  
      if (topOption) {   
        setTimeout(() => {
          updateNameAndId(index, topOption);
          handlePresence(index, topOption);
          setSelectedMedicine({ name: topOption[1], id: topOption[0] });
          document.getElementById(`quantity-${index}`).focus();
        }, 100); // Delay to ensure the input value is fully captured
      }
    }

    else if ( e.key === "Enter" && field === "quantity") {
        setTimeout(() => document.getElementById(`return-${index}`).focus(), 0); 
    } 
    else if (e.key === "Enter" &&  field === "return") {
        handleAddRow(); 
        setTimeout(() => document.getElementById(`name-${index + 1}`).focus(), 0); 
   }

  };
          
  const handlePresence = async (index, value) => {
    const medicineNames = medicines
      ?.slice(0, medicines?.length > 1 ? medicines?.length - 1 : 0)
      .map((med) => med.name)
      .join(",");
    // encodeURIComponent(medicineNames)
    // console.log(medicineNames);
    try {
      const res = await getMedicineByName(
        encodeURIComponent(medicineNames),
        value[1]
      );

      //  console.log(res.payload?.presence);
      if (res?.payload?.presence) {
        const updatedMedicines = medicines.map((medicine, i) =>
          i === index
            ? { ...medicine, id: "", name: "", description: "", remark: "" }
            : medicine
        );

        // Update the state with the cleared fields
        setMedicines(updatedMedicines);

        console.log(updatedMedicines, "Updated medicines list");
        showToast("Medicine already present in the list", "ERROR");
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(value, " call api to check ");
    setFilteredMedicines([]);
  };

  const handleMedicineChange = (index, field, value) => {
    const newMedicines = medicines.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );
    setMedicines(newMedicines);
    // console.log(index,value);
  };

  //packing and stock show after select medicine
  useEffect(() => {
    if (medicineDetail && select !== null) {
      setMedicines((prevMedicines) =>
        prevMedicines.map((medicine, idx) =>
          idx === select
            ? {
                ...medicine,
                stock: medicineDetail?.payload?.stock_left,
                packing: medicineDetail?.payload?.packing,
              }
            : medicine
        )
      );
      // console.log(packing, stock_left, '<-stock and packing');
    }
  }, [medicineDetail, select]);

  //  console.log(medicines);

  const handleQuantityChange = (index, field, value) => {
    const newMedicines = medicines.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );
    console.log(medicines, "newMedicines");
    setMedicines(newMedicines);
    getPriceByQuantity(medicines[index].id, value, medicines[index].batchId)
      .then((res) => {
        console.log(res.payload);
        const {
          selected_batch,
          discount,
          gst,
          mrp,
          batches,
          unit_price,
          grand_total,
        } = res.payload;

        // Update the medicine entry with the new price data
        const updatedMedicines = newMedicines.map((medicine, idx) =>
          idx === index
            ? {
                ...medicine,
                batchId: selected_batch?.id,
                batch: selected_batch,
                batchArray: batches,
                stock: selected_batch?.stock_left,
                packing: batches[0]?.packing,
                unitMrp: medicine.returned ? -unit_price : unit_price,
                mrp: medicine.returned ? -mrp : mrp,
                discount: medicine.returned ? `-${discount}%` : `${discount}%`,
                gst,
                amount: medicine.returned ? -grand_total : grand_total,
                expiry_date: batches[0]?.expiry_date,
              }
            : medicine
        );
        setMedicines(updatedMedicines);
      })
      .catch((error) => {
        console.error("Error fetching price:", error);
      });
  };

  // console.log(medicines);
  const handleReturnChange = (index) => {
    const newMedicines = medicines?.map((medicine, idx) =>
      idx === index ? { ...medicine, returned: !medicine.returned } : medicine
    );
    setMedicines(newMedicines);

    getPriceByQuantityForReturn(medicines[index].id, medicines[index].quantity)
      .then((res) => {
        console.log(res.payload);
        const {
          selected_batch,
          discount,
          gst,
          mrp,
          batches,
          unit_price,
          grand_total,
        } = res.payload;

        // Update the medicine entry with the new price data
        const updatedMedicines = newMedicines.map((medicine, idx) =>
          idx === index
            ? {
                ...medicine,
                batchId: selected_batch?.id,
                batch: selected_batch,
                batchArray: batches,
                stock: selected_batch?.stock_left,
                packing: batches[0]?.packing,
                unitMrp: medicine.returned ? -unit_price : unit_price,
                mrp: medicine.returned ? -mrp : mrp,
                discount: medicine.returned ? `-${discount}%` : `${discount}%`,
                gst,
                amount: medicine.returned ? -grand_total : grand_total,
              }
            : medicine
        );

        setMedicines(updatedMedicines);
      })
      .catch((error) => {
        console.error("Error fetching price:", error);
      });
  };

  
  const handleRemoveRow = (index) => {
    const newMedicines = medicines.filter((_, idx) => idx !== index);
    setMedicines(newMedicines);
  };
  
  useEffect(() => {
    let totalAmount = 0;
    let validMedicines = 0;
    let returnMedicine = 0;
    let hasInvalidQuantity = false;

    medicines?.forEach((medicine) => {
      // Check if the medicine has a valid name and quantity
      if (medicine?.name && medicine?.quantity && medicine?.quantity > 0) {
        validMedicines++;

        if (medicine?.amount < 0) {
          returnMedicine += parseFloat(medicine.amount);
        } else {
          totalAmount += parseFloat(medicine.amount);
        } 
      }  

      // Check if there are medicines with a name but invalid quantity (null, 0, or undefined)
      if (medicine.name && (!medicine.quantity || medicine.quantity <= 0)) {
        hasInvalidQuantity = true;
      }
    });

    setSelect(validMedicines);
    setTotal(Math.abs(totalAmount + returnMedicine));

    // If any invalid quantity is found, hide the button
    setShowButton(!hasInvalidQuantity && validMedicines > 0);
  }, [medicines]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "medicine invoice Print",
    onAfterPrint: () => console.log("Print job finished"),
  });
         
  // console.log(formState , 'formState');
  //handle save & print invoice
  const saveAndPrint = async () => {
    const formattedMedicines = medicines?.map((medicine) => ({
      id: medicine?.id,
      name: medicine?.name,
      batch: medicine?.batch?.id,
      packing: medicine?.packing,
      quantity: medicine?.quantity,
      amount: medicine.unitMrp * medicine.quantity,
      discount: medicine.discount,
      net: medicine.amount,
    }));
    console.log(formState, "formState");
    if (formState?.patient_id) {
      const payload = {
        patient: {
        full_name: formState.full_name,
        dob: formState.dob,
        gender: formState.gender,
        mobile: formState.mobile,
        email: formState.email,
        },
        patient_id: formState?.patient_id ||null ,
        prescription_id:  formState?.prescription_id || null,
        invoice_id: formState?.invoice_id || null,
        medicines: formattedMedicines,
      };
      const res = await generateBill(payload);
      setPrintInvoiceData(res);
      console.log(res, "<-res");

      // const statusMessage = res?.status?.status || "ERROR";
      // const detailedMessage = res?.status?.message || "Something went wrong";
      // showToast(statusMessage, detailedMessage);
      showToast(
        res?.status?.message || res?.status?.status,
        res?.status?.status
      );

      if (res) {
        setTimeout(() => {
          handlePrint();
        }, 0);
      }
      showToast(`Generating prescription PDF`, "SUCCESS");
      refetch();
    } else {
      const payload = {
        patient: {
          full_name: formState.full_name,
          dob: formState.dob,
          gender: formState.gender,
          mobile: formState.mobile,      
          email: formState.email,
        },
        invoice_id: formState?.invoice_id || null,
        medicines: formattedMedicines,
      };

      const res = await generateBill(payload);   
      setPrintInvoiceData(res);
      refetch();

      console.log(res);
      if (res) {
        setTimeout(() => {
          handlePrint();
        }, 0);
      }
      // const statusMessage = res?.status?.status || "ERROR";
      // const detailedMessage = res?.status?.message || "Something went wrong";
      // showToast(statusMessage, detailedMessage);
      showToast(
        res?.status?.message || res?.status?.status,
        res?.status?.status
      );
    }
  };
  //  console.log(medicines);
  //handle reprint  (invoiceId , prescriptionId)
  const rePrint = async () => {
    const res = await reprintInvoice(
      formState.invoice_id,
      formState.prescription_id
    );
    setPrintInvoiceData(res);
    console.log(res);
    if (res?.status?.status==='SUCCESS') {
      setTimeout(() => {
        handlePrint();
      }, 0);
    }
    const statusMessage = res?.status?.status || "ERROR";
    const detailedMessage = res?.status?.message || "Something went wrong";
    showToast(statusMessage, detailedMessage);
    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
    showToast(`Generating prescription PDF`, "SUCCESS");
    refetch();
  };

  // refund and cancel
  const refundAndCancel = async () => {
    const res = await refundInvoice(formState.invoice_id);
    setPrintInvoiceData(res);
    const statusMessage = res?.status?.status || "ERROR";
    const detailedMessage = res?.status?.message || "Something went wrong";
    showToast(statusMessage, detailedMessage);
    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
    // showToast(`Generating prescription PDF`, "SUCCESS");
    refetch();
    setOpenBillByInvoice(false);
  };

  // console.log(showBtn, "showBtn");
  return (
    <>
    <Box  sx={{ width: "100%", display:'flex' , height:"90vh" ,  flexDirection:'column' , justifyContent:"space-between" }}>
   
     <Box sx={{overflowY :'auto' , maxHeight:"80vh" ,  '&::-webkit-scrollbar': {
      display: 'none', 
    },
    '-ms-overflow-style': 'none',  
    'scrollbar-width': 'none',  }} >

<Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
      >
        <Box></Box>
  

         <Box sx={{display:'flex', justifyContent:'center' , alignItems:'center' }} >
        <ReceiptLongIcon sx={{color:'neutral.navyBlue' , marginBottom:"4px" , marginRight:'8px'}} /> 
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom 
          sx={headingStyle}
        >
        Bill 
       </Typography>
       </Box>
        <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
          <CloseIcon size="small"  sx={{color:"red"}}/>
        </IconButton>
      </Box> 
    

      {isLoading? (
          <Skeleton 
            variant="rectangular"
            width="100%"
             height={30}
             /> 
        ): (
      <Grid
        container
        sx={{ justifyContent: "space-between", width: "100%", mt: "30px" }}
      >
        <Typography>Name : {formState?.full_name}</Typography>

          {invoiceDetails?.patient_info?.patient_uid ? (
          <Typography>Patient ID: {invoiceDetails.patient_info.patient_uid}</Typography>
        ) : (
          <Typography>Invoice ID: {id}</Typography>
        )}
        {/* <Typography>invoice ID: {id} </Typography> */}
        <Typography>Mobile: {formState?.mobile}</Typography>
        {formState?.age >= 0 && <Typography>Age : {formState?.age==='0'? formState?.age  : '1'} </Typography>}
        {formState?.gender && <Typography>{formState?.gender}</Typography>}
      </Grid>
        )}

      <Grid item xs={12}>
        <TableContainer sx={{ mt: 2, width: "100%" }}>
          <Table sx={{ width: "100%" }}>
            <TableHead stickyHeader>
            

              <TableRow  sx={{ ...drTableRowBlueBg, textAlign: "center" ,  }}>
                <TableCell
                variant="body1_text_bold" 
                  sx={{
                    borderTopLeftRadius:"20px",
                  color: 'neutral.white',
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                  //  paddingX:"4px",
                   textAlign: "center",
                   width: "200px",
                 }}
                >
                  Name
                </TableCell>

                <TableCell  
                 
                  variant="body1_text_bold" 
                  sx={{
                    width: "150px",
                    paddingRight: "30px",
                  color: 'neutral.white',
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Batch
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Return
                </TableCell>

                <TableCell
                variant="body1_text_bold" 
                sx={{
                color: 'neutral.white',
                  p: "8px",
                  textAlign: "center",
                }}
                >
                  Total Stock
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Pack
                </TableCell>

                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Unit MRP
                </TableCell>

                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  MRP
                </TableCell>

                <TableCell
                variant="body1_text_bold" 
                sx={{
                color: 'neutral.white',
                  p: "8px",
                  textAlign: "center",
                }}
                >
                  Discount
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  GST
                </TableCell>

                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                   color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                   borderTopRightRadius: showBtn? '20px' :' ',
                 }}
                
                >
                  Amount
                </TableCell>

                
                {!showBtn && ( <TableCell
                  sx={{ p: "4px", textAlign: "center" , borderTopRightRadius:"20px"}}
                  
                >
                  <IconButton  onClick={handleAddRow} sx={{...orangeButton , marginLeft:"7px"  ,marginY:'2px'}}>
                  <AddIcon sx={{color:'neutral.white',
           width:'30px',
           height:'32px' , padding:'0px'}} />
                  </IconButton>
                </TableCell>
                )} 
              </TableRow>


            </TableHead>

            {isLoading? (
          <Skeleton 
            variant="rectangular"
            width="10000%"
             height={30}
             />
        ):  (
 
            <TableBody>
              {medicines?.length > 0 &&
                medicines?.map((medicine, index) => (
                  <TableRow sx={tableRowLab}>
                    <TableCell sx={tableCellLab}>
                      <Typography variant="body_Grey1" color={"text.grey"}>
                        {index + 1}
                      </Typography>
                    </TableCell>

                  
              <TableCell sx={tableCellLab}>     
              <Autocomplete
              options={filteredMedicines}
              freeSolo
              clearOnEscape={false}
              // clearIcon={null}
              open={openMedicineList===index}
              onOpen={() => setOpenMedicines(index)}
              onClose={() => setOpenMedicines(false)}
              id={`name-${index}`}
              inputValue={medicine?.name !== null ? medicine?.name : ""}
              onInputChange={(e, value) => {
                handleChange(index, "name", value, e);
              }}
              disabled={showBtn}
              onChange={(e, value) => {
                // console.log(value);
                if (value) {
                  handleChange(index, "id", value[0]);
                  handlePresence(index, value);
                  setTimeout(() => {
                    document.getElementById(`quantity-${index}`).focus();
                  }, 0);
                }
              }}
              sx={textfieldText}
              PopperComponent={(props) => (
                <Grid item lg={8}>
                  <Popper
                    {...props}
                    placement="bottom-start"
                    style={menuItemStyle}
                    modifiers={[
                      {
                        name: "preventOverflow",
                        options: {
                          boundary: "block",
                        },
                      },
                    ]}
                  >
                    <div
                      style={{
                        maxHeight: 300,
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        maxWidth: "320px",
                        minWidth: "310px",
                      }}
                    >
                      {props.children}
                    </div>
                  </Popper>
                </Grid>
              )}
              getOptionLabel={(option) => option[1]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={handleSearch}
                  value={searchTerm}
                  placeholder="Medicine"
                  onKeyDown={(e) => handleKeyDown(e, index, "name")}
                />
              )}
              renderOption={(props, option, { index }) => (
                <MenuItem
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#f58633 !important",
                      color: "white !important",
                    },
                    ...(index === 0 && {
                      backgroundColor: "#f58633 !important",
                      color: "white !important",
                    }),
                  }}
                  {...props}
                  key={option[0]}
                >
                  {option[1]}
                </MenuItem>
              )}
            />
                  </TableCell>
                  

                    <TableCell sx={tableCellLab}>
                      <TextField
                        fullWidth
                        placeholder="Quantity"
                        variant="outlined"
                        type="number"
                        error={
                          medicine?.quantity ? medicine?.quantity <= 0 : false
                        }
                        helperText={
                          medicine?.quantity <= 0 && medicine?.quantity
                            ? "Quantity must be more than zero"
                            : null
                        }
                        id={`quantity-${index}`}
                        sx={textfieldText}
                        value={medicine?.quantity}
                        onKeyDown={(e) => handleKeyDown(e, index, "quantity")}
                        onChange={(e) => {
                          handleChange(index, "quantity",  e.target.value);
                          handleQuantityChange(index, "quantity", e.target.value);
                        }}
                      />
                    </TableCell>

                    <TableCell sx={{ ...tableCellLab, width: "100px" }}>
                      <FormControl fullWidth variant="outlined">
                        <Select
                          sx={selectField}
                          placeholder="batch"
                          fullWidth
                          value={medicines[index]?.batchId || ""}
                          onChange={(e) => {
                            handleQuantityChange(
                              index,
                              "batchId",
                              e.target.value
                            );
                          }}
                        >
                          {medicines[index]?.batchArray?.map((batch) => (
                            <MenuItem key={batch.id} value={batch.id}>
                              {batch.batch_no}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* Render the batch stock and expiry date */}

                        <Box
                          justifyContent={"space-between"}
                          display={"flex"}
                          paddingX={"2px"}
                        >
                          <Typography
                            fontSize={"12px"}
                            color={` ${
                              medicine?.quantity > medicine?.stock
                                ? "#ed5564"
                                : "text.grey"
                            } `}
                          >
                            {medicine?.stock}
                          </Typography>
                          <Typography fontSize={"12px"}>
                            {medicine?.expiry_date}
                          </Typography>
                        </Box>
                      </FormControl>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Checkbox
                        id={`return-${index}`}
                        checked={medicine?.returned}
                        onKeyDown={(e) => handleKeyDown(e, index, "return")}
                        onChange={(e) => {
                          handleChange(index, "return",  e.target.value);
                          handleReturnChange(index);
                        }}
                        sx={{ height: "10px", width: "10px" }}
                      />
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Typography
                        variant="body_Grey1"
                        color={` ${
                          medicine?.quantity > medicine.stock
                            ? "#ed5564"
                            : "text.grey"
                        } `}
                      >
                        {medicine?.stock || "--"}
                      </Typography>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Typography variant="body_Grey1" color={"text.grey"}>
                        {medicine?.packing}
                      </Typography>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      {medicine.returned ? (
                        <Typography variant="body_Grey1" color={"#ed5564"}>
                          {" "}
                          {medicine?.mrp}{" "}
                        </Typography>
                      ) : (
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine.mrp}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      {medicine.returned ? (
                        <Typography variant="body_Grey1" color={"#ed5564"}>
                          {" "}
                          {medicine?.mrp}{" "}
                        </Typography>
                      ) : (
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine.mrp}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      {medicine.returned ? (
                        <Typography variant="body_Grey1" color={"#ed5564"}>
                          {" "}
                          {medicine.discount}{" "}
                        </Typography>
                      ) : (
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine.discount}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Typography variant="body_Grey1" color={"text.grey"}>
                        {medicine.gst}
                      </Typography>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      {medicine.returned ? (
                        <Typography variant="body_Grey1" color={"#ed5564"}>
                          {" "}
                          {medicine?.amount}{" "}
                        </Typography>
                      ) : (
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine?.amount}
                        </Typography>
                      )}
                    </TableCell>

                    {!showBtn && (

                  <TableCell sx={tableCellLab}>
                  <IconButton sx={{ marginLeft:'8px', marginBottom:"7px",}}
                      onClick={() => handleRemoveRow(index)}
                    >
                  <CloseIcon sx={{ ...xBtn, width:'26px', height:'26px', borderRadius:'20%' }}/>
                    </IconButton> 
                  </TableCell>

                    )}


                  </TableRow>
                ))}
            </TableBody>  
        )}   
          </Table>
        </TableContainer>

        

        <Box
          sx={{
            ...tableRowLab,
            borderBottomLeftRadius:"20px",
            borderBottomRightRadius:"20px", 
            borderTopLeftRadius:"0px",
            borderTopRightRadius:"0px",
            justifyContent: "space-between",
            display: "flex",
            height: "60px",
            alignItems: "center",
            padding: "3px",   
            paddingX:"40px"
          }}
        >
          <Box sx={{ justifyContent: "space-between",
            display: "flex",
            height: "60px",
            alignItems: "center"}} columnGap={'10px'}>
              <Typography variant="h6">Selected : </Typography>
            <Typography variant="h6">
              {select}
            </Typography>
          </Box>
{/*           
          <Box></Box>

          <Box></Box> */}

          <Box  sx={{ justifyContent: "space-between",
            display: "flex",
            height: "60px",
            alignItems: "center"}} columnGap={'10px'}>

              <Box>
            <Typography variant="h6">Total:</Typography>
            {showBtn &&  <Typography variant="p">Round-Off</Typography>}
            </Box>

            <Box>
            <Typography variant="h6">  {!showBtn ? (isNaN(total) ? 0 : total) : invoiceDetails?.grand_total}</Typography>
            {showBtn && <Typography variant="p" fontFamily={'sans-serif'}> {invoiceDetails?.total_net} </Typography> }
            </Box>
          </Box>
        </Box>

      </Grid>
      </Box>     

      <Box
        container
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ zIndex: "10" }}
        lg={12} 
        marginBottom={'5px'}
      >
        <Grid item>
          
        </Grid>

        <Box item sx={{ display: showButton ? "flex" : "none"  ,justifyContent:'space-between' , width:"100%"}}>
            <Box>
          {!showBtn && (
              <Button
              onClick={saveAndPrint}
               className="btn draw-border"
              variant="contained"
               id="submit"
              sx={{
               ...primaryBtn, marginRight: "5px"
              }}
              disabled={isLoading}
            >
              <Typography
                textTransform={"capitalize"}
                color={"neutral.white"}
                variant="title1_medium"
              >
                Save and Print
              </Typography> 
            </Button>
          )}
 
          <Button
            variant="contained"
            sx={{ ...primaryBtn, marginRight: "5px" , width:"140px" }}
            onClick={rePrint}
          >
            <Typography  textTransform={"capitalize"}
                color={"neutral.white"}
                variant="title1_medium">Reprint</Typography>
          </Button>
          </Box>

          {!showBtn && (
            <Button
              variant="contained"
              sx={{...primaryBtnBlue,
                width:'195px',
                display: showButton ? "flex" : "none",
           
                alignItems: 'center',
                justifyContent: 'center'}}
              onClick={refundAndCancel}
            >
              <Typography fontSize={"1.1rem"}>Refund & Cancel</Typography>
            </Button>
          )}

        </Box>
      </Box>
   
      
    </Box>
    
    <div style={{ display: "none" }}>
    <PharmacyInvoice props={printInvoiceData} ref={printRef} />
  </div>
 </> 
  );
};

export default PastBillPharmacyByInvoice;
    