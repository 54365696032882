import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { drTableRow } from "../../style/DoctorListComponent";

const tableCell = { fontSize: "0.775rem", fontWeight: "bold", p: "10px" };

const TotalAppointment = ({ onClose }) => {
  const appointments = [
    {
      srNo: 1,
      date: "09/08/2024 10:10 AM",
      visitTime: "09/08/2024 10:10 AM",
      name: "MAKHAN SINGH",
      patientID: 1841051,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 2,
      date: "09/08/2024 11:05 AM",
      visitTime: "09/08/2024 11:05 AM",
      name: "SEEMA RANI",
      patientID: 2050115,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 3,
      date: "09/08/2024 01:15 PM",
      visitTime: "09/08/2024 01:15 PM",
      name: "MANPREET KAUR",
      patientID: 1100809,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 4,
      date: "09/08/2024 02:20 PM",
      visitTime: "09/08/2024 02:20 PM",
      name: "RAKESH KUMAR",
      patientID: 1203345,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 5,
      date: "09/08/2024 03:30 PM",
      visitTime: "09/08/2024 03:30 PM",
      name: "SUNITA DEVI",
      patientID: 1902234,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 6,
      date: "09/08/2024 04:00 PM",
      visitTime: "09/08/2024 04:00 PM",
      name: "RAJESH SHARMA",
      patientID: 1803245,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 7,
      date: "09/08/2024 04:45 PM",
      visitTime: "09/08/2024 04:45 PM",
      name: "KAVITA SHARMA",
      patientID: 1508976,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 8,
      date: "09/08/2024 05:10 PM",
      visitTime: "09/08/2024 05:10 PM",
      name: "RAHUL VERMA",
      patientID: 1607890,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 9,
      date: "09/08/2024 05:45 PM",
      visitTime: "09/08/2024 05:45 PM",
      name: "ANKIT JAIN",
      patientID: 2104532,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 10,
      date: "09/08/2024 06:30 PM",
      visitTime: "09/08/2024 06:30 PM",
      name: "PRITAM SINGH",
      patientID: 2305647,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 11,
      date: "09/08/2024 07:00 PM",
      visitTime: "09/08/2024 07:00 PM",
      name: "RITU KHANNA",
      patientID: 2408970,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 12,
      date: "09/08/2024 07:30 PM",
      visitTime: "09/08/2024 07:30 PM",
      name: "MOHIT KUMAR",
      patientID: 2502134,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 13,
      date: "09/08/2024 08:00 PM",
      visitTime: "09/08/2024 08:00 PM",
      name: "SUNIL GUPTA",
      patientID: 2607895,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 14,
      date: "09/08/2024 08:30 PM",
      visitTime: "09/08/2024 08:30 PM",
      name: "PREETI RANI",
      patientID: 2708901,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 15,
      date: "09/08/2024 09:00 PM",
      visitTime: "09/08/2024 09:00 PM",
      name: "KIRAN DEVI",
      patientID: 2801234,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 16,
      date: "09/08/2024 09:30 PM",
      visitTime: "09/08/2024 09:30 PM",
      name: "VIKAS SINGH",
      patientID: 2905678,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 17,
      date: "09/08/2024 10:00 PM",
      visitTime: "09/08/2024 10:00 PM",
      name: "ALOK KUMAR",
      patientID: 3002345,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 18,
      date: "09/08/2024 10:30 PM",
      visitTime: "09/08/2024 10:30 PM",
      name: "SANGEETA SHARMA",
      patientID: 3101234,
      doctor: "Dr Bharti Aggarwal",
    },
    {
      srNo: 19,
      date: "09/08/2024 11:00 PM",
      visitTime: "09/08/2024 11:00 PM",
      name: "MANOJ JAIN",
      patientID: 3207896,
      doctor: "Dr Parshant Aggarwal",
    },
    {
      srNo: 20,
      date: "09/08/2024 11:30 PM",
      visitTime: "09/08/2024 11:30 PM",
      name: "RAKHI SINGH",
      patientID: 3309871,
      doctor: "Dr Bharti Aggarwal",
    },
  ];

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body"
          fontWeight={700}
          gutterBottom
          fontSize={"20px"}
        >
          Future Appointments Status
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <TableContainer sx={{ mt: 1 }}>
        <Table aria-label="appointments table">
          <TableHead>
            <TableRow sx={{ ...drTableRow, p: "4px" }}>
              <TableCell sx={tableCell}>Sr. No.</TableCell>
              <TableCell sx={tableCell}>Date</TableCell>
              <TableCell sx={tableCell}>Visit Time</TableCell>
              <TableCell sx={tableCell}>Name</TableCell>
              <TableCell sx={tableCell}>PatientID</TableCell>
              <TableCell sx={tableCell}>Doctor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.map((appointment) => (
              <TableRow sx={{ p: "10px" }} key={appointment.srNo}>
                <TableCell
                  sx={{ p: "5px", fontSize: "0.875rem", fontWeight: 550 }}
                >
                  {appointment.srNo}
                </TableCell>
                <TableCell
                  sx={{ p: "5px", fontSize: "0.875rem", fontWeight: 550 }}
                >
                  {appointment.date}
                </TableCell>
                <TableCell
                  sx={{ p: "5px", fontSize: "0.875rem", color: "#6c757d" }}
                >
                  {appointment.visitTime}
                </TableCell>
                <TableCell
                  sx={{ p: "5px", fontSize: "0.875rem", color: "#6c757d" }}
                >
                  {appointment.name}
                </TableCell>
                <TableCell
                  sx={{ p: "5px", fontSize: "0.875rem", color: "#6c757d" }}
                >
                  {appointment.patientID}
                </TableCell>
                <TableCell
                  sx={{ p: "5px", fontSize: "0.875rem", color: "#6c757d" }}
                >
                  {appointment.doctor}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TotalAppointment;
