import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getupcomingHolidays } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/appointmentSlice";

const UseFetchUpcomingholidays = () => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getupcomingHolidays"],
    queryFn: getupcomingHolidays,

    onError: (error) => {
      console.error("Error in UseFetchDoctorList:", error);
      dispatch(setError(true));
      dispatch(setLoading(false));
    },

    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely

    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseFetchUpcomingholidays;
