import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  keyframes,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField, 
  Typography, 
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import {
  buttonWrapper,
  menuItemStyle,
  primaryBtn,
  tableCellStyle,
  textfieldText,
} from "../../style/AppointmentStyle";
import TextView from "../UI/TextView/TextView";
import { nurseForm } from "../../utils/constant";
import UseFetchOptions from "../../helper/UseFetchOptions";
import UseFetchDoctorList from "../../helper/UseFetchDoctorList";
import { UseFetchSlots } from "../../helper/UseFetchSlots";
import { addOptionInAppointForm } from "../../connections/postApi";
import { textfieldTextPrep } from "../../style/PatientDetailsStyle";
import {
  setAppointmentData,
  setPatientDetailsData,
  setSearchPatient,
} from "../../redux/slices/nurseSlice";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/UseDebounce";
import UsePatientDetail from "../../helper/UsePatientDetail";
import UseSearchPatient from "../../helper/UseSearchPatient";
import { drTableRow } from "../../style/DoctorListComponent";
import { showToast } from "../../utils/toastUtil";
import {
  saveOldPatientAppointment,
  updatePatientAppointment,
} from "../../connections/putApi";
import UseFetchReferDoctor from "../../helper/UseFetchReferDoctor";
import { tableRowLab } from "../../style/LapPageStyle";
import CustomModal from "../CustomModal/CustomModal";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Referrer from "../ModalChildren/Referrer";
import UseFetchAppointmentDetailsPatient from "../../helper/UseFetchAppointmentDetailsPatient";
import { appointmentDetail } from "../../connections/getApi";
import loadingSpinner from '../../assets/loadingSpinner.svg';

const rotateSpinner = keyframes`
0% { transform: rotate(0deg); }
10% { transform: rotate(36deg); }
20% { transform: rotate(72deg); }
30% { transform: rotate(108deg); }
40% { transform: rotate(144deg); }
50% { transform: rotate(180deg); }
60% { transform: rotate(216deg); }
70% { transform: rotate(252deg); }
80% { transform: rotate(288deg); }
90% { transform: rotate(324deg); }
100% { transform: rotate(360deg); }
`;

const PatientForm = () => {
  const [allFormFields, setAllFormFields] = useState(nurseForm);
  const [formState, setFormState] = useState({});
  const [newValues, setNewValues] = useState({});
  const [showNewValueField, setShowNewValueField] = useState({});
  const [fieldOptions, setFieldOptions] = useState({});
  const [openSearchDropDown, setOpenSearchDropDown] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [resetSlot, setResetSlot] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [newSlotOption, setNewSlotOption] = useState({});
  const [errors, setErrors] = useState({});

  const [searchData, setSearchData] = useState({
    q: "", 
    page: 1,
    perPage: 10,
  });

  const {
    nurse: { patientData, patientDetailsData, appointmentData },
  } = useSelector((state) => state);
  // hooks to get data

  const dispatch = useDispatch();
  const { data: allOccupation, refetch: refetchOccupation } =
    UseFetchOptions("occupations");
  const { data: allCities, refetch: refetchCities } = UseFetchOptions("cities");
  const { data: allEducations, refetch: refetchEducation } =
    UseFetchOptions("educations");
  const { data: drQuery, status } = UseFetchDoctorList();
  const { data: allSlots, status: slotStatus } = UseFetchSlots(
    formState?.visitDate || new Date().toISOString().split("T")[0]
  );

  const { data: patientDetailsQuery , isLoading } = UsePatientDetail(patientId);
  const { data: patientAppointmentQuery } = UseFetchAppointmentDetailsPatient(
    patientDetailsQuery?.payload?.appointments[0]?.id
  );
  const { data: searchResult } = UseSearchPatient(searchData);
  const { data: referDr } = UseFetchReferDoctor();

  // Initialize fieldOptions with the initial options from appointmentForm

  useEffect(() => {
    const initialOptions = {};
    allFormFields.forEach((field) => {
      if (field.field === "Autocomplete") {
        initialOptions[field.name] = field.options;
      }
    });
    setFieldOptions(initialOptions);
  }, []);

  // set education occupation and cities option in fields
  useEffect(() => {
    const newReferDr = referDr?.payload?.referrers?.map((refer) => ({
      id: refer?.id,
      title: `${refer?.full_name || ""}${
        refer?.profession ? ` [${refer?.profession}]` : ""
      }${refer?.qualification ? ` [${refer?.qualification}]` : ""}${
        refer?.city ? ` - ${refer?.city}` : ""
      }${refer?.phone ? ` - ${refer?.phone}` : ""}`,
      value: refer?.full_name,
    }));
    const newAllCities = allCities?.cities?.map((el) => ({
      id: el?.id,
      title: el?.name,
      value: el?.name,
    }));
    setFieldOptions((prev) => ({
      ...prev,
      occupation: allOccupation?.occupations,
      education: allEducations?.educations,
      city: newAllCities,
      referrer: newReferDr,
    }));
  }, [allCities, allEducations, allOccupation, referDr]);
  // //set doctor and slot option

  useEffect(() => {
    let updatedFormFields = allFormFields;
    if (slotStatus === "success" && allSlots?.payload?.slots) {
      const formattedSlots = allSlots.payload.slots.map((slot) => ({
        id: slot.id,
        value: slot.id,
        label: slot.time,
      }));

      const slotOptions =
        formattedSlots.length > 0
          ? formattedSlots
          : [{ value: "default", label: "Slot" }]; // Default option if no slots available

      // Update form fields with slot options
      updatedFormFields = updatedFormFields.map((field) =>
        field.name === "slot"
          ? {
              ...field,
              options:
                newSlotOption && Object.keys(newSlotOption).length > 0
                  ? [newSlotOption, ...slotOptions]
                  : slotOptions,
            }
          : field
      );
    }

    // Update doctor options if status is "success" and doctor data is available
    if (status === "success" && drQuery?.doctors) {
      const formattedDrList = drQuery.doctors.map((dr) => ({
        value: dr.id,
        label: dr.full_name,
        id: dr.id,
      }));

      updatedFormFields = updatedFormFields.map((field) => {
        if (
          field.name === "attendingDoctor" ||
          field.name === "consultancyDoctor"
        ) {
          return { ...field, options: formattedDrList };
        }
        return field;
      });
    }
    // Set updated form fields
    setAllFormFields(updatedFormFields);
  }, [allSlots, slotStatus, status, drQuery, resetSlot]); // Combine all dependencies

  const handleFormChange = (event, value, name) => {
    let fieldName;

    if (event) {
      fieldName = event.target.name;
      const { name: eventName, value: eventValue } = event.target;
      setFormState((prevState) => ({
        ...prevState,
        [eventName]: eventValue,
      }));
    } else if (name) {
      if (value?.value === "other") {
        setShowNewValueField((prev) => ({ ...prev, [name]: true }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setShowNewValueField((prev) => ({ ...prev, [name]: false }));
      }
    }
    setErrors((prev) => ({ ...prev, [fieldName]: undefined }));
  };

 
  // handle check box
  // const handleCheckBox = (field) => {
  //   const currentValue = formState[field];
  //   const newValue = currentValue === false ? true : false;

  //   setFormState((prevState) => ({ ...prevState, [field]: newValue }));
  // };

  //add new value to occupation education and cities also post req
  const handleAddNewValue = async (name) => {
    if (newValues[name]) {
      const newValue = newValues[name];

      let newObj = {
        id: `${Math.floor(Math.random() * 90) + 10}${newValue}`,
        value: newValue,
        title: newValue,
      };
      await addOptionInAppointForm({ value: newValue }, name);
      if (name === "occupation") {
        refetchOccupation();
      } else if (name === "education") {
        refetchEducation();
      } else {
        refetchCities();
      }
      setFormState((prevState) => ({
        ...prevState,
        [name]: newObj,
      }));
      // dispatch(setSelectedSlot({ date: newValue, slot: "" }));
      setFieldOptions((prevOptions) => ({
        ...prevOptions,
        [name]: [...(prevOptions[name] || []), newObj],
      }));
      setNewValues((prev) => ({ ...prev, [name]: "" }));
      setShowNewValueField((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleAddOrEditValue = (name, referrer) => {
    console.log(name, referrer);
    referrer = {
      title: referrer.name + " - " + referrer.address + " - " + referrer.phone,
      value: referrer.name,
    };
    setFormState((prevState) => ({
      ...prevState,
      [name]: referrer,
    }));
  };    
   

  const handleModal = (val) => {
    setOpenModal(val);
  };
             
  // search query for patient by name or id
  const debouncedInputValue = useDebounce(inputValue, 1000);
  useEffect(() => {
    if (debouncedInputValue) {
      setSearchData((prev) => ({ ...prev, q: debouncedInputValue }));
    } else {
      dispatch(setSearchPatient([]));
    }
  }, [debouncedInputValue, dispatch]);

  const selectedPatient = (id) => {
    setPatientId(id?.id);
  };      

  useEffect(() => {
    dispatch(setAppointmentData(patientAppointmentQuery));
  }, [patientId, dispatch, patientAppointmentQuery]);

  //  console.log(appointmentData);
  //  console.log(patientDetailsData);
  // console.log(formState);

  useEffect(()=>{
    if(patientDetailsQuery){
    dispatch(setPatientDetailsData(patientDetailsQuery?.payload));
    }
  }, [ dispatch ,patientId, patientDetailsQuery])

  useEffect(() => {
    if(patientDetailsData){
      const firstAppointment = patientDetailsData.appointments?.[0] || {};
      const patientObj = {
        id: patientDetailsData?.id,
        fullName: patientDetailsData?.full_name,
        email: patientDetailsData?.email,
        mobileNumber: patientDetailsData?.mobile,
        maritalStatus: patientDetailsData?.marital_status,
        diet: patientDetailsData?.diet,
        gender: patientDetailsData?.gender,
        age: patientDetailsData?.age,
        attendingDoctor: firstAppointment?.doctor_id,
        consultancyDoctor:
        firstAppointment?.consultant_id,
        visitDate: firstAppointment?.visit_date,
        slot: firstAppointment?.appointment_slot_id,
        occupation: patientDetailsData?.occupation_id,
        education: patientDetailsData?.education_id,
        city: +patientDetailsData?.city,
        address: patientDetailsData?.address,
        pincode: patientDetailsData?.pincode,
        patientType: firstAppointment?.visit_type,

        //appointment details
        // height: patientDetailsQuery?.payload?.appointments[0]?.height,
        // weight: patientDetailsQuery?.payload?.appointments[0]?.weight,
        // fever: patientDetailsQuery?.payload?.appointments[0]?.fever,
        // bps: patientDetailsQuery?.payload?.appointments[0]?.bp_s,
        // bpd: patientDetailsQuery?.payload?.appointments[0]?.bp_d,
        // bmi: patientDetailsQuery?.payload?.appointments[0]?.bmi,
        // smoking: patientDetailsQuery?.payload?.appointments[0]?.smoking,
        // tobacco: patientDetailsQuery?.payload?.appointments[0]?.tobacco,
        // alcohol: patientDetailsQuery?.payload?.appointments[0]?.alcohol,
        // referrer: patientDetailsQuery?.payload?.appointments[0]?.referrer_id,
      };
      // setResetSlot(!resetSlot);
      setErrors(false)
      setFormState((prev) => ({ ...prev, ...patientObj }));
    }
  }, [dispatch, patientDetailsData]);

 
  

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  useEffect(() => {
    if (searchResult?.payload?.patients) {
      dispatch(setSearchPatient(searchResult?.payload?.patients));
    }
  }, [searchResult, dispatch, inputValue]);

  const validateForm = (formData) => {
    let newErrors = {};
    let isValid = true;

    nurseForm.forEach((field) => {
      if (field.pattern) {
        const value = formData[field.name];
        if (!new RegExp(field.pattern).test(value)) {
          newErrors[field.name] = field.errorMessage || "Invalid input";
          isValid = false;
        }
      }
      // Add any other validation rules here
      if (field.required && !formData[field.name]) {
        newErrors[field.name] = `${[field.name]} field is required`;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdatePatient = async () => {
    // console.log(formState);

    if (!validateForm(formState)) {
      return null;
    }

    if (formState?.id) {
      let oldData = {
        patient: {
          uid: formState?.uid,
          full_name: formState?.fullName,
          email: formState?.email,
          mobile: formState?.mobileNumber,
          marital_status: formState?.maritalStatus?.toLowerCase(),
          diet: formState?.diet?.split("-")?.join("_")?.toLowerCase(),
          gender: formState?.gender?.toLowerCase(),
          age: formState?.age,
          occupation_id: formState?.occupation?.id || formState?.occupation || null,
          education_id:  formState?.education?.id || formState?.education || null,
          city: formState.city?.id || formState?.city || null,
          address: formState?.address,
          pincode: formState?.pincode,
        },
      };

      let result = await saveOldPatientAppointment(oldData, formState?.id);
      showToast(`${result?.status?.message || result?.status?.status} `, result?.status?.status);

      if (appointmentData?.id) {
        let data = {
          appointment: {
            //appointment details
            consultant_id: formState?.consultant_id,
            doctor_id: formState?.doctor_id,
            appointment_slot_id: formState?.slot,
            referrer_id: formState?.referrer?.id || formState?.referrer || null,
            state: "green",
            visit_date: formState?.visit_date,
            visit_type: formState?.visit_date,
            height: formState?.height,
            weight: formState?.weight,
            fever: formState?.fever,
            bp_s: formState?.bps,
            bp_d: formState?.bpd,
            bmi: formState?.bmi,
            smoking: formState?.smoking,
            tobacco: formState?.tobacco,
            alcohol: formState?.alcohol,
          },
        };
        let result = await updatePatientAppointment(data, appointmentData?.id);
        showToast(
          ` ${result?.status?.message ||   result?.status?.status} `,
          result?.status?.status
        );
      } else {
        showToast("patient does not have any appointment ", "ERROR");
      }
    } else {
      showToast("select patient", "ERROR");
    }
  };    

  // console.log(formState);
  //  console.log(appointmentData);
  const renderField = (field) => {
    switch (field.field) {
      case "TextField":
        return (
          <TextField
            fullWidth
            type={field.type}
            disabled={field.isDisabled}
            placeholder={field.placeholder}
            sx={field.sx}
            variant={field.variant}
            name={field.name}
            value={formState[field.name] || field.defaultValue || ""}
            onChange={handleFormChange}
            error={Boolean(errors[field.name])}
            helperText={errors[field.name] || ""}
            inputProps={
              field.type === "date"
                ? { max: new Date().toISOString().split("T")[0] }
                : {}
            }
          />
        );
      case "Select":
        return (
          <FormControl fullWidth variant="outlined">
            <Select
              fullWidth
              sx={{
                ...field.sx,
                cursor:
                  formState[field.name] === "slot" ? "not-allowed" : "default",
                color: formState[field.name]
                  ? "text.black"
                  : "text.default_grey",
              }}
              value={formState[field.name] || ""}
              onChange={handleFormChange}
              name={field.name}
              displayEmpty
              required={true}
              disabled={field.isDisabled}
            >
              <MenuItem value="" disabled>
                <span>{field?.placeholder || "Select an option"}</span>
              </MenuItem>
              {field?.options?.map((option) => (
                <MenuItem
                  id={option?.value || "menu"}
                  key={option.value + Math.random()}
                  value={option.value}
                  sx={{
                    backgroundColor:
                      option.value === formState[field.name]
                        ? "#f58633 !important"
                        : "inherit",
                    color:
                      option.value === formState[field.name]
                        ? "white !important"
                        : "black",
                    "&:hover": {
                      backgroundColor:
                        option.value === formState[field.name]
                          ? "#f58633 !important"
                          : "lightgray",
                      color:
                        option.value === formState[field.name]
                          ? "white !important"
                          : "black",
                    },
                    ...(option.value === field.defaultValue
                      ? field.defaultMenuStyle
                      : field.menuItemStyle),
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errors[field.name] && (
              <Typography fontSize={"12px"} color={"#d32f2f"}>
                {errors[field.name]}
              </Typography>
            )}
          </FormControl>
        );
      case "Autocomplete":
        return (
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              options={[
                ...(fieldOptions[field.name] || field.options),
                { id: "other", value: "other", title: "Other" },
              ]}
              disabled={field.isDisabled}
              value={
                (formState[field.name] &&
                  (fieldOptions[field.name] || field.options)?.find(
                    (option) =>
                      option.value ===
                        (formState[field.name]?.value ||
                          formState[field.name]) ||
                      option.title ===
                        (formState[field.name]?.title ||
                          formState[field.name]) ||
                      option.id === formState[field.name]
                  )) ||
                null
              }
              onChange={(event, newValue) =>
                handleFormChange(null, newValue, field.name)
              }
              // onClose={() => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={formState[field.name]}
                  placeholder={field.placeholder}
                  variant={field.variant}
                  fullWidth
                  sx={field.sx}
                />
              )}
              getOptionLabel={(option) =>
                option.title && option.value ? `${option.title}` : ""
              }
              renderOption={(props, option) => (
                <MenuItem
                  sx={menuItemStyle}
                  {...props}
                  key={option.id + Math.random()}
                >
                  {option.title}
                </MenuItem>
              )}
            />
            {showNewValueField[field.name] && (
              <Box mt={1} display="flex" alignItems="center">
                <TextField
                  placeholder={`Add new ${field.name}`}
                  variant="outlined"
                  fullWidth
                  value={newValues[field.name] || ""}
                  onChange={(e) =>
                    setNewValues({ ...newValues, [field.name]: e.target.value })
                  }
                  sx={{
                    ...field.sx,
                    "& .MuiInputBase-root": {
                      backgroundColor: "neutral.lightOrange",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => handleAddNewValue(field.name)}
                        sx={{
                          p: 0,
                          m: 0,
                          backgroundColor: "white",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <AddCircleOutlineRoundedIcon color="white" />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            )}
            {errors[field.name] && (
              <Typography fontSize={"12px"} color={"#d32f2f"}>
                {errors[field.name]}
              </Typography>
            )}
          </FormControl>
        );
      case "Autocomplete1":
        return (
          <>
            {/* {console.log(formState[field.name])} */}
            <Autocomplete
              options={fieldOptions[field.name] || field.options}
              clearIcon={false}
              popupIcon={false}
              getOptionLabel={(option) =>
                option.title && option.value
                  ? `${option.title} - ${option.value}`
                  : ""
              }
              // value={formState[field.name] || ""}
              value={ 
                (formState[field.name] &&
                  (fieldOptions[field.name] || field.options)?.find(
                    (option) =>
                      option.value ===
                        (formState[field.name]?.value ||
                          formState[field.name]) ||
                      option.title ===
                        (formState[field.name]?.title ||
                          formState[field.name]) ||
                      option.id === formState[field.name]
                  )) ||
                  formState[field.name]
              }       
              // value={newValues[field.name] || ""}
              onChange={(event, newValue) =>
                handleFormChange(null, newValue, field.name)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={field.placeholder}
                  variant={field.variant}
                  fullWidth
                  sx={field.sx}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        <IconButton onClick={() => handleModal(true)}>
                          <EditNoteIcon />
                        </IconButton>
                      </>
                    ),
                  }}
                />
              )}
            />

            <CustomModal
              open={openModal}
              onClose={() => setOpenModal(false)}
            >
              <Referrer
                selectedReferrer={formState["referrer"]}
                setOpenModal={setOpenModal}
                onSave={handleAddOrEditValue}
              />
            </CustomModal>
          </>
        );
      default:
        return null;
    }
  };

  
  // console.log(formState,'formState');
  return (
    <Grid container marginBottom={"auto"} lg={4} md={4} sm={5.5} 
    sx={{backgroundColor:"#F7F7FF" ,
      border:'none !important',
      outline:'none !important',
       padding:"30px" , borderTopRightRadius:"20px" , borderBottomRightRadius:"20px" }}
    
    > 
      {/* {"-----Search Patient Information-----"} */}
      <Grid item sm={12}>
        <Autocomplete
          popupIcon={false}
          open={openSearchDropDown}
          clearIcon={false}
          onOpen={() => setOpenSearchDropDown(true)}
          onClose={() => {
            dispatch(setSearchPatient([]));
            setOpenSearchDropDown(false);
          }}
          onChange={(event, newValue) => {
            if (newValue) {
              selectedPatient(newValue);
              setInputValue("");
            }
          }}
          onInputChange={handleInputChange}
          options={patientData || []}
          filterOptions={(options) => options}
          getOptionLabel={(option) =>
            option.uid && option.full_name
              ? `${option.uid} - ${option.full_name}`
              : ""
          }
          renderOption={(props, option) => (
            <MenuItem
              key={option.id + Math.random()}
              // onClick={() => selectedPatient(option)}
              {...props}
              sx={{
                ...menuItemStyle,
                width: "100%",
                "&:hover, &.Mui-focused": {
                  backgroundColor: "#0067FF40 !important",
                  color: "black !important",
                },
               
              }}
            >{`${option.uid} - ${option.full_name}`}</MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params} 
              fullWidth 
              id="search"
              placeholder="Search Patient"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <SearchIcon sx={{ opacity: 0.4 }} color="grey" />

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                ...textfieldTextPrep,
              }}
            />
          )}
        />
      </Grid>
      <Grid  
        item
         sx={{ paddingTop:"25px" }}
      >
        {/* {"-----Patient Form----"}  */}
        <Grid container spacing={1.5}>
          {allFormFields?.slice(0, allFormFields.length - 1)?.map((field) => (
            <Grid item {...field.gridProps} key={field.id}>
              {field.isTitle && (
                <TextView variant="title_medium">{field.isTitle}</TextView>
              )}
              {renderField(field)}
            </Grid>
          ))}
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={8}
          sx={{ marginTop: "3rem", marginBottom: "1rem" }}
        >
          <TextView variant={"title1_medium"}>Conditions</TextView>
        </Grid> */}

        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="text"
              name="height"
              placeholder="Height (Ft 'In)"
              sx={{ ...textfieldText }}
              variant="outlined"
              value={formState.height}
              onChange={handleFormChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              placeholder="Weight"
              type="text"
              name="weight"
              sx={textfieldText}
              variant="outlined"
              value={formState.weight}
              onChange={handleFormChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="text"
              name="fever"
              placeholder="Fever"
              sx={textfieldText}
              variant="outlined"
              value={formState.fever}
              onChange={handleFormChange}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              placeholder="BP S"
              type="text"
              name="bps"
              sx={textfieldText}
              variant="outlined"
              value={formState.bps}
              onChange={handleFormChange}
            />
          </Grid>

          <Grid item xs={12} sm={2.1}>
            <TextField
              fullWidth
              type="text"
              name="bpd"
              placeholder="BP D"
              sx={textfieldText}
              variant="outlined"
              value={formState.bpd}
              onChange={handleFormChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              placeholder="BMi"
              type="text"
              name="bmi"
              sx={textfieldText}
              variant="outlined"
              value={formState.bmi}
              onChange={handleFormChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              alignItems="center"
              mb={2}
              onClick={() => handleCheckBox("smoking")}
            >
              <Typography variant="title1_medium" sx={{ marginRight: -1 }}>
                Smoking
              </Typography>
              <Checkbox name="smoking" checked={formState?.smoking} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              alignItems="center"
              mb={2}
              onClick={() => handleCheckBox("tobacco")}
            >
              <Typography
                variant="title1_medium"
                sx={{ marginRight: -1, fontWeight: "bold" }}
              >
                Tobacco
              </Typography>      
              <Checkbox name="tobacco" checked={formState?.tobacco} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography
                variant="title1_medium"
                sx={{ marginRight: -1, fontWeight: "bold" }}
                onClick={() => handleCheckBox("alcohol")}
              >
                Alcohol
              </Typography>
              <Checkbox name="alcohol" checked={formState?.alcohol} />
            </Box>
          </Grid>
        </Grid> */}

        {/* {"Button For update"} */}
        {/* <Box sx={buttonWrapper}>
          <Button
            onClick={handleUpdatePatient}
            variant="contained"
            sx={primaryBtn}
            disabled={isLoading}
          >
            <Typography variant="small_Text">Update Info</Typography>
          </Button>
        </Box> */}
      </Grid>
 
      {patientDetailsQuery?.payload?.appointments?.length > 0 && (
        <Grid mt={4} item sm={12} sx={{borderRadius:"20px" , backgroundColor:"white" , padding:"20px"}}>
          <Grid item xs={12}>
          <Typography sx={{marginTop:"20px"}}
                variant="heading1"
                // gutterBottom
                fontSize={'18px'}
              >
                Appointments  
                {` ${"  "}: ${patientDetailsQuery?.payload?.appointments?.length}`}
              </Typography>
              <hr style={{opacity:"0.6"}}/>


            <TableContainer sx={{ mt: 2 }}>
              <Table sx={{ overflowY: "scroll" }}>
                <TableHead>
                  <TableRow >
                    <TableCell sx={{ ...tableCellStyle, textAlign: "center" }}>
                      <Typography>Status</Typography>
                    </TableCell>

                    <TableCell sx={{ ...tableCellStyle, textAlign: "center" }}>
                      <Typography>
                        Appointment ID
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ ...tableCellStyle, textAlign: "center" }}>
                      <Typography>
                        Visit Time
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={{ alignSelf: "center" }}>
                  {patientDetailsQuery?.payload?.appointments?.length === 0 && (
                    <TableCell
                      sx={{ textAlign: "center", border: "none" }}
                      rowSpan={3}
                      colSpan={3}
                    >
                      <Typography variant="heading2" color="textSecondary">
                        No Data Found
                      </Typography>
                    </TableCell>
                  )}

                  {patientDetailsQuery?.payload?.appointments?.map(
                    (el, index) => (
                      <TableRow
                        key={el?.id + index}
                        sx={{
                          backgroundColor:'#F7F7FF',
                          paddingY:'6px', 
                           cursor: "pointer",
                          //  "&:hover": {
                          //   //  boxShadow: "rgba(0, 0, 0, 0.25) 3px 3px 6px",
                          //   boxShadow: 'rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset',
                          //    borderRadius:'20px',
                          //  }
                          //  ,
                          //  borderTop: index===0? "2px solid white" : "",
                           borderBottom:'8px solid white',

                         
               
                borderTop: index === 0 ? '2px solid white' : '',
                transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
                '&:hover': {
                  transform: 'scale(1.001)', // Slight scaling for the pop-up effect
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for outer glow
                  borderRadius: '20px !important',
                },

                         }} 
                      >
                        <TableCell
                          sx={{
                            padding: "12px",
                            paddingLeft: "16px",
                            textAlign: "center",
                            borderTopLeftRadius: "20px" ,  borderBottomLeftRadius: "20px",
                          }}
                        >
                          <Typography
                            variant="body_Grey1"
                            color="textSecondary"
                            fontSize={"15px"}
                          >
                            {el?.status}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "4px",
                            paddingLeft: "16px",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="body_Grey1"
                            color="textSecondary"
                            fontSize={"15px"}
                          >
                            {el?.id}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "4px",
                            paddingLeft: "16px",
                            textAlign: "center",
                            borderTopRightRadius: "20px" , 
                            borderBottomRightRadius: "20px",
                          }}
                        >
                          <Typography
                            variant="body_Grey1"
                            color="textSecondary"
                            fontSize={"15px"}
                          >
                            {el?.visit_date + "   "}{" "}
                            {el?.visit_time || (
                              <Typography
                                variant="span"
                                fontSize={"12px"}
                                style={{
                                  borderRadius: "20px",
                                  backgroundColor: "#F0E68C",
                                  padding: "4px",
                                  border: "2px",
                                  marginLeft: "2px",
                                }}
                              >
                                {" "}
                                waiting
                              </Typography>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}

       <Modal
         open={isLoading }
          // open={true}
          //  onClose={}
           sx={{
        border:'none !important',
        outline:'none !important',
        position:"absolute", 
        top:"40%",
        left:"47%",
      
        
      }} 
    >   
         <Box sx={{ animation: `${rotateSpinner} 1s linear infinite`,  width:"50px" , border:'none !important',
        outline:'none !important',}}>
        <img src={loadingSpinner}  alt='' style={{ width:"50px" , height:"50px" ,  border:'none !important',
        outline:'none !important', }}/>  
        </Box>
      
      
    </Modal>
    </Grid>
  );
};

export default PatientForm;
