import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
  Popper,
} from "@mui/material";
import {
  headingStyle,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
 import {
  textfieldTextBlueBorder,
} from "../../style/PatientDetailsStyle";
import AddIcon from "@mui/icons-material/Add";
import {
  textfieldTextBlueBr,
} from "../../style/PharmacyStyle";
import {
  orangeButton,
  primaryBtnBlue,
} from "../../style/PatientDetailsStyle";
import { showToast } from "../../utils/toastUtil";
import { useLocation } from "react-router-dom";
import {
  autoFocusOffWithOutBorder,
  drTableRowBlueBg,
} from "../../style/PatinetFormStyle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UseFetchSupplierList from "../../helper/UseFetchSupplierList";
import UseFetchLabInventories from "../../helper/UseFetchLabInventories";
import UseFetchPackList from "../../helper/UseFetchPackList";
import { putPurchaseOrder } from "../../connections/putApi";
import { menuItemStyle, textfieldTextBlueBorderPharmacy } from "../../style/AppointmentStyle";
import UseGetAllMedicineList from "../../helper/UseGetAllMedicineList";

const EditPurchaseOrder = ({ onClose }) => {
  const location = useLocation();
  const supplierData = location.state || null;

  // console.log(supplierData);
  const { data: medicineList } = UseGetAllMedicineList();
  const { data: supplierList } = UseFetchSupplierList(1,10,'all');
  const { data: labInventoriesList } = UseFetchLabInventories();
  const { data: packList } = UseFetchPackList();

  const [fieldOptions, setFieldOptions] = useState({
    supplierList: JSON.parse(localStorage.getItem("supplierList")) || [],
    labInventoriesList:
      JSON.parse(localStorage.getItem("labInventoriesList")) || [],
    packList: JSON.parse(localStorage.getItem("packList")) || [],
  });
  const [formState, setFormState] = useState({
    id: supplierData?.id || "",
    suppliers: supplierData?.supplier?.id || "",
    purchase_date: supplierData?.created_at?.split(" ")[0] || "",
    bill_type: supplierData?.bill_type || "",
    bill_number: supplierData?.bill_number || "",
    grand_total: supplierData?.grand_total || "",
  });


  const [errors, setErrors] = useState({});
  const [purchaseOrderArr, setPurchaseOrderArr] = useState([
    ...(supplierData?.purchase_items ||
      {
        medicine_id: "",
        medicine_name:'',
        pack: "",
        hsn_code: "",
        batch: "",
        expiry_date: "",
        quantity: "",
        cgst: "",
        sgst: "",
        rate: "",
        mrp: "",
        mfr: "",
        discount: "",
        amount: "",
        department_id: "9",
      }),
  ]);

  const [filteredMedicines, setFilteredMedicines] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openMedicineList, setOpenMedicines] = useState(false);

  useEffect(() => {
    // Simulating fetching all data on component mount and storing it in local storage or state.
    if (
      medicineList?.payload?.medicines &&
      medicineList?.payload?.medicines?.length > 0
    ) {
      localStorage.setItem(
        "allMedicines",
        JSON.stringify(medicineList?.payload?.medicines)
      );
    }      
  }, [medicineList]);


  console.log(supplierData);
  console.log(purchaseOrderArr);
  useEffect(() => {
    if (supplierList?.payload?.suppliers?.length > 0) {
      localStorage.setItem(
        "supplierList",
        JSON.stringify(supplierList.payload.suppliers)
      );
      setFieldOptions((prev) => ({
        ...prev,
        supplierList: supplierList.payload.suppliers,
      }));
    }

    if (labInventoriesList?.payload?.lab_inventories?.length > 0) {
      localStorage.setItem(
        "labInventoriesList",
        JSON.stringify(labInventoriesList.payload.lab_inventories)
      );
      setFieldOptions((prev) => ({
        ...prev,
        labInventoriesList: labInventoriesList.payload.lab_inventories,
      }));
    }

    if (packList?.payload?.packing?.length > 0) {
      localStorage.setItem(
        "packList",
        JSON.stringify(packList.payload.packing)
      );
      setFieldOptions((prev) => ({
        ...prev,
        packList: packList.payload.packing,
      }));
    }
  }, [supplierList, labInventoriesList, packList]);

  //handle form change
  const handleFormChange = (e) => {

    const { name, value } = e.target;
    console.log(name ,value )
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: undefined }));
  };

  const handleAddRow = () => {
    setPurchaseOrderArr((prev) => [
      ...prev, // Spread the existing rows
      {
        medicine_id: "",
        medicine_name:'',
        pack: "",
        hsn_code: "",
        batch: "",
        expiry_date: "",
        quantity: "",
        cgst: "",
        sgst: "",
        rate: "",
        mrp: "",
        mfr: "",
        discount: "",
        amount: "",
        department_id: "9",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    setPurchaseOrderArr(
      (prev) => prev.filter((_, i) => i !== index) // Keep rows that don't match the index
    );
  }; 

  const handleChange = (index, field, value) => {
    // console.log(index, field, value)
    const newPurchaseOrders = [...purchaseOrderArr];
    newPurchaseOrders[index] = { ...newPurchaseOrders[index], [field]: value };
    setPurchaseOrderArr(newPurchaseOrders);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return ""; // Handle invalid date strings
    return date.toISOString().split("T")[0]; // Format as yyyy-MM-dd
  };

  const validateForm = () => {
    const errors = {};
  
    // Check required fields in formState
    if (!formState.suppliers) {
      errors.suppliers = "Supplier is required";
    }
    if (!formState.purchase_date) {
      errors.purchase_date = "Purchase date is required";
    }
    if (!formState.bill_type) {
      errors.bill_type = "Bill type is required";
    }
    if (!formState.bill_number) {
      errors.bill_number = "Bill number is required";
    }
    if (!formState.grand_total || formState.grand_total <= 0) {
      errors.grand_total = "Grand total is required";
    }
  
    // Validate purchaseOrderArr
    // purchaseOrderArr.forEach((item, index) => {
    //   if (!item.hsn_code) {
    //     errors[`hsn_code_${index}`] = "HSN Code is required";
    //   }
    //   if (!item.batch) {
    //     errors[`batch_${index}`] = "Batch is required";
    //   }
    //   if (!item.expiry_date) {
    //     errors[`expiry_date_${index}`] = "Expiry date is required";
    //   }
    //   if (!item.quantity || item.quantity <= 0) {
    //     errors[`quantity_${index}`] = "Quantity must be greater than 0";
    //   }
    //   if (!item.rate || item.rate <= 0) {
    //     errors[`rate_${index}`] = "Rate must be greater than 0";
    //   }
    // });
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  
  const handleSearch = (event) => {
    // console.log(event.target.value);
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    const storedMedicines =
      JSON.parse(localStorage.getItem("allMedicines")) || [];

    // Filter medicines based on search query
    const filteredMedicineList = storedMedicines.filter((medicine) =>
      medicine[1].toLowerCase().startsWith(query)
    );

    // Limit the results to top 10 and sort
    const limitedResults = filteredMedicineList.slice(0, 10);

    setFilteredMedicines(limitedResults);
  };



  const handleUpdateOrder =    async (e) => {
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

      e.preventDefault();
      const payload = {
        purchase_order: {
          ...formState,
          department_id: "9",
          ...(purchaseOrderArr.length > 0 && {
            purchase_items_attributes: purchaseOrderArr,
          }),
        },
      };
      try {
        const result = await putPurchaseOrder(
          payload,
          supplierData?.id
        );
        if (result?.status?.status === "SUCCESS") {
          showToast("Purchase Order Updated Successfully" ,"SUCCESS");
          // navigate("/admin/purchase_orders");
        }else{
          showToast(result?.status?.message ||  result?.status?.status  , result?.status?.status  );
        }
      } catch (error) {
        showToast(error.message , 'ERROR');
      }

  }
  
  //  console.log(fieldOptions)
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "90vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "80vh",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            mb="10px"
          >
            <Box></Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BorderColorIcon
                sx={{
                  color: "neutral.navyBlue",
                  marginBottom: "4px",
                  marginRight: "8px",
                }}
              />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={headingStyle}
              >
                Edit Purchase Order
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Autocomplete
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                    borderRadius: "15px",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiInputBase-input": {
                    padding: "2px 8px",
                  },
                  "& .MuiAutocomplete-inputRoot": {
                    padding: "2px 8px", // Remove extra padding around input
                  },
                  // padding:'0px',
                  borderRadius: "15px",
                  backgroundColor: "#F7F7FF",
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  },
                }}
                options={fieldOptions?.supplierList || []}
                getOptionLabel={(option) => option.name || ""}
                value={
                  fieldOptions?.supplierList?.find(
                    (supplier) => supplier.id === formState.suppliers
                  ) || null
                }
                onChange={(e, newValue) => {
                  handleFormChange({
                    target: {
                      name: "suppliers",
                      value: newValue ? newValue.id : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Supplier"
                    error={!!errors.suppliers}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              {errors.suppliers && (
                <Typography color="error">{errors.suppliers}</Typography>
              )}
            </Grid>

            <Grid item sm={2}>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  required
                  onChange={handleFormChange}
                  value={
                    formState?.purchase_date
                      ? formatDate(formState.purchase_date) // Ensure correct format
                      : new Date().toISOString().split("T")[0] // Default to today
                  }              
                  type="date"
                  name="purchase_date"
                  sx={autoFocusOffWithOutBorder}
                  // label="purchase Details"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="end">
                  //       <img src={calender_icon} alt="" style={{ width: 20, height: 20 }} /> {/* Your custom SVG */}
                  //     </InputAdornment>
                  //   ),
                  //   // Hide the default calendar icon
                  //   inputProps: {
                  //     style: { appearance: 'none', WebkitAppearance: 'none', MozAppearance: 'none' },  // Hide default icon
                  //   }
                  // }}
                  placeholder="Purchase Details"
                  error={!!errors.purchase_date}
                  helperText={errors.purchase_date}
                  inputProps={{ max: new Date().toISOString().split("T")[0] }}
                />
              </FormControl>
            </Grid>

            <Grid item sm={2}>
              <FormControl fullWidth variant="outlined">
                <Select
                  error={!!errors.billType}
                  required
                  fullWidth

                  sx={{
                    borderRadius:"15px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Remove border
                      borderRadius: "15px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Remove border on hover
                      borderRadius: "15px",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none", // Ensure border remains none on focus
                      borderRadius: "15px",
                    },
                    "& .MuiSelect-select": {
                      padding: "9px",
                      borderRadius: "15px",
                      backgroundColor: "#F7F7FF",
                    }, 
                    "&:hover": {
                      boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                    },
                  }}
                  value={formState?.bill_type}
                  onChange={handleFormChange}
                  name="bill_type"
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "text.default_grey" }}>
                          Bill Type
                        </span>
                      );
                    }
                    return selected === "credit"
                      ? "Credit"
                      : selected === "cash"
                      ? "Cash"
                      : "";
                  }}
                >
                  <MenuItem value="credit">Credit</MenuItem>
                  <MenuItem value="cash">Cash</MenuItem>
                  {/* <MenuItem value="other">Other</MenuItem> */}
                </Select>
                {errors.bill_type && (
                  <Typography color="error">{errors.bill_type}</Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                // label="Bill number"
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                onChange={handleFormChange}
                value={formState.bill_number}
                name="bill_number"
                placeholder="Bill number"
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.bill_number}
                helperText={errors.bill_number}
              />
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={handleFormChange}
                value={formState?.grand_total}
                name="grand_total"
                placeholder="Grand Total"
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.grand_total}
                helperText={errors.grand_total}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}> 
            <TableContainer sx={{ mt: 2, width: "100%" }}>
              <Table sx={{ width: "100%" }}>
                <TableHead stickyHeader>
                  <TableRow sx={{ ...drTableRowBlueBg, textAlign: "center" }}>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        borderTopLeftRadius: "20px",
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                        width: "120px",
                      }}
                    >
                       Medicine
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        //  paddingX:"4px",
                        textAlign: "center",
                        width: "120px",
                      }}
                    >
                      pack
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        paddingRight: "30px",
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      HSC code
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Batch
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Expiry
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      CGST
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      SGST
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Rate
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      MRP
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      MFR
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Discount
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: "neutral.white",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Department
                    </TableCell>

                    <TableCell
                      sx={{
                        p: "4px",
                        textAlign: "center",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <IconButton
                        onClick={handleAddRow}
                        sx={{
                          ...orangeButton,
                          marginLeft: "7px",
                          marginY: "2px",
                        }}
                      >
                        <AddIcon
                          sx={{
                            color: "neutral.white",
                            width: "30px",
                            height: "32px",
                            padding: "0px",
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {purchaseOrderArr?.length> 0 ? (
                  <TableBody>
                    {purchaseOrderArr?.map((item, index) => (
                        
                      <TableRow sx={{ padding: "0px", margin: "0px" }}>
                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                            minWidth: "200px",
                          }}
                        >
                           <Autocomplete
                      options={filteredMedicines ||[]}
                      freeSolo
                      clearOnEscape={false}
                      open={openMedicineList === index}
                      onOpen={() => setOpenMedicines(index)}
                      onClose={() => setOpenMedicines(false)}
                      id={`medicine_name-${index}`}
                      inputValue={item?.medicine_name || ""}
                     
                      onInputChange={(e, value) => {
                        handleChange(index, "medicine_name", value, e);
                      }}
                      
                      onChange={(e, value) => {
                        // console.log(value);
                        if (value) {
                          handleChange(index, "medicine_name", value[1]);
                          handleChange(index, "medicine_id", value[0]);
                        }
                      }}
                      sx={textfieldTextBlueBorderPharmacy}
                      PopperComponent={(props) => (
                        <Grid item lg={8}>
                          <Popper
                            {...props}
                            placement="bottom-start"
                            style={menuItemStyle}
                            modifiers={[
                              {
                                name: "preventOverflow",
                                options: {
                                  boundary: "block",
                                },
                              },
                            ]}
                          >
                            <div
                              style={{
                                maxHeight: 300,
                                overflowY: "auto",
                                scrollbarWidth: "none",
                                maxWidth: "320px",
                                minWidth: "310px",
                              }}
                            >
                              {props.children}
                            </div>
                          </Popper>
                        </Grid>
                      )}
                      getOptionLabel={(option) => option[1]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleSearch}
                          value={searchTerm}
                          placeholder="Medicine"
                          // onKeyDown={(e) => handleKeyDown(e, index, "name")}
                        />
                      )}
                   
                      renderOption={(props, option, { index }) => (
                        <MenuItem
                          sx={{ 
                            ...menuItemStyle, 
                            "&:hover": {
                              backgroundColor: "#0067FF40 !important",
                              // borderRadius:"20%",
                              borderRadius:"16px",
                              color: "black !important",
                            },
                            ...(index === 0 && {
                              backgroundColor: "#0067FF40 !important",
                              color: "black !important",
                              borderRadius:"16px",
                            }),
                          }}
                          {...props}
                          key={option[0]}
                        >
                          {option[1]}
                        </MenuItem>
                      )}
                    />
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                            minWidth: "120px",
                          }}
                        >
                          <Autocomplete
                               clearOnEscape={false}
                              clearIcon={null}
                              sx={{...textfieldTextBlueBorder , "& .MuiOutlinedInput-root": {
                                borderRadius:'10px',  height: "40px",
                                padding: "9px !important",
                                "& input": {
                                  padding: "8px",
                                  fontSize: "14px",
                                },
                                "& .MuiAutocomplete-popupIndicator": {
                                  display: "none", // Hides the dropdown icon
                                },
                              }}}
                            options={
                              fieldOptions?.packList?.filter(
                                (option) => option
                              ) || []
                            } // Filter out invalid entries
                            getOptionLabel={(option) => option || ""} // Use the string value directly
                            inputValue={item?.pack || ""} 
                            onInputChange={(e, value) => {
                              handleChange(index, "pack", value, e); // Update the form state on input change
                            }}
                            onChange={(e, value) => {
                              if (value) {
                                // Assuming `value` is a string directly
                                // console.log(value)
                                handleChange(index, "pack", value);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                             
                                placeholder="Pack"
                                error={!!errors.suppliers}
                              />
                            )}
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option}>
                                {option}
                              </MenuItem>
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            } // Compare values directly
                          />
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                            placeholder="HSN code"
                            value={item?.hsn_code}
                            onChange={(e) => {
                              handleChange(index, "hsn_code", e.target.value );
                            }}
                            sx={textfieldTextBlueBr}
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                            placeholder="Batch"
                            sx={textfieldTextBlueBr}
                            value={item?.batch}
                            onChange={(e) => {
                              handleChange(index, "batch", e.target.value );
                            }}
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField

                            placeholder="EXP"
                            sx={textfieldTextBlueBr}
                            value={item?.expiry_date}
                            // label="Expiry date"
                            // InputLabelProps={{
                            //   shrink: true,
                            // }}
                            onChange={(e) => {
                              handleChange(index, "expiry_date", e.target.value );
                            }}
                            type="date"
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                          value={item?.quantity}
                          onChange={(e) => {
                            handleChange(index, "quantity", e.target.value );
                          }}
                            placeholder="Quantity"
                            sx={textfieldTextBlueBr}
                            type="number"
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                          value={item?.cgst}
                          onChange={(e) => {
                            handleChange(index, "cgst", e.target.value );
                          }}
                            placeholder="CGST"
                            sx={textfieldTextBlueBr}
                            type="number"
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                           value={item?.sgst}
                           onChange={(e) => {
                             handleChange(index, "sgst", e.target.value );
                           }}
                            placeholder="SGST"
                            sx={textfieldTextBlueBr}
                            type="number"
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                           value={item?.rate}
                           onChange={(e) => {
                             handleChange(index, "rate", e.target.value );
                           }}
                            placeholder="Rate"
                            sx={textfieldTextBlueBr}
                            type="number"
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                           value={item?.mrp}
                           onChange={(e) => {
                             handleChange(index, "mrp", e.target.value );
                           }}
                            placeholder="MRP"
                            sx={textfieldTextBlueBr}
                            type="number"
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                           value={item?.mfr}
                           onChange={(e) => {
                             handleChange(index, "mfr", e.target.value );
                           }}
                            placeholder="MFR"
                            sx={textfieldTextBlueBr}
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField        
                          value={item?.discount}
                          onChange={(e) => {
                            handleChange(index, "discount", e.target.value );
                          }}
                            placeholder="Discount"
                            sx={textfieldTextBlueBr}
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                           value={item?.amount}
                           onChange={(e) => {
                             handleChange(index, "amount", e.target.value );
                           }}
                            placeholder="Amount"
                            sx={textfieldTextBlueBr}
                            type="number"
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <TextField
                           value={item?.department_id}
                           inputProps={{ readOnly: true }}   
                           onChange={(e) => {
                             handleChange(index, "department_id", e.target.value );
                           }}
                            placeholder="Department"
                            sx={textfieldTextBlueBr}
                          ></TextField>
                        </TableCell>

                        <TableCell
                          variant="body1_text_bold"
                          sx={{
                            borderTopLeftRadius: "20px",
                            color: "neutral.white",
                            p: "4px",
                            textAlign: "center",
                          }}
                        >
                          <IconButton
                            sx={{ marginLeft: "8px", marginBottom: "7px" }}
                            onClick={() => handleDeleteRow(index)}
                          >
                            <CloseIcon
                              sx={{
                                ...xBtn,
                                width: "28px",
                                height: "28px",
                                borderRadius: "20%",
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableRow> 
                    <TableCell   sx={{
                    textAlign: "center",
                  }}
                  colSpan={15}>
                     <Typography color={"black"} variant="heading2">
                    Data Not Found
                  </Typography> </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Box>

        <Box
          container
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ zIndex: "10" }}
          lg={12}
          marginBottom={"5px"}
        >
          <Grid item></Grid>

          <Grid item>
            <Button
            onClick={handleUpdateOrder}
              className="btn draw-border"
              variant="contained"
              id="submit"
              sx={{
                ...primaryBtnBlue,
                width: "195px",
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
              //   disabled={isLoading}
            >
              <Typography
                textTransform={"capitalize"}
                color={"neutral.white"}
                variant="title1_medium"
              >
                Update Order
              </Typography>
            </Button>
          </Grid>
        </Box>
      </Box>
      {/* <div style={{ display: "none" }}>
        <PharmacyInvoice props={printInvoiceData} ref={printRef} />
      </div> */}
    </>
  );
};

export default EditPurchaseOrder;
