import { Box } from "@mui/material";
import React from "react";
import PatientDetails from "../Components/Prescription/PatientDetails";
import DoctorDetail from "../Components/Prescription/DoctorDetail";
import { prescriptionWrapper } from "../style/PatientReportStyle";

const Prescription = () => {
  return (
    <Box sx={prescriptionWrapper}>
      <PatientDetails />
      <DoctorDetail /> 
    </Box>     
  );
}; 

export default Prescription;
