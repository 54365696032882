import { useQuery } from "@tanstack/react-query";
import { useDispatch} from "react-redux";
import { getAllTreatmentShortCodeList } from "../connections/getApi";
import { setError, setLoading} from "../redux/slices/prescriptionSlice";

const UseGetTreatmentShortCodeList = () => {
  
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getAllTreatmentShortCodeList"],
    queryFn: getAllTreatmentShortCodeList,
    // enabled: !!,
    onError: (error) => {
      console.error("Error in UseFetch treatment List:", error);
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSuccess: (data) => {
      console.log("Successfully fetched treatment list:", data);
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseGetTreatmentShortCodeList;
