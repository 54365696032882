import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patientData: [],
  data: [],
  printData: [],
  isLoading: false,
  isError: false,
  inVoiceData: [],
  renderInvoice: false,
  patientId: null,
  medicalTest: [],
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setSearchPatient: (state, action) => {
      state.patientData = action.payload;
    }, 
    setData: (state, action) => {
      state.data = action.payload;
    },
    setPatientId: (state, action) => {
      state.patientId = action.payload;
    },
    setPrintData: (state, action) => {
      state.printData = action.payload;
    },
    setPatientInvoice: (state, action) => {
      state.inVoiceData = action.payload;
    },
    setRenderInvoice: (state, action) => {
      state.renderInvoice = !state.renderInvoice;
    },
    setMedicalTestData: (state, action) => {
      state.medicalTest = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setSearchPatient,
  setData,
  setPrintData,
  setPatientInvoice,
  setRenderInvoice,
  setPatientId,
  setMedicalTestData,
} = billingSlice.actions;
export default billingSlice.reducer;
