export const Neutral = {
  main: "#FF8D1E", 
  black: "black",
  white: "white",
  greyLight: "#f8f9fa",
  grey1: "#e4e4e4",
  tablet_head: "rgba(31, 32, 34, 0.1)",
  table_row_lab: "rgba(235, 235, 235, 0.3)",
  table_row_primary: "#F7F7FF",
  disableBG: "rgba(204, 204, 204, 0.1)",
  lightOrange: "#FFE4C4",
  red: "#FF392B",
  blue: "#349ef3",
  brown: "#ed5564",
  beige: "beige",
  green2: "#b7ce63",
  blue1: "#64b5f6", 
  olive: "#b7ce63",
  green1: "#58c77f",
  red1: "#de648a",
  crimson: "crimson",
  green: "green",
  textDanger: "#ed5564 ",
  orange:'rgba(255, 141, 30, 1)',
  navyBlue: '#0067FF',
  blueShadeBg:'#F7F7FF',
};

export const CypherMD_Text = {
  black: "#3A3A3A",
  white: "white",
  grey: "#6c757d",
  grey1: "#6c757",
  grey3: "#1F2022",
  lightBlue1: "#336cfb",
  textFieldGreyBG: "#ebebeb",
  default_grey: "#b0b0b0",
  green1: "#A5C13D",
  tomato: "#f1592a",
};
