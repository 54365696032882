import { ThemeProvider } from "@emotion/react";
import "./App.css";
import ALLRoute from "./routing/Route";
import theme from "./theme/theme";
import { Provider } from "react-redux";
import store from "./redux/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastNotifier } from "./utils/toastUtil";
import { ToastContainer } from "react-toastify";
import { BaseUrlProvider } from "./connections/connectionUrl";

const queryClient = new QueryClient();
function App() {
  return (
    <Provider store={store}>
    <BaseUrlProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ALLRoute />
          <ToastNotifier />
          <ToastContainer />
        </ThemeProvider>
      </QueryClientProvider>
      </BaseUrlProvider>
    </Provider>
  ); 
}

export default App;
