export const loginWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "90vh",
};

export const LoginForm = {
  mt: 1,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

export const loginBtn = {
  mt: 3,
  mb: 2,
  backgroundColor: "neutral.main",
  "&:hover": {
    backgroundColor: "neutral.main",
    color: "neutral.black",
  },
};
