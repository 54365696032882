import {
  loginStart,
  loginSuccess,
  loginFailure,
} from "../redux/slices/authSlice";
import { BASE_URL } from "./connectionUrl"; 
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const authStaging = Cookies.get("authStaging")
  ? JSON.parse(Cookies.get("authStaging"))
  : null;
const token = authStaging?.token || "";
 
//appointment starts
export const addOptionInAppointForm = async (option, type) => {
  console.log(token);
  const url = `${BASE_URL}/api/v1/master/resource/${type}`;
  try {
    const res = await fetch(url, {
      method: "POST", 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(option),
    });

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Fetching  failed:", error);
    return error;
  }
};

export const savePatientAppointment = async (appointment) => {
  const url = `${BASE_URL}/api/v1/appointments`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(appointment),
    });

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Fetching available slots failed:", error);
    return error;
  }
};
export const createAppointment = async (appointment) => {
  const url = `${BASE_URL}/api/v1/patients`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(appointment),
    });

    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const addReminders = async (Data) => {
  const url = `${BASE_URL}/api/v1/reminders`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(Data),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

//login  starts
export const loginUser = (credentials) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const response = await fetch(
      `${BASE_URL}/api/v1/accounts/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      }
    );

    const data = await response.json();
    if (data.status.code === "001" && data.status.status === "SUCCESS") {
      const { uid, token, redirect , first_name, last_name, email, mobile } = data.payload;
      dispatch(
        loginSuccess({ user: uid, token, redirect, first_name, last_name, email, mobile, isAuthenticated: true })
      );
    } else {
      dispatch(loginFailure("Invalid Credentials"));
    }
  } catch (error) {
    dispatch(loginFailure(error.message + " "));
  }
};

export const checkAuthStatus = () => (dispatch) => {
  const authStaging = Cookies.get("authStaging")
    ? JSON.parse(Cookies.get("authStaging"))
    : null;
  if (authStaging && authStaging.isAuthenticated) {
    dispatch(loginSuccess(authStaging));
  }
};

// prescription Starts here
export const createPatient = async (Data) => {
  const url = `${BASE_URL}/api/v1/patients`; // create patient
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(Data),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const sendReferrerMail = async (data, referrerId, patientId) => {
  const url = `${BASE_URL}/api/v1/prescriptions/send_referrer_mail?referrer_id=${referrerId}&patient_id=${patientId}`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(data),
    });
    const result = await res.json();
    return result;
  } catch (error) {
    return error;
  } 
};

export const medicalTestReports = async (data) => {
  const url = `${BASE_URL}/api/v1/medical_test_reports`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(data),
    });
    const result = await res.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const createAndUpdatePrescription = async (payload) => {
  const url = `${BASE_URL}/api/v1/prescriptions`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const result = await res.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const createReferrer = async (payload) => {
  const url = `${BASE_URL}/api/v1/referrers`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const result = await res.json();
    return result;
  } catch (error) {
    return error;
  }
};



/// billing post apis

export const saveAndPrintTestData = async (payload) => {
  const url = `${BASE_URL}/api/v1/appointments/invoice`; // create patient
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const postAdmission = async (payload) => {
  const url = `${BASE_URL}/api/v1/admissions`; // create patient
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const updateMedicalTest = async (payload) => {
  const url = `${BASE_URL}/api/v1/medical_test_reports/investigations`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;   
  }
};
export const rescheduleAppointmentData = async (payload, id) => {
  const url = `${BASE_URL}/api/v1/appointments/${id}/reschedule`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};
export const NewAppointmentPrint = async (payload, id) => {
  const url = `${BASE_URL}/api/v1/appointments/${id}/invoice`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};
// lap post api

export const postMedicalTestReport = async (payload) => {
  const url = `${BASE_URL}/api/v1/medical_test_reports`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};
export const postPrintLabTest = async (payload) => {
  const { id, mobile, paid_date, TestIdS } = payload;
  const url = `${BASE_URL}/api/v1/medical_test_reports/print?patient_id=${id}&mobile=${mobile}&paid_date=${paid_date}&test_ids=${TestIdS}`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const postMedicalReport = async (payload, report_id) => {
  // const endpoint = report_id
  //   ? `/api/v1/medical_test_reports?id=${report_id}`
  //   : "/api/v1/medical_test_reports";
  const url = `${BASE_URL}/api/v1/medical_test_reports`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

//pharmacy post api

export const generateBill = async (payload) => {
  const url = `${BASE_URL}/api/v1/pharmacy/generate_bill`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error; 
  }      
};

export const generatePurchaseOrder = async (payload) => {
  const url = `${BASE_URL}/api/v1/pharmacy/purchase_orders`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }      
};

export const generateSupplier = async (payload) => {
  const url = `${BASE_URL}/api/v1/pharmacy/suppliers`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }      
};


export const generateMedicine= async (payload) => {
  const url = `${BASE_URL}/api/v1/pharmacy/medicines`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }      
};
