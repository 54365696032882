import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  Tab,
  Tabs,
  TextField,
  IconButton,
} from "@mui/material";
import { drTableRow } from "../../style/DrApotComponentStyle";
import { primaryBtn } from "../../style/AppointmentStyle";
import { textfieldText } from "../../style/AppointmentStyle";
import updownar from '../../assets/updownar.svg'
import ReportPrint from "./ReportPrint";
import {
  collectBtn,
  headingStyle,
  labPageButton,
  startBtn,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import "react-toastify/dist/ReactToastify.css";
import { postMarkStatus } from "../../connections/getApi";
import { postMedicalReport } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import { deleteLabReport } from "../../connections/deleteApi";
import { primaryBtnBlue } from "../../style/PatientDetailsStyle";
import { compose } from "@reduxjs/toolkit";

const AddNewReport = ({
  patientData = {},
  onClose,
  report = [],
  handlePreview,
  refetchGetPatientPrevReport,
}) => {
  const [selectedDate, setSelectedDate] = useState(Object.keys(report)[0]);
  const [showPrintPreview, setShowPrintPreview] = useState(false);

  const [cellStates, setCellStates] = useState({});
  const [reportValue, setReportValue] = useState("");
  const [reportValueComp, setReportValueComp] = useState("");

  const handlePrint = () => {
    setShowPrintPreview(true);
  };
  const handleAction = async (reportIndex, subIndex, item) => {
    const cellKey = `${selectedDate}-${reportIndex}-${subIndex}`;
    setCellStates((prevStates) => ({
      ...prevStates,
      [cellKey]: prevStates[cellKey] === "start" ? "input" : "start",
    }));
    const payload = {
      status: "inprocess",
      medical_test_id: item?.medical_test_id,
      invoice_item_id: item?.invoice_item_id,
      patient_id: item?.patient_id,
    };
  
    const res=  await postMarkStatus(payload);
    const patient = {
      id: item.patient_id,
      mobile: item.mobile,
      testDate: item?.created_date,
    };
     if(res?.status?.status==="ERROR"){showToast(res?.status?.message || res?.status?.status,  res?.status?.status) }
    handlePreview(patient);
  };

  const handleCollectSample = async (reportIndex, subIndex, item) => {
    const cellKey = `${selectedDate}-${reportIndex}-${subIndex}`;
    setCellStates((prevStates) => ({
      ...prevStates,
      [cellKey]: "collect",
    }));
    const payload = {
      status: "collection",
      medical_test_id: item?.medical_test_id,
      invoice_item_id: item?.invoice_item_id,
      patient_id: item?.patient_id,
    };

    await postMarkStatus(payload);
    const patient = {
      id: item.patient_id,
      mobile: item.mobile,
      testDate: item?.created_date,
    };
    handlePreview(patient);
  };

  const handlePostMedicalReportValue = async (item, index , ) => {
    const payload = {
      medical_test_report: {
        patient_id: item?.patient_id,
        medical_test_id: item?.medical_test_id,
        invoice_item_id: item?.invoice_item_id,
        value: reportValue[index],
      },
      // id: item?.report_id,
      ...(item?.report_id && { id: item.report_id }), 
    };

    if (item.value !== reportValue[index] && reportValue[index] !== undefined) {
      const result = await postMedicalReport(payload, item?.report_id);
      //  console.log(result);
      const patient = {
        id: item.patient_id,
        mobile: item.mobile,
        testDate: item?.created_date,
      };
      handlePreview(patient);
      setReportValue("");
      showToast(result?.status?.message || result?.status?.status , result?.status?.status);
    } else {
      // showToast("Something Wrong", "ERROR");
      return;
    }
  };

  const handlePostMedicalReportValueComp = async (item, index) => {

    const payload = {
      medical_test_report: {
        patient_id: item?.patient_id, 
        medical_test_id: item?.medical_test_id,
        invoice_item_id: item?.invoice_item_id, 
        value: reportValueComp[index],
        composite_parent_test_id: item?.composite_parent_test_id,
      },
      // id: item?.report_id,
      ...(item?.medical_test_report_id && { id: item?.medical_test_report_id }), 
    };


    if (
      item.value !== reportValueComp[index] &&
      reportValueComp[index] !== undefined
    ) {
      const result = await postMedicalReport(payload, item?.report_id);
      // console.log(result);
      const patient = {
        id: item.patient_id,
        mobile: item.mobile,
        testDate: item?.created_date,
      };
      handlePreview(patient); 
      setReportValueComp("");  
      showToast(result?.status?.message || result?.status?.status , result?.status?.status);
    } else {
      return;
      // showToast("Something Wrong", "ERROR");
    }
  };

  const handleDeleteReportItem = async (item) => {
    try {
      await deleteLabReport(item?.invoice_item_id);
      const patient = {
        id: item.patient_id,
        mobile: item.mobile,
        testDate: item?.created_date,
      };
      handlePreview(patient);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (event, newValue) => {
    setSelectedDate(newValue);
  };

  const reportsForSelectedDate = report[selectedDate] || [];

  if (showPrintPreview) {
    return (
      <ReportPrint
        onClose={onClose}
        patientDataReport={{ patientData, report }}
        handleAction={handleAction}
        handleDeleteReportItem={handleDeleteReportItem}
        handleCollectSample={handleCollectSample}
        reportValue={reportValue}
        setReportValue={setReportValue}
        handlePostMedicalReportValue={handlePostMedicalReportValue}
        selectedDate={selectedDate}
      />
    );
  }
  
  // console.log(reportValueComp , 'reportValueComp')
  // console.log(reportsForSelectedDate , 'reportsForSelectedDate')

  return (
    <Box sx={{ width: "80vw" }}>  
     <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={-0.5}>
      <Box></Box>
              
      
              <Box sx={{display:'flex' , alignItems:'center' , gap:"8px"}}>
              <ContentPasteIcon sx={{width:'28px' , color:'#0067FF'}}/>
                <Typography
                  variant="heading1" 
                  fontWeight={600}
                  gutterBottom
                  sx={{...headingStyle }}
        
                >
                Add New Reports
                </Typography>
                </Box>
        
      
      
              <IconButton 
             onClick={onClose}
              >
                <CloseIcon  sx={{ color:'red'}}size="small" color="red" />
              </IconButton>
            </Box>
      <Grid 
        container
        sx={{ justifyContent: "space-between", width: "100%", mt: "30px" }}
      >
        <Typography>Name: {patientData?.name || "-"}</Typography>
        <Typography>Patient ID: {patientData?.uid || "-"}</Typography>
        <Typography>Mobile: {patientData?.mobile || "-"}</Typography>
        <Typography>Age :{patientData?.age || "-"}</Typography>
        <Typography>{patientData?.gender || "-"}</Typography>
      </Grid>

      <Tabs
        value={selectedDate}
        onChange={handleDateChange}
        textColor="neutral.navyBlue"
        indicatorColor="neutral.navyBlue"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          bgcolor: "background.paper",
          "& .MuiTabs-indicator": {
            backgroundColor: "neutral.navyBlue",
          },
        }}
      >
        {Object.keys(report).map((date) => (
          <Tab
            key={date}
            value={date}
            label={date}
            sx={{
              fontSize: "1rem", 
              color: "neutral.navyBlue",
              mt: 2,
              "&.Mui-selected": {
                color: "neutral.navyBlue",
              },
            }}
          />
        ))}
      </Tabs>

   
      <Grid container sm={12} md={12} marginBottom={"auto"} lg={12}>
        <Grid item xs={12}>
          <TableContainer sx={{ mt: 2, width: "100%" }}>
            <Table sx={{ width: "100%" }}>
              <TableHead stickyHeader>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "14px",
                      p: "6px",
                      paddingLeft: "15px",
                    }}
                  >
                    Name 
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "14px", p: "6px" }}
                  >
                    Amount 
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "14px",  p: "6px" }}
                  >
                    Generated Date 
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "14px" ,p: "6px" }}
                  >
                    Critical Range 
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "14px",  p: "6px" }}
                  >
                    Min-Max Range 
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "14px",  p: "6px" }}
                  >
                    Value/Action 
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportsForSelectedDate?.map((report, index) => (              
                  <React.Fragment key={index}>
                    <TableRow sx={{...tableRowLab , borderTop:'2px solid white' , borderBottom:'8px solid white' , 
                            "&:hover": {
                              transform: "scale(1.001)", // Slight scaling for the pop-up effect
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                              borderRadius: "20px !important",
                            }
                    }}>
                      <TableCell sx={{ padding: "2px", paddingLeft: "15px"  , borderTopLeftRadius:"20px" , borderBottomLeftRadius:'20px' }}>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            padding: "0px",
                          }}
                        >
                          {report?.name}
                        </Typography>
                        <Typography variant="body_Grey">
                          {report?.code}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "0px", paddingLeft: "15px" }}>
                        {report?.amount}
                      </TableCell>
                      {!report?.composites && (
                        <>
                          <TableCell
                            sx={{ padding: "0px", paddingLeft: "15px" }}
                          >
                            {report?.created_date}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "0px", paddingLeft: "15px" }}
                          >
                            <Typography
                              variant="body_Grey"
                              sx={{ fontWeight: "bold", padding: "0px" }}
                            >
                              {report?.critical_range !== undefined
                                ? report?.critical_range
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ padding: "0px", paddingLeft: "15px" }}
                          >
                            <Typography
                              variant="body_Grey"
                              sx={{ fontWeight: "bold", padding: "0px" }}
                            >
                              {patientData?.gender === "Female"
                                ? "Female Min-Max:"
                                : "Male Min-Max:"}
                            </Typography> 
                            {report?.min_max_range}
                          </TableCell>

                          <TableCell width={"190px"} sx={{ padding: "8px" ,  borderTopRightRadius:"20px" , borderBottomRightRadius:'20px' }}>
                            {report?.value === "Sample collecting" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Button
                                  sx={{
                                    ...collectBtn,
                                    // paddingY: "2px",
                                    fontSize:"12px",
                                    paddingX: "14px",
                                  }}
                                  onClick={() =>
                                    handleAction(index, index, report)
                                  }
                                >
                                  Start
                                </Button>
                                <IconButton
                                  onClick={() => handleDeleteReportItem(report)}
                                >
                                  <CloseIcon
                                    sx={{
                                      ...xBtn,
                                      paddingY: "0px",
                                      paddingX: "2px",
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            )}
                            {report?.value === null && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Button
                                  sx={{
                                    ...collectBtn,
                                    // paddingY: "2px",
                                    paddingX: "6px",
                                  }}
                                  onClick={() =>
                                    handleCollectSample(index, index, report)
                                  }
                                >
                                  Collect Sample
                                </Button>
                                <IconButton
                                  onClick={() => handleDeleteReportItem(report)}
                                >
                                  <CloseIcon
                                    sx={{
                                      ...xBtn,
                                      paddingY: "0px",
                                      paddingX: "2px",
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            )}
                            {report?.value === "Processing" && (
                              <TextField
                                placeholder="Enter value"
                                // type="number"
                                // defaultValue={report?.value}
                                sx={{ ...textfieldText }}
                                variant="outlined"
                                name="value"
                                // value={reportValue[index]}
                                onChange={(e) => {
                                  setReportValue((prev) => ({
                                    ...prev,
                                    [index]: e.target.value,
                                  }));
                                }}
                                
                                onBlur={() =>
                                  handlePostMedicalReportValue(report, index)
                                }
                              />
                            )}
                            {report?.value !== "Processing" &&
                              report?.value !== null &&
                              report?.value !== "Sample collecting" && (
                                <TextField
                                  placeholder="Enter value"
                                  // type="number"
                                  defaultValue={report?.value}
                                  sx={{ ...textfieldText }}
                                  variant="outlined"
                                  name="value"
                                  value={reportValue[index]}
                                  onChange={(e) => {
                                    setReportValue((prev) => ({
                                      ...prev,
                                      [index]: e.target.value,
                                    }));
                                  }}
                                  onBlur={() =>
                                    handlePostMedicalReportValue(report, index)
                                  }
                                />
                              )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>

                    {/* Render subcategories if they exist */}
                    {report?.composites &&
                      report?.composites?.map((subReport, subIndex) => (
                        <TableRow key={`${index}-${subIndex}`} sx={{...tableRowLab , borderTop:'2px solid white' , borderBottom:'8px solid white' ,
                          "&:hover": {
                            transform: "scale(1.001)", // Slight scaling for the pop-up effect
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                            borderRadius: "20px !important",
                          }
                        }}>
                          <TableCell
                            sx={{ padding: "0px", paddingLeft: "40px" , borderTopLeftRadius:"20px" , borderBottomLeftRadius:"20px" }}
                          >
                            <Typography variant="body_Grey">
                              {subReport?.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ padding: "0px", paddingLeft: "16px" }}
                          >
                            {/* {report?.amount} */}
                          </TableCell>
                          <TableCell sx={{ padding: "0px" }}>
                            {subReport?.generated_date}
                          </TableCell>
                          <TableCell sx={{ padding: "0px" , textAlign:'center'}}>
                            <Typography
                              variant="body_Grey"
                              sx={{ fontWeight: "bold" }}
                            >
                              {subReport?.critical_range}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ padding: "0px", paddingLeft: "15px" }}
                          >
                            <Typography
                              variant="body_Grey1"
                              sx={{  padding: "0px" }}
                            >
                              {patientData?.gender === "Female"
                                ? "Female Min-Max:"
                                : "Male Min-Max:"}
                            </Typography>
                            {report?.min_max_range}
                          </TableCell>
                          {/* ...{console.log(subReport?.value , subIndex)} */}
                          <TableCell width={"190px"} sx={{ padding: "8px"  , borderTopRightRadius:"20px" , borderBottomRightRadius:"20px" }}>
                            <TextField
                              placeholder="Enter value"
                              // type="number"
                              sx={{ ...textfieldText }}
                              variant="outlined"
                              name="value"
                              defaultValue={( subReport?.value !== null && subReport?.value !== "Processing"    )? subReport?.value : ""}
                              //  value={reportValueComp[`${index}-${subIndex}`] || ''}
                              //  value={subReport?.value  || ''}
                              onChange={(e) => {
                                setReportValueComp((prev) => ({
                                  ...prev,
                                  [`${index}-${subIndex}`]: e.target.value,
                                  // [subIndex]: e.target.value,
                                }));
                                
                              }}
                              onBlur={() =>
                                handlePostMedicalReportValueComp(
                                  subReport,
                                 `${index}-${subIndex}`
                                )
                              }
                            />
                          </TableCell>
                          
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>

            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ mt: 3 }}
        lg={12}
      >
        <Grid item>
         
        </Grid>

        <Grid item>
          <Button variant="contained" sx={primaryBtnBlue} onClick={handlePrint}>
            <Typography fontSize={"1.1rem"}>Preview</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
 
export default AddNewReport;

