import React, { useRef } from "react";
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";

const ReportPrint = React.forwardRef((props, ref) => {
  // const { printData } = props;

  const {
    billing: { printData },
  } = useSelector((state) => state);
  // console.log(printData);
  // const { invoices } = printData;
  // console.log(printData, "invoice");
  if (!printData || !printData.invoices) {
    return null;
  }
  if (!printData || !printData.invoices) {
    // If printData is still loading or unavailable, show a message or a loader
    return (
      <div
        style={{
          padding: "20px",
          maxWidth: "800px",
          margin: "0 auto",
          // display: "none",
          "@media print": {
            display: "block",
          },
        }}
        ref={ref}
      >
        <p>{""}</p> {/* Placeholder while loading */}
      </div>
    );
  }
  return (
    <Box
      ref={ref}
      sx={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        display: "none",
        "@media print": {
          display: printData?.invoices && "block",
        },
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        fontFamily={"Outfit"}
        marginTop={"-5px"}
      >
        {/* Punjab Rheumatology & Immunology Centre */}
        {printData?.invoices[0]?.organization?.name}
      </Typography>
      <Typography align="center" variant="subtitle1">
        <span style={{ display: "block", marginTop: "-5px", fontSize: "14px" }}>
          {" "}
          {/* B-35-922/2/1, Ferozpur Road Near MBD Mall */}
          {printData?.invoices[0]?.organization?.address}
        </span>
        <span style={{ display: "block", marginTop: "-5px", fontSize: "14px" }}>
          {printData?.invoices[0]?.organization?.pincode}{" "}
          {printData?.invoices[0]?.organization?.city}
        </span>

        <span style={{ display: "block", marginTop: "-5px", fontSize: "14px" }}>
          {/* +91 98787-36644 */}

          {printData?.invoices[0]?.organization?.contact_number}
        </span>
      </Typography>
      <Typography
        sx={{ textDecoration: "underline", marginTop: "5px" }}
        align="center"
        alignSelf="center"
      >
        Invoice
      </Typography>

      {/* Invoice Information */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          border: "0.5px solid",
          borderColor: "lightgray",
          p: 1,
          pl: 2,
          pr: 2,
        }}
      >
        <Box width="40$">
          <Typography>Receipt ID: {printData?.invoices[0]?.uid}</Typography>
        </Box>
        <Box>
          <Typography>
            {printData?.invoices[0]?.status === "generated"
              ? new Date()
                  .toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                  .replace(/\//g, "-")
                  .replace(", ", " ")
              : printData?.invoices[0]?.generated_at}
          </Typography>
        </Box>
      </Box>

      {/* Patient Information */}
      <Box sx={{ marginTop: "10px", p: 2, pt: 1 }}>
        {printData?.invoices && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {printData?.invoices[0]?.patient_id && (
              <Typography fontFamily={"Outfit"}>
                Patient ID: {printData?.invoices[0]?.patient_uid}
                {/* Patient ID: {appointmentState?.uid} */}
              </Typography>
            )}
            <Typography fontFamily={"Outfit"}>
              Mobile Number: {printData?.invoices[0]?.mobile}
            </Typography>
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {printData?.invoices && (
            <Typography fontWeight={"bold"}>
              {printData?.invoices[0]?.name}
            </Typography>
          )}
          {/* <Typography>Appointment: 19 Sep, 2024 19:40 PM</Typography> */}
        </Stack>
        <Typography>
          {printData?.invoices[0]?.age && `${printData?.invoices[0]?.age}  | `}
          {printData?.invoices[0]?.gender &&
            `${printData?.invoices[0]?.gender} | `}
          {printData?.invoices[0]?.patient_address &&
            `${printData?.invoices[0]?.patient_address}`}
        </Typography>
      </Box>
      {/* <Typography fontWeight={"bold"} align="center"> 
        Visit type:{" "} 
      </Typography> */}
      {/* Charges Table */}

      <Table
        sx={{
          marginTop: "10px",
          width: "100%",
          border: "1px solid",
          borderColor: "lightgrey",
          borderCollapse: "collapse",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ p: "4px", pb: "0px" }}>
              <strong>Charges Desc</strong>
            </TableCell>
            <TableCell sx={{ p: "4px", pb: "0px" }}>
              <strong>MRP</strong>
            </TableCell>
            <TableCell sx={{ p: "4px", pb: "0px" }}>
              <strong>Discount</strong>
            </TableCell>
            <TableCell sx={{ p: "4px", pb: "0px" }}>
              <strong>Net</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <TableRow> */}
          {printData?.invoices &&
            printData?.invoices[0]?.content?.map((el, index) => (
              <TableRow>
                <TableCell sx={{ p: "4px" }}>
                  {el?.specialist_name
                    ? `${el?.specialist_name} [${el?.specialist_type}]`
                    : el?.name}
                </TableCell>
                <TableCell sx={{ p: "4px" }}>Rs. {el?.amount}</TableCell>
                <TableCell sx={{ p: "4px" }}>
                  Rs. {el?.discount || 0.0}
                </TableCell>
                <TableCell sx={{ p: "4px" }}>
                  Rs.{el?.amount - (el?.discount || 0)}
                </TableCell>
              </TableRow>
            ))}

          {/* </TableRow> */}
          {printData?.invoices && (
            <TableRow>
              <TableCell sx={{ p: "4px" }}>
                <strong>Net Payable Amount</strong>
              </TableCell>
              <TableCell sx={{ p: "4px" }}>
                Rs. {printData?.invoices[0]?.grand_total}
              </TableCell>
              <TableCell sx={{ p: "4px" }}>
                Rs.{printData?.invoices[0]?.discount || 0}
              </TableCell>
              <TableCell sx={{ p: "4px" }}>
                <strong>
                  Rs.{" "}
                  {Math.abs(
                    (printData?.invoices[0]?.grand_total || 0) -
                      (printData?.invoices[0]?.discount || 0)
                  )}{" "}
                  /-
                </strong>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* <div
        style={{
          height: "1in", // This adds the margin-top equivalent for page 2+
          breakBefore: "page", // Force a page break before this
        }}
      ></div> */}
    </Box>
  );
});

export default ReportPrint;
