import { Box } from "@mui/material";
import React from "react";
import NewAppointment from "../Components/Appointment/NewAppointment";
import SlotList from "../Components/Appointment/SlotList";
import DoctorsList from "../Components/Appointment/DoctorsList";
import { appointmentWrapper } from "../style/AppointmentStyle";

const Appointment = () => {
  return (
    <Box sx={appointmentWrapper}>
      <NewAppointment />
      <SlotList />
      <DoctorsList />
    </Box>
  );
};

export default Appointment;
