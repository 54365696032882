import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Skeleton,
  Chip, 
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { selectFieldPrep, slotChip } from "../../../style/PatientDetailsStyle";
import { menuItemStyle } from "../../../style/AppointmentStyle";
import { useDispatch, useSelector } from "react-redux";
import { TreatmentList } from "../../../connections/getApi";
import { selectedTreatment } from "../../../connections/getApi";
import { setPrescriptionData } from "../../../redux/slices/prescriptionSlice";
import { getSplInst } from "../../../connections/getApi";
import { autoFocusOff } from "../../../style/PatientsInfoStyle";
import UseGetTreatmentShortCodeList from "../../../helper/UseGetTreatmentShortCodeList";
import UseGetSelectTreatementId from "../../../helper/UseGetSelectTreatementId";

const TreatmentDetail = ({ setCommonData }) => {
  const prescriptionData = useSelector(
    (state) => state.prescription.prescriptionData
  );

  // const dispatch = useDispatch();
  //  console.log(prescriptionData)

  const [treatments, setTreatments] = useState([]);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState(null);
  const [data, setData] = useState({
    diagnosis: "",
    notes: "",                 
    selfNotes: "",             
    splInst: "", 
    medicalTests:"",
  });
  const [medicines, setMedicines]=useState([]);
  // const [selectedChips, setSelectedChips] = useState([]); 

  // Fetch Treatment List on Component Mount
  useEffect(() => {
    const fetchTreatments = async () => {
      const treatmentListData = await TreatmentList();
      if (treatmentListData) {
        setTreatments(treatmentListData?.treatments);
      }
    };
    fetchTreatments();
  }, []);

  // console.log(prescriptionData);

  useEffect(() => {
    // Update form data with prescriptionData on load
    if (prescriptionData) {
      setData({
        diagnosis: prescriptionData?.diagnosis_list || "",
        notes: prescriptionData?.notes || "",
        selfNotes: prescriptionData?.self_notes || "",
        splInst: prescriptionData?.special_instructions || "",
        medicalTests : prescriptionData?.medical_tests_list|| "", 
      });
    }
  }, [prescriptionData]);

  // Fetch Treatment details based on selected ID

 const{ data : treatmentDetails , isLoading  }= UseGetSelectTreatementId(selectedTreatmentId)
//  console.log(treatmentDetails);
  useEffect(() => {
    if (selectedTreatmentId) {
      const fetchSelectedTreatment = async () => {
        //  const treatmentDetails = await selectedTreatment(selectedTreatmentId);
        if (treatmentDetails) {
          setData({
            diagnosis: treatmentDetails?.diagnosis  || "",
            notes: treatmentDetails?.self_notes || "",
            selfNotes: treatmentDetails?.self_notes || "",
            splInst:  treatmentDetails?.special_instructions|| "",
            medicalTests: treatmentDetails?.medical_tests,
          });
          setMedicines(treatmentDetails?.medicines)
        }      
      };                
      fetchSelectedTreatment();
    }
  }, [selectedTreatmentId , treatmentDetails]);

  const handleTreatmentChange = (event) => {
    const selectedId = event.target.value;
    setSelectedTreatmentId(selectedId);
  };

  const handleInputChange = (field) => (event) => {
    setData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  // for handle splINSt change
  // const handleChangeSplInst = async (chip) => {

  //   let res = await getSplInst(chip);
  //   if (res) {
  //     setData((prevData) => ({
  //       ...prevData,
  //       splInst: data.splInst + " " + (res?.value || ""),
  //     }));
  //   }
  // };
 
  const {data : treatmentShortCodeList } = UseGetTreatmentShortCodeList()
  // console.log(treatmentShortCodeList);

  // console.log(isLoading);

  const handleChangeSplInst = async (chip) => {
    let res = await getSplInst(chip);
    if (res) {
      const newInstruction = res.value || "";
  
      // Split the current splInst by comma and trim whitespace from each entry
      const currentInstructions = data.splInst.split(",").map(inst => inst.trim()).filter(Boolean); // filter(Boolean) removes empty strings
  
      // Check if the new instruction already exists
      if (!currentInstructions.includes(newInstruction)) {
        // Add the new instruction to the array
        currentInstructions.push(newInstruction);
  
        // Join the array back into a string with commas
        const updatedInstructions = currentInstructions.join(", ");
        
        setData((prevData) => ({
          ...prevData,
          splInst: updatedInstructions,
        }));
      }
    }
  };
   
  
  useEffect(() => {
    setCommonData((prev) => ({
      ...prev,
      treatmentDetail: data,
      medicines : medicines,           
      treatments,
    }));

  }, [treatments, data , medicines]);

 
  return (
    <Box p={2} sx={{backgroundColor :"#F7F7FF"}}>
      <Box
        sx={{ p: 0, ml: -1 ,  mt:1 , mb:-2.5 , marginRight:'0px'}}
        
        display={"flex"} 
        alignItems={"center"} 
        justifyContent={"space-between"}     
      >
        <Grid p={0} item xs={12} sm={6} md={3}>            
          <FormControl fullWidth variant="outlined">
            <Select
              sx={{...selectFieldPrep }}
              value={selectedTreatmentId || "default"}
              onChange={handleTreatmentChange}
              displayEmpty
            >
              <MenuItem value="default" disabled>
                Select Treatment
              </MenuItem>
              {treatments?.map(([treatmentName, id]) => (
                <MenuItem key={id} value={id} sx={{...menuItemStyle , '& :hover' :{
                color:'black',
                }
                }}>
                  {treatmentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid textAlign={"end"} item md={5}>
          {true && (
            <Typography color={"neutral.black"} variant="heading2_14">
              {/* Dr Bharti Aggarwal */}
              {prescriptionData?.appointment?.doctor?.full_name
                ? `Consultancy : ${prescriptionData?.appointment?.doctor?.full_name}`
                : " "}
            </Typography>
          )}
        </Grid>
      </Box>       

      <Grid
      //  alignItems={"center"}
        sx={{p: 0}} container spacing={1} mt={4.5} >
        <Grid item md={1.5} mt={1}>
          <Typography variant="body1_text_bold" color={"neutral.black"} fontWeight={'420'} >
             Diagnosis
          </Typography>
        </Grid>
                     
        <Grid item md={10.5}  mt={-1}>
        {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%" 
          height={60}
          sx={{backgroundColor: 'lightgray' , borderRadius:'15px'}}
          />) :(
          <TextField 
            variant="outlined"
            value={data.diagnosis}
            onChange={handleInputChange("diagnosis")}
            fullWidth
            placeholder="Diagnosis"
            type="text"   
            multiline 
            rows={2}
            sx={{
              borderRadius:'15px', 
               "& fieldset": {
                  border: "none", 
              },
                "& .MuiOutlinedInput-root": {
                  fontFamily: 'Outfit',
                marginTop:'6px',
                  // padding: "0px 11px", // Adjust internal padding
                  padding: "6px 10px",
                  borderRadius: "15px",
                  mt:'15px',
                  mb:'15px',
                  backgroundColor: 'white',
                  transition: "box-shadow 0.3s ease, border-color 0.3s ease",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // Consistent border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // Consistent on hover
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // No change in border color on focus
                },
                "& .MuiInputBase-input": {
                  padding: "0px 4px", // Input text padding
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em', // Focus shadow
                },
                "& fieldset": {
                  border: "none", // Remove default border
                },
                "&:hover fieldset": {
                  border: "none", // Keep border removed on hover
                },
           // Apply outer border radius for the component
              }}
          />
          )}
        </Grid>
      </Grid>
        
   
      <Grid 
      // alignItems={"center"}  
      sx={{ p: 0 }} container spacing={2} mt={1}>
        <Grid item md={1.5} mt={1}>
          <Typography variant="body1_text_bold" color={"neutral.black"} fontWeight={'420'}>
            Notes
          </Typography>
        </Grid>
        <Grid item md={10.5} mt={-1}>
        {isLoading ? (
        <Skeleton    
          variant="rectangular"
          width="100%"
          height={60}
          sx={{backgroundColor: 'lightgray'  , borderRadius:'15px'}}
          />) :(
          <TextField
           multiline
           rows={2}
           sx={{
            borderRadius:'15px', 
             "& fieldset": {
                border: "none", 
            },
              "& .MuiOutlinedInput-root": {
                fontFamily: 'Outfit',
              marginTop:'6px',
                // padding: "0px 11px", // Adjust internal padding
                padding: "6px 10px",
                borderRadius: "15px",
                mt:'15px',
                mb:'15px',
                backgroundColor: 'white',
                transition: "box-shadow 0.3s ease, border-color 0.3s ease",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc", // Consistent border color
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc", // Consistent on hover
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc", // No change in border color on focus
              },
              "& .MuiInputBase-input": {
                padding: "0px 4px", // Input text padding
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em', // Focus shadow
              },
              "& fieldset": {
                border: "none", // Remove default border
              },
              "&:hover fieldset": {
                border: "none", // Keep border removed on hover
              },
         // Apply outer border radius for the component
            }}
            value={data.notes}
            onChange={handleInputChange("notes")}
            fullWidth
            placeholder="Notes"
            type="text"
          />
          )}
        </Grid> 
      </Grid>

      <Grid 
      // alignItems={"center"} 
      sx={{p: 0}} container spacing={2} mt={1}>
        <Grid item md={1.5} mt={1}>
          <Typography variant="body1_text_bold" color={"neutral.black"}>
            Self Notes 
          </Typography>
        </Grid>
          
        <Grid item md={10.5} mt={-1}>
        {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={60}
          sx={{backgroundColor: 'lightgray', borderRadius:'15px'}}
          />) :(
          <TextField
            multiline
            rows={2}
            sx={{
              borderRadius:'15px', 
               "& fieldset": {
                  border: "none", 
              },
                "& .MuiOutlinedInput-root": {
                  fontFamily: 'Outfit',
                marginTop:'6px',
                  // padding: "0px 11px", // Adjust internal padding
                  padding: "6px 10px",
                  borderRadius: "15px",
                  mt:'15px',
                  mb:'15px',
                  backgroundColor: 'white',
                  transition: "box-shadow 0.3s ease, border-color 0.3s ease",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // Consistent border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // Consistent on hover
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // No change in border color on focus
                },
                "& .MuiInputBase-input": {
                  padding: "0px 4px", // Input text padding
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em', // Focus shadow
                },
                "& fieldset": {
                  border: "none", // Remove default border
                },
                "&:hover fieldset": {
                  border: "none", // Keep border removed on hover
                },
           // Apply outer border radius for the component
              }}
            value={data.selfNotes}
            onChange={handleInputChange("selfNotes")}
            fullWidth
            placeholder="Self Notes"
            type="text"
          />
          )}
        </Grid>
        
      </Grid>
        

      <Grid 
      // alignItems={"center"}
       sx={{ p: 0}} container spacing={2}  mt={1}>
        
        <Grid item md={1.5} mt={3}>
          <Typography
            variant="body1_text_bold"
            color={"neutral.black"}
            sx={{ mr: "2px" }}
          >
            Special Instructions 
          </Typography>
        </Grid>
        <Grid item md={10.5} mt={-1}>
          
{treatmentShortCodeList?.length > 0 &&
  treatmentShortCodeList.map((shortCode) =>
    shortCode[1] ? (        
      <Chip
        onClick={() => handleChangeSplInst(shortCode[1])}
        // color={"text.tomato"}
      
        sx={{...slotChip, mr: "5px", cursor: "pointer" }}  
        key={shortCode[0]} // Ensure unique key is provided, assuming shortCode[0] is unique
        label={shortCode[1]}
        clickable
         variant="outlined"
         color="primary"
      >
        {shortCode[1]}
      </Chip>
    ) : null // Return null if shortCode[1] is falsy
  )
}
       {isLoading ? (     
        <Skeleton   
          variant="rectangular"
          width="100%"
          height={60}
          sx={{backgroundColor: 'lightgray' ,borderRadius:'15px' }}
          />) :(  
          <TextField
            multiline
            rows={2}
            // sx={{...autoFocusOff , backgroundColor :"white" , marginTop:'6px',  borderRadius:'15px',                   "& fieldset": {
            //   border: "none", // Remove default border
            // },
            // "&:hover fieldset": {
            //   border: "none", // Remove border on hover
            // }, }}
             sx={{
              borderRadius:'15px', 
               "& fieldset": {
                  border: "none", 
              },
                "& .MuiOutlinedInput-root": {
                  fontFamily: 'Outfit',
                marginTop:'6px',
                  // padding: "0px 11px", // Adjust internal padding
                  padding: "6px 10px",
                  borderRadius: "15px",
                  mt:'15px',
                  mb:'15px',
                  backgroundColor: 'white',
                  transition: "box-shadow 0.3s ease, border-color 0.3s ease",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // Consistent border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // Consistent on hover
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // No change in border color on focus
                },
                "& .MuiInputBase-input": {
                  padding: "0px 4px", // Input text padding
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em', // Focus shadow
                },
                "& fieldset": {
                  border: "none", // Remove default border
                },
                "&:hover fieldset": {
                  border: "none", // Keep border removed on hover
                },
           // Apply outer border radius for the component
              }}
            value={data.splInst}
            onChange={handleInputChange("splInst")}
            fullWidth
            placeholder="Spl Inst."
            type="text"
          />
        )}
        </Grid>      
      </Grid>
        
    </Box>
  );
};

export default TreatmentDetail;
