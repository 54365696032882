import { useQuery } from "@tanstack/react-query";

import { getLabPatientsReportData } from "../connections/getApi";
const UseGetLaPatientData = (testDate) => {
  return useQuery({
    queryKey: ["getLabPatientsReportData", testDate],
    queryFn: () => getLabPatientsReportData(testDate),
    // enabled: !!testDate,
    staleTime: 0, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseGetLaPatientData;
