import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import {
  headingStyle,
  startBtn,
  tableHeadCell,
  tableRowLab,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { drTableRow } from "../../style/DoctorListComponent";
import { DShapeSaveButton, textfieldText } from "../../style/AppointmentStyle";
import { postMedicalTestReport } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import UseGetPendingLabReport from "../../helper/UseGetPendingLabReport";
import pending_reports  from '../../assets/pending_reports.svg'
import updownar from '../../assets/updownar.svg'
import DoneIcon from '@mui/icons-material/Done';

const PendingReport = ({ onClose }) => {
  const [testValue, setTestValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => prevPage + direction);
  };
          
  const { data: reports , refetch: pendingTestRefetch } =  UseGetPendingLabReport(currentPage,perPage);
  // console.log(reports)

  const [patients, setPatients] = useState(reports?.payload?.invoice_items || []);
  const totalPages = Math.ceil(reports?.meta?.total_count / perPage);    

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const resData = await pendingTestRefetch(currentPage, perPage);
        setPatients(resData?.data?.payload?.invoice_items || []);
        // console.log(resData?.data, 'resdata')
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPatients(); 
  }, [currentPage, perPage, pendingTestRefetch]);

  const handleSaveMedicalReport = async (item) => {
    const payload = {
      medical_test_report: {
        medical_test_id: item?.invoice_itemable_id,
        patient_id: item?.patient_id,
        value: testValue,
        invoice_item_id: item?.id,
      },      
    };

    try {
      await postMedicalTestReport(payload);
      setTestValue("");
      // const resData = await pendingTestRefetch();
      const resData = await pendingTestRefetch(currentPage, perPage);
      console.log(resData?.data?.payload?.invoice_items);
      setPatients(resData?.data?.payload?.invoice_items || []);
      showToast("Report value saved", "SUCCESS");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box p={2}>


      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={-0.5}>
      

      <Box></Box>
              
      
              <Box sx={{display:'flex' , alignItems:'center' , gap:"8px"}}>
              <img src={pending_reports}  alt =" " sx={{width:'28px'}}/>
                <Typography
                  variant="heading1" 
                  fontWeight={600}
                  gutterBottom
                  sx={{...headingStyle }}
        
                >
                 {` Pending Reports ( ${reports?.meta?.total_count || ""}  ) `}
                </Typography>
                </Box>
        
      
      
              <IconButton 
             onClick={onClose}
              >
                <CloseIcon  sx={{ color:'red'}}size="small" color="red" />
              </IconButton>
            </Box>



      <TableContainer>
        <Table> 
          <TableHead>
            <TableRow >
              <TableCell
                sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}
              > 
                Name
                
              </TableCell>
              <TableCell
                sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}
              >
                Patient     
              </TableCell>
              <TableCell
                 sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}
              >
                Category     
              </TableCell>
              <TableCell
                sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}
              >
                Payment Date     
              </TableCell>
              <TableCell
                sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}
              >
                Critical     
              </TableCell>
              <TableCell
                 sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}
              >
                Min-Max Range     
              </TableCell>
              <TableCell
                sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}
              >
                Value/ Action     
              </TableCell> 
            </TableRow> 
          </TableHead>

          <TableBody sx={{ textAlign: "start" }}>
            {patients?.length === 0 && (
              <TableCell
                rowSpan={3}
                colSpan={7}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                No Data
              </TableCell>
            )}
            {patients?.map((patient, index) => (
              <TableRow key={index} sx={{...tableRowLab , borderTop: '2px solid white' , borderBottom:"8px solid white" ,
                "&:hover": {
                  transform: "scale(1.001)", // Slight scaling for the pop-up effect
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                  borderRadius: "20px !important",
                }
              }}>
                <TableCell sx={{ p: "8px", textAlign: "center" , borderTopLeftRadius:"20px" , borderBottomLeftRadius:"20px" }}>
                  <Typography
                    sx={{  
                      fontSize: "15px",
                      fontWeight: "bold",
                      padding: "0px",
                    }}
                  >
                    {patient?.name}
                  </Typography>
                  <Typography variant="body_Grey1">{patient.code}</Typography>
                </TableCell>
 
                <TableCell sx={{ p: "8px", textAlign: "center"  }}>
                  <Typography variant="body_Grey1" color={"neutral.navyBlue"}>
                    {patient?.patient_name}
                  </Typography>
                  <br />
                  <Typography variant="body2_text">
                    {patient?.patient_uid}
                  </Typography>
                </TableCell>

                <TableCell sx={{ p: "8px", textAlign: "center"  }}>
                  <Typography variant="body_Grey1">
                    {patient?.category}
                  </Typography>
                </TableCell>

                <TableCell sx={{ p: "8px", textAlign: "center"  }}>
                  <Typography variant="body_Grey1">
                    {patient?.payment_date}
                  </Typography>
                </TableCell>

                <TableCell sx={{ p: "8px", textAlign: "center"  }}>
                  <Typography variant="body_Grey1">
                    {patient?.critical_range || "-"}
                  </Typography>
                </TableCell>

                <TableCell sx={{ p: "8px", textAlign: "center"  }}>
                  <Typography variant="body_Grey1">
                    Female: {patient?.female_min}-{patient?.female_max}
                  </Typography>
                  <br />
                  <Typography variant="body_Grey1">
                    Male: {patient?.male_min}-{patient?.male_max}
                  </Typography>
                </TableCell>

                <TableCell sx={{ p: "8px", textAlign: "center"  , borderBottomRightRadius:"20px" , borderTopRightRadius:"20px" }}>
                  <TextField
                    placeholder="value"
                    type="number"
                    sx={{
                      ...textfieldText,
                      margin: "0px",
                      width: {
                        base: "100%",
                        md: "40%",
                      },
                      
                      "& .MuiOutlinedInput-root": {
                        borderRadius:'15px',
                        borderTopRightRadius:"0px",
                      borderBottomRightRadius:"0px",
                        height: "40px",
                        padding: "9px !important",
                        "& input": {
                          padding: "4px",
                          fontSize: "14px",
                        },
                      },
                    
                    }}
                    variant="outlined"
                    name="valueAction"
                    value={patient.valueAction}
                    onChange={(e) => setTestValue(e.target.value)}
                  />
                  <Button
                    onClick={() => handleSaveMedicalReport(patient)}
                    sx={{ ...DShapeSaveButton }}
                  >
                    < DoneIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        mt={2}
        gap={1}
        justifyContent="end"
        display="flex"
        alignItems="center"
      >
        <Button
          startIcon={<ArrowBackIcon sx={{ fontSize: "14px !important" }} />}
          sx={{
            fontSize: "12px",
            textTransform: "capitalize",
            border: currentPage === 1 ? "none" : "1px solid rgba(0, 0, 0, 0.2)",
            color: currentPage === 1 ? "grey" : "#336cfb",
          }}
          disabled={currentPage === 1}
          onClick={() => handlePageChange(-1)}
        >
          Previous
        </Button>

        <Typography>{`${currentPage} / ${totalPages || ""}`}</Typography>

        <Button
          endIcon={<ArrowForwardIcon sx={{ fontSize: "14px !important" }} />}
          sx={{
            fontSize: "12px",
            textTransform: "capitalize",
            border:
              currentPage === totalPages
                ? "none"
                : "1px solid rgba(0, 0, 0, 0.2)",
            color: currentPage === totalPages ? "grey" : "#336cfb",
          }}
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(1)}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default PendingReport;
