import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  Popper,
  InputAdornment,
  Modal,
} from "@mui/material";
import {
  headingStyle,
  labPageButton,
  tableCellLab,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  dateFiled,
  menuItemStyle,
  primaryBtn,
  selectField,
  textfieldText,
  textfieldTextBlueBorder,
  textfieldTextBlueBorderPharmacy,
} from "../../style/AppointmentStyle";
import SearchIcon from "@mui/icons-material/Search";
import { drTableRow } from "../../style/DrApotComponentStyle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { addBtn, primaryBtnPharmacy, textfieldTextBlueBr } from "../../style/PharmacyStyle";
import {
  setSearchPatient,
  setPatientDetailsData,
  setLoading,
} from "../../redux/slices/pharmacySlice";
import { useDispatch, useSelector } from "react-redux";
import { orangeButton, primaryBtnBlue, textfieldTextPrep } from "../../style/PatientDetailsStyle";
import UseSearchPatient from "../../helper/UseSearchPatient";
import UsePatientDetail from "../../helper/UsePatientDetail";
import useDebounce from "../../hooks/UseDebounce";
import UseGetMedicineList from "../../helper/UseGetMedicineList";
import UseMedicineByName from "../../helper/UseMedicineByName";
import UseGetMedicineDetail from "../../helper/UseGetMedicineDetail";
import {
  getMedicineByName,
  getPriceByQuantity,
  getPriceByQuantityForReturn,
} from "../../connections/getApi";
import PharmacyInvoice from "../PrintCompoents/PharmacyInvoice";
import { useReactToPrint } from "react-to-print";
import { generateBill } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import UseGetAllMedicineList from "../../helper/UseGetAllMedicineList";
import { Link } from "react-router-dom";
import calender_icon from '../../assets/calender_icon.svg';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { autoFocusOffWithOutBorder, drTableRowBlueBg } from "../../style/PatinetFormStyle";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import updownar from "../../assets/updownar.svg";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { DeleteForeverRounded } from "@mui/icons-material";
import json_icon from '../../assets/json_icon.svg';
import csv_icon from '../../assets/csv_icon.svg';
import CustomModal from "../CustomModal/CustomModal";
import PurchaseOrderDetails from "./PurchaseOrderDetails";
import EditPurchaseOrder from "./EditPurchaseOrder";
import NewPurchaseOrder from "./NewPurchaseOrder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditMedicines from "./EditMedicines";
 import medicine_salt from  '../../assets/medicine_salt.svg'
import EditMedicineSalt from "./EditMedicineSalt";

const NewMedicineSalt = ({ onClose }) => {
 
  // console.log(medicines);
   const[filterModal, setFilterModal] =useState(false);
   const[deleteModal, setDeleteModal] =useState(false);
   const[detailsModal,setDetailsModal]=useState(false);
   const[editPurchaseOrderModal,setEditPurchaseOrderModal]=useState(false);
   const[newPurchaseOrderModal,setNewPurchaseOrderModal]=useState(false);
 
  return ( 
    <>
    <Box sx={{ width: "100%", display:'flex' , height:"90vh" ,  flexDirection:'column' , justifyContent:"space-between" }}>



    {/* //modals for delete and filter */}
   <Modal 
    open={deleteModal}
    onClose={()=>setDeleteModal(false)}
    sx={{
    position:'absolute',
    top:'25%',
    width:"450px",
    margin:'auto',
    borderRadius:'20px',
   }}
  >
     
     <Box
        sx={{
          mt: 2,
          bgcolor: "background.paper",
          borderRadius: 3.6,
          boxShadow: 24,
          p: 4.5,         
          "&:focus": {
            outline: "none",
          },
        
        
        }}
      >

        <Box sx={{display:'flex' ,flexDirection:'column'}}>
          <Typography textAlign={'center'}>Are you sure?</Typography>
          <Typography textAlign={'center'}>You want to delete these selected purchase orders?</Typography>
          
        </Box>

        <Box  sx={{display:'flex' ,flexDirection:'flex'  , gap:'25px' , width:'max-content',  margin:'auto' , marginTop:"40px"}}>
       <Button sx={{ backgroundColor:"#FF392B" , color:"white" , border:'' , width:"150px" ,  borderRadius:"5px" ,   fontSize:"12px" , textTransform:'capitalize' ,'&:hover': {
         backgroundColor:"red",
        } }}>Delete</Button>
       <Button onClick={()=>setDeleteModal(false)}  sx={{ backgroundColor:"white" , color:"black" , border:'2px solid #FF392B' , width:"150px" , borderRadius:"5px",  fontSize:"12px" , textTransform:'capitalize'  }}>Cancel</Button>
        </Box>
     
      </Box>
   </Modal> 
   
   <Modal 
    open={filterModal}
    onClose={()=>setFilterModal(false)}
    sx={{
    position:'absolute',
    top:'14%',
    // left:'50%',
    width:"340px", 
    // height:"400px",
    margin:'auto',
    //  display: "flex",
    //  alignItems: "flex-start",
    //  justifyContent: "center",
    //  overflow: "auto",
    borderRadius:'20px',
   }}
  >
     
     <Box
        sx={{
          mt: 2,
          bgcolor: "background.paper",
          borderRadius: '20px',
          boxShadow: 24,
          p: 1,         
          "&:focus": {
            outline: "none",
          },
        
        
        }} 
      >    

<Grid container spacing={2} sx={{padding:'9px'}}>
        <Grid item xs={12}>
        <Typography variant="subTitle116px" fontWeight={'600'} textAlign={'center'}  margin={'auto'}>Filters</Typography>
      </Grid>



      {/* <Grid item xs={12}>
        <Typography  variant="subTitle116px" >Suppliers</Typography>
      </Grid> */}

      {/* Filter Selection */}
      <Grid item xs={12}>
        
        <Select fullWidth defaultValue=""  placeholder="Select Filter"  sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove border
                }, 
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove border on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Ensure border remains none on focus
                },
                "& .MuiSelect-select": {
                  padding: "9px",
                  borderRadius: "15px",
                  backgroundColor: "#F7F7FF",
                },
                "&:hover": {
                  boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                }}} 
                
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span style={{ color: "text.default_grey" }}>
                        Select filter
                      </span>
                    );
                  }
                  return selected === "filter1"
                    ? "filter2"
                    : selected === "filter3"
                    ? "filter3"
                    : "select filter";
                }}
                >
          <MenuItem value="filter1">Filter 1</MenuItem>
          <MenuItem value="filter2">Filter 2</MenuItem>
          <MenuItem value="filter3">Filter 3</MenuItem>
        </Select>
      </Grid>

      {/* Purchase Date */}
      <Grid item xs={12}>
        <Typography variant="subTitle116px">Purchase Date</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={autoFocusOffWithOutBorder}
          label="From"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={autoFocusOffWithOutBorder}
          label="To"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      {/* Department Selection */}
      <Grid item xs={12}>
        <Typography variant="subTitle116px">Department</Typography>
        <Select fullWidth defaultValue=""  sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove border
            }, 
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove border on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none", // Ensure border remains none on focus
            },
            "& .MuiSelect-select": {
              padding: "9px",
              borderRadius: "15px",
              backgroundColor: "#F7F7FF",
            },
            "&:hover": {
              boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
            }
        }}>
          <MenuItem value="dept1">Department 1</MenuItem>
          <MenuItem value="dept2">Department 2</MenuItem>
          <MenuItem value="dept3">Department 3</MenuItem>
        </Select>
      </Grid>

      {/* Created At */}
      <Grid item xs={12}>
        <Typography variant="subTitle116px">Created At</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={autoFocusOffWithOutBorder}
          label="From"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={autoFocusOffWithOutBorder}
          label="To"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      {/* Buttons */}
      <Grid item xs={6}>
        <Button fullWidth variant="subTitle116px16px" sx={{...primaryBtnBlue , color:'white'}}>Apply</Button>
      </Grid>
      <Grid item xs={6}>
        <Button onClick={()=>setFilterModal(false)} fullWidth variant="subTitle116px16px" sx={{...primaryBtn , color:'white' , border:'none'}}>Clear Filters</Button>
      </Grid>
    </Grid>


       
      </Box>
   </Modal> 


    <Box sx={{overflowY :'auto' , maxHeight:"80vh" ,  '&::-webkit-scrollbar': {
    display: 'none',
    },
    '-ms-overflow-style': 'none',  
    'scrollbar-width': 'none',  }}  >
      
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
      >
        <Box></Box>
  

         <Box sx={{display:'flex', justifyContent:'center' , alignItems:'center'  , gap:"5px"}} >
         <img src={medicine_salt}  alt='' sx={{color:'neutral.navyBlue' , marginBottom:"4px" , marginRight:'14px'}} /> 
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={{...headingStyle}} 
        >
         New  Medicine salt
       </Typography>
       </Box>
        <IconButton sx={{ marginBottom: "1rem" }} onClick={onClose}>
          <CloseIcon size="small"  sx={{color:"red"}}/>
        </IconButton>
      </Box> 
     
      <Box
      
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        // paddingX="25px"
        width='96%'
        gap='40px'
        margin='auto'
        marginBottom='30px'
>
         <Typography> Name </Typography>

         <TextField placeholder="Name" fullWidth sx={{...autoFocusOffWithOutBorder }}/>

      </Box> 

      <Box          
        sx={{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
          position:"sticky",
          // paddingX="25px"
          width:'98%',
          gap:'40px',
          marginTop:'50px',
          margin:'auto',

        }} 
       >

       <Typography> Brands </Typography>

       <Box>
       <IconButton sx={{...orangeButton , marginLeft:"7px"  ,marginY:'2px'}}>
                  <AddIcon sx={{color:'neutral.white',
                   width:'30px',
                   height:'32px' , padding:'0px'}} />
                  </IconButton>
       </Box> 

    </Box> 

      <Box sx={{display:'flex' , flexDirection:"column" , gap:"14px" , marginTop:"16px"}}>
      {[...Array(2)].map((_, rowIndex) => (
      <Grid container sx={{...tableRowLab , padding:"12px"}}>
      <Grid item xs={11.6}>
        <Grid container spacing={1}>

          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>
          <Grid item xs={1.5}> 
          <TextField sx={textfieldTextBlueBr}></TextField>
          </Grid>

        </Grid> 
      </Grid>

      <Grid item xs={0.4} mt={2}>
      <IconButton sx={{ marginLeft:'8px', marginBottom:"7px",}}
                    // onClick={() => handleRemoveRow(index)}
                  >
                      <CloseIcon sx={{ ...xBtn, width:'28px', height:'28px', borderRadius:'20%' }}/>
                  </IconButton>   
      </Grid>

      </Grid>

      ))}
      </Box>
    

    

      </Box>     
    </Box>
    </> 
  );
};

export default NewMedicineSalt;
             