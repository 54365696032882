export const SearchTextField = {
  "& .MuiOutlinedInput-root": {
    height: "35px",
    "& input": {
      padding: "8px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white",
    transition: "background-color 0.3s",
  },
 
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
};

export const buttonWraper = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "end",
  marginTop: 2,
  gap: "1rem",
  flexWrap: "wrap",
};

export const dateFiled = {
  marginTop: "2px",
  "& .MuiOutlinedInput-root": {
    height: "35px",
    "& input": {
      padding: "8px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#ebebeb",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
};

export const disableTextField = {
  "& .MuiOutlinedInput-root": {
    height: "35px",
    "& input": {
      padding: "8px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "rgba(204, 204, 204, 0.1)",
    transition: "background-color 0.3s",
  },
};
export const textfiledText = {
  "& .MuiOutlinedInput-root": {
    height: "30px",
    "& input": {
      padding: "8px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#ebebeb",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
};

export const selectField = {
  padding: "6px 0px",
  fontSize: "14px",

  backgroundColor: "#ebebeb",
  "& .MuiSelect-select": {
    height: "100%",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "&.Mui-focused": {
    backgroundColor: "white",
  },
  "&.MuiSelect-select.Mui-focused": {
    backgroundColor: "white",
  },
};

export const primaryBtn = {
  mt: 3,
  mb: 2,
  backgroundColor: "#f58633",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
  "&:hover": {
    backgroundColor: "#ff6600",
    color: "black",
  },
};

export const closeBtn = {
  mt: 3, 
  mb: 2,
  Padding: "5px",
  backgroundColor: "#4d4b47",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
  "&:hover": {
    backgroundColor: "black",
    color: "white",
  },
};

export const Wrapper = {
  marginTop: "7rem",
  display: "flex",
  justifyContent: "space-between",
  // padding: "0rem 1rem",
  padding:'0px',
  flexWrap: "wrap",
};

export const editIconStyle = {
  position: "absolute",
  right: "-55px",
  top: "60%",
  height: "20x",
  width: "20px",
  transform: "translateY(-60%)",
  zIndex: "20",
  backgroundColor: "text.textFieldGreyBG",
};

export const drTableRowBlueBg = {
  backgroundColor: "neutral.navyBlue",
  borderBottom: "1px solid #ebebeb",
  marginBottom: "3px",
  borderTopLeftRadius: "15px",
  borderTopRightRadius: "15px",
  p: 0,
};


export const autoFocusOffWithOutBorder ={
    "& .MuiOutlinedInput-root": {
      padding: "9px", // No padding inside the input field
      borderRadius: "15px",
       backgroundColor:'#F7F7FF',
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc", // Border should remain consistent on hover
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc", // Ensure border remains consistent
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc", // No border color change on focus
    },
    "&:hover": {
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
    },
    "& .MuiInputBase-input": {
      padding: "0px 4PX", 
    },
    borderRadius:'15px',
    "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  }, 
}