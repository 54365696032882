import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { doctorAppointmentStats } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/prescriptionSlice";
import { useLocation } from "react-router-dom";

const UseAppointmentsStats = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isEnable = location.pathname === "/prescription";
  
  return useQuery({
    queryKey: ["doctorAppointmentStats"],
    queryFn: doctorAppointmentStats, // Call the function here
    enabled: isEnable,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    refetchOnWindowFocus: false,
  });
};

export default UseAppointmentsStats;
