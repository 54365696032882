import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  keyframes,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import {
  buttonWrapper,
  menuItemStyle,
  primaryBtn,
  tableCellStyle,
} from "../../style/AppointmentStyle";
import TextView from "../UI/TextView/TextView";
import { billingForm } from "../../utils/constant";
import UseFetchOptions from "../../helper/UseFetchOptions";
import UseFetchDoctorList from "../../helper/UseFetchDoctorList";
import { UseFetchSlots } from "../../helper/UseFetchSlots";
import {
  addOptionInAppointForm,
  createAppointment,
  NewAppointmentPrint,
  rescheduleAppointmentData,
  savePatientAppointment,
} from "../../connections/postApi";
import {
  primaryBtnBlue,
  textfieldTextPrep,
} from "../../style/PatientDetailsStyle";
import {
  setData,
  setMedicalTestData,
  setRenderInvoice,
  setSearchPatient,
} from "../../redux/slices/billingSlice";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/UseDebounce";
import UsePatientDetail from "../../helper/UsePatientDetail";
import UseSearchPatient from "../../helper/UseSearchPatient";
import { drTableRow } from "../../style/DoctorListComponent";
import { showToast } from "../../utils/toastUtil";
import { saveOldPatientAppointment } from "../../connections/putApi";
import {
  getMedicalTestByPatientId,
  rePrintInvoice,
} from "../../connections/getApi";
import PrintAppointment from "../PrintCompoents/PrintAppointment";
import ReactToPrint from "react-to-print";
import loadingSpinner from "../../assets/loadingSpinner.svg";
import { setSelectedSlot } from "../../redux/slices/appointmentSlice";
import UseFetchDoctorsPlan from "../../helper/UseFetchDoctorsPlan";

const rotateSpinner = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(36deg); }
  20% { transform: rotate(72deg); }
  30% { transform: rotate(108deg); }
  40% { transform: rotate(144deg); }
  50% { transform: rotate(180deg); }
  60% { transform: rotate(216deg); }
  70% { transform: rotate(252deg); }
  80% { transform: rotate(288deg); }
  90% { transform: rotate(324deg); }
  100% { transform: rotate(360deg); }
  `;

const PatientsInfo = () => {
  const [allFormFields, setAllFormFields] = useState(billingForm);
  const [formState, setFormState] = useState({
    visitDate: new Date().toISOString().split("T")[0],
  });
  const [showGenRateInvoice, setgenRateInvoice] = useState(true);
  const [newValues, setNewValues] = useState({});
  const [showNewValueField, setShowNewValueField] = useState({});
  const [fieldOptions, setFieldOptions] = useState({});
  const [openSearchDropDown, setOpenSearchDropDown] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [resetSlot, setResetSlot] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [errors, setErrors] = useState({});
  const [appointmentState, setAppointmentState] = useState();
  const [appointmentPrint, setAppointPrint] = useState();
  const printRef = useRef();
  const [drConsultId, setDrConsultId] = useState("");
  // const [visitType, setVisitType] = useState("");
  const [searchData, setSearchData] = useState({
    q: "",
    page: 1,
    perPage: 10,
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    billing: { patientData, data, inVoiceData, patientId: loadPatientId },
  } = useSelector((state) => state);
  // console.log(data, "test again data");
  // hooks to get data
  const dispatch = useDispatch();
  const { data: allOccupation, refetch: refetchOccupation } =
    UseFetchOptions("occupations");
  const { data: allCities, refetch: refetchCities } = UseFetchOptions("cities");
  const { data: allEducations, refetch: refetchEducation } =
    UseFetchOptions("educations");
  const { data: drQuery, status } = UseFetchDoctorList();
  const {
    data: doctorPlans,
    status: doctorPlansStatus,
    isLoading: isLoadingPlans,
  } = UseFetchDoctorsPlan(drConsultId, formState?.patientType);
  const { data: allSlots, status: slotStatus } = UseFetchSlots(
    formState?.visitDate || new Date().toISOString().split("T")[0]
  );
  const { data: patientDetailsQuery, isLoading } = UsePatientDetail(patientId);
  const { data: searchResult } = UseSearchPatient(searchData);
  const [newSlotOption, setNewSlotOption] = useState({});
  // Initialize fieldOptions with the initial options from appointmentForm
  useEffect(() => {
    const initialOptions = {};
    allFormFields.forEach((field) => {
      if (field.field === "Autocomplete") {
        initialOptions[field.name] = field.options;
      }
    });
    //this put so that run only one time and set form state
    if (drQuery?.doctors?.length > 0) {
      setDrConsultId(drQuery?.doctors[0]?.id);
      setFormState((prev) => ({
        ...prev,
        patientType: "New",
        consultancyDoctor: drQuery?.doctors[0]?.id,
        attendingDoctor: drQuery?.doctors[0]?.id,
        consultant_id: drQuery?.doctors[0]?.id || null,
        doctor_id: drQuery?.doctors[0]?.id || null,
      }));
    }
    setFieldOptions(initialOptions);
  }, [drQuery]);

  // set education occupation and cities option in fields
  useEffect(() => {
    const newAllCities = allCities?.cities?.map((el) => ({
      id: el?.id,
      title: el?.name,
      value: el?.name,
    }));
    setFieldOptions((prev) => ({
      ...prev,
      occupation: allOccupation?.occupations,
      education: allEducations?.educations,
      city: newAllCities,
    }));
  }, [allCities, allEducations, allOccupation]);
  // //set doctor and slot option

  function cleanPayload(obj) {
    if (Array.isArray(obj)) {
      return obj.map(cleanPayload).filter((item) => item != null);
    } else if (typeof obj === "object" && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj)
          .map(([key, value]) => [key, cleanPayload(value)])
          .filter(([_, value]) => value != null && value !== "")
      );
    }
    return obj;
  }

  useEffect(() => {
    let updatedFormFields = allFormFields;

    // Update slot options if slotStatus is "success" and allSlots contains data
    if (allSlots?.payload?.slots) {
      // console.log(slotStatus, newSlotOption, "data");
      // console.log(formState, "data");

      const formattedSlots =
        allSlots.payload.slots.map((slot) => ({
          id: slot.id,
          value: slot.id,
          label: slot.time,
        })) || [];

      const slotOptions =
        formattedSlots.length > 0
          ? formattedSlots
          : [{ value: "default", label: "Slot" }]; // Default option if no slots available

      // Update form fields with slot options
      updatedFormFields = updatedFormFields.map((field) =>
        field.name === "slot"
          ? {
              ...field,
              isDisabled: newSlotOption?.value ? true : false,
              options:
                newSlotOption && Object.keys(newSlotOption).length > 0
                  ? [newSlotOption, ...slotOptions]
                  : slotOptions,
            }
          : field
      );
      setTimeout(() => {
        setFormState((prev) => ({
          ...prev,
          slot: newSlotOption?.value
            ? newSlotOption?.value
            : formattedSlots[0]?.value,
        }));
        setAllFormFields((prev) => [...prev, updatedFormFields]);
      }, 500);
    }

    // Update doctor options if status is "success" and doctor data is available
    if (status === "success" && drQuery?.doctors) {
      const formattedDrList =
        drQuery?.doctors?.map((dr) => ({
          value: dr.id,
          label: dr.full_name,
          id: dr.id,
        })) || [];
      // console.log(formattedDrList, "formattedDrList");

      updatedFormFields = updatedFormFields.map((field) => {
        if (
          field.name === "attendingDoctor" ||
          field.name === "consultancyDoctor"
        ) {
          return { ...field, options: formattedDrList };
        }
        return field;
      });
    }

    // console.log(formState)
    if (!formState?.gender) {
      setFormState((prev) => ({
        ...prev,
        gender: "male",
      }));
    }
    // Update doctor plans  options if status is "success" and doctor data is available
    if (
      doctorPlansStatus === "success" &&
      doctorPlans?.payload?.specialist_plans?.length > 0
    ) {
      const formattedDrPlanList = doctorPlans?.payload?.specialist_plans?.map(
        (plan) => ({
          value: plan.id,
          label: plan?.title,
          id: plan?.id,
          amount: plan?.amount,
        })
      );

      updatedFormFields = updatedFormFields.map((field) => {
        if (field.name === "getPlans") {
          return { ...field, options: formattedDrPlanList };
        }
        return field;
      });
      setFormState((prev) => ({
        ...prev,
        getPlans:
          formState?.appointmentId && formState?.invoiceId
            ? formState?.getPlans
            : doctorPlans?.meta?.selected_plan_id,
      }));

      // console.log(formState?.getPlans, "formState?.getPlans");
      const plan = doctorPlans?.payload?.specialist_plans?.find(
        (plan) =>
          plan.id ===
          (formState?.getPlans || doctorPlans?.meta?.selected_plan_id)
      );

      if (plan) {
        // If a match is found, update the formState with the eventValue
        setFormState((prevState) => ({
          ...prevState,
          planAmount: plan?.amount - (plan?.discount ? plan?.discount : 0),
        }));
        setFormState((prevState) => ({
          ...prevState,
          getPlans: plan?.id,
          plansDetail: plan,
        }));
      }
    }

    // Set updated form fields
    setTimeout(() => {
      setAllFormFields(updatedFormFields);
    }, 100);
  }, [
    allSlots,
    // slotStatus,
    status,
    drQuery,
    resetSlot,
    doctorPlans,
    // formState?.getPlans,
  ]); // Combine all dependencies
  // console.log(formState, "FormState");
  const handleFormChange = (event, value, name) => {
    let slotId = value?.props?.id;
    let fieldName;

    if (event) {
      const { name: eventName, value: eventValue } = event.target;
      fieldName = event.target.name;
      // console.log(eventValue, fieldName, "check");

      if (
        (fieldName === "age" && eventValue?.length > 3) ||
        (fieldName === "mobileNumber" && eventValue?.length > 10)
      ) {
        return;
      }

      if (fieldName === "consultancyDoctor") {
        if (drConsultId !== eventValue) {
          setDrConsultId(eventValue);

          setFormState((prevState) => ({
            ...prevState,
            getPlans: "",
            planAmount: "",
            plansDetail: "",
          }));
        }

        // console.log(eventValue)
        setFormState((prevState) => ({
          ...prevState,
          [eventName]: eventValue,
        }));
      } else if (fieldName === "getPlans") {
        const plan = doctorPlans?.payload?.specialist_plans?.find(
          (plan) => plan.id === eventValue
        );

        if (plan) {
          // If a match is found, update the formState with the eventValue
          setFormState((prevState) => ({
            ...prevState,
            ["planAmount"]:
              plan?.amount - (plan?.discount ? plan?.discount : 0),
          }));

          setFormState((prevState) => ({
            ...prevState,
            [eventName]: eventValue,
            ["plansDetail"]: plan,
          }));
        }
      } else if (fieldName === "patientType") {
        setFormState((prevState) => ({
          ...prevState,
          getPlans: "",
          planAmount: "",
          plansDetail: "",
          [eventName]: eventValue,
        }));
        return;
        // setFormState((prevState) => ({
        //   ...prevState,
        //   [eventName]: eventValue,
        // }));
        // setVisitType(eventValue);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [eventName]: eventValue,
        }));
      }
    } else if (name) {
      fieldName = name;
      if (value?.value === "other") {
        setShowNewValueField((prev) => ({ ...prev, [name]: true }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setShowNewValueField((prev) => ({ ...prev, [name]: false }));
      }
    }
    setErrors((prev) => ({ ...prev, [fieldName]: undefined }));
  };

  //add new value to occupation education and cities also post req

  // const handleAddNewValue = async (name) => {
  //   if (newValues[name]) {
  //     const newValue = newValues[name];

  //     let newObj = {
  //       id: `${Math.floor(Math.random() * 90) + 10}${newValue}`,
  //       value: newValue,
  //       title: newValue,
  //     };
  //     await addOptionInAppointForm({ value: newValue }, name);
  //     if (name === "occupation") {
  //       refetchOccupation();
  //     } else if (name === "education") {
  //       refetchEducation();
  //     } else {
  //       refetchCities();
  //     }
  //     setFormState((prevState) => ({
  //       ...prevState,
  //       [name]: newObj,
  //     }));
  //     // dispatch(setSelectedSlot({ date: newValue, slot: "" }));
  //     setFieldOptions((prevOptions) => ({
  //       ...prevOptions,
  //       [name]: [...(prevOptions[name] || []), newObj],
  //     }));
  //     setNewValues((prev) => ({ ...prev, [name]: "" }));
  //     setShowNewValueField((prev) => ({ ...prev, [name]: false }));
  //   }
  // };

  const handleAddNewValue = async (name) => {
    if (newValues[name]) {
      const newValue = newValues[name];
      let result;
      // let newObj = {
      //   // id: ${Math.floor(Math.random() * 90) + 10}${newValue},
      //   value: newValue,
      //   title: newValue,
      // };
      result = await addOptionInAppointForm({ value: newValue }, name);
      if (result?.status?.status === "SUCCESS") {
        let newObj = {
          id: result?.payload?.id,
          title: result?.payload?.title || result?.payload?.name,
          value: result?.payload?.value || result?.payload?.name,
        };
        if (name === "occupation") {
          refetchOccupation();
        } else if (name === "education") {
          refetchEducation();
        } else {
          refetchCities();
        }
        setFormState((prevState) => ({
          ...prevState,
          [name]: newObj,
        }));
        setFieldOptions((prevOptions) => ({
          ...prevOptions,
          [name]: [...(prevOptions[name] || []), newObj],
        }));
        setNewValues((prev) => ({ ...prev, [name]: "" }));
        setShowNewValueField((prev) => ({ ...prev, [name]: false }));
      }
    }
  };

  // search query for patient by name or id
  const debouncedInputValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    if (debouncedInputValue) {
      setSearchData((prev) => ({ ...prev, q: debouncedInputValue }));
    } else {
      dispatch(setSearchPatient([]));
    }
  }, [debouncedInputValue, dispatch]);
  const selectedPatient = (id) => {
    setPatientId(id?.id);
  };

  // set data for patient detail
  useEffect(() => {
    const capitalizeFirstLetter = (string) => {
      return string
        ? string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
        : null;
    };
    if (patientDetailsQuery?.payload && patientId) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setNewSlotOption({
        id: patientDetailsQuery?.payload?.appointments?.[0]
          ?.appointment_slot_id,
        value:
          patientDetailsQuery?.payload?.appointments?.[0]?.appointment_slot_id,
        label:
          patientDetailsQuery?.payload?.appointments?.[0]
            ?.appointment_slot_time,
      });
      dispatch(setData(patientDetailsQuery));
      const patientObj = {
        id: patientDetailsQuery?.payload?.id,
        uid: patientDetailsQuery?.payload?.uid,
        fullName: patientDetailsQuery?.payload?.full_name,
        email: patientDetailsQuery?.payload?.email,
        mobileNumber: patientDetailsQuery?.payload?.mobile,
        maritalStatus: capitalizeFirstLetter(
          patientDetailsQuery?.payload?.marital_status
        ),
        diet: patientDetailsQuery?.payload?.diet,
        appointmentId: patientDetailsQuery?.payload?.appointments[0]?.id,
        invoiceId: patientDetailsQuery?.payload?.invoice_id,
        gender: patientDetailsQuery?.payload?.gender,
        age: patientDetailsQuery?.payload?.age,
        attendingDoctor:
          patientDetailsQuery?.payload?.appointments[0]?.doctor_id,
        consultancyDoctor:
          patientDetailsQuery?.payload?.appointments[0]?.consultant_id,

        visitDate: new Date().toISOString().split("T")[0],
        slot: patientDetailsQuery?.payload?.appointments[0]
          ?.appointment_slot_id,
        occupation: patientDetailsQuery?.payload?.occupation_id,
        education: patientDetailsQuery?.payload?.education_id,
        city: +patientDetailsQuery?.payload?.city,
        address: patientDetailsQuery?.payload?.address,
        pincode: patientDetailsQuery?.payload?.pincode,
        patientType: capitalizeFirstLetter(
          patientDetailsQuery?.payload?.appointments[0]?.visit_type
        ),
        patientAppointment: patientDetailsQuery?.payload?.appointments,

        getPlans:
          patientDetailsQuery?.payload?.appointments[0]?.specialist_plan_id,
      };
      setDrConsultId(
        patientDetailsQuery?.payload?.appointments[0]?.consultant_id
      );
      setResetSlot(!resetSlot);
      if (patientDetailsQuery?.payload?.appointments[0]?.status === "paid") {
        setgenRateInvoice(false);
      } else {
        setgenRateInvoice(true);
      }
      setPatientId(null);
      setFormState((prev) => ({ ...prev, ...patientObj }));
      setErrors({});
    }
  }, [dispatch, patientId, patientDetailsQuery?.payload?.id]);

  useEffect(() => {
    // console.log(data, "coming from", inVoiceData);
    if (inVoiceData && Object?.keys(inVoiceData)?.length) {
      // console.log("inside data", data);
      const capitalizeFirstLetter = (string) => {
        return string
          ? string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
          : null;
      };
      if (data?.payload) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setNewSlotOption({
          id: data?.payload?.appointments?.[0]?.appointment_slot_id,
          value: data?.payload?.appointments?.[0]?.appointment_slot_id,
          label: data?.payload?.appointments?.[0]?.appointment_slot_time,
        });
        dispatch(setData(data));
        const patientObj = {
          id: patientDetailsQuery?.payload?.id,
          uid: patientDetailsQuery?.payload?.uid,
          fullName: data?.payload?.full_name,
          email: data?.payload?.email,
          mobileNumber: data?.payload?.mobile,
          maritalStatus: capitalizeFirstLetter(data?.payload?.marital_status),
          diet: data?.payload?.diet,

          gender: data?.payload?.gender,
          age: data?.payload?.age,
          attendingDoctor: data?.payload?.appointments[0]?.doctor_id,
          consultancyDoctor: data?.payload?.appointments[0]?.consultant_id,
          appointmentId: data?.payload?.appointments[0]?.id,
          invoiceId: data?.payload?.invoice_id,
          // visitDate: data?.payload?.appointments[0]?.visit_date,
          slot: data?.payload?.appointments[0]?.appointment_slot_id,
          occupation: data?.payload?.occupation_id,
          education: data?.payload?.education_id,
          city: +data?.payload?.city,
          address: data?.payload?.address,
          pincode: data?.payload?.pincode,
          patientType: capitalizeFirstLetter(
            data?.payload?.appointments[0]?.visit_type
          ),
          patientAppointment: data?.payload?.appointments,
          getPlans: data?.payload?.appointments[0]?.specialist_plan_id,
        };
        setResetSlot(!resetSlot);
        if (data?.payload?.appointments[0]?.status === "paid") {
          setgenRateInvoice(false);
        } else {
          setgenRateInvoice(true);
        }
        setFormState((prev) => ({ ...prev, ...patientObj }));
        setErrors({});
      }
    }
    //  else {
    //   setFormState({
    //     visitDate: new Date().toISOString().split("T")[0],
    //   });
    // }
  }, [inVoiceData]);

  useEffect(() => {
    // console.log(data, "coming from", inVoiceData);
    if (data?.payload) {
      // console.log("inside data", data);
      const capitalizeFirstLetter = (string) => {
        return string
          ? string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
          : null;
      };
      if (data?.payload) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setNewSlotOption({
          id: data?.payload?.appointments?.[0]?.appointment_slot_id,
          value: data?.payload?.appointments?.[0]?.appointment_slot_id,
          label: data?.payload?.appointments?.[0]?.appointment_slot_time,
        });
        // dispatch(setData(data));
        const patientObj = {
          id: data?.payload?.id,
          uid: data?.payload?.uid,
          fullName: data?.payload?.full_name,
          email: data?.payload?.email,
          mobileNumber: data?.payload?.mobile,
          maritalStatus: capitalizeFirstLetter(data?.payload?.marital_status),
          diet: data?.payload?.diet,
          patientAppointment: data?.payload?.appointments,
          gender: data?.payload?.gender,
          age: data?.payload?.age,
          attendingDoctor: data?.payload?.appointments[0]?.doctor_id,
          consultancyDoctor: data?.payload?.appointments[0]?.consultant_id,
          appointmentId: data?.payload?.appointments[0]?.id,
          invoiceId: data?.payload?.invoice_id,
          // visitDate: data?.payload?.appointments[0]?.visit_date,
          slot: data?.payload?.appointments[0]?.appointment_slot_id,
          occupation: data?.payload?.occupation_id,
          education: data?.payload?.education_id,
          city: +data?.payload?.city,
          address: data?.payload?.address,
          pincode: data?.payload?.pincode,
          patientType: capitalizeFirstLetter(
            data?.payload?.appointments[0]?.visit_type
          ),
          getPlans: data?.payload?.appointments[0]?.specialist_plan_id,
        };
        // setResetSlot(!resetSlot);
        if (data?.payload?.appointments[0]?.status === "paid") {
          setgenRateInvoice(false);
        } else {
          setgenRateInvoice(true);
        }
        setFormState((prev) => ({ ...prev, ...patientObj }));
        // setErrors({});
      }
    }
  }, [
    data,
    // patientDetailsQuery?.payload?.id,
    // patientDetailsQuery?.payload?.uid,
  ]);
  // useEffect(() => {
  // console.log(data, "loadPatientId", inVoiceData);
  // dispatch(setData(data));
  // if (loadPatientId ) {
  //   setPatientId(loadPatientId);
  // }
  // }, [dispatch, loadPatientId]);
  // console.log(loadPatientId, patientId, ":patienid");
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  useEffect(() => {
    if (searchResult?.payload?.patients) {
      dispatch(setSearchPatient(searchResult?.payload?.patients));
    }
  }, [searchResult, dispatch, inputValue]);
  const validateForm = (formData, isNew) => {
    let newErrors = {};
    let isValid = true;

    billingForm.forEach((field) => {
      if (
        !isNew &&
        (field.name === "attendingDoctor" ||
          field.name === "consultancyDoctor" ||
          field.name === "slot" ||
          field.name === "getPlans" ||
          field.name === "patientType")
      ) {
        return; // Skip this iteration
      }
      if (field.pattern) {
        const value = formData[field.name];
        if (!new RegExp(field.pattern).test(value)) {
          newErrors[field.name] = field.errorMessage || "Invalid input";
          isValid = false;
        }
      }
      // Add any other validation rules here
      if (field.required && !formData[field.name]) {
        newErrors[field.name] = "This field is required";
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSavePatient = async (isNew = false) => {
    // console.log(patientDetailsQuery, "patientDetailsQuery");
    setButtonLoading(true);
    if (!validateForm(formState, isNew)) {
      setButtonLoading(false);
      return null;
    }

    const printPayload = {
      appointment: {
        patient_id: formState?.id,
        visit_type: formState?.patientType?.toLowerCase(),
        visit_date: formState?.visitDate,
        consultant_id: formState?.consultancyDoctor,
        doctor_id: formState?.attendingDoctor,
        appointment_slot_id: formState?.slot || null,
      },
    };

    if (formState?.uid || formState?.id) {
      let oldData = {
        patient: {
          uid: formState?.uid || null,
          full_name: formState?.fullName || null,
          email: formState?.email || null,
          mobile: formState?.mobileNumber || null,
          age: formState?.age || null,
          gender: formState?.gender?.toLowerCase() || null,
          marital_status: formState?.maritalStatus?.toLowerCase() || null,
          address: formState?.address || null,
          pincode: formState?.pincode || null,
          diet: formState?.diet?.split("-")?.join("_")?.toLowerCase() || null,
          city: formState?.city?.id
            ? String(formState.city.id)
            : formState?.city
            ? String(formState.city)
            : null,
          occupation_id: formState?.occupation?.id
            ? String(formState.occupation.id)
            : formState?.occupation
            ? String(formState.occupation)
            : null,
          education_id: formState?.education?.id
            ? String(formState.education.id)
            : formState?.education
            ? String(formState.education)
            : null,

          // visit_date: formState?.visitDate,
        },
      };
      oldData = cleanPayload(oldData);
      let result = await saveOldPatientAppointment(oldData, formState?.id);
      showToast(
        result?.status?.message || result?.status?.status,
        result?.status?.status
      );

      if (isNew) {
        const userConfirmation = window.confirm(
          "Do you want to reschedule the appointment?"
        );
        if (userConfirmation) {
          const rePrintAppointment = await rescheduleAppointmentData(
            printPayload,
            patientDetailsQuery?.payload?.appointments[0]?.id
          );
          showToast(
            rePrintAppointment?.status?.message ||
              rePrintAppointment?.status?.status,
            rePrintAppointment?.status?.status
          );

          if (rePrintAppointment?.status?.status === "SUCCESS") {
            const printAppointMentDetail = await NewAppointmentPrint(
              {
                invoice_type: "consultancy",
                specialist_plan_id: formState?.plansDetail?.id,
              },
              rePrintAppointment?.payload?.id
            );
            setAppointPrint(printAppointMentDetail?.payload);
            setAppointmentState(patientDetailsQuery?.payload);
            if (printAppointMentDetail?.payload?.invoices?.length > 0) {
              setTimeout(() => {
                document.getElementById("print-button").click();
                printRef.current.click();
              }, 2000);
            }
            setFormState({
              visitDate: new Date().toISOString().split("T")[0],
              slot: "",
              slotID: undefined,
            });
            dispatch(setRenderInvoice());
            setInputValue("");
          }
        }
      }
      setButtonLoading(false);
    } else {
      // console.log("createData", formState);
      let createData = {
        patient: {
          full_name: formState?.fullName || null,
          visit_type: formState?.patientType?.toLowerCase() || null,
          email: formState?.email || null,
          mobile: formState?.mobileNumber || null,
          age: formState?.age || null,
          gender: formState?.gender?.toLowerCase() || null,
          marital_status: formState?.maritalStatus?.toLowerCase() || null,
          address: formState?.address || null,
          pincode: formState?.pincode || null,
          diet: formState?.diet?.split("-")?.join("_")?.toLowerCase() || null,
          city: formState?.city?.id
            ? String(formState.city.id)
            : formState?.city
            ? String(formState.city)
            : null,
          occupation_id: formState?.occupation?.id
            ? String(formState.occupation.id)
            : formState?.occupation
            ? String(formState.occupation)
            : null,
          education_id: formState?.education?.id
            ? String(formState.education.id)
            : formState?.education
            ? String(formState.education)
            : null,
        },
      };
      createData = cleanPayload(createData);
      let result = await createAppointment(createData);
      if (result?.status?.status === "SUCCESS") {
        setFormState((prev) => ({
          ...prev,
          uid: result?.payload?.uid,
          id: result?.payload?.id,
        }));
      }
      setInputValue("");
      if (isNew) {
        let createAppoint = {
          appointment: {
            visit_type: formState?.patientType?.toLowerCase() || null,
            visit_date: formState?.visitDate || null,
            appointment_slot_id: formState?.slot || null,
            consultant_id: formState?.consultancyDoctor || null,
            doctor_id: formState?.attendingDoctor || null,
            patient_id: result?.payload?.id || null,
          },
        };

        createAppoint = cleanPayload(createAppoint);
        let resultAppointment = await savePatientAppointment(createAppoint);
        showToast(
          resultAppointment?.status?.message ||
            resultAppointment?.status?.status,
          resultAppointment?.status?.status
        );
        if (resultAppointment?.status?.status === "SUCCESS") {
          const printAppointMentDetail = await NewAppointmentPrint(
            {
              invoice_type: "consultancy",
              specialist_plan_id: formState?.plansDetail?.id,
            },
            resultAppointment?.payload?.id
          );
          setAppointPrint(printAppointMentDetail?.payload);
          setAppointmentState(resultAppointment?.payload?.patient);
          if (printAppointMentDetail?.payload?.invoices?.length > 0) {
            setTimeout(() => {
              document.getElementById("print-button").click();
              printRef.current.click();
            }, 2000);
          }
          setFormState({
            visitDate: new Date().toISOString().split("T")[0],
            slot: "",
            slotID: undefined,
          });
          dispatch(setRenderInvoice());
          setInputValue("");
        }
      }
      showToast(
        result?.status?.message || result?.status?.status,
        result?.status?.status
      );

      setButtonLoading(false);
    }
  };

  //  console.log(formState, "formState");
  const handleSavePatient1 = async (isNew = false) => {
    // console.log(data, "handleSavePatient1");
    // console.log(patientDetailsQuery, "patientDetailsQuery");
    setButtonLoading(true);
    if (!validateForm(formState, isNew)) {
      setButtonLoading(false);
      return null;
    }

    const printPayload = {
      appointment: {
        patient_id: formState?.id,
        visit_type: formState?.patientType?.toLowerCase(),
        visit_date: formState?.visitDate,
        consultant_id: formState?.consultancyDoctor,
        doctor_id: formState?.attendingDoctor,
        appointment_slot_id: formState?.slot || null,
      },
    };

    if (formState?.uid || formState?.id) {
      let oldData = {
        patient: {
          uid: formState?.uid || null,
          full_name: formState?.fullName || null,
          email: formState?.email || null,
          mobile: formState?.mobileNumber || null,
          age: formState?.age || null,
          gender: formState?.gender?.toLowerCase() || null,
          marital_status: formState?.maritalStatus?.toLowerCase() || null,
          address: formState?.address || null,
          pincode: formState?.pincode || null,
          diet: formState?.diet?.split("-")?.join("_")?.toLowerCase() || null,
          city: formState?.city?.id
            ? String(formState.city.id)
            : formState?.city
            ? String(formState.city)
            : null,
          occupation_id: formState?.occupation?.id
            ? String(formState.occupation.id)
            : formState?.occupation
            ? String(formState.occupation)
            : null,
          education_id: formState?.education?.id
            ? String(formState.education.id)
            : formState?.education
            ? String(formState.education)
            : null,

          // visit_date: formState?.visitDate,
        },
      };
      oldData = cleanPayload(oldData);
      let result = await saveOldPatientAppointment(oldData, formState?.id);
      showToast(
        result?.status?.message || result?.status?.status,
        result?.status?.status
      );
      // console.log(data, "patient data");
      if (
        isNew &&
        data?.appointments?.length > 0 &&
        data?.appointments?.[0]?.status === "pending" &&
        data?.appointments?.[0]?.appointment_slot_id === formState?.slot
      ) {
        const printAppointMentDetail = await NewAppointmentPrint(
          {
            invoice_type: "consultancy",
            specialist_plan_id: formState?.plansDetail?.id,
          },
          data?.appointments?.[0]?.id
        );
        if (printAppointMentDetail?.status?.status === "SUCCESS") {
          setAppointPrint(printAppointMentDetail?.payload);
          setAppointmentState(data);
          if (printAppointMentDetail?.payload?.invoices?.length > 0) {
            setTimeout(() => {
              document.getElementById("print-button").click();
              printRef.current.click();
            }, 2000);
          }
          setFormState({
            visitDate: new Date().toISOString().split("T")[0],
            slot: "",
            slotID: undefined,
          });
          dispatch(setRenderInvoice());
          setInputValue("");
        }
      } else if (
        isNew &&
        data?.appointments?.length > 0 &&
        data?.appointments?.[0]?.status === "pending" &&
        data?.appointments?.[0]?.appointment_slot_id !== formState?.slot
      ) {
        const userConfirmation = window.confirm(
          "Do you want to reschedule the appointment?"
        );
        if (userConfirmation) {
          const rePrintAppointment = await rescheduleAppointmentData(
            printPayload,
            patientDetailsQuery?.payload?.appointments[0]?.id
          );
          showToast(
            rePrintAppointment?.status?.message ||
              rePrintAppointment?.status?.status,
            rePrintAppointment?.status?.status
          );

          if (rePrintAppointment?.status?.status === "SUCCESS") {
            const printAppointMentDetail = await NewAppointmentPrint(
              {
                invoice_type: "consultancy",
                specialist_plan_id: formState?.plansDetail?.id,
              },
              rePrintAppointment?.payload?.id
            );
            setAppointPrint(printAppointMentDetail?.payload);
            setAppointmentState(patientDetailsQuery?.payload);
            if (printAppointMentDetail?.payload?.invoices?.length > 0) {
              setTimeout(() => {
                document.getElementById("print-button").click();
                printRef.current.click();
              }, 1000);
            }
            setFormState({
              visitDate: new Date().toISOString().split("T")[0],
              slot: "",
              slotID: undefined,
            });
            setInputValue("");
            dispatch(setRenderInvoice());
          }
        }
      } else {
        let createAppoint = {
          appointment: {
            visit_type: formState?.patientType?.toLowerCase() || null,
            visit_date: formState?.visitDate || null,
            appointment_slot_id: formState?.slot || null,
            consultant_id: formState?.consultancyDoctor || null,
            doctor_id: formState?.attendingDoctor || null,
            patient_id: result?.payload?.id || null,
          },
        };
        createAppoint = cleanPayload(createAppoint);
        let resultAppointment = await savePatientAppointment(createAppoint);
        showToast(
          resultAppointment?.status?.message ||
            resultAppointment?.status?.status,
          resultAppointment?.status?.status
        );
        if (resultAppointment?.status?.status === "SUCCESS") {
          const printAppointMentDetail = await NewAppointmentPrint(
            {
              invoice_type: "consultancy",
              specialist_plan_id: formState?.plansDetail?.id,
            },
            resultAppointment?.payload?.id
          );
          setAppointPrint(printAppointMentDetail?.payload);
          setAppointmentState(resultAppointment?.payload?.patient);
          if (printAppointMentDetail?.payload?.invoices?.length > 0) {
            setTimeout(() => {
              document.getElementById("print-button").click();
              printRef.current.click();
            }, 1000);
          }
          setFormState({
            visitDate: new Date().toISOString().split("T")[0],
            slot: "",
            slotID: undefined,
          });
          setInputValue("");
          dispatch(setRenderInvoice());
        }
      }
      setButtonLoading(true);
    } else {
      let createData = {
        patient: {
          full_name: formState?.fullName || null,
          visit_type: formState?.patientType?.toLowerCase() || null,
          email: formState?.email || null,
          mobile: formState?.mobileNumber || null,
          age: formState?.age || null,
          gender: formState?.gender?.toLowerCase() || null,
          marital_status: formState?.maritalStatus?.toLowerCase() || null,
          address: formState?.address || null,
          pincode: formState?.pincode || null,
          diet: formState?.diet?.split("-")?.join("_")?.toLowerCase() || null,
          city: formState?.city?.id
            ? String(formState.city.id)
            : formState?.city
            ? String(formState.city)
            : null,
          occupation_id: formState?.occupation?.id
            ? String(formState.occupation.id)
            : formState?.occupation
            ? String(formState.occupation)
            : null,
          education_id: formState?.education?.id
            ? String(formState.education.id)
            : formState?.education
            ? String(formState.education)
            : null,
        },
      };
      createData = cleanPayload(createData);
      let result = await createAppointment(createData);

      if (isNew && result?.status?.status === "SUCCESS") {
        const createAppoint = {
          appointment: {
            visit_type: formState?.patientType?.toLowerCase() || null,
            visit_date: formState?.visitDate || null,
            appointment_slot_id: formState?.slot || null,
            consultant_id: formState?.consultancyDoctor || null,
            doctor_id: formState?.attendingDoctor || null,
            patient_id: result?.payload?.id || null,
          },
        };

        let resultAppointment = await savePatientAppointment(createAppoint);
        showToast(
          resultAppointment?.status?.message ||
            resultAppointment?.status?.status,
          resultAppointment?.status?.status
        );
        if (resultAppointment?.status?.status === "SUCCESS") {
          const printAppointMentDetail = await NewAppointmentPrint(
            {
              invoice_type: "consultancy",
              specialist_plan_id: formState?.plansDetail?.id,
            },
            resultAppointment?.payload?.id
          );
          // console.log(printAppointMentDetail)
          if (printAppointMentDetail.status?.status === "SUCCESS") {
            setAppointPrint(printAppointMentDetail?.payload);
            setAppointmentState(resultAppointment?.payload?.patient);
            if (printAppointMentDetail?.payload?.invoices?.length > 0) {
              setTimeout(() => {
                document.getElementById("print-button").click();
                printRef.current.click();
              }, 2000);
            }
            setFormState({
              visitDate: new Date().toISOString().split("T")[0],
              slot: "",
              slotID: undefined,
            });
            setInputValue("");
            dispatch(setRenderInvoice());
          }
        }
      }
      showToast(
        result?.status?.message || result?.status?.status,
        result?.status?.status
      );
    }
    setButtonLoading(false);
  };
  const handleSavePatientReprintAppointment = async () => {
    // console.log(formState);
    const idInvoice = patientDetailsQuery?.payload?.invoice_id
      ? patientDetailsQuery?.payload?.invoice_id
      : formState?.invoiceId;
    const result = await rePrintInvoice(idInvoice);
    setAppointPrint({ invoices: [result?.payload] });
    setAppointmentState(patientDetailsQuery?.payload);
    if (result?.payload?.id) {
      setTimeout(() => {
        document.getElementById("print-button").click();
        printRef.current.click();
      }, 1000);
    }
    if (result?.status?.status === "SUCCESS") {
      // setFormState({
      //   visitDate: new Date().toISOString().split("T")[0],
      //   slot: "",
      //   slotID: undefined,
      // });
      setInputValue("");
    }
  };

  const getMedicalTestByPaid = async (id) => {
    const result = await getMedicalTestByPatientId(id);
    if (result?.status?.status === "ERROR") {
      showToast(
        result?.status?.message || result?.status?.status,
        result?.status?.status
      );
    } else if (result?.status?.status === "SUCCESS") {
      dispatch(setMedicalTestData(result?.payload?.medical_tests));
    }
  };

  const renderField = (field) => {
    const getCurrentDate = () => {
      return new Date().toISOString().split("T")[0];
    };
    let minDate, maxDate, maxLength;
    if (field.type === "date") {
      if (field.name === "visitDate") {
        const currentDate = getCurrentDate();
        minDate = currentDate;
        maxDate = currentDate;
      }
    } else if (field.name === "mobileNumber") {
      maxLength = 10;
    } else if (field.name === "age") {
      maxLength = 3;
    }

    switch (field.field) {
      case "TextField":
        return (
          <TextField
            fullWidth
            required={true}
            type={field.type}
            disabled={field.isDisabled}
            placeholder={field.placeholder}
            sx={field.sx}
            variant={field.variant}
            name={field.name}
            value={formState[field.name] || field.defaultValue || ""}
            onChange={handleFormChange}
            inputProps={{
              min: minDate,
              max: maxDate,
              maxLength: maxLength,
            }}
            error={Boolean(errors[field.name])}
            helperText={errors[field.name] || ""}
          />
        );
      case "Select":
        return (
          <FormControl fullWidth variant="outlined">
            <Select
              fullWidth
              // disabled={field?.isDisabled}
              required={true}
              sx={{
                ...field.sx,
                color: formState[field.name]
                  ? "text.black"
                  : "text.default_grey",
              }}
              value={formState[field.name] || ""}
              onChange={handleFormChange}
              name={field.name}
              displayEmpty
            >
              <MenuItem value="" disabled={field?.name !== "slot"}>
                {field?.placeholder || "Select an option"}
              </MenuItem>

              {field?.options?.map((option) => (
                <MenuItem
                  id={option?.value || "menu"}
                  key={option.value + Math.random()}
                  value={option.value}
                  sx={{
                    backgroundColor:
                      option.value === formState[field.name]
                        ? "#0067FF40" // Background color for the selected option
                        : "inherit",
                    // backgroundColor:
                    //   option.value === formState[field.name]
                    //     ? "#f58633 !important"
                    //     : "inherit",
                    color:
                      option.value === formState[field.name]
                        ? "black !important"
                        : "black",

                    ...(option.value === field.defaultValue
                      ? field.defaultMenuStyle
                      : field.menuItemStyle),
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {field.name === "getPlans" && isLoadingPlans && (
              <InputAdornment
                position="end"
                sx={{ marginLeft: "150px", marginTop: "-20px" }}
              >
                <CircularProgress size={20} />
              </InputAdornment>
            )}
            {errors[field.name] && (
              <Typography fontSize={"12px"} color={"#d32f2f"}>
                {errors[field.name]}
              </Typography>
            )}
          </FormControl>
        );
      case "Autocomplete":
        return (
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              options={[
                ...(fieldOptions[field.name] || field.options),
                { id: "other", value: "other", title: "Other" },
              ]}
              value={
                (formState[field.name] &&
                  (fieldOptions[field.name] || field.options)?.find(
                    (option) =>
                      option.value ===
                        (formState[field.name]?.value ||
                          formState[field.name]) ||
                      option.title ===
                        (formState[field.name]?.title ||
                          formState[field.name]) ||
                      option.id === formState[field.name]
                  )) ||
                null
              }
              onChange={(event, newValue) =>
                handleFormChange(null, newValue, field.name)
              }
              PaperComponent={({ children }) => (
                <div
                  style={{
                    width: "max-content",
                    minWidth: "100%",
                    zIndex: 100000,
                    backgroundColor: "white",
                  }}
                >
                  {children}
                </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={formState[field.name]}
                  placeholder={field.placeholder}
                  variant={field.variant}
                  fullWidth
                  sx={field.sx}
                />
              )}
              getOptionLabel={(option) =>
                option.title && option.value ? `${option.title}` : ""
              }
              renderOption={(props, option, { index }) => (
                <MenuItem
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      color: "black !important",
                      borderRadius: "20px",
                    },
                    ...(index === 0 && {
                      backgroundColor: "#0067FF40 !important",
                      color: "black !important",
                      borderRadius: "20px",
                    }),
                  }}
                  {...props}
                  key={option.id + Math.random()}
                >
                  {option.title}
                </MenuItem>
              )}
            />
            {showNewValueField[field.name] && (
              <Box mt={1} display="flex" alignItems="center">
                <TextField
                  placeholder={`Add new ${field.name}`}
                  variant="outlined"
                  fullWidth
                  value={newValues[field.name] || ""}
                  onChange={(e) =>
                    setNewValues({ ...newValues, [field.name]: e.target.value })
                  }
                  sx={{
                    ...field.sx,
                    "& .MuiInputBase-root": {
                      backgroundColor: "neutral.lightOrange",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => handleAddNewValue(field.name)}
                        sx={{
                          p: 0,
                          m: 0,
                          backgroundColor: "white",

                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <AddCircleOutlineRoundedIcon color="white" />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            )}
            {errors[field.name] && (
              <Typography fontSize={"12px"} color={"#d32f2f"}>
                {errors[field.name]}
              </Typography>
            )}
          </FormControl>
        );
      default:
        return null;
    }
  };
  // console.log(formState, "Form");
  const renderWaitingChip = () => {
    return (
      <Chip
        label="waiting"
        sx={{
          bgcolor: "#e9e165",
          border: "1px solid #e9e165",
          color: "black", // Text color to match the design

          width: "fit-content",
          height: "22px",
          padding: "0px !important",
          fontSize: "0.7rem",
          "& .MuiChip-label": {
            padding: "0px",
            pl: "6px",
            pr: "6px",
          },
        }}
      />
    );
  };
  // console.log(formState, "formState");
  // console.log(data, "patientData");
  // console.log(patientDetailsQuery, "patientDetailsQuery");
  return (
    <>
      <Grid
        container
        md={3.6}
        lg={3.6}
        sm={5.5}
        sx={{
          backgroundColor: "#F7F7FF",
          paddingX: "15px",
          paddingY: "35px",
          borderTopRightRadius: "20px",
          marginLeft: "-10px",
        }}
        height={"100%"}
      >
        {/* {"-----Search Patient Information-----"} */}
        <Grid item sm={12}>
          <Autocomplete
            popupIcon={false}
            open={patientData?.length > 0 && openSearchDropDown}
            clearIcon={false}
            onOpen={() => setOpenSearchDropDown(true)}
            onClose={() => {
              dispatch(setSearchPatient([]));
              setOpenSearchDropDown(false);
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                selectedPatient(newValue);
                setInputValue("");
              }
            }}
            // value={inputValue}
            onInputChange={handleInputChange}
            options={patientData || []}
            filterOptions={(options) => options}
            getOptionLabel={(option) =>
              option.uid && option.full_name
                ? `${option.uid} - ${option.full_name}`
                : ""
            }
            noOptionsText=""
            renderOption={(props, option) => (
              <MenuItem
                key={option.id + Math.random()}
                // onClick={() => selectedPatient(option)}
                {...props}
                sx={{
                  ...menuItemStyle,
                  width: "100%",
                  "&:hover, &.Mui-focused": {
                    backgroundColor: "#0067FF40 !important",
                    color: "black !important",
                  },
                }}
              >
                {option.uid
                  ? `${option.uid} - ${option.full_name}`
                  : option.full_name}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={false}
                fullWidth
                id="search"
                placeholder="Search Patient Id, Name, Mobile, Email"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <SearchIcon sx={{ opacity: 0.4 }} color="grey" />
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                sx={{
                  ...textfieldTextPrep,
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          sx={{
            //  border: "1px solid rgba(0, 0, 0, 0.125)",
            padding: "1rem",
          }}
        >
          {/* {"-----Patient Form----"}  */}
          <Grid container spacing={1.5}>
            {allFormFields?.map((field) => (
              <Grid item {...field.gridProps} key={field.id}>
                {field.isTitle && (
                  <TextView variant="title_medium">{field.isTitle}</TextView>
                )}
                {renderField(field)}
              </Grid>
            ))}
          </Grid>

          {/* {"Button For Appointment"} */}
          <Box sx={buttonWrapper}>
            {formState?.patientAppointment?.length > 0 &&
              formState?.patientAppointment[0]?.status === "paid" &&
              formState?.patientAppointment[0]?.visit_date ===
                new Date().toISOString().split("T")[0] && (
                <Button
                  onClick={handleSavePatientReprintAppointment}
                  variant="contained"
                  className="btn draw-border"
                  disabled={buttonLoading}
                  sx={{
                    ...primaryBtn,
                    order: 0,
                    backgroundColor: "#FF392B",
                    "&:hover": {
                      backgroundColor: "neutral.brown",
                    },
                  }}
                >
                  Reprint
                </Button>
              )}
            <Button
              onClick={() => handleSavePatient(false)}
              variant="contained"
              disabled={buttonLoading}
              sx={primaryBtnBlue}
              className="btn draw-border"
            >
              Save Patient Info
            </Button>

            {showGenRateInvoice && (
              <Button
                onClick={() => {
                  handleSavePatient1(true);
                }}
                disabled={buttonLoading}
                variant="contained"
                sx={primaryBtnBlue}
                className="btn draw-border"
              >
                Generate Invoice
              </Button>
            )}
          </Box>
        </Grid>

        {formState?.patientAppointment?.length > 0 && (
          <Grid
            mt={4}
            item
            sm={12}
            sx={{
              borderRadius: "20px",
              backgroundColor: "white",
              padding: "20px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{ marginTop: "20px" }}
                variant="heading1"
                // gutterBottom
                fontSize={"18px"}
              >
                Appointments
                {` ${"  "}: ${formState?.patientAppointment?.length}`}
              </Typography>

              <hr style={{ opacity: "0.6" }} />

              <TableContainer sx={{ mt: 2 }}>
                <Table sx={{ overflowY: "scroll" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ ...tableCellStyle, textAlign: "center" }}
                      >
                        <Typography>Appointment Status</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ ...tableCellStyle, textAlign: "center" }}
                      >
                        <Typography>Visit Details</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ alignSelf: "center" }}>
                    {formState?.patientAppointment?.length === 0 && (
                      <TableCell
                        sx={{ textAlign: "center", border: "none" }}
                        rowSpan={3}
                        colSpan={3}
                      >
                        <Typography variant="heading2" color="textSecondary">
                          No Data Found
                        </Typography>
                      </TableCell>
                    )}
                    {formState?.patientAppointment?.map((el, index) => (
                      <TableRow
                        key={el?.id + index}
                        sx={{
                          backgroundColor: "#F7F7FF",
                          paddingY: "6px",
                          cursor: "pointer",
                          borderBottom: "8px solid white",
                          borderTop: index === 0 ? "6px solid white" : "",
                          transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
                          "&:hover": {
                            transform: "scale(1.001)", // Slight scaling for the pop-up effect
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                            borderRadius: "20px !important",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "4px",
                            paddingY: "10px",
                            paddingLeft: "16px",
                            textAlign: "center",
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                          }}
                        >
                          <Typography
                            onClick={() => {
                              if (el?.status === "completed") {
                                getMedicalTestByPaid(el?.id);
                              }
                            }}
                            variant="body_Grey1"
                            color="textSecondary"
                            sx={{
                              cursor:
                                el?.status === "completed" ? "pointer" : "text",
                            }}
                          >
                            {el?.status}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            p: "4px",
                            paddingLeft: "16px",
                            textAlign: "center",
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                          }}
                        >
                          <Typography
                            variant="body_Grey1"
                            color="textSecondary"
                          >
                            {el?.visit_date ? el?.visit_date : "--"}
                            {el?.visit_time
                              ? ` ${" , "}  ${" "} ${el?.visit_time} `
                              : renderWaitingChip()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
        <Box>
          <ReactToPrint
            trigger={() => (
              <Button
                sx={{ ...primaryBtn, display: "none" }}
                id="print-button"
                ref={printRef}
              >
                Print
              </Button>
            )}
            content={() => printRef.current}
          />

          <PrintAppointment
            appointmentState={appointmentState}
            printData={appointmentPrint}
            formState={formState}
            ref={printRef}
          />
          <Modal
            open={isLoading}
            // open={true}
            //  onClose={}
            sx={{
              border: "none !important",
              outline: "none !important",
              position: "absolute",
              top: "40%",
              left: "47%",
            }}
          >
            <Box
              sx={{
                animation: `${rotateSpinner} 1s linear infinite`,
                width: "50px",
                border: "none !important",
                outline: "none !important",
              }}
            >
              <img
                src={loadingSpinner}
                alt=""
                style={{
                  width: "50px",
                  height: "50px",
                  border: "none !important",
                  outline: "none !important",
                }}
              />
            </Box>
            {/* <img src={ring_loader_blue}  alt='' style={{ width:"260px" }}/>   */}
            {/* </Box> */}
          </Modal>
        </Box>
      </Grid>
    </>
  );
};

export default PatientsInfo;
