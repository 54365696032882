// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import appointmentReducer from "./slices/appointmentSlice";
import prescriptionReducer from "./slices/prescriptionSlice";
import billingSlice from "./slices/billingSlice";
import nurseSlice from "./slices/nurseSlice";
import pharmacySlice from "./slices/pharmacySlice";

const store = configureStore({
  reducer: {
    appointment: appointmentReducer,
    prescription: prescriptionReducer,
    auth: authReducer,
    billing: billingSlice,
    nurse :  nurseSlice,
    pharmacy :pharmacySlice,
  },
});

export default store;
