import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getMedicineDetail } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/pharmacySlice";

const UseGetMedicineDetail = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getMedicineDetail", id],
    queryFn: () => getMedicineDetail(id),
    enabled: !!id,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
  });
};

export default UseGetMedicineDetail;
