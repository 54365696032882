export const titleStyle = {
  flexGrow: 1,
  textAlign: "center",
  fontSize: "1.2rem",
  marginBottom: "2rem",
};

export const appointmentTableHead = {
  background: "rgba(31, 32, 34, 0.1)",
  pr: "15px",
};
export const appointmentTableCell = { p: "10px", fontWeight: 400 }