
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Box, Typography, IconButton, Button,   Table,
  TableBody,TableRow,  TableContainer, TableHead,
    Chip,Paper,
   Select,
   InputLabel,
   FormControl,
   MenuItem,
   Autocomplete,
   TextField,
   ListSubheader} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
  slotBoxWrapper,
  slotButton,
  slotWrapper,
} from "../../style/SlotComponentStyle";
import { useDispatch, useSelector } from "react-redux";
import { UseFetchSlots } from "../../helper/UseFetchSlots";
import {
  setLoading,
  setSelectedSlot,
  setSlots,
} from "../../redux/slices/appointmentSlice";
import UseFetchFutureAppointment from "../../helper/UseFetchFutureAppointment";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  appointmentBox,
  appointmentTableHead,
  titleStyle,
} from "../../style/FutureAppointmentStatusStyle";
import Loader from "../UI/Loader";
import { textfieldTextBlueBorder } from "../../style/PatientDetailsStyle";
import { Datepicker, DatepickerEvent} from "@meinefinsternis/react-horizontal-date-picker";
import { enUS } from "date-fns/locale";
import { menuItemStyle } from "../../style/AppointmentStyle";

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const SlotList = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); 
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const currentYear = new Date().getFullYear();

  const dispatch = useDispatch();
  const formatDate = useCallback((date) => {
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }, []);

  const dates = useMemo(() => {
    return {
      currentDay: formatDate(selectedDate),
    };
  }, [selectedDate, formatDate]);
 

  const handleChangeDate = (d: DatepickerEvent) => {
    const [newDate] = d;
    setSelectedDate(newDate);
    refetch(formatDate(newDate));
    
  };

  // const handleChangeMonth = (event) => {
  //   const newMonth = event.target.value; // Ensure this returns the correct month index (0 for Jan, 11 for Dec)
  //   setSelectedMonth(newMonth);
  
  //   const today = new Date();
  
  //   // Check if the selected month is the current month and set the date appropriately
  //   const newDate = newMonth === today.getMonth()
  //     ? new Date(currentYear, newMonth, today.getDate())
  //     : new Date(currentYear, newMonth, 1);
  
  //   setSelectedDate(newDate);
  //   refetch(formatDate(newDate)); // Ensure formatDate handles dates correctly
  // }; 
  
  // const handleChangeMonth = (event) => {

  //   const newMonth = event.target.value; // Ensure this returns the correct month index (0 for Jan, 11 for Dec)
  //   setSelectedMonth(newMonth);

  //   if(!newMonth){
  //     console.log(event)
  //     console.log(newMonth ,'newMonth')
  //     return
  //   }
  
  //   const today = new Date();
  //   let newDate;
  
  //   // Handle year transition and set new date
  //   if (newMonth === 0 && today.getMonth() === 11) { // Jan 2024 selected after Dec 2024
  //     newDate = new Date(currentYear + 1, newMonth, 1);
  //   } else {
  //     newDate = new Date(currentYear, newMonth, 1);
  //   }
  
  //     // const newDate = newMonth === today.getMonth()
  //     // ? new Date(currentYear, newMonth, today.getDate())
  //     // : new Date(currentYear, newMonth, 1);
  //   setSelectedDate(newDate);
  //   refetch(formatDate(newDate)); // Ensure formatDate handles dates correctly

  // };

  const handleChangeMonth = (event) => {
    const newMonth = event.target.value; // Ensure this returns the correct month index (0 for Jan, 11 for Dec)
    setSelectedMonth(newMonth);
  
    if (newMonth === undefined || newMonth === null) {
      console.log("Event:", event);
      console.log("New Month:", newMonth);
      return;
    }
  
    const today = new Date();
    const currentMonth = today.getMonth(); // Current month index (0-11)
    const currentYear = today.getFullYear(); // Current year
    let newYear = currentYear;
  
    // Determine if the selected month is for the next year
    if (newMonth < currentMonth) {
      // If the selected month is less than the current month, it's in the next year
      newYear = currentYear + 1;
    }
  
    // Check if the user selects the current month
    const newDate = newMonth === currentMonth
      ? new Date(currentYear, newMonth, today.getDate()) // Use today's date if same month
      : new Date(newYear, newMonth, 1); // Otherwise, default to the 1st of the month
  
    setSelectedDate(newDate);
    refetch(formatDate(newDate)); // Ensure formatDate correctly formats the date
  };
  

   // Calculate the upcoming 3 months dynamically and group by year-end transition
   const upcomingMonths = useMemo(() => {
    const monthsToShow = [];
    const currentMonthIndex = new Date().getMonth();
    
    // Loop for 3 months, and adjust year if needed
    for (let i = 0; i < 3; i++) {
      const monthIndex = (currentMonthIndex + i) % 12;
      const year = currentMonthIndex + i >= 12 ? currentYear + 1 : currentYear; // Handle year transition
      monthsToShow.push({ month: months[monthIndex], year, monthIndex });
    }
  
    // Group by year if needed
    const groupedMonths = monthsToShow.reduce((acc, { year, month, monthIndex }) => {
      if (!acc[year]) acc[year] = [];
      acc[year].push({ month, monthIndex });
      return acc;
    }, {});
  
    return groupedMonths;
  }, [currentYear]);



  const currentQuery = UseFetchSlots(dates?.currentDay);
  const { slots } = useSelector((state) => state.appointment);

  useEffect(() => {
    dispatch(setLoading(true));
    if (currentQuery.data) {
      dispatch(
        setSlots({
          date: "currentDay",
          slots: currentQuery?.data?.payload?.slots,
        })
      );
      dispatch(
        setSlots({ date: "allSlots", slots: currentQuery.data.payload.slots })
      );
    }
    }, [currentQuery.data , dispatch]);
  
  const handleSelectSlot = (date, slot, slotId) => {
    dispatch(setSelectedSlot({ date, slot, slotId }));
  };

  const getSelectedDate = () => {
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(selectedDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  

  const getFutureAppointmentQuery = UseFetchFutureAppointment(
    getSelectedDate(),
    currentPage,
    perPage
  );

  const { data, isLoading, refetch } = getFutureAppointmentQuery;
  useEffect(() => {
    refetch(getSelectedDate(), currentPage, perPage);
  }, [selectedDate, refetch]);

  
   // Disable past months in the dropdown
   const currentMonth = new Date().getMonth();

  
  //  console.log(selectedDate);
  //  console.log(selectedMonth);


  return ( 
    <Grid
      container
      justifyContent={"center"}
      
      sm={5}
      md={4.5}
      lg={4.4} 
      sx={{...slotWrapper , marginLeft:'6px'}}
    >


       {/*appointments */}
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{width:'97%', margin:'auto' }}>
        
        <Box>
        <Typography
          variant="body"
          fontWeight={400}
          gutterBottom
          sx={titleStyle}
           fontFamily={'Outfit'}
        >     
          Schedule calender 
        </Typography>  
        </Box>

      <Box>
       {/* <FormControl fullWidth variant="outlined" sx={{ minWidth: 100 }}>
            <InputLabel id="month-select-label">Month</InputLabel>
            <Select
              labelId="month-select-label"
              value={selectedMonth}
              onChange={handleChangeMonth}
              label="Month"
              sx={{
                ...textfieldTextBlueBorder,
                borderRadius: '10px',
                "& .MuiSelect-select": {
                  borderRadius: '10px',
                  padding: "8px !important",
                },
              }}
            > 
              {months.map((month, index) => (
                <MenuItem key={index} value={index} 
                sx={{
                   ...menuItemStyle   
                }}>
                  {month} 
                </MenuItem>
              ))}
            </Select>

          </FormControl>  */}

{/*        
   <FormControl fullWidth variant="outlined" sx={{ minWidth: 100 }}>
  <InputLabel id="month-select-label">Month</InputLabel>
  <Select
    labelId="month-select-label"
    value={selectedMonth}
    onChange={handleChangeMonth}
    label="Month"
    sx={{
      ...textfieldTextBlueBorder,
      borderRadius: '10px',
      "& .MuiSelect-select": {
        borderRadius: '10px',
        padding: "8px !important",
      },
    }}
  >
    {Object.keys(upcomingMonths).flatMap((year) => [
      <ListSubheader key={`year-${year}`} disableSticky>
        {year}
      </ListSubheader>,
      ...upcomingMonths[year].map(({ month, monthIndex }) => (
        <MenuItem 
          key={`${year}-${monthIndex}`} 
          value={monthIndex} 
          sx={{ ...menuItemStyle }}
        >
          {month}
        </MenuItem>
      )),
    ])}
  </Select>
   </FormControl>
 */}

          
     </Box> 

      </Box> 

    
          <Datepicker
          // maxDate={new Date("2025-12-31")}
          // key={selectedDate.toString()} 
          startValue={selectedDate} 
          endValue={selectedDate} 
          locale={enUS}
          onChange={handleChangeDate}
          weekStartsOn={1}
          showWeekdays 
          // highlightWeekends
          // highlightWeekends={false}
          // classNames={'FC'}
          styles={{
            container: {
              marginBottom: '20px',
              fontFamily: 'Outfit',
            },
            
          }}
        />
     
   

{data?.payload?.length > 0 && (
  <Box mt={-1} sx={{ width: '100%', padding: '10px' }}>
    <Typography fontSize={'18px'} fontWeight={'500'} fontFamily={'Outfit'}>
      Booked Slots
    </Typography>
    {data?.payload?.slice(0, 1).map((row) => (
      <Box key={row.visit_date} mt={0.5}>
        <Box
          sx={{
            fontSize: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
            <Typography color={'#7A7A7A'} fontSize={'14px'}>Old</Typography>
            <Typography textAlign={'center'}>{row.old}</Typography>
          </Box>

          <Box sx={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
            <Typography color={'#7A7A7A'} fontSize={'14px'}>New</Typography>
            <Typography textAlign={'center'}>{row.new}</Typography>
          </Box>

          <Box sx={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
            <Typography color={'#7A7A7A'} fontSize={'14px'}>Review</Typography>
            <Typography textAlign={'center'}>{row.review}</Typography>
          </Box>

          <Box sx={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
            <Typography color={'#7A7A7A'} fontSize={'14px'}>Complimentary</Typography>
            <Typography textAlign={'center'}>{row.complementary}</Typography>
          </Box>

          <Box sx={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
            <Typography color={'#7A7A7A'} fontSize={'14px'}>Appointments count</Typography>
            <Typography textAlign={'center'}>{row.appointments_count}</Typography>
          </Box>
        </Box>
      </Box>
    ))}
  </Box>
)}



{data?.payload?.length > 0 && (
  <Box mt={-1} mb={2} sx={{ width: '100%', padding: '10px' }}>
    <Typography fontSize={'18px'} fontWeight={'500'} fontFamily={'Outfit'}>
      Available Slots
    </Typography>
    {data?.payload?.slice(0, 1).map((row) => (
      <Box key={row.visit_date} mt={0.5}>
        <Box
          sx={{
            fontSize: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {Object?.keys(row.available_slots).map((label, index) => (
            <Box
              key={index}
              sx={{
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography color={'#7A7A7A'} fontSize={'14px'}>
              {label === 'copy' ? 'Complimentary' : label.charAt(0).toUpperCase() + label.replace(/_/g, " ").slice(1)}
              </Typography>
              <Typography textAlign={'center'}>
                {row.available_slots[label]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    ))}
  </Box>
)}

      {/* {"-------Slot lists-------"} */}
      <Grid
        justifyContent={"center"}
        display={"flex"}
         flexWrap={"wrap"}
        container
        md={12}
        mt={0.5}
        spacing={1.5}
      >
        <Box
          // component={Grid}
          // item
          // md={2.6}
          pr={1}
        
          sx={{ maxHeight: "600px" , display:'flex',  marginRight:"-8px" , flexWrap:"wrap" , overflowY:'auto' ,
            '&::-webkit-scrollbar': {
             display: 'none', // Hides the scrollbar in Webkit browsers (e.g., Chrome, Safari)
              },
            '-ms-overflow-style': 'none', // Hides scrollbar in IE and Edge
            'scrollbar-width': 'none', // Hides scrollbar in Firefox
          }}
        >
        
          {slots?.currentDay?.map((slot, index) => (
            <Button
              onClick={() =>
                handleSelectSlot(dates.currentDay, slot.time, slot.id)
              }
              key={slot.id}
              sx={{...slotButton ,
                flex: '1 1 18%', // Flex basis set to 20% to ensure 5 items per row
                maxWidth: '20%', // Max width to ensure the layout holds
                boxSizing: 'border-box', // Ensures padding/borders don't affect width
                marginLeft:'8px',
                marginBottom: '8px', 
                fontFamily:'Outfit',
                color:'#3A3A3A',
              }}
            >
              {slot?.time}
            </Button>
          ))}
        </Box>


      </Grid>
    </Grid>
  );
};

export default SlotList;
