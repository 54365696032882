export const patientSearchTextField = {
  "& .MuiOutlinedInput-root": {
    height: "30px",
    "& input": {
      padding: "8px",
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white",
    transition: "background-color 0.3s",
  },

  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
  },
};
export const billingWrapper = {
  marginTop: "7rem",
  display: "flex",
  justifyContent: "space-between",
  // padding: "0rem 2rem",
  flexWrap: "wrap",
};
 
export const autoFocusOff = {
  padding: "0px", 
  maxHeight: 58,
  overflowY: "auto",
  "& .MuiOutlinedInput-root": {
    fontFamily:'Outfit',
    padding: "6px 10px",
    height: "auto",
  },
  "& .MuiInputBase-input": {
    padding: "0px 4PX", 
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ccc",
    borderRadius:"20px",
  },
  // "&:hover .MuiOutlinedInput-notchedOutline": {
  //   borderColor: "#ccc",
  //   borderRadius:"20px"
  // },
  // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //   borderColor: "#ccc",
  //   borderRadius:"20px"
  // }, 
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",   
    boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  // "& fieldset": {
  //   border: "none", // Remove default border
  // },
  // "&:hover fieldset": {
  //   border: "none", // Remove border on hover
  // },    
  // "&:hover": {
  //   boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
  //   borderRadius:"20px"
  // },
};

