export const appointmentWrapper = {
  marginTop: "7rem",
  display: "flex",
  justifyContent: "space-between",
  // padding: "0rem 2rem",
  flexWrap: "wrap",
};

export const newAppointmentSearchTextField = {
  "& .MuiOutlinedInput-root": {
    height: "30px",
    "& input": {
      padding: "8px",
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "neutral.white",
    transition: "background-color 0.3s",
  },

  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "neutral.white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
  },
};

export const disableTextField = {
  fontSize: "14px",
  "& .MuiOutlinedInput-root": {
    borderRadius:'15px',
    height: "40px",
    padding: "9px !important",
    "& input": {
      padding: "4px",
      fontSize: "14px",
      "&::placeholder": {
        color: "black", // Darker placeholder color
        opacity: 1, // Ensures the color appears as specified
      },
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#EBEBEB",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "#EBEBEB",   
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },  
};

// export const textfieldText = { 
//   padding: "10px 0px",
//   fontSize: "14px",
//   borderRadius:'15px',
//   "& .MuiOutlinedInput-root": {
  
//     padding: "9px !important",
//     height: "40px",
//     fontSize: "14px",
//     border: "none",
//     color: "#1f2022",
//     "& input": { 
//       padding: "8px",
//     },
//   },

//   "& fieldset": {
//     border: "none", // Remove default border
//   },
//   "&:hover fieldset": {
//     border: "none", // Remove border on hover
//   },     

//   "& .MuiInputBase-root": {
//     backgroundColor: "neutral.white",
//     transition: "background-color 0.3s",
//   },
//   "& .MuiInputBase-root.Mui-focused": {
//     backgroundColor: "text.white",
//     border: "1px solid #336cfb !important",
//     boxShadow:
//       "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
//   },
// };

export const textfieldText = {
  //  padding: "10px 0px",
   fontSize: "14px",
   fontFamily:'Outfit',
   transition: "all 0.4s ease-in-out",
  "& .MuiOutlinedInput-root": {
    borderRadius:'15px',
    height: "40px",
    padding: "9px !important",
    "& input": {
      padding: "4px",
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#FFFFFF",
    transition: "background-color 0.4s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "neutral.white",    
    boxShadow:
    'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },     
};

export const DShapeSaveButton={
  // margin :"0px",
  paddingY:'8px',
  borderRadius:'0px',
  borderTopRightRadius :'15px',
  borderBottomRightRadius :'15px',
  backgroundColor: "neutral.white",
  textTransform: "capitalize",
  color :'neutral.navyBlue',
  // boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
  width: "auto",
  minWidth: "0px",
  "&:hover": {
    backgroundColor: "neutral.white",
    color: "neutral.navyBlue",
    fontWeight:'800'
  },

}

export const textfieldTextBlueBorderPharmacy = {
  //  padding: "10px 0px",
   fontSize: "14px",
  "& .MuiOutlinedInput-root": {
    borderRadius:'10px',
    height: "40px",
    padding: "9px !important",
    "& input": {
      padding: "4px",
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#FFFFFF",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "neutral.white",   
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
   
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0067FF", // Blue border color
    borderWidth: "1px", // Border thickness
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0067FF", // Blue border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0067FF", // Blue border color when focused
  },
};  

// export const selectField = {
//   padding: "5px 0px",
//   fontSize: "14px",
//   color: "#1f2022",
//   backgroundColor: "text.textFieldGreyBG",
//   "& fieldset": {
//     border: "none", // Remove default border
//   },
//   "&:hover fieldset": {
//     border: "none", // Remove border on hover
//   },

//   "& .MuiSelect-select": {
//     height: "100%",
//     paddingTop: 0,
//     paddingBottom: 0,
//   },
//   "&.Mui-focused": {
//     backgroundColor: "text.white",
//     border: "1px solid #336cfb !important",
//   },
//   "&.MuiSelect-select.Mui-focused": {
//     backgroundColor: "text.white",
//     border: "1px solid #336cfb !important",
//   },
// };

// export const selectField = {
//   padding: "10px 0px",
//   fontSize: "14px",
//   fontFamily:'Outfit',
//   borderRadius:'15px',
//   backgroundColor: "white",
//   "& .MuiSelect-select": {        
//     height: "100%",
//     paddingTop: 0,
//     paddingBottom: 0,
//   },
//   "&.Mui-focused": {
//     backgroundColor: "white",
//   },
//   "&.MuiSelect-select.Mui-focused": {
//     backgroundColor: "white", 
//     boxShadow:
//     'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
//   },
//   "& fieldset": {
//     border: "none", // Remove default border
//   },
//   "&:hover fieldset": {
//     border: "none", // Remove border on hover
//   },
// };

export const selectField = {
  padding: "10px 0px",
  fontSize: "14px",
  fontFamily:'Outfit',
  borderRadius:'15px',
  backgroundColor: "white",
  "& .MuiSelect-select": {
    height: "100%",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "&.Mui-focused": {
    backgroundColor: "white",
    // border:'1px solid grey',
    boxShadow:
    // "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  "&.MuiSelect-select.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
    // "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },
};

// export const dateFiled = {
//   marginTop: "2px",
//   "& .MuiOutlinedInput-root": {
//     height: "30px",
//     fontSize: "14px",
//     "& input": {
//       padding: "8px",
//     },
//   },
//   "& fieldset": {
//     border: "none", // Remove default border
//   },
//   "&:hover fieldset": {
//     border: "none", // Remove border on hover
//   },
//   "& .MuiInputBase-root": {
//     backgroundColor: "#ebebeb",
//     transition: "background-color 0.3s",
//   },
//   "& .MuiInputBase-root.Mui-focused": {
//     backgroundColor: "white",
//     border: "1px solid #336cfb !important",
//     boxShadow:
//       "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
//   },
// };


export const disableSelectField = {
  padding: "10px 0px",
  fontSize: "14px",
  borderRadius:'15px',
  fontFamily:'Outfit',
  backgroundColor: "#EBEBEB",
  "& .MuiSelect-select": {        
    height: "100%",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "&.Mui-focused": {
    backgroundColor: "#EBEBEB",
  },
  "&.MuiSelect-select.Mui-focused": {
    backgroundColor: "#EBEBEB", 
    boxShadow:
    'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },
};

export const dateFiled = {
  marginTop: "2px",
  "& .MuiOutlinedInput-root": {
    height: "40px",
    fontFamily:'Outfit',
    borderRadius:'15px',
    fontSize: "14px",
    "& input": {
      padding: "8px",
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },
}; 

export const buttonWrapper = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "end",
  marginTop: 2,
  gap: "1rem",
  flexWrap: "wrap",
};
 
export const primaryBtn = {
  backgroundColor: "#6E9BDF",
  textTransform: "capitalize",
  borderRadius:'20px',
  transition: "all 0.4s ease-in-out",
  fontSize:"14px",
  "&:hover": {
    // fontSize: "14.6px",
    backgroundColor: "#4B6C9E",
    color: "white",
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
  },
  boxShadow:'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  height:'28px',
  p: 2.4, 
  m: 0, 
  "&:active": {
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 12px 18px -4px, rgba(0, 0, 0, 0.08) 0px 6px 8px -3px',
    backgroundColor: "#4B6C9E",
  },

};        

export const sevendayslotWrapper = {
  border: "1px solid rgba(0, 0, 0, 0.125)",
  padding: "15px",
  marginTop: "2rem",
};
export const eachSlotWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "3px",
  padding: "0.675rem",
  minWidth: "3rem",
  minHeight: "3rem",
  borderRadius: "6px",
  border: "1px solid rgba(0, 0, 0, 0.125)",
};
export const slotBoxWrapper = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  flexWrap: "wrap",
  justifyContent: "center",
  mt: "2rem",
};
export const menuItemStyle = {
  fontFamily:'Outfit',
  // textTransform:'capitalize',
  textTransform: 'capitalize',
  "&:hover": {
    backgroundColor: "#0067FF40 !important",
    borderRadius:"16px",
    color: "black !important",
  }, 
  fontSize: "14px",
  marginX:"8px",
  fontWeight:"420",
  // ...(index === highlightedIndex && {
  //   backgroundColor: "#0067FF40 !important",
  //   borderRadius:"16px",
  //   color: "white !important",
  // }),
};

export const tableCellStyle = {
  padding: "10px 10px 10px 16px",
  fontFamily:'Outfit'
};
export const actionIconStyle = {
  color: "text.green1",
  width: "35px",
  height: "35px",
  boxShadow: "2xl",
  cursor: "pointer",
};
