import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setError } from "../redux/slices/appointmentSlice";
import { getAvailableSlots } from "../connections/getApi";

export const UseFetchSlots = (date) => {
  const dispatch = useDispatch();
  const { slots } = useSelector((state) => state.appointment);
  return useQuery({
    queryKey: ["getAvailableSlots", date],
    queryFn: () => getAvailableSlots(date),
    enabled: !!date || !slots,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};
