export const prescriptionWrapper = {
  marginTop: "5rem",
  display: "flex",
  justifyContent: "space-between",
  // padding: "0rem 1rem",
   backgroundColor:'#F1F1FF', 
  //  background:"transparent",
};

export const primaryBtn = {
  mt: 3,
  mb: 3, 
  width: "100%",
  backgroundColor: "#f58633",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
  "&:hover": {
    backgroundColor: "#ff6600",
    color: "black",
  },
};

export const textfiledText = {
  "& .MuiOutlinedInput-root": {
    height: "35px",
    "& input": {
      padding: "8px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#ebebeb",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
};
