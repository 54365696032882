export function pxToRem(value) {
  return `${value / 16}rem`;
}

const FONT_FAMILY = {
  loto: "Outfit, Lato, sans-serif",
  Outfit :'Outfit',
};

const typography = {
  title: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(40),
    lineHeight: pxToRem(16),
    fontWeight: 400,
  },
  title_medium: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(14), 
    lineHeight: pxToRem(16),
    fontWeight: 550,
  },
  title1_medium: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
    fontWeight: 500,
  },
  title_small: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(16),
    fontWeight: 600,
  },
  title_small2: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(18),
    fontWeight: 500,
  },
  heading_Large: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(16),
    fontWeight: 600,
  },
  heading1: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(25.2),
    fontWeight: 600,
  },
  heading2: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16), 
    fontWeight: 800,
  },
  heading2_14: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 500,
  },
  heading2_16: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 700,
  },
  heading_light: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(17),
    lineHeight: pxToRem(16),
    fontWeight: 400,
  },
  subTitle116px: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(16),
     lineHeight: pxToRem(20),
    fontWeight: 400,
  },

  subTitle1: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(26),
    fontWeight: 400,
  },
  body_Grey: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(12),
    fontWeight: 400,
  },
  body_Grey1: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    fontWeight: 400,
  },
  body1_text: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 400,
  },
  body2_text: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(12.25),
    lineHeight: pxToRem(16),
    fontWeight: 400,
  },
  body3_text: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  body1_text_bold: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22),
    fontWeight: 420,
  }, 
  body1_text_bold2: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(13),
    lineHeight: pxToRem(16),
    fontWeight: 900,
  },
  small_Text: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  small_Text1: {
    fontFamily: FONT_FAMILY.Outfit,
    fontSize: pxToRem(16),
    fontWeight: 200,
  },
};

export default typography;
