import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControl,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MobileScreenShareRoundedIcon from "@mui/icons-material/MobileScreenShareRounded";
import { drListWrapper, drTableRow } from "../../style/DoctorListComponent";
import {
  actionIconStyle,
  dateFiled,
  menuItemStyle,
  selectField,
  tableCellStyle,
} from "../../style/AppointmentStyle";
import UseFetchAppointmentList from "../../helper/UseFetchAppointmentList";
import { useSelector } from "react-redux";
import CustomModal from "../CustomModal/CustomModal";
import AppointmentCalls from "../ModalChildren/AppointmentCalls";
import updownar from "../../assets/updownar.svg";
import not_found from "../../assets/not_found.svg";

const DoctorsList = () => {
  const { doctorOptions , renderAppointment } = useSelector((state) => state.appointment);
  const [callModal, setCallModal] = useState(false);
  const [modalDetail, setModalDetail] = useState({ title: "", id: null });
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [status, setStatus] = useState("Pending");
  const [data, setData] = useState({
    visitDate: date,
    status: status.toLowerCase(),
    doctorId: 1,
  });
  const {
    data: filterList,
    refetch,
    isError,
    isLoading,
  } = UseFetchAppointmentList(data);

  const handleDateChange = (event) => {
    setDate(event.target.value);
    setData((prev) => ({
      ...prev,
      visitDate: event.target.value,
    }));
  };

  // console.log(isError, "error");
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setData((prev) => ({
      ...prev,
      status: event.target.value.toLowerCase(),
    }));
  };
  const handleDoctorChange = (event) => {
    setData((prev) => ({
      ...prev,
      doctorId: event.target.value,
    }));
  }; 
  useEffect(() => {
    refetch(data);
  }, [data, callModal === false  , renderAppointment]);
  // console.log(data, filterList);
  return (
    <>
      <Grid
        mt={0.1}
        sx={{
          backgroundColor: "#F7F7FF",
          paddingX: "15px",
          paddingY: "35px",
          marginRight: "-10px",
          marginLeft: "8px",
          borderTopLeftRadius: "20px",
        }}
        container
        spacing={2}
        justifyContent="center"
        lg={4}
        md={3.5}
        sm={12}
      >
        {/* Doctor list */}
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            mt={-3}
          >
            <FormControl
              sx={{
                width: {
                  base: "100%",
                  sm: "25%",
                  md: "35%",
                },
              }}
              variant="outlined"
            >
              <Select
                fullWidth
                sx={{
                  ...selectField,
                }}
                value={data?.doctorId || ""}
                onChange={handleDoctorChange}
                name="doctor"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  {"Select Doctor"}
                </MenuItem>
                {doctorOptions?.doctors?.map((option) => (
                  <MenuItem
                    id={option?.value || "menu"}
                    key={option.value + Math.random()}
                    value={option.id}
                    sx={menuItemStyle}
                  >
                    {option.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="date"
              value={date}
              onChange={handleDateChange}
              variant="outlined"
              sx={dateFiled}
            />

            <FormControl
              sx={{
                width: {
                  base: "100%",
                  sm: "25%",
                  md: "25%",
                },
              }}
              variant="outlined"
            >
              <Select
                fullWidth
                value={status}
                onChange={handleStatusChange}
                sx={selectField}
              >
                <MenuItem sx={menuItemStyle} value="Finished">
                  Finished
                </MenuItem>
                <MenuItem sx={menuItemStyle} value="Pending">
                  Pending
                </MenuItem>
                <MenuItem sx={menuItemStyle} value="Confirmed">
                  Confirmed
                </MenuItem>
                <MenuItem sx={menuItemStyle} value="Rescheduled">
                  Rescheduled
                </MenuItem>
                <MenuItem sx={menuItemStyle} value="Refunded">
                  Refunded
                </MenuItem>
                <MenuItem sx={menuItemStyle} value="Missed">
                  Missed
                </MenuItem>
                <MenuItem sx={menuItemStyle} value="Completed">
                  Completed
                </MenuItem>
                <MenuItem sx={menuItemStyle} value=" Cancelled">
                  Cancelled
                </MenuItem>
                {/* <MenuItem sx={menuItemStyle} value=" Paid">
                  Paid
                </MenuItem> */}
              </Select>
            </FormControl>
          </Box>

          <TableContainer
            // component={Paper}
            sx={{ mt: 2, maxHeight: "100vh", minHeight: "70vh" }}
          >
            <Table sx={{ overflowY: "scroll" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableCellStyle}>
                    <Typography variant="small_Text1">Name </Typography>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <Typography variant="small_Text1">Patient ID</Typography>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <Typography variant="small_Text1">Visit Time</Typography>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <Typography variant="small_Text1">Calls </Typography>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <Typography variant="small_Text1">Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              {filterList?.appointments?.length > 0 ? (
                <TableBody>
                  {filterList?.appointments?.map((patient, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: "white",
                        paddingY: "6px",
                        cursor: "pointer",
                        //  "&:hover": {
                        //    boxShadow: "rgba(0, 0, 0, 0.25) 3px 3px 6px",
                        //    borderRadius:'20px',
                        //  },

                        borderBottom: "8px solid #F7F7FF",
                        borderTop: index === 0 ? "6px solid #F7F7FF" : "",
                        // '&:hover': {
                        //   boxShadow: 'rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset',
                        //   borderRadius: '20px !important',
                        // },
                        // transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
                        "&:hover": {
                          transform: "scale(1.001)", // Slight scaling for the pop-up effect
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                          borderRadius: "20px !important",
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "4px",
                          paddingLeft: "16px",
                          cursor: "pointer",
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                      >
                        <Typography variant="heading2">
                          {" "}
                          {patient?.patient_name}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <MobileScreenShareRoundedIcon
                            sx={{ height: "12px", width: "12px" }}
                          />
                          <Typography variant="body2_text">
                            {" "}
                            {patient?.mobile}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ p: "4px", pl: "16px" }}>
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {patient?.uid}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: "4px", pl: "16px" }}>
                        <Typography color={"text.grey"} variant="body3_text">
                          {patient?.visit_date}
                        </Typography>{" "}
                        <br />
                        <Typography color={"text.grey1"} variant="body2_text">
                          {patient.visit_time}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "4px", pl: "16px" }}>
                        <Typography
                          variant="body_Grey1"
                          color={"text.lightBlue1"}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalDetail((prev) => ({
                              ...prev,
                              title: patient.patient_name,
                              id: patient.id,
                            }));
                            // if (modalDetail?.title) {
                            setCallModal(true);
                            // }
                          }}
                        >
                          {patient.reminders_count}-call
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          paddingLeft: "16px",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setModalDetail((prev) => ({
                              ...prev,
                              title: patient.patient_name,
                              id: patient.id,
                            }));

                            setCallModal(true);
                          }}
                          sx={actionIconStyle}
                        >
                          <InfoIcon
                            sx={{ ...actionIconStyle, color: "#0067FF" }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableRow sx={{ borderBottom: "2px solid #F7F7FF" }}>
                  <TableCell
                    sx={{
                      textAlign: "center",
                    }}
                    rowSpan={5}
                    colSpan={5}
                  >
                    {/* <Typography color={"black"} variant="heading2">
                    Data Not Found
                  </Typography> */}

                    <Box
                      sx={{
                        width: "100%",
                        margin: "auto",
                        display: isLoading ? "none" : "",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "white",
                          borderRadius: "20px",
                          height: "170px",
                          marginTop: "80px",
                        }}
                      >
                        <Box sx={{ marginTop: "-80px", marginLeft: "30px" }}>
                          <img src={not_found} alt="" width={"160px"} />
                        </Box>

                        <Box sx={{ paddingRight: "20px", marginTop: "-14px" }}>
                          <Typography
                            fontSize={"22px"}
                            fontWeight={"bold"}
                            color={"neutral.navyBlue"}
                          >
                            <Typography variant="span" color={"black"}>
                              {" "}
                              Data not found!{" "}
                            </Typography>
                          </Typography>
                          {/* <Typography fontSize={'24px'}  fontWeight={'bold'} >You've made it </Typography> */}
                          <Typography sx={{ marginTop: "8px" }}> </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <CustomModal
        open={callModal}
        customStyles={{ minWidth: "60%" }}
        // onClose={() => setCallModal(false)}

        titleName={`Call details for ${modalDetail.title}`}
      >
        <AppointmentCalls
          refetchAppointment={refetch}
          setCallModal={setCallModal}
          id={modalDetail?.id}
          titleName={`Call details for ${modalDetail.title}`}
        />
      </CustomModal>
    </>
  );
};

export default DoctorsList;
