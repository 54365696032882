import React from 'react';
import { Box } from '@mui/material';
import { labPageWrapper } from '../style/LapPageStyle';
import TabComponent from '../Components/Lab/TabComponent';

const Lab = () => {
  return (
  <Box sx={labPageWrapper}>
   <TabComponent/>
  </Box>
  )
}

export default Lab;
 