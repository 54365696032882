import React,{useRef, useState} from 'react'
import { Typography,Box , IconButton  , Grid, Button } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { headingStyle, labPageButton } from '../../style/LapPageStyle'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { primaryBtn } from '../../style/AppointmentStyle';
import { useSelector } from 'react-redux';
import  { useReactToPrint } from "react-to-print";
// import { sendReferrerMail } from '../../connections/getApi';
import { sendReferrerMail } from '../../connections/postApi';
import PrintMail from '../Prescription/PrintMail';
import { showToast } from '../../utils/toastUtil';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { primaryBtnBlue } from '../../style/PatientDetailsStyle';

const ReferrerEmail = ({ mailTemplate , onClose}) => {
  // const{patientDetailsData}= useSelector=((state)=>)
    // console.log(mailTemplate?.id);
  const [editorData, setEditorData] = useState(`
    <p>Dated: ${new Date().toISOString().split("T")[0]}</p>
    <p>Dear ${mailTemplate?.referrer?.full_name? mailTemplate?.referrer?.full_name:' '}</p>
    <p>${mailTemplate?.referrer?.address? mailTemplate?.referrer?.address: ''},</p>
    <p>Thanks for referring ${mailTemplate?.full_name? mailTemplate?.full_name :'' } (${mailTemplate?.current_age? mailTemplate?.current_age :' ' }, ${mailTemplate?.gender? mailTemplate?.gender:' ' }) to Punjab Rheumatology & Immunology Clinic. salutation has diagnosis.</p>
    <p>Your suggestions & feedback are important for us, please feel free to write to our e-mail punjabrheumatology@gmail.com. You can also contact me at my personal number 9876702493 Dr Parshant 9876875444 Dr Bharti. For scheduling an appointment your patients can contact 9814267774, 9878736644. Looking forward to your continued support & feedback in our endeavor to limit suffering from Rheumatic & Immunological disorders</p>
    <p>With sincere Regards.</p>
    <p>Dr Parshant Aggarwal & Dr Bharti Aggarwal</p>
  `);
  
  const stripHtmlTags = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };


  const sendMail = async () => {
    const referrerId = mailTemplate?.referrer?.id;
    const patientId = mailTemplate?.id;
    const strippedData = stripHtmlTags(editorData);

  const data = {
    content: strippedData, 
    // subject: 'Referrer Email for Patient',
  }; 
    try {
      const response = await sendReferrerMail(data, referrerId, patientId);
      if(response?.status?.status==='SUCCESS'){
      showToast('mail sent successfully ' , 'SUCCESS');
      }
    
     console.log('Mail sent:', response);
    } catch (error) {
      console.error('Error sending mail:', error);
    }
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  documentTitle: 'Prescription Print',
  onAfterPrint: () => console.log('Print job finished') 
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

   // Check if the email is valid
   const isEmailValid = validateEmail(mailTemplate?.referrer?.email || "");

  return (
   
    
        <Box sx={{ width: "1100px" }}>
          
          <Box display="flex" justifyContent="space-between" alignItems="center">
           
           <Box></Box>
        

      <Box sx={{display:'flex' , alignItems:'center'}}>
      <ForwardToInboxOutlinedIcon sx={{ color:"neutral.navyBlue" ,width:'28px' ,height:'28px' , marginRight:"8px" ,  marginTop:'-4px'}}/>
        <Typography
          variant="heading1" 
          fontWeight={600}
          gutterBottom
          sx={{...headingStyle }}

        >
         Draft a  Referrer Email
        </Typography>
        </Box>


            <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
              <CloseIcon size="small" sx={{color:'red'}} />
            </IconButton>
          </Box>

          

<Box sx={{display:'flex' , justifyContent:"space-between"}}>
    
          
          <Grid
            container
            sx={{ justifyContent: "space-between", flexDirection:'column',   width: "100%" ,marginBottom:'50px' }}
          >
             <Typography sx={{md: "30px" , color:'neutral.navyBlue' , fontWeight:'bold'}}> Patient </Typography>
            <Typography>Name: {mailTemplate?.full_name} </Typography>
            <Typography>Patient ID: {mailTemplate?.id} </Typography>
            {/* <Typography>Mobile: {mailTemplate?.mobile}</Typography> */}
            <Typography>Age : {mailTemplate?.current_age}</Typography>
            <Typography>Male : {mailTemplate?.gender}</Typography>
          </Grid>

         
          <Grid
            container
            sx={{  marginLeft:"350px" , justifyContent: "space-between", flexDirection:'column',  width: "100%" ,marginBottom:'50px' }}
          >
             <Typography  sx={{md: "30px", color:'neutral.navyBlue' , fontWeight:'bold'}}> Referrer  </Typography>
            <Typography>{`Name: ${mailTemplate?.referrer?.full_name||''}` }</Typography>
            <Typography>{`Address: ${mailTemplate?.referrer?.full_name ||''}` }</Typography>
            <Typography>{`Email: ${mailTemplate?.referrer?.email ||''}` }</Typography>
            <Typography>{`Phone: ${mailTemplate?.referrer?.phone ||''}` }</Typography>
            
          </Grid>

          </Box>
          
        <CKEditor
        editor={ClassicEditor}
        data={editorData}
        config={{
          toolbar: [
            'undo', 'redo', '|' , 'heading', '|', 'bold', 'italic', '|' , 'link', 'imageUpload', 'insertTable', 'blockQuote', 'mediaEmbed',  '|' , 'bulletedList', 'numberedList',
            
          ],
          fontFamily: {
            options: [ 'Outfit', 'sans-serif', 'serif', 'monospace' ],
          }
        }}
        onReady={editor => {
          console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
        }}
      />

        <Grid container display={"flex"} justifyContent={"space-between"} sx={{ mt: 2 }} lg={12}>
       
        <Grid item>
          {/* <Button variant="contained" sx={{...primaryBtn , backgroundColor:"gray" ,"&:hover": {
          backgroundColor: "neutral.black",
        }, }}>
            <Typography fontSize={"1.1rem"} onClick={onClose}>Close</Typography>
            
          </Button> */}
        </Grid>

        <Grid item>
          <Button variant="contained" sx={{...primaryBtnBlue , width:"100px",  backgroundColor:"neutral.navyBlue"  ,"&:hover": {
         backgroundColor: "neutral.blue",
          }, 
         }} onClick={handlePrint}>
            <Typography fontSize={"1.1rem"}>Print</Typography>
          </Button>
        </Grid>
         
          {/* Conditionally render the "Send" button based on email validation */}
        {isEmailValid && (
         <Grid item>
         <Button variant="contained" 
         sx={{...primaryBtn, width:"100px"}}
         onClick={sendMail}
         >
           <Typography fontSize={"1.1rem"} >Send</Typography>
         </Button>
       </Grid>
        )}


      </Grid>
      <div style={{ display: "none" }}>
        <PrintMail ref={printRef} data={editorData} />
       </div>
 
   </Box>
  )
}

export default ReferrerEmail;