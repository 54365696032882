import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Modal,
  Skeleton,
} from "@mui/material";
import {
  headingStyle,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { DeleteForeverRounded } from "@mui/icons-material";
import CustomModal from "../CustomModal/CustomModal";
import supplier  from '../../assets/supplier.svg';
import EditSupplier from "./EditSupplier";
import UseFetchSupplierDetails from "../../helper/UseFetchSupplierDetails";
import { useNavigate } from "react-router-dom";
import { deleteSupplier } from "../../connections/deleteApi";
import { showToast } from "../../utils/toastUtil";

const SupplierDetails = ({ onClose  , id ,  refetch , page, itemPerPage  }) => {

  // console.log(medicines); setEditSupplierOrderModal
  const navigate = useNavigate();
    const {data: supplierDetails , isLoading}= UseFetchSupplierDetails(id);
    // console.log(supplierDetails)

    const[filterModal, setFilterModal] =useState(false);
    const[deleteModal, setDeleteModal] =useState(false);
    const[detailsModal,setDetailsModal]=useState(false);
    const[editSupplierOrderModal,setEditSupplierOrderModal]=useState(false);
    const[newPurchaseOrderModal,setNewPurchaseOrderModal]=useState(false);
    const [formState, setFormState] = useState({
      id: "",
      name: "",
      phone: "",
      gst_no: "",
      address: "",
      email: "",
      created_at: "",
      updated_at: "",
    });

    useEffect(() => {
      if (supplierDetails) {
        setFormState({
          id: supplierDetails?.payload?.id || "",
          name: supplierDetails?.payload?.name || "",
          phone: supplierDetails?.payload?.phone || "",
          gst_no: supplierDetails?.payload?.gst_no || "",
          address: supplierDetails?.payload?.address || "",
          email: supplierDetails?.payload?.email || "",
          created_at: supplierDetails?.payload?.created_at || "",
          updated_at: supplierDetails?.payload?.updated_at || "",
        });
      }
    }, [supplierDetails]);
 
 
    const handleDelete = async(id)=>{
      setDeleteModal(false);
       const res = await  deleteSupplier(id);
       console.log(res);
       
       showToast(  res?.status?.message ||   res?.status?.status ,  res?.status?.status);
       if( res?.status?.status==='SUCCESS'){
        refetch(page,itemPerPage);
        onClose();
       } 
    }


  return (  
    <Box sx={{ width: "100%", display:'flex' , height:"90vh" ,  flexDirection:'column' }}>

    {/* //modals for delete and filter */}
   
   <Modal 
    open={deleteModal}
    onClose={()=>setDeleteModal(false)}
    sx={{
    position:'absolute',
    top:'25%',
    width:"450px",
    margin:'auto',
    borderRadius:'20px',
   }}
  >
     
     <Box
        sx={{
          mt: 2,
          bgcolor: "background.paper",
          borderRadius: 3.6,
          boxShadow: 24,
          p: 4.5,         
          "&:focus": {
            outline: "none",
          },
        
        
        }}
      > 

        <Box sx={{display:'flex' ,flexDirection:'column'}}>
          <Typography textAlign={'center'}>Are you sure?</Typography>
          <Typography textAlign={'center'}>You want to delete these selected purchase orders?</Typography>
          
        </Box>

        <Box  sx={{display:'flex' ,flexDirection:'flex'  , gap:'25px' , width:'max-content',  margin:'auto' , marginTop:"40px"}}>
       <Button sx={{ backgroundColor:"#FF392B" , color:"white" , border:'' , width:"150px" ,  borderRadius:"5px" ,   fontSize:"12px" , textTransform:'capitalize' ,'&:hover': {
         backgroundColor:"red",
        } }} onClick={()=>{handleDelete(id)}}>Delete</Button>
       <Button onClick={()=>setDeleteModal(false)}  sx={{ backgroundColor:"white" , color:"black" , border:'2px solid #FF392B' , width:"150px" , borderRadius:"5px",  fontSize:"12px" , textTransform:'capitalize'  }}>Cancel</Button>
        </Box>
     
      </Box>
   </Modal> 
   
    <Box sx={{overflowY :'auto' , maxHeight:"80vh" ,  '&::-webkit-scrollbar': {
    display: 'none',
    },
    '-ms-overflow-style': 'none',  
    'scrollbar-width': 'none',  }}  >
      
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
      >
        <Box></Box>

         <Box sx={{display:'flex', justifyContent:'center' , alignItems:'center'  , gap:'9px'}} >
        <img src={supplier}  alt='' sx={{color:'neutral.navyBlue' , marginBottom:"4px" , marginRight:'8px'}} /> 
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={headingStyle}
        >
        Suppliers Details
       </Typography>
       </Box>
        <IconButton sx={{ marginBottom: "0rem" }} onClick={onClose}>
          <CloseIcon size="small"  sx={{color:"red"}}/>
        </IconButton>
      </Box> 
      
      <Box
       mt={2}
       mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        paddingX="25px"
      >
        <Box ></Box>
        {/* <Box > <AddRoundedIcon onClick={()=>setNewPurchaseOrderModal(true)} sx={{backgroundColor:"#FFE7DC" ,p:'4px',  width:"20px", color:"#FF8D1E" , borderRadius:"8px" ,cursor:"pointer"}}   /></Box> */}
        {/* supplierDetails?.payload */}
       <Box sx={{display:'flex' , gap:'10px' }}>  <EditRoundedIcon onClick={()=>{ setEditSupplierOrderModal(true) 
        navigate("/pharmacy",
          { state:  supplierDetails?.payload}
        )
       
       }}
     sx={{ backgroundColor:"#FF8D1E" , p:'2px',  width:"20px", color:'white' , borderRadius:"8px" ,cursor:"pointer"}}/>
       <DeleteForeverRounded onClick={()=>{setDeleteModal(true)}} sx={{backgroundColor:"#FF392B" ,p:'2px',  width:"18px", color:"white" , borderRadius:"8px" ,cursor:"pointer"}}/></Box>
      
      
    </Box> 
    </Box>

    <Box sx={{display:"flex" , flexDirection:'column' , gap:'8px'}}>
     
     <Box sx={{display:"flex" , gap:'40px' , alignItems:'center' }}> 
      <Typography variant="heading_light"> Name</Typography>
      
      {isLoading? ( <Skeleton 
        variant="rectangular"
        sx={{
          width: "250px",
          height: "18px", // Adjust to match your table row height
          backgroundColor: "#f0f0f0", // Light grey color
        }}
      />
      ):( <Typography>{formState?.name} </Typography>)}
     </Box>

     <Box sx={{display:"flex", gap:'40px' ,  alignItems:'center' }}> 
      <Typography variant="heading_light"> Phone</Typography>
  
      {isLoading? ( <Skeleton 
        variant="rectangular"
        sx={{
          width: "250px",
          height: "18px", // Adjust to match your table row height
          backgroundColor: "#f0f0f0", // Light grey color
        }}
      />
      ):(  <Typography> {formState?.phone}</Typography>)}
     </Box>
     
     <Box sx={{display:"flex", gap:'40px' , alignItems:'center' }}> 
      <Typography variant="heading_light"> GST No</Typography>
      {isLoading? ( <Skeleton 
        variant="rectangular"
        sx={{
          width: "250px",
          height: "18px", // Adjust to match your table row height
          backgroundColor: "#f0f0f0", // Light grey color
        }}
      />
      ):(  <Typography> {formState?.gst_no}</Typography>)}
     </Box>

     <Box sx={{display:"flex", gap:'40px' ,  alignItems:'center' }}> 
      <Typography variant="heading_light"> Address</Typography>
      {isLoading? (<Skeleton 
        variant="rectangular"
        sx={{
          width: "250px",
          height: "18px", // Adjust to match your table row height
          backgroundColor: "#f0f0f0", // Light grey color
        }}
      />
      ):(  <Typography> {formState?.address} </Typography>)}
     </Box>

     <Box sx={{display:"flex", gap:'40px' ,  alignItems:'center' }}> 
      <Typography variant="heading_light"> Email</Typography>
     
      {isLoading? ( <Skeleton 
        variant="rectangular"
        sx={{
          width: "250px",
          height: "18px", // Adjust to match your table row height
          backgroundColor: "#f0f0f0", // Light grey color
        }}
      />
      ):(  <Typography>  {formState?.email} </Typography>)}
     </Box>

     <Box sx={{display:"flex", gap:'40px' ,  alignItems:'center' }}> 
     <Typography variant="heading_light"> Created at </Typography>
      {isLoading? ( <Skeleton 
        variant="rectangular"
        sx={{
          width: "250px",
          height: "18px", // Adjust to match your table row height
          backgroundColor: "#f0f0f0", // Light grey color
        }}
      />
      ):( <Typography variant="heading_light">  {formState?.created_at} </Typography>)}
    
     </Box>
 
     <Box sx={{display:"flex", gap:'40px' ,  alignItems:'center' , }}> 
      <Typography variant="heading_light"> Updated at</Typography>
      
      {isLoading? ( <Skeleton 
        variant="rectangular"
        sx={{
          width: "250px",
          height: "18px", // Adjust to match your table row height
          backgroundColor: "#f0f0f0", // Light grey color
        }}
      />
      ):( <Typography> {formState?.updated_at}</Typography>)}
     </Box>

    </Box>  

   

    <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={editSupplierOrderModal}
        onClose={() => setEditSupplierOrderModal(false)}
      >
        <EditSupplier onClose={() =>  setEditSupplierOrderModal(false)} />
      </CustomModal>  
    </Box>   
  );
};

export default SupplierDetails;
