import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../connections/postApi";
import { Link, useNavigate } from "react-router-dom";
import { Container, TextField, Button, Box, Typography, Grid } from "@mui/material";
import TextView from "../Components/UI/TextView/TextView";
import { primaryBtn, textfieldText } from "../style/AppointmentStyle";
import { loginBtn, LoginForm, loginWrapper } from "../style/Login";
import Cookies from "js-cookie";
import { showToast } from "../utils/toastUtil";
import MountPageLoader from "../Components/UI/MountPageLoader";
import logo from '../assets/logo.png'
import { primaryBtnBlue } from "../style/PatientDetailsStyle";
import login_hero from "../../src/assets/login_hero.png";
import { setSubDomainRoot } from "../redux/slices/authSlice";
// import  setSubDomainRoot  from "../redux/slices/authSlice";

const Login = () => {
  const [uid, setUid] = useState("");
  const [password, setPassword] = useState("");
  const [subDomain, setSubDomain]=useState(localStorage.getItem('cyphermdSubDomain') ||"");
  const [subDomainExist, setSubDomainExist]=useState(false);
  // const [isAnimating, setIsAnimating] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingScreen, setLoadingScreen] = useState(false);

  const { isLoading, isError, errorMessage } = useSelector(
    (state) => state.auth
  );

  const authStaging = Cookies.get("authStaging")
    ? JSON.parse(Cookies.get("authStaging"))
    : null;
  const isAuthenticated = authStaging?.isAuthenticated || false;
  const redirect = authStaging?.redirect;

  // setLoadingScreen(true);
  useEffect(() => {
    if (isAuthenticated && redirect) {
      setLoadingScreen(true);
      window.location.reload();
      setTimeout(() => { 
        navigate(redirect);
        // setLoadingScreen(false);
      }, 600);
    }
  }, [isAuthenticated, redirect, navigate]);

  // Show error toast if there is an error
  useEffect(() => {
    if (isError) {
      showToast(errorMessage || "Login failed. Please try again.", "ERROR");
    }
  }, [isError, errorMessage]);

  const handleLogin = (e) => {
    if (!uid.trim()) {
      showToast("User ID is required.", "ERROR");
      return;
    }
  
    // Validate Password
    if (!password.trim()) {
      showToast("Password is required.", "ERROR");
      return;
    }
    e.preventDefault();
    dispatch(loginUser({ account: { uid, password } }));
    showToast("");
  };

  
  const handleEditSubDomain =()=>{
    // setIsAnimating(false); // Start the animation
    localStorage.setItem("cyphermdSubDomain", "");
    setTimeout(() => setSubDomainExist(false), 600);
  }
  

  useEffect(()=>{
    const storedSubDomain = localStorage.getItem('cyphermdSubDomain');
    if(storedSubDomain){
      setSubDomainExist(true);
    }
  },[subDomainExist])

  const handleStoreSubDomain = (e) => {

    if (!subDomain.toLowerCase().trim()) {
      showToast("Organization code is required.", "ERROR");
      return;
    }

     setSubDomain(subDomain.toLowerCase().trim())  // Convert to lowercase
    const isValid = /^[a-z]{0,8}$/.test(subDomain); // Regex: Only letters, max 8 characters
    if (isValid) {
      // console.log(subDomain)
      setSubDomain(subDomain); // Update state only if valid
      if (subDomain?.length <= 8 && subDomain?.length > 0) {
        localStorage.setItem("cyphermdSubDomain", subDomain); // Store in local storage
        // showToast("Subdomain saved successfully!", "SUCCESS");     
        dispatch(setSubDomainRoot(subDomain));
        // setSubDomainExist(true);
        // window.location.reload()
        // setIsAnimating(true); // Start the animation
      setTimeout(() => setSubDomainExist(true), 600);
      }}
      else {
      showToast("Invalid subdomain! Only letters are allowed, max length is 8.", "ERROR");
    }

   
  };


  if (loadingScreen || isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MountPageLoader />
      </Box>
    );
  }

  return (

   <Grid container sx={{margin:"auto"  , height:'100vh'}}>

     <Grid item lg={4.5} xm={12} md={12}  sx={{borderTopRightRadius:"20px" , marginTop:'-8px', marginLeft:"-8px", borderBottomRightRadius:'20px', backgroundColor:"#F7F7FF"}}>
     <Container maxWidth="xs" sx={{...loginWrapper , width: { xs: "100%", md: "100%" },
    }}>
 

      
      <Box display="flex" alignItems="center">
        <img
            src={logo}
            alt="Logo"
            style={{ width: "14rem" , marginBottom:'1.2rem' , marginRight:"14px"}}
          />
      
      </Box>
       
       {subDomainExist? 
       (  
        <Box sx={{...LoginForm , transition: "transform 0.6s ease-in-out"}}>
        <TextView color={"text.black"} variant="title_small" gutterBottom>
          Sign in
        </TextView>
        <TextView
          mb={"1rem"}
          variant="body_Grey"
          color={"text.grey"}
          gutterBottom
        >
          Sign in to access your Account
        </TextView>
        <TextField
          required
          fullWidth
          id="login"
          placeholder="Login"
          name="login"
          autoComplete="login"
          value={uid}
          onChange={(e) => setUid(e.target.value)}
          autoFocus
          sx={textfieldText}
        />
        <TextField
          required
          fullWidth
          name="password"
          placeholder="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ ...textfieldText, mt: "0.675rem" }}
        />
        <Button
          type="submit"        
          variant="contained"
          disabled={isLoading}
          className="btn draw-border"
          sx={{
           ...primaryBtnBlue,
           fontSize:'18px',
           marginTop:"22px",
          paddingX:'30px',
          marginBottom:"10px",
          width:"100%",
           "&:hover": {
    backgroundColor: "#055ee3", 
    color: "neutral.white",
    fontSize:"18.6px",

    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',

  },
          }}
          onClick={handleLogin}
        >
          Sign In 
        </Button>

        <Link
          type="submit"        
          variant="contained"
          // disabled={isLoading}
          // className="btn draw-border"
          sx={{
            paddingTop:'20px',

               "&:hover": {color: "blue", 
               },
          }} 
        //   sx={{
        //    ...primaryBtnBlue,
        //    fontSize:'18px',
        //    marginTop:"22px",
        //   paddingX:'30px',
        //   width:"100%",
        //    "&:hover": {
        //   backgroundColor: "#055ee3", 
        //   color: "neutral.white",
        //   fontSize:"18.6px",
        //   boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
        // }
        // }}
          onClick={handleEditSubDomain}
        >
          Change  Code ?
        </Link>
        </Box> 
       
        ) :(
       <Box sx={{...LoginForm }}>
        <TextView color={"text.black"} variant="title_small" gutterBottom>
        Organization Code
        </TextView>
        <TextView
          mb={"1rem"}
          variant="body_Grey"
          color={"text.grey"}
          gutterBottom
        >
          Enter your Organization Code
        </TextView>
        <TextField
          required
          fullWidth
          id="subdomain"
          placeholder="Organization Code"
          name="subdomain"
          autoComplete="subdomain"
          value={subDomain}         
          onChange={(e) => {
            const value = e.target.value.toLowerCase(); // Convert input to lowercase
            if (/^[a-z]*$/.test(value) && value.length <= 8) {
              // Only allow lowercase letters and restrict length to 8
              setSubDomain(value);
            }else{
              showToast("Invalid subdomain! Only letters are allowed, and  max length is 8.", "ERROR");
            }
          }}
          autoFocus
          sx={textfieldText}
        />
        
        <Button
          type="submit"        
          variant="contained"
          disabled={isLoading}
          className="btn draw-border"
          sx={{
           ...primaryBtnBlue,
           fontSize:'18px',
           marginTop:"22px",
          paddingX:'30px',
          width:"100%",
           "&:hover": {
          backgroundColor: "#055ee3", 
          color: "neutral.white",
          fontSize:"18.6px",
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
        }
        }}
          onClick={handleStoreSubDomain}
        >
          Proceed
        </Button>
        </Box>
        )}


     </Container>
     </Grid>  

     <Grid item lg={7.5} xm={12}  md={12}   sx={{

      display: { xs: "none" ,sm:'none' , md: "block" , height:'100vh'  , }, // Hide for small screens
    }}>
       <Box sx={{ display:'flex' ,width:"min-content" , height:'91%',  margin:'auto' , justifyContent:'center' , alignItems:'center', }}>
        <img src={login_hero} alt="" height={'88%'}
         sx={{ 
          height: { md: '80%', lg: '86%' , marginRight:"20px" } 
        }} 
        />
       </Box>
     </Grid>   
    </Grid>
  );
};

export default Login;


// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { loginUser } from "../connections/postApi";
// import { useNavigate } from "react-router-dom";
// import { Container, TextField, Button, Box, Typography } from "@mui/material";
// import TextView from "../Components/UI/TextView/TextView";
// import { primaryBtn, textfieldText } from "../style/AppointmentStyle";
// import { loginBtn, LoginForm, loginWrapper } from "../style/Login";
// import Cookies from "js-cookie";
// import { showToast } from "../utils/toastUtil";
// import MountPageLoader from "../Components/UI/MountPageLoader";
// import logo from '../assets/logo.png'
// import { primaryBtnBlue } from "../style/PatientDetailsStyle";
// import login_hero from "../../src/assets/login_hero.svg"

// const Login = () => {
//   const [uid, setUid] = useState("");
//   const [password, setPassword] = useState("");
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [loadingScreen, setLoadingScreen] = useState(false);
//   const { isLoading, isError, errorMessage } = useSelector(
//     (state) => state.auth
//   );

//   const authStaging = Cookies.get("authStaging")
//     ? JSON.parse(Cookies.get("authStaging"))
//     : null;
//   const isAuthenticated = authStaging?.isAuthenticated || false;
//   const redirect = authStaging?.redirect;

//   // setLoadingScreen(true);
//   useEffect(() => {
//     if (isAuthenticated && redirect) {
//       setLoadingScreen(true);
//       window.location.reload();
//       setTimeout(() => {
//         navigate(redirect);
//         // setLoadingScreen(false);
//       }, 600);
//     }
//   }, [isAuthenticated, redirect, navigate]);

//   // Show error toast if there is an error
//   useEffect(() => {
//     if (isError) {
//       showToast(errorMessage || "Login failed. Please try again.", "ERROR");
//     }
//   }, [isError, errorMessage]);

//   const handleLogin = (e) => {
//     e.preventDefault();
//     dispatch(loginUser({ account: { uid, password } }));
//     showToast("");
//   };

//   if (loadingScreen || isLoading) {
//     return (
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100vh",
//         }}
//       >
//         <MountPageLoader />
//       </Box>
//     );
//   }

//   return (

//     <Container maxWidth="xs" sx={loginWrapper}>
 

      
//       <Box display="flex" alignItems="center">
//         {/* <Typography
//           variant="h4"
//           component="span"
//           sx={{
//             fontWeight: "bold",
//             color: "#000",
//             letterSpacing: 1,
//           }}
//         >
//           CYPHER
//         </Typography>
//         <Typography
//           variant="h4"
//           component="span"
//           sx={{
//             fontWeight: "bold",      
//             color: "#FF0000", // Red color for "MD"
//             letterSpacing: 1,
//           }}
//         >
//           MD
//         </Typography> */}
//         <img
//             src={logo}
//             alt="Logo"
//             style={{ width: "14rem" , marginBottom:'1.2rem' , marginRight:"14px"}}
//           />
      
//       </Box>

//       <Box sx={LoginForm}>
//         <TextView color={"text.black"} variant="title_small" gutterBottom>
//           Sign in
//         </TextView>
//         <TextView
//           mb={"1rem"}
//           variant="body_Grey"
//           color={"text.grey"}
//           gutterBottom
//         >
//           Sign in to access your Account
//         </TextView>
//         <TextField
//           required
//           fullWidth
//           id="login"
//           placeholder="Login"
//           name="login"
//           autoComplete="login"
//           value={uid}
//           onChange={(e) => setUid(e.target.value)}
//           autoFocus
//           sx={textfieldText}
//         />
//         <TextField
//           required
//           fullWidth
//           name="password"
//           placeholder="Password"
//           type="password"
//           id="password"
//           autoComplete="current-password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           sx={{ ...textfieldText, mt: "0.675rem" }}
//         />
//         <Button
//           type="submit"
//           variant="contained"
//           disabled={isLoading}
//           className="btn draw-border"
//           sx={{
//            ...primaryBtnBlue,
//            marginTop:"22px",
//           paddingX:'30px',
//           }}
//           onClick={handleLogin}
//         >
//           Sign In 
//         </Button>
//         {/* {isError && <p>{errorMessage}</p>} */}
//       </Box>


//     </Container>
//   );
// };

// export default Login;
