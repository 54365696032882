import { palette } from "./themeStyling/palette";
import typography from "./themeStyling/typography";
import { createTheme } from "@mui/material";

let theme = createTheme({
  palette,
  typography,
});

export default theme;
