import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  slots: {
    prevDay: [],
    currentDay: [],
    nextDay: [],
    allSlots: [],
    selectedSlot: {},
  },
  renderAppointment:false,
  patientData: [],
  data: [],
  doctorOptions: [],
  isLoading: false,
  isError: false,
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    setSlots: (state, action) => {
      const { date, slots } = action.payload;
      state.slots[date] = slots;
    },
    setSelectedSlot: (state, action) => {
      state.slots.selectedSlot = action.payload;
    },
    setSearchPatient: (state, action) => {
      state.patientData = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDoctorOption: (state, action) => {
      state.doctorOptions = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload; 
    },
    setRenderAppointment: (state, action) => {
      state.renderAppointment = !state.renderInvoice;
    },
  },
});

export const {
  setSlots,
  setLoading,
  setError,
  setSelectedSlot,
  setSearchPatient,
  setData,
  setDoctorOption,
  setRenderAppointment,
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
