import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from "@mui/material";

const PrintAppointment = React.forwardRef((props, ref) => {
  const { printData, appointmentState , formState } = props;
  // console.log(formState)
  
  // Guard clause: Check if printData and invoices exist and are valid
  if (!printData || !Array.isArray(printData?.invoices) || printData?.invoices?.length === 0) {
    // console.error("Invalid or missing data:", printData);
    return null; // Render nothing if data is invalid
  }

  //  console.log(printData)
  //  console.log(props);

  //   useEffect(() => {
  //     if (printData) {
  //       window.print();
  //     }
  //   }, [printData]);
  // console.log(appointmentState, "printData");

  //   if (!printData) {
  //     // If printData is still loading or unavailable, show a message or a loader
  //     return null;
  //   }
  // console.log(printData?.invoices[0]?.consultancy_fee)

  return (
    <Box
      ref={ref}
      sx={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        display: "none",
        fontFamily:'Outfit',
        "@media print": {
          display: "block",
        },
      }}
    >
      <Typography variant="h4" align="center" gutterBottom fontFamily={'Outfit'}>
        {printData?.invoices[0]?.organization?.name}
      </Typography>
      <Typography sx={{ lineHeight: 1.2 }} align="center" variant="subtitle1" fontFamily={'Outfit'}>
        <span style={{ display: "block" , marginTop:'-5px', fontSize:'14px' }}>
          {" "}
          {/* B-35-922/2/1, Ferozpur Road Near MBD Mall */}
          {printData?.invoices[0]?.organization?.address}
        </span>
        <span style={{ display: "block" , marginTop:'1px' ,  fontSize:'14px'}}> {printData?.invoices[0]?.organization?.pincode} {" "} {printData?.invoices[0]?.organization?.city}</span>

        <span style={{ display: "block", marginTop:'1px' , fontSize:'14px' }}>
          {/* +91 98787-36644
           */}

       {printData?.invoices[0]?.organization?.contact_number}
        </span>
      </Typography>
      <Typography
        sx={{ textDecoration: "underline"  ,marginTop:'5px' }}
        align="center"
        alignSelf="center"
        fontFamily={'Outfit'}
        // fontSize={'14px'}
      >
        Invoice
      </Typography>

      {/* Invoice Information */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          border: "0.5px solid",
          borderColor: "lightgray",
          p: 1,
          pl: 2,
          pr: 2, 
        }}
      >
        <Box width="40$">
          {/* <Typography>Receipt ID: { printData?.invoices[0] &&   printData?.invoices[0]?.uid}</Typography> */}
          <Typography variant="small_Text1" fontWeight={'400'} fontSize={'15px'}>
          Receipt ID: {Array.isArray(printData?.invoices) && printData?.invoices?.length > 0 && printData.invoices[0]?.uid}
          </Typography>
        </Box>
        <Box>
          <Typography  variant="small_Text1" fontWeight={'400'} fontSize={'15px'}>
            {printData?.invoices[0]?.status === "generated"
              ? new Date()
                  .toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                  .replace(/\//g, "-")
                  .replace(", ", " ")
              : printData?.invoices[0]?.generated_at}
          </Typography>
        </Box>
      </Box>

      {/* Patient Information */}
      <Box sx={{ marginTop: "10px", p: 2, pt: 1 }}>
        {printData?.invoices && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            fontFamily={'Outfit'}
          >
            <Typography fontFamily={'Outfit'}>
              Patient ID: {appointmentState?.uid
                ? appointmentState?.uid
                : printData?.invoices[0]?.patient_uid}
            </Typography>
            <Typography fontFamily={'Outfit'} sx={{marginRight:"38px"}}>
              Mobile Number: {printData?.invoices[0]?.mobile}
            </Typography>
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {printData?.invoices && (
            <Typography fontWeight={"bold"}>
              {printData?.invoices[0]?.name}
            </Typography>
          )}
          <Typography fontFamily={'Outfit'}>
            Appointment: {" "}
            { appointmentState?.appointments && appointmentState?.appointments[0]?.visit_date
              ? appointmentState?.appointments &&  appointmentState?.appointments[0]?.visit_date?.split("-")
                  .reverse()
                  .join("-")
              :     formState?.patientAppointment &&  formState?.patientAppointment[0]?.visit_date?.split("-")
              .reverse()
              .join("-") }{" "}
            {appointmentState?.appointments && appointmentState?.appointments[0]?.visit_time ?  appointmentState?.appointments[0]?.visit_time : formState?.patientAppointment && formState?.patientAppointment[0]?.visit_time }
          </Typography>
        </Stack>
        <Typography fontFamily={'Outfit'}>
          {printData?.invoices[0]?.age && `${printData?.invoices[0]?.age} | `}
          {printData?.invoices[0]?.gender &&
            `${printData?.invoices[0]?.gender} | `}
          {printData?.invoices[0]?.patient_address &&
            `${printData?.invoices[0]?.patient_address}`}
        </Typography> 
      </Box>
      <Typography
        textTransform={"capitalize"}
        fontWeight={"600"}
        fontFamily={'Outfit'}
        align="center"
      >
        Visit Type: {appointmentState?.appointments[0]?.visit_type}
      </Typography>
      {/* Charges Table */}
      <Table
        sx={{
          marginTop: "10px",
          width: "100%",
          border: "1px solid",
          borderColor: "lightgrey",
          borderCollapse: "collapse",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ p: "4px", pb: "10px" }}>
              <strong>Charges Desc</strong>
            </TableCell>

            <TableCell sx={{ p: "4px", pb: "10px" }}>
              <strong>Fees</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <TableRow> */}
          {printData?.invoices &&
            printData?.invoices?.map((el) => (
              <>
                {/* <TableRow sx={{borderBottom :'1px solid rgba(224, 224, 224,1)'}}>
                  {printData?.invoices[0]?.consultancy_fee?.charges_desc && (
                    <TableCell sx={{ p: "4px",  textTransform:'capitalize' }}>
                      {printData?.invoices[0]?.consultancy_fee?.charges_desc}
                    </TableCell>
                  )}
                  {
                  
                 ( printData?.invoices[0]?.consultancy_fee?.charges_fee===0 || printData?.invoices[0]?.consultancy_fee?.charges_fee )&& 
                  (
                    <TableCell sx={{ p: "4px",  }}>
                      Rs.{" "}  {printData?.invoices[0]?.consultancy_fee?.charges_fee}
                    </TableCell>
                  )}
                </TableRow> */}
                <TableRow>
                  <TableCell sx={{ p: "4px" }}>
                    {el?.consultancy_fee?.consultant_name}
                  </TableCell>
                  <TableCell sx={{ p: "4px" }}>
                    Rs. {el?.consultancy_fee?.fees}
                  </TableCell>
                </TableRow>
              </>
            ))}

          {/* </TableRow> */}
          {printData?.invoices && (
            <TableRow>
              <TableCell sx={{ p: "4px" }}>
                <strong>Net Payable Amount</strong>
              </TableCell>
              {/* <TableCell>Rs. {printData?.invoices[0]?.grand_total}</TableCell> */}
              {/* <TableCell>Rs.{printData?.invoices[0]?.discount || 0}</TableCell> */}
              <TableCell sx={{ p: "4px" }}>
                <strong>
                  Rs.{" "}
                  {Math.abs(
                    (printData?.invoices[0]?.consultancy_fee
                      ?.net_payable_amount || 0) -
                      (printData?.invoices[0]?.discount || 0)
                  )}{" "}
                  /-
                </strong>

                {/* <strong>
                  Rs.{" "}
                  {printData?.invoices[0]?.grand_total -
                    printData?.invoices[0]?.discount}{" "}
                  /-
                </strong> */}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
});

export default PrintAppointment;
