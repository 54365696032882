import { useQuery } from "@tanstack/react-query";
import { getPendingLabReport } from "../connections/getApi";
import { useLocation } from "react-router-dom";

const UseGetPendingLabReport = (page, perPage) => {
  const location = useLocation();
  const isEnable = location.pathname === "/lab";
  

  return useQuery({ 
    queryKey: ["getPendingLabReport",page, perPage], 
    queryFn: () => getPendingLabReport(page,perPage),
    enabled: !!page && !!perPage && isEnable,
    staleTime: 0, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseGetPendingLabReport;
