export const pharmacyPageWrapper  = {
    marginTop: "7rem",
    display: "flex",
    justifyContent: "space-between",  
    padding: "0rem 1rem",
    flexWrap: "wrap",
    gap: "1rem",
  }

  export const primaryBtnPharmacy={
    color:'neutral.white',
    fontSize:'1rem',
    padding:"4px",
    transition: "background-color 0.1s ease-in 0.1s, color 0.1s ease-in 0.1s", 
    "&:hover": {
      backgroundColor: "neutral.main",
      color: "black",
    },
  }

  export const addBtn={
    backgroundColor :"neutral.blue1" ,
    borderRadius :'5px',
    height :"20px", 
    marginTop:"10px",
    width :'20px', 
    padding:"5px",
     cursor:"pointer",
     "&:hover": {
      backgroundColor: "#4ba8f2",
      color: "black",
     },
     "&:active": {
    backgroundColor: "#269fff", 
    color: "black",
    transform: "scale(0.98)", 
  }
  }

  
export const pharmacyPageBlueBtn = {
  mt: 3,
  mb: 2,
  color: "black",
  backgroundColor: "#64b5f6",
  textTransform: "capitalize",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
  "&:hover": {
    backgroundColor: "#4ba8f2",
  },
  };

  export const yellowBgBtn = {
    margin :"0px",
    borderRadius :'5px',
    backgroundColor: "#e9e165",
    textTransform: "capitalize",
    color :'black',
    fontWeight :'400',
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
    width: "auto",
    cursor :'pointer',
    minWidth: "0px",
    marginRight :"5px",
    marginTop:'8px',
    "&:hover": {
      backgroundColor: "#e3d838",
    },
    };
  

  export const borderInset={
 boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
  }
  

  export const bgWhiteSnowSelectField ={  
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none", // Remove border
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none", // Remove border on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none", // Ensure border remains none on focus
      },
      "& .MuiSelect-select": {
        padding: "9px",
        borderRadius: "15px",
        backgroundColor: "#F7F7FF",
      },
      "&:hover": {
        borderRadius:"20px",
        // boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
      },
  }


export const selectFieldTextBlueBr = {

  fontSize: "14px",
  transition: "all 0.4s ease-in-out",
  borderRadius: "10px",
  
  "& .MuiOutlinedInput-root": {
    border: '1px solid #0067FF', // Set blue border for the input
    borderRadius: "10px",
  },
  "& .MuiSelect-select": {
    padding: "9px",
    fontSize: "14px",
    borderRadius: "10px",
    "&:focus": {
      borderRadius: "10px", // Maintain border radius on focus
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #0067FF", // Set blue border for the outline
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0067FF", // Set blue border on focus
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0056D2", // Slightly darker blue border on hover
  },
};



  
export const textfieldTextBlueBr = {
  //  padding: "10px 0px",
   fontSize: "14px",
   transition: "all 0.4s ease-in-out",
  "& .MuiOutlinedInput-root": {
    borderRadius:'10px',
    height: "40px",
    padding: "9px !important",
    "& input": {
      padding: "4px",
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#FFFFFF",
    transition: "background-color 0.4s",
    border:'1px solid #0067FF',
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "neutral.white",    
    boxShadow:
    'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
  },
  "& fieldset": {
    border: "none", // Remove default border
  },
  "&:hover fieldset": {
    border: "none", // Remove border on hover
  },     
};