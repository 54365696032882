export const drListWrapper = {
  margin: 0,
  padding: "15px",
  marginBottom: "auto",
 
};

export const drDateFiled = {
  marginTop: "2px",
  "& .MuiOutlinedInput-root": {
    height: "30px",
    fontSize: "14px",
    "& input": {
      padding: "8px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#ebebeb",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
};

export const drSelectFiled = {
  // height: "30px",
  fontSize: "14px",
  padding: "5px 0px",
  backgroundColor: "#ebebeb",
  "& .MuiSelect-select": {
    height: "100%",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "&.Mui-focused": {
    backgroundColor: "white",
  },
  "&.MuiSelect-select.Mui-focused": {
    backgroundColor: "white",
  },
};

export const drTableRow = {
  backgroundColor: "neutral.tablet_head",
  borderBottom: "1px solid #ebebeb",
  marginBottom: "3px",
  p: 0,
};
