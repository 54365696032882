import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useDispatch } from "react-redux";
import { getFutureAppointment } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/appointmentSlice";

const UseFetchFutureAppointment = (date, page, per_page) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ["getFutureAppointment", date, page, per_page],
    queryFn: () => getFutureAppointment(date, page, per_page),
    enabled: !!date,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseFetchFutureAppointment;
