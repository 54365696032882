export const drListWraper = {
    margin: 0,
    padding: "10px",
    marginBottom: "auto",
    border: "1px solid rgba(0, 0, 0, 0.125)",
    // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
  };

  export const whiteText={
    color : 'white',
  }

  export const drTableRow = {
    backgroundColor: "rgba(31, 32, 34, 0.1)",
    borderBottom: "1px solid #ebebeb",
    marginBottom: "3px"
  }

