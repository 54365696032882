import { useDispatch } from "react-redux";
import { getPurchaseOrderList } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/pharmacySlice";
import { useQuery } from "@tanstack/react-query";

const UseFetchPurchaseOrder = (page,pageSize,type) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getPurchaseOrderList" ,page,pageSize,type ],
    queryFn: () => getPurchaseOrderList(page,pageSize,type),
     enabled: !!page ||  !!pageSize ||  !!type,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};
export default UseFetchPurchaseOrder;
