import { useQuery } from "@tanstack/react-query";

import { getPatientPreviousReport } from "../connections/getApi";
const UseGetPatientPreviousReports = (id, mobile, date) => {
  return useQuery({
    queryKey: ["getPatientPreviousReport", id, mobile, date],
    queryFn: () => getPatientPreviousReport(id, mobile, date),
    enabled: !!id,
    staleTime: 0, // Keep the data fresh indefinitely
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseGetPatientPreviousReports;
