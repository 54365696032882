import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TableContainer,
  Table,
  Typography,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import { drTableRow } from "../../style/DoctorListComponent";
import MobileScreenShareRoundedIcon from "@mui/icons-material/MobileScreenShareRounded";
import {
  addNewReportBg,
  tableCellLab,
  tableHeadCell, 
  tableRowLab,
} from "../../style/LapPageStyle";
import CustomModal from "../CustomModal/CustomModal";
import AddNewReport from "../ModalChildren/AddNewReport";
import { getPatientPreviousReport } from "../../connections/getApi";
import updownar from "../../assets/updownar.svg";

const InProcess = ({ data = [], testDate, isLoading }) => {
  const [patients, setPatients] = useState(data);
  const [openModal, setOpenModal] = useState(false);
  const [cellData, setCellData] = useState({});

  const handlePreview = async (patient) => {
    try {
      const data = await getPatientPreviousReport(
        patient?.id,
        patient?.mobile,
        testDate
      );
      const transformedData = data?.tests?.reduce((acc, obj) => {
        const dateKey = obj.created_date;

        // If the date already exists in the accumulator, push to the array
        if (acc[dateKey]) {
          acc[dateKey].push(obj);
        } else {
          // Otherwise, initialize with an array containing the first object
          acc[dateKey] = [obj];
        }

        return acc;
      }, {});
      // setCellData(data);
      setCellData((prev) => ({
        ...prev,
        id: patient?.id,
        report: transformedData,
        patient_info: data?.patient_info,
      }));
      setOpenModal(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setPatients(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      <Grid
        container
        sm={12}
        md={12}
        marginBottom={"auto"}
        lg={12}
        sx={{ mb: "50px" ,
       backgroundColor:'#F7F7FF'}}
      > 
      
      <Grid item xs={11.5} mt={0} sx={{margin:'auto'}}> 
          <Grid item sm={12} sx={{ padding: "16px" }}>
            {/* <Typography variant="heading1">In Process</Typography> */}
          </Grid>

          <TableContainer >
            <Table> 
              <TableHead> 
              <TableRow>
                  <TableCell sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}>S NO   </TableCell>
                  <TableCell sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}>Name    </TableCell>

                  <TableCell sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}> Patient ID   </TableCell>

                  <TableCell sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}>  Invoice ID   </TableCell>

                  <TableCell sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}>  New Report    </TableCell>

                  {/* <TableCell sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}>Print</TableCell> */}
                </TableRow> 
              </TableHead>

              <TableBody>
                {patients?.length === 0 && (
                  <TableCell
                    sx={{ textAlign: "center" }}
                    colSpan={5}
                    rowSpan={4}
                  >
                    <Typography>No Data</Typography>
                  </TableCell>
                )}
                {patients?.length > 0 &&
                  patients?.map((patient, index) => (
                    <TableRow key={index}  sx={{...tableRowLab , backgroundColor:'white' , borderTop:'2px solid #F7F7FF' , borderBottom:"8px solid #F7F7FF" ,
                      "&:hover": {
                        transform: "scale(1.001)", // Slight scaling for the pop-up effect
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                        borderRadius: "20px !important",
                      }
                      
                    }}>
                      <TableCell sx={{ p: "8px", textAlign: "center" , borderTopLeftRadius:"20px" , borderBottomLeftRadius:"20px" }}>
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {index + 1}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ p: "8px", textAlign: "center" }}>
                        <Typography variant="heading2">
                          {" "}
                          {patient.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MobileScreenShareRoundedIcon
                            sx={{ height: "12px", width: "12px" }}
                          />
                          <Typography variant="body2_text">
                            {" "}
                            {"1234567890"}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell sx={{ p: "8px", textAlign: "center"}}>
                        <Typography variant="body_Grey1">
                          {patient.id}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ p: "8px", textAlign: "center" }}>
                        <Typography variant="body_Grey1">
                          {patient.id}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ p: "8px", textAlign: "center" , borderTopRightRadius:"20px" , borderBottomRightRadius:"20px" }}>
                        <Typography
                          variant="body_Grey2"
                          sx={addNewReportBg}
                          onClick={() => handlePreview(patient)}
                        >
                          {" "}
                          Add new report
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <AddNewReport
          patientData={cellData?.patient_info}
          report={cellData?.report}
          onClose={() => setOpenModal(false)}
          handlePreview={handlePreview}
        />
      </CustomModal>
    </>
  );
};

export default InProcess;
