import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Button,
  Typography,
  Autocomplete,
  IconButton,
  Modal,
  keyframes,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextView from "../UI/TextView/TextView";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import {
  buttonWrapper,
  menuItemStyle,
} from "../../style/AppointmentStyle";
import { appointmentForm } from "../../utils/constant.js";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedSlot,
  setSlots,
  setSearchPatient,
  setData,
  setDoctorOption,
  setRenderAppointment,
} from "../../redux/slices/appointmentSlice.js";
import UseSearchPatient from "../../helper/UseSearchPatient.jsx";
import {
  primaryBtnBlue,
  textfieldTextPrep,
} from "../../style/PatientDetailsStyle/index.js";
import useDebounce from "../../hooks/UseDebounce.jsx";
import UsePatientDetail from "../../helper/UsePatientDetail.jsx";
import UseFetchOptions from "../../helper/UseFetchOptions.jsx";
import {
  addOptionInAppointForm,
  createAppointment,
  rescheduleAppointmentData,
  savePatientAppointment,
} from "../../connections/postApi.js";
import { getAvailableSlots } from "../../connections/getApi.js";
import UseFetchDoctorList from "../../helper/UseFetchDoctorList.jsx";
import { saveOldPatientAppointment } from "../../connections/putApi.js";
import { showToast } from "../../utils/toastUtil.js";
import loadingSpinner from "../../assets/loadingSpinner.svg";

const rotateSpinner = keyframes`
0% { transform: rotate(0deg); }
10% { transform: rotate(36deg); }
20% { transform: rotate(72deg); }
30% { transform: rotate(108deg); }
40% { transform: rotate(144deg); }
50% { transform: rotate(180deg); }
60% { transform: rotate(216deg); }
70% { transform: rotate(252deg); }
80% { transform: rotate(288deg); }
90% { transform: rotate(324deg); }
100% { transform: rotate(360deg); }
`;

const NewAppointment = () => {
  const [allFormFields, setAllFormFields] = useState(appointmentForm);
  const [formState, setFormState] = useState({});
  const [newValues, setNewValues] = useState({});
  const [showNewValueField, setShowNewValueField] = useState({});
  const [fieldOptions, setFieldOptions] = useState({});
  const [openSearchDropDown, setOpenSearchDropDown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [patientId, setPatientId] = useState(null);
  const [renderOption, setRenderOption] = useState(false);
  const [errors, setErrors] = useState({});
  const [newSlotOption, setNewSlotOption] = useState({});
  const [searchData, setSearchData] = useState({
    q: "",
    page: 1,
    perPage: 10,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();

  const { 
    slots,
    patientData,
    doctorOptions,
    data: oldPatientData,
  } = useSelector((state) => state.appointment);

  // api hook to get information
  const { data: drQuery, status } = UseFetchDoctorList();
  const { data: patientDetailsQuery, isLoading } = UsePatientDetail(patientId);
  const { data: searchResult } = UseSearchPatient(searchData);
  const { data: allOccupation, refetch: refetchOccupation } =
    UseFetchOptions("occupations");
  const { data: allCities, refetch: refetchCities } = UseFetchOptions("cities");
  const { data: allEducations, refetch: refetchEducation } =
    UseFetchOptions("educations");

  // form inputs value changes logic
  const handleFormChange = async (event, value, name, newValue) => {
    let fieldName, fieldValue;
    let slotId = value?.props?.id;
    if (event) {
      const { name: eventName, value: eventValue } = event.target;


      fieldName = event.target.name;
      fieldValue = event.target.value;

      if (( fieldName === "age" && eventValue?.length>3   )|| (fieldName==="mobileNumber" && eventValue?.length>10) ) {
        return;
     }
 

      if (eventName === "visitDate") {
        let data = await getAvailableSlots(eventValue);

        dispatch(
          setSlots({
            date: "allSlots",
            slots: data.payload.slots,
          })
        );

        dispatch(setSelectedSlot({ eventValue, slot: "" }));
      } else if (eventName === "slot") {
        // console.log(eventName, eventValue, newValue, value);
        const formatDate = (
          dateStr = new Date().toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        ) => {
          const [day, month, year] = dateStr.split("-");
          return `${year}/${month}/${day}`;
        };
      }
      setFormState((prevState) => ({
        ...prevState,
        [eventName]: eventValue,
        [`${eventName}id`]: slotId,
      }));
    } else if (name) {
      fieldName = name;
      fieldValue = value;
      if (value?.value === "other") {
        setShowNewValueField((prev) => ({ ...prev, [name]: true }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setShowNewValueField((prev) => ({ ...prev, [name]: false }));
      }
    }
    setErrors((prev) => ({ ...prev, [fieldName]: undefined }));
  };

  //add new value to occupation education and cities also post req
  // const handleAddNewValue = (name) => {
  //   if (newValues[name]) {
  //     const newValue = newValues[name];

  //     let newObj = {
  //       id: `${Math.floor(Math.random() * 90) + 10}${newValue}`,
  //       value: newValue,
  //       title: newValue,
  //     };
  //     addOptionInAppointForm({ value: newValue }, name);
  //     if (name === "occupation") {
  //       refetchOccupation();
  //     } else if (name === "education") {
  //       refetchEducation();
  //     } else {
  //       refetchCities();
  //     }
  //     setFormState((prevState) => ({
  //       ...prevState,
  //       [name]: newObj,
  //     }));
  //     // dispatch(setSelectedSlot({ date: newValue, slot: "" }));
  //     setFieldOptions((prevOptions) => ({
  //       ...prevOptions,
  //       [name]: [...(prevOptions[name] || []), newObj],
  //     }));
  //     setNewValues((prev) => ({ ...prev, [name]: "" }));
  //     setShowNewValueField((prev) => ({ ...prev, [name]: false }));
  //   }
  // };

  const handleAddNewValue = async (name) => {
    if (newValues[name]) {
      const newValue = newValues[name];
      let result;
      // let newObj = {
      //   // id: ${Math.floor(Math.random() * 90) + 10}${newValue},
      //   value: newValue,
      //   title: newValue,
      // };
      result = await addOptionInAppointForm({ value: newValue }, name);
      if (result?.status?.status === "SUCCESS") {
        let newObj = {
          id: result?.payload?.id,
          title: result?.payload?.title || result?.payload?.name,
          value: result?.payload?.value || result?.payload?.name,
        };
        if (name === "occupation") {
          refetchOccupation();
        } else if (name === "education") {
          refetchEducation();
        } else {
          refetchCities();
        }
        setFormState((prevState) => ({
          ...prevState,
          [name]: newObj,
        }));
        setFieldOptions((prevOptions) => ({
          ...prevOptions,
          [name]: [...(prevOptions[name] || []), newObj],
        }));
        setNewValues((prev) => ({ ...prev, [name]: "" }));
        setShowNewValueField((prev) => ({ ...prev, [name]: false }));
      }
    }
  };

  // const handleAddNewValue = async (name) => {
  //   if (newValues[name]) {
  //     const newValue = newValues[name];
  //     let result;
  //     // let newObj = {
  //     //   // id: ${Math.floor(Math.random() * 90) + 10}${newValue},
  //     //   value: newValue,
  //     //   title: newValue,
  //     // };
  //     result = await addOptionInAppointForm({ value: newValue }, name);
  //     if (result?.status?.status === "SUCCESS") {
  //       let newObj = {
  //         id: result?.payload?.id,
  //         title: result?.payload?.title || result?.payload?.name,
  //         value: result?.payload?.value || result?.payload?.name,
  //       };
  //       if (name === "occupation") {
  //         refetchOccupation();
  //       } else if (name === "education") {
  //         refetchEducation();
  //       } else {
  //         refetchCities();
  //       }
  //       setFormState((prevState) => ({
  //         ...prevState,
  //         [name]: newObj,
  //       }));
  //       // dispatch(setSelectedSlot({ date: newValue, slot: "" }));
  //       setFieldOptions((prevOptions) => ({
  //         ...prevOptions,
  //         [name]: [...(prevOptions[name] || []), newObj],
  //       }));
  //       setNewValues((prev) => ({ ...prev, [name]: "" }));
  //       setShowNewValueField((prev) => ({ ...prev, [name]: false }));
  //     }
  //   }
  // };

  //set options for autocomplete on mount phase
  useEffect(() => {
    const initialOptions = {};

    allFormFields?.forEach((field) => {
      if (field.field === "Autocomplete") {
        initialOptions[field.name] = field.options;
      }
    });
    setFieldOptions(initialOptions);
  }, []);

  // set slot options for mount phase
  useEffect(() => {
    const formattedSlots =
      slots?.allSlots &&
      slots?.allSlots?.map((slot) => ({
        id: slot?.id,
        value: slot?.id,
        label: slot?.time,
      }));
    const slotOptions =
      formattedSlots?.length === 0
        ? [{ value: "default", label: "Slot" }]
        : formattedSlots;
    const newData = allFormFields?.map((el) =>
      el.name === "slot" ? { ...el, options: slotOptions } : el
    );

    setAllFormFields(newData);
    const formatDate = (
      dateStr = new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    ) => {
      const [day, month, year] = dateStr.split("/");
      return `${year}-${month}-${day}`;
    };
    const formattedDate = formatDate(slots?.selectedSlot?.date);
    // console.log(slots?.selectedSlot?.date);s
    if (formState.visitDate !== formattedDate) {
      setFormState((prev) => ({
        ...prev,
        visitDate: formattedDate,
        slot: slots?.selectedSlot?.slot || slots?.allSlot || "",
        slotID: slots?.selectedSlot?.id,
      }));
    }
  }, [slots]);
  // handle slot date changes from slots list
  // useEffect(() => {}, [slots?.selectedSlot]);

  // handle Slot change from slot list
  useEffect(() => {
    let slotOptions = [];

    if (slots?.selectedSlot?.slot) {
      // If there's a selected slot, only show that option
      slotOptions = [
        {
          id: slots?.selectedSlot?.slotId,
          value: slots?.selectedSlot?.slotId,
          label: slots?.selectedSlot?.slot,
        },
      ];
    } else if (slots?.allSlots?.length > 0) {
      // If no slot is selected, show all available slots

      slotOptions = slots.allSlots.map((slot) => ({
        value: slot?.id,
        label: slot?.time,
      }));
    } else {
      // If no slots are available
      slotOptions = [{ value: "default", label: "No slots available" }];
    }

    const newData = allFormFields?.map((field) =>
      field.name === "slot"
        ? {
            ...field,
            options:
              newSlotOption && Object.keys(newSlotOption).length > 0
                ? [newSlotOption, ...slotOptions]
                : slotOptions,
          }
        : field
    );

    setAllFormFields(newData);

    // Update formState with the new slot if it's not already set
    if (
      slots?.selectedSlot?.slot &&
      formState.slot !== slots?.selectedSlot?.slot
    ) {
      setFormState((prev) => ({
        ...prev,

        slot: slots?.selectedSlot?.slotId,
        slotID: slots?.selectedSlot?.slotId,
      }));
    }
  }, [slots?.allSlots, slots?.selectedSlot?.slot, newSlotOption]);

  // search query for patient by name or id
  const debouncedInputValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    if (debouncedInputValue) {
      setSearchData((prev) => ({ ...prev, q: debouncedInputValue }));
    } else {
      dispatch(setSearchPatient([]));
    }
  }, [debouncedInputValue, dispatch]);
  // change input for search
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  useEffect(() => {
    if (searchResult?.payload?.patients) {
      dispatch(setSearchPatient(searchResult?.payload?.patients));
    }
  }, [searchResult, dispatch, inputValue]);

  // get patient detail by id
  const selectedPatient = (id) => {
    setPatientId(id?.id);
  };
  // set data for patient detail
  useEffect(() => {
    const capitalizeFirstLetter = (string) => {
      return string
        ? string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
        : null;
    };
    setNewSlotOption({
      id: patientDetailsQuery?.payload?.appointments?.[0]?.appointment_slot_id,
      value:
        patientDetailsQuery?.payload?.appointments?.[0]?.appointment_slot_id,
      label:
        patientDetailsQuery?.payload?.appointments?.[0]?.appointment_slot_time,
    });
    if (patientDetailsQuery?.payload) {
      dispatch(setData(patientDetailsQuery?.payload));
      const patientObj = {
        id: patientDetailsQuery?.payload?.id,
        uid: patientDetailsQuery?.payload?.uid,
        fullName: patientDetailsQuery?.payload?.full_name,
        email: patientDetailsQuery?.payload?.email,
        mobileNumber: patientDetailsQuery?.payload?.mobile,
        maritalStatus: capitalizeFirstLetter(
          patientDetailsQuery?.payload?.marital_status
        ),
        diet: patientDetailsQuery?.payload?.diet,

        gender: patientDetailsQuery?.payload?.gender,
        age: patientDetailsQuery?.payload?.age,
        attendingDoctor:
          patientDetailsQuery?.payload?.appointments[0]?.doctor_id,
        consultancyDoctor:
          patientDetailsQuery?.payload?.appointments[0]?.consultant_id,

        visitDate: patientDetailsQuery?.payload?.appointments[0]?.visit_date,
        slot: patientDetailsQuery?.payload?.appointments[0]
          ?.appointment_slot_id,
        occupation: patientDetailsQuery?.payload?.occupation_id,
        occupation_title: patientDetailsQuery?.payload?.occupation,
        education: patientDetailsQuery?.payload?.education_id,
        city: +patientDetailsQuery?.payload?.city,
        address: patientDetailsQuery?.payload?.address,
        pincode: patientDetailsQuery?.payload?.pincode,
        patientType: capitalizeFirstLetter(
          patientDetailsQuery?.payload?.appointments[0]?.visit_type
        ),
        appointment_slot_time:
          patientDetailsQuery?.payload?.appointments[0]?.appointment_slot_time,
      };

      setFormState((prev) => ({ ...prev, ...patientObj }));
      setErrors({});
    }
  }, [dispatch, patientId, patientDetailsQuery?.payload.id]);

  // set education occupation and cities option in fields
  useEffect(() => {
    const newAllCities = allCities?.cities?.map((el) => ({
      id: el?.id,
      title: el?.name,
      value: el?.name,
    }));
    setFieldOptions((prev) => ({
      ...prev,
      occupation: allOccupation?.occupations,
      education: allEducations?.educations,
      city: newAllCities,
    }));
  }, [allCities, allEducations, allOccupation]);

  // set option for dr
  useEffect(() => {
    if (status === "success") {
      dispatch(setDoctorOption(drQuery));
    }
  }, [drQuery?.doctors, status]);

  useEffect(() => {
    if (doctorOptions?.doctors) {
      const formattedDrList = doctorOptions.doctors.map((dr) => ({
        value: dr?.id,
        label: dr?.full_name,
        id: dr?.id,
      }));

      const DoctorList =
        formattedDrList.length === 0
          ? [{ value: "default", label: "Doctor" }]
          : formattedDrList;

      let newData = allFormFields?.map((field) => {
        if (
          field.name === "attendingDoctor" ||
          field.name === "consultancyDoctor"
        ) {
          return { ...field, options: DoctorList };
        }
        return field;
      });
      setTimeout(() => {
        setRenderOption(true);
      }, 1000);
      setAllFormFields(newData);
    }
  }, [doctorOptions, renderOption]);

  // console.log(formState)
  const validateForm = (formData , isNew) => {
    let newErrors = {};
    let isValid = true;

    appointmentForm.forEach((field) => {

      if (
        !isNew &&
        (field.name === "attendingDoctor" ||
          field.name === "consultancyDoctor" ||
          field.name === "slot" ||   field.name==="patientType" ||   field.name==="visitDate")
      ) {
        return; // Skip this iteration
      }  

      if (field.pattern) {
        const value = formData[field.name];
        if (!new RegExp(field.pattern).test(value)) {
          newErrors[field.name] = field.errorMessage || "Invalid input";
          isValid = false;
        }
      }  
      // Add any other validation rules here
      if (field.required && !formData[field.name]) {
        newErrors[field.name] = "This field is required";
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
    
  };

  // const cleanPayload = (obj) => {
  //   return Object.fromEntries(
  //     Object.entries(obj).filter(
  //       ([_, value]) => value !== "" && value !== null && value !== undefined
  //     )
  //   );
  // };

  function cleanPayload(obj) {
    if (Array.isArray(obj)) {
      return obj.map(cleanPayload).filter((item) => item != null);
    } else if (typeof obj === "object" && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj)
          .map(([key, value]) => [key, cleanPayload(value)])
          .filter(([_, value]) => value != null && value !== "")
      );
    }
    return obj;
  }

  const handleSavePatient = async (isNew) => {
    setButtonLoading(true);

    if (!validateForm(formState , isNew)) {
      // Form is valid, proceed with submission

      // Add your form submission logic here
      showToast("Validation Failed...", "ERROR");
      setButtonLoading(false);
      return null;
    }

    try {
      if (Object.keys(oldPatientData).length > 0) {
        let oldData = {
          patient: {
            uid: oldPatientData?.uid,
            full_name: formState?.fullName,
            email: formState?.email,
            mobile: formState?.mobileNumber,
            age: formState?.age,
            gender: formState?.gender?.toLowerCase(),
            marital_status: formState?.maritalStatus?.toLowerCase(),
            address: formState?.address,
            pincode: formState?.pincode,
            diet: formState?.diet?.split("-")?.join("_")?.toLowerCase(),
            city: formState.city?.id || formState?.city,
            occupation_id: formState?.occupation?.id || formState?.occupation,
            education_id: formState?.education?.id || formState?.education,
          },
        };

        oldData = cleanPayload(oldData);

        let result = await saveOldPatientAppointment(
          oldData,
          oldPatientData?.id
        );

        if (isNew === false) {
          showToast(
            result?.status?.message || result?.status?.status,
            result?.status?.status
          );
        }
        let reschedulePayload = {
          appointment: {
            patient_id: formState?.id,
            visit_type: formState?.patientType?.toLowerCase(),
            visit_date: formState?.visitDate,
            consultant_id: formState?.consultancyDoctor,
            doctor_id: formState?.attendingDoctor,
            appointment_slot_id:
              formState?.slotid ||
              patientDetailsQuery?.payload?.appointments[0]
                ?.appointment_slot_id,
          },
        };
        reschedulePayload = cleanPayload(reschedulePayload);

        if (
          isNew &&
          patientDetailsQuery?.payload?.appointments[0]?.status !==
            "completed" &&
          patientDetailsQuery?.payload?.appointments?.length > 0
        ) {
          const userConfirmation = window.confirm(
            ` One Of the Appointment is Pending on ${
              patientDetailsQuery?.payload?.appointments[0]?.visit_date
                ? patientDetailsQuery?.payload?.appointments[0]?.visit_date
                : ""
            } Do you want to cancel and  reschedule it?`
          );
          if (userConfirmation) {
            const rescheduleResult = await rescheduleAppointmentData(
              reschedulePayload,
              patientDetailsQuery?.payload?.appointments[0]?.id
            );
            showToast(
              rescheduleResult?.status?.message ||
                rescheduleResult?.status?.status,
              rescheduleResult?.status?.status
            );
            if (rescheduleResult?.status?.status === "SUCCESS") {
              setFormState({
                visitDate: "2024-08-23",
                slot: "",
                slotID: undefined,
              });
              dispatch(setRenderAppointment(true));
            }
          }
        } else if (
          isNew &&
          patientDetailsQuery?.payload?.appointments[0]?.status ===
            "completed" &&
          patientDetailsQuery?.payload?.appointments?.length > 0
        ) {
          const rescheduleResult = await rescheduleAppointmentData(
            reschedulePayload,
            patientDetailsQuery?.payload?.appointments[0]?.id
          );
          showToast(
            rescheduleResult?.status?.message ||
              rescheduleResult?.status?.status,
            rescheduleResult?.status?.status
          );
          if (rescheduleResult?.status?.status === "SUCCESS") {
            setFormState({
              visitDate: "2024-08-23",
              slot: "",
              slotID: undefined,
            });
          }
        } else if (
          isNew &&
          patientDetailsQuery?.payload?.appointments?.length === 0
        ) {
          let createAppoint = {
            appointment: {
              visit_type: formState?.patientType?.toLowerCase(),
              visit_date: formState?.visitDate,
              appointment_slot_id: formState?.slotid,
              consultant_id: formState?.consultancyDoctorid,
              doctor_id: formState?.attendingDoctorid,
              patient_id: formState?.id,
            },
          };
          createAppoint = cleanPayload(createAppoint);
          let result = await savePatientAppointment(createAppoint);
          showToast(
            result?.status?.message || result?.status?.status,
            result?.status?.status
          );
          if (result?.status?.status === "SUCCESS") {
            setFormState({
              visitDate: "2024-08-23",
              slot: "",
              slotID: undefined,
            });
            dispatch(setRenderAppointment(true));
          }
        }
        setFormState({
          visitDate: "2024-08-23",
          slot: "",
          slotID: undefined,
        });
      } else {
        let createData = {
          patient: {
            full_name: formState?.fullName,
            email: formState?.email,
            mobile: formState?.mobileNumber,
            age: formState?.age,
            gender: formState?.gender?.toLowerCase(),
            marital_status: formState?.maritalStatus?.toLowerCase(),
            address: formState?.address,
            pincode: formState?.pincode,
            diet: formState?.diet?.split("-")?.join("_")?.toLowerCase(),
            city: formState?.city?.id || formState?.city || null,
            occupation_id:
              formState?.occupation?.id || formState?.occupation || null,
            education_id:
              formState?.education?.id || formState?.education || null,
          },
        };
        createData = cleanPayload(createData);
        console.log(createData);
        let createdPatient = await createAppointment(createData);
        if (isNew && createdPatient?.status?.status === "SUCCESS") {
          let createAppoint = {
            appointment: {
              visit_type: formState?.patientType?.toLowerCase(),
              visit_date: formState?.visitDate,
              appointment_slot_id: formState?.slotid,
              consultant_id: formState?.consultancyDoctorid,
              doctor_id: formState?.attendingDoctorid,
              patient_id: createdPatient?.payload?.id,
            },
          };
          createAppoint = cleanPayload(createAppoint);
          let result = await savePatientAppointment(createAppoint);
          // console.log(result);
          showToast(
            result?.status?.message || result?.status?.status,
            result?.status?.status
          );
        }
        if (createdPatient?.status?.status === "ERROR") {
          showToast(
            createdPatient?.status?.message || createdPatient?.status?.status,
            createdPatient?.status?.status
          );
        }

        if (createdPatient?.status?.status === "SUCCESS") {
          showToast(
            createdPatient?.status?.message || createdPatient?.status?.status,
            createdPatient?.status?.status
          );
          setFormState({
            visitDate: "2024-08-23",
            slot: "",
            slotID: undefined,
          });
          dispatch(setRenderAppointment(true));
        }
      }
      setButtonLoading(false);
    } catch (error) {
      console.error("Error saving patient data:", error);
      showToast("An error occurred while saving patient data", "ERROR");
      setButtonLoading(false);
    }
  };

  // const selectedOption = fieldOptions["occupation"]?.find((option) => {
  //   console.log("Option:", option); // Log each option being checked
  //   console.log("formState['occupation']:", formState["occupation"]); // Log the current value of formState['occupation']

  //   return (
  //     option.value ===
  //       (formState["occupation"]?.value || formState["occupation"]) ||
  //     option.title ===
  //       (formState["occupation"]?.title || formState["occupation"]) ||
  //     option.id === formState["occupation"] ||
  //     option.id ===
  //       (formState["occupation"]?.title || formState["occupation"]) ||
  //     option.title === formState?.occupation_title
  //   );
  // });

  // console.log("Selected Option:", selectedOption);
  const renderField = (field) => {
    const getCurrentDate = () => {
      return new Date().toISOString().split("T")[0];
    };
    let minDate, maxDate, maxLength;
    if (field.type === "date") {
      if (field.name === "age") {
        maxDate = getCurrentDate(); // Disable future dates for age
      } else if (field.name === "visitDate") {
        minDate = getCurrentDate(); // Disable past dates for visit date
      }
    } else if (field.name === "mobileNumber") {
      maxLength = 10;
    }
    switch (field.field) {
      case "TextField":
        return (
          <TextField
            fullWidth
            required={true}
            type={field.type}
            disabled={field.isDisabled}
            placeholder={field.placeholder}
            sx={field.sx}
            variant={field.variant}
            name={field.name}
            value={formState[field.name] || ""}
            onChange={handleFormChange}
            inputProps={{
              min: minDate,
              max: maxDate,
              maxLength: maxLength,
            }}
            error={Boolean(errors[field.name])}
            helperText={errors[field.name] || ""}
          />
        );
      case "Select":
        return (
          <FormControl fullWidth variant="outlined">
            <Select
              fullWidth
              sx={{
                ...field.sx,
                color: formState[field.name]
                  ? "text.black"
                  : "text.default_grey",
              }}
              value={formState[field.name] || ""}
              onChange={handleFormChange}
              name={field.name}
              displayEmpty
              required={true}
            >
              <MenuItem value="" disabled>
                {field?.placeholder || "Select an option"}
              </MenuItem>
              {field?.options?.map((option) => (
                <MenuItem
                  id={option?.value || "menu"}
                  key={option.value + Math.random()}
                  value={option.value}
                  sx={{
                    ...(option.value === field.defaultValue
                      ? field.defaultMenuStyle
                      : field.menuItemStyle),
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errors[field.name] && (
              <Typography fontSize={"12px"} color={"#d32f2f"}>
                {errors[field.name]}
              </Typography>
            )}
          </FormControl>
        );
      case "Autocomplete":
        return (
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              options={[
                ...(fieldOptions[field.name] || field.options),
                { id: "other", value: "other", title: "Other" },
              ]}
              // value={
              //   (formState[field.name] &&
              //     fieldOptions[field.name]?.find(
              //       (option) =>
              //         option.value ===
              //           (formState[field.name]?.value ||
              //             formState[field.name]) ||
              //         option.title ===
              //           (formState[field.name]?.title ||
              //             formState[field.name]) ||
              //         option.id === formState[field.name] ||
              //         option.id ===
              //           (formState[field.name]?.title ||
              //             formState[field.name]) ||
              //         option.title === formState?.occupation_title
              //     )) ||
              //   null
              // }
              value={
                (formState[field.name] &&
                  (fieldOptions[field.name] || field.options)?.find(
                    (option) =>
                      option.value ===
                        (formState[field.name]?.value ||
                          formState[field.name]) ||
                      option.title ===
                        (formState[field.name]?.title ||
                          formState[field.name]) ||
                      option.id === formState[field.name]
                  )) ||
                null
              }
              onChange={(event, newValue) =>
                handleFormChange(null, newValue, field.name)
              }
              isOptionEqualToValue={(option, value) =>
                option.id === value.id ||
                option.value === value.value ||
                option.title === value.title
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={formState[field.name]}
                  placeholder={field.placeholder}
                  variant={field.variant}
                  fullWidth
                  sx={field.sx}
                />
              )}
              getOptionLabel={(option) =>
                option.title && option.value ? `${option.title}` : ""
              }
              renderOption={(props, option, { index }) => (
                <MenuItem
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      color: "black !important",
                      borderRadius: "20px",
                    },
                    ...(index === 0 && {
                      backgroundColor: "#0067FF40 !important",
                      color: "#000000 !important",
                      borderRadius: "20px",
                    }),
                  }}
                  {...props}
                  key={option.id + Math.random()}
                >
                  {option.title}
                </MenuItem>
              )}
              blurOnSelect={
                formState?.city || formState?.occupation || formState?.education
                  ? false
                  : true
              }
              selectOnFocus={true}
              clearOnBlur={
                formState?.city || formState?.occupation || formState?.education
                  ? false
                  : true
              }
            />
            {showNewValueField[field.name] && (
              <Box mt={1} display="flex" alignItems="center">
                <TextField
                  placeholder={`Add new ${field.name}`}
                  variant="outlined"
                  fullWidth
                  value={newValues[field.name] || ""}
                  onChange={(e) =>
                    setNewValues({ ...newValues, [field.name]: e.target.value })
                  }
                  sx={{
                    ...field.sx,
                    "& .MuiInputBase-root": {
                      backgroundColor: "neutral.lightOrange",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => handleAddNewValue(field.name)}
                        sx={{
                          p: 0,
                          m: 0,
                          backgroundColor: "white",

                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <AddCircleOutlineRoundedIcon color="white" />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            )}
            {errors[field.name] && (
              <Typography fontSize={"12px"} color={"#d32f2f"}>
                {errors[field.name]}
              </Typography>
            )}
          </FormControl>
        );
      default:
        return null;
    }
  };
  // console.log(formState, "formState search");
  // console.log(allFormFields, "all form field", newSlotOption);

  return (
    <>
      <Grid
        container
        marginBottom={"auto"}
        lg={3.5}
        md={3.5}
        sm={5.5}
        sx={{
          backgroundColor: "#F7F7FF",
          paddingX: "15px",
          paddingY: "35px",
          marginLeft: "-10px",
          borderTopRightRadius: "20px",
        }}
      >
        {/* {"-----Search Patient Information-----"} */}
        <Grid item sm={12}>
          <Autocomplete
            popupIcon={false}
            open={patientData?.length>0 && openSearchDropDown}
            clearIcon={false}
            onOpen={() => setOpenSearchDropDown(true)}
            onClose={() => {
              dispatch(setSearchPatient([]));
              setOpenSearchDropDown(false);
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                selectedPatient(newValue);
                setInputValue("");
              }
            }}
            onInputChange={handleInputChange}
            options={patientData || []}
            filterOptions={(options) => options}
            getOptionLabel={(option) =>
              option.uid && option.full_name
                ? `${option.uid} - ${option.full_name}`
                : ""
            }
            noOptionsText="" 
            renderOption={(props, option) => (
              <MenuItem
                key={option.id + Math.random()}
                // onClick={() => selectedPatient(option)}
                {...props}
                sx={{
                  ...menuItemStyle,
                  width: "100%",
                  "&:hover, &.Mui-focused": {
                    backgroundColor: "#0067FF40 !important",
                    color: "#000000 !important",
                  },
                }}
              >
                {option.uid
                  ? `${option.uid} - ${option.full_name}`
                  : option.full_name}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                id="search"
                placeholder="Search Patient"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <SearchIcon sx={{ opacity: 0.4 }} color="grey" />

                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                sx={{
                  ...textfieldTextPrep,
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          sx={{
            //  border: "1px solid rgba(0, 0, 0, 0.125)",
            padding: "1rem",
          }}
        >
          {/* {"-----Patient Form----"}  */}
          <Grid container spacing={1.5} mb={2}>
            {allFormFields.map((field) => (
              <Grid item {...field.gridProps} key={field.id}>
                {field.isTitle && (
                  <TextView variant="title_medium">{field.isTitle}</TextView>
                )}
                {renderField(field)}
              </Grid>
            ))}
          </Grid>

          {/* {"Button For Appointment"} */}
          <Box sx={{ ...buttonWrapper }}>
            {formState?.id && (
              <Button
                disabled={buttonLoading}
                onClick={() => handleSavePatient(false)}
                variant="contained"
                sx={{ ...primaryBtnBlue, marginTop: "10px" }}
                className="btn draw-border"
              >
                Save Patient Info
              </Button>
            )}
            <Button
              disabled={buttonLoading}
              onClick={() => handleSavePatient(true)}
              variant="contained"
              sx={{ ...primaryBtnBlue, marginTop: "10px" }}
              className="btn draw-border"
            >
              Take New Appointment
            </Button>
          </Box>
        </Grid>

        {/* {"---Seven Day Slot ---"} */}
        {/* 
      <Grid container spacing={2} sx={sevendayslotWrapper}>
        <Grid item xs={12} sm={12}>
          <TextView variant={"heading1"} color={"neutral.main"}>
            7 days Slots Availability
          </TextView>
        </Grid>
        <Box sx={slotBoxWrapper}>
          <Box sx={eachSlotWrapper}>
            <TextView variant={"title_medium"}>Mon (50)</TextView>
            <TextView color={"neutral.main"} variant={"body1_text"}>
              29 Aug
            </TextView>
            <TextView variant={"body1_text"}>(N R O)</TextView>
          </Box>
          <Box sx={eachSlotWrapper}>
            <TextView variant={"title_medium"}>Tue (50)</TextView>
            <TextView color={"neutral.main"} variant={"body1_text"}>
              29 Aug
            </TextView>
            <TextView variant={"body1_text"}>(N R O)</TextView>
          </Box>
          <Box sx={eachSlotWrapper}>
            <TextView variant={"title_medium"}>Wed (50)</TextView>
            <TextView color={"neutral.main"} variant={"body1_text"}>
              29 Aug
            </TextView>
            <TextView variant={"body1_text"}>(N R O)</TextView>
          </Box>

          <Box sx={eachSlotWrapper}>
            <TextView variant={"title_medium"}>Thu (50)</TextView>
            <TextView color={"neutral.main"} variant={"body1_text"}>
              29 Aug
            </TextView>
            <TextView variant={"body1_text"}>(N R O)</TextView>
          </Box>

          <Box sx={eachSlotWrapper}>
            <TextView variant={"title_medium"}>Fri (50)</TextView>
            <TextView color={"neutral.main"} variant={"body1_text"}>
              29 Aug
            </TextView>
            <TextView variant={"body1_text"}>(N R O)</TextView>
          </Box>
          <Box sx={eachSlotWrapper}>
            <TextView variant={"title_medium"}>Sat (50)</TextView>
            <TextView color={"neutral.main"} variant={"body1_text"}>
              29 Aug
            </TextView>
            <TextView variant={"body1_text"}>(N R O)</TextView>
          </Box>

          <Box sx={eachSlotWrapper}>
            <TextView variant={"title_medium"}>Sun (50)</TextView>
            <TextView color={"neutral.main"} variant={"body1_text"}>
              29 Aug
            </TextView>
            <TextView variant={"body1_text"}>(N R O)</TextView>
          </Box>
        </Box>  
      </Grid> */}

        <Modal
          open={isLoading}
          // open={true}
          //  onClose={}
          sx={{
            position: "absolute",
            top: "40%",
            left: "47%",
            border: "none !important",
            outline: "none !important",
          }}
        >
          <Box
            sx={{
              animation: `${rotateSpinner} 1s linear infinite`,
              width: "50px",
              border: "none !important",
              outline: "none !important",
            }}
          >
            <img
              src={loadingSpinner}
              alt=""
              style={{
                width: "50px",
                height: "50px",
                border: "none !important",
                outline: "none !important",
              }}
            />
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default NewAppointment;
