import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Box,
  Modal,
  keyframes,
} from "@mui/material";
import { drTableRow } from "../../style/DoctorListComponent";
import { tableCellStyle } from "../../style/AppointmentStyle";
import UseGetBillingInvoice from "../../helper/UseGetBillingInvoice";
import { getInvoiceDataById, getPatient } from "../../connections/getApi";
import { useDispatch, useSelector } from "react-redux";
import {
  setData,
  setPatientId,
  setPatientInvoice,
  setRenderInvoice,
} from "../../redux/slices/billingSlice";
import { showToast } from "../../utils/toastUtil";
import { putRefundMoney } from "../../connections/putApi";
import updownar from "../../assets/updownar.svg";
import loadingSpinner from "../../assets/loadingSpinner.svg";
import bye from "../../assets/bye.svg";
import not_found from "../../assets/not_found.svg";

const rotateSpinner = keyframes`
0% { transform: rotate(0deg); }
10% { transform: rotate(36deg); }
20% { transform: rotate(72deg); }
30% { transform: rotate(108deg); }
40% { transform: rotate(144deg); }
50% { transform: rotate(180deg); }
60% { transform: rotate(216deg); }
70% { transform: rotate(252deg); }
80% { transform: rotate(288deg); }
90% { transform: rotate(324deg); }
100% { transform: rotate(360deg); }
`;

const Invoice = () => {
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [indexRow, setIndexRow] = useState("");
  const {
    billing: { renderInvoice },
  } = useSelector((state) => state);
  const {
    data: invoiceData,
    refetch,
    isLoading,
  } = UseGetBillingInvoice(renderInvoice);
  const dispatch = useDispatch();

  const handleClickInvoice = async (inVoiceId, patientId, index) => {
    setLoadingInvoice(true);
    try {
      if (inVoiceId) {
        const data = await getInvoiceDataById(inVoiceId);
        dispatch(setPatientInvoice(data?.payload));
      }
      if (patientId) {
        const PatientData = await getPatient(patientId);
        dispatch(setData(PatientData));
        // console.log(PatientData);
        dispatch(setPatientId(PatientData?.payload?.id || patientId));
      }
      setIndexRow(inVoiceId);
      setLoadingInvoice(false);
    } catch (error) {
      showToast("Something Went Wrong", "ERROR");
      setLoadingInvoice(false);
    }
  };

  useEffect(() => {
    // console.log('refetc')
    refetch();
    setIndexRow("");
  }, [renderInvoice]);

  const handleRefund = async (id) => {
    try {
      const data = await putRefundMoney(id);
      if (data?.payload?.id) {
        showToast("Refund ..Success", "SUCCESS");

        refetch();
        dispatch(setRenderInvoice());
      } else {
        showToast("Error in Refund ..", "ERROR");
      }
    } catch (error) {
      showToast(error?.status?.message, error?.status?.status);
    }
  };
  return (
    <Grid
      // sx={{ border: "1px solid rgba(0, 0, 0, 0.125)", padding: "15px" }}
      sx={{
        backgroundColor: "#F7F7FF",
        paddingX: "15px",
        paddingY: "35px",
        borderTopLeftRadius: "20px",
        marginRight: "-8px",
        outline: "none !important",
        border: "none !important",
      }}
      container
      sm={12}
      md={3.6}
      lg={3.6}
      // justifyContent="center"

      textAlign={"center"}
    >
      <Grid item xs={12}>
        {/* 
        <Typography variant="h6" sx={{ position:'relative' , justifyContent:'flex-start' ,left:'0'}} >
          My Invoices ({invoiceData?.payload?.invoices?.length})
        </Typography> */}

        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography
            letterSpacing={"0.15px"}
            variant="heading1"
            //  color={"neutral.navyBlue"}
            fontSize={"18px"}
          >
            {" "}
            My Invoices{" "}
            {invoiceData?.payload?.invoices?.length > 0 &&
              ` : ${" "} ${invoiceData?.payload?.invoices?.length}`}
          </Typography>
        </Box>

        <TableContainer
          // component={Paper}
          sx={{
            mt: 2,
            maxHeight: "70vh",
            minHeight: "35vh",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
            "scrollbar-width": "none", // Hide scrollbar for Firefox
          }}
        >
          <Table
            sx={{
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none", // Hide the scrollbar for Webkit browsers (Chrome, Safari)
              },
              "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
              "scrollbar-width": "none", // Hide scrollbar for Firefox
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableCellStyle, textAlign: "center" }}>
                  <Typography variant="small_Text1">Name </Typography>
                </TableCell>
                <TableCell sx={{ ...tableCellStyle, textAlign: "center" }}>
                  <Typography variant="small_Text1">Time </Typography>
                </TableCell>
                <TableCell sx={{ ...tableCellStyle, textAlign: "center" }}>
                  <Typography variant="small_Text1"> Amount Paid </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            {invoiceData?.payload?.invoices?.length > 0 ? (
              <TableBody sx={{ alignSelf: "center" }}>
                {invoiceData?.payload?.invoices?.map((invoice, index) => {
                  if (invoice === null) {
                    return null;
                  }
                  return (
                    <TableRow
                      key={invoice?.id + index}
                      sx={{
                        backgroundColor:
                          indexRow === invoice?.id ? "#0067FF40" : "white",
                        paddingY: "6px",
                        cursor: "pointer",
                        //  "&:hover": {
                        //    boxShadow: "rgba(0, 0, 0, 0.25) 3px 3px 6px",
                        //    borderRadius:'20px',
                        //  },

                        borderBottom: "8px solid #F7F7FF",
                        borderTop: index === 0 ? "6px solid #F7F7FF" : "",
                        transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
                        "&:hover": {
                          transform: "scale(1.001)", // Slight scaling for the pop-up effect
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                          borderRadius: "20px !important",
                        },
                      }}
                    >
                      <TableCell
                        onClick={() =>
                          handleClickInvoice(
                            invoice.id,
                            invoice?.patient_id,
                            index
                          )
                        }
                        sx={{
                          padding: "4px",
                          paddingLeft: "16px",
                          textAlign: "center",
                          cursor: "pointer",
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                      >
                        <Typography variant="heading2">
                          {invoice?.name}
                        </Typography>
                        <br />
                        <Typography
                          sx={{ cursor: "text" }}
                          variant="body2_text"
                          color="textSecondary"
                        >
                          {invoice?.patient_uid}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px",
                          paddingLeft: "16px",
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      >
                        <Typography color={"text.grey"} variant="body2_text">
                          {invoice?.generated_at}
                        </Typography>
                        <br />
                        <Typography
                          variant="body2_text"
                          color="text.lightBlue1"
                        >
                          {invoice?.departments[0]}
                        </Typography>
                        <br />
                        <Typography variant="body2_text" color="text.grey">
                          {invoice?.consultant_name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px",
                          paddingLeft: "16px",
                          textAlign: "center",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        {invoice?.refund_available === "main" ? (
                          <>
                            <Typography
                              color={"text.grey2"}
                              variant="body_Grey1"
                            >
                              {invoice?.total_amount}
                            </Typography>
                            <br />
                            <Chip
                              onClick={() => handleRefund(invoice?.id)}
                              size="small"
                              sx={{
                                alignSelf: "center",
                                p: "2px",
                                backgroundColor: "#FF392B",
                                "&:hover": {
                                  backgroundColor: "#e02012",

                                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                },
                                color: "neutral.white",
                              }}
                              label="Refund"
                            />{" "}
                          </>
                        ) : (
                          <Typography color={"text.grey2"} variant="body_Grey1">
                            {invoice?.total_amount}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {invoiceData?.payload?.invoices?.map((invoice, index) => 
                <TableRow key={invoice?.id + index}>
                  <TableCell
                    onClick={() =>
                      handleClickInvoice(invoice.id, invoice?.patient_id)
                    }
                    sx={{
                      padding: "4px",
                      paddingLeft: "16px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="heading2">{invoice?.name}</Typography>
                    <br />
                    <Typography
                      sx={{ cursor: "text" }}
                      variant="body2_text"
                      color="textSecondary"
                    >
                      {invoice?.patient_uid}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "4px",
                      paddingLeft: "16px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography color={"text.grey"} variant="body2_text">
                      {invoice?.generated_at}
                    </Typography>
                    <br />
                    <Typography variant="body2_text" color="text.lightBlue1">
                      {invoice?.departments[0]}
                    </Typography>
                    <br />
                    <Typography variant="body2_text" color="text.grey">
                      {invoice?.consultant_name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "4px",
                      paddingLeft: "16px",
                    }}
                  >
                    {invoice?.refund_available === "main" ? (
                      <>
                        <Typography color={"text.grey2"} variant="body_Grey1">
                          {invoice?.total_amount}
                        </Typography>
                        <br />
                        <Chip
                          onClick={() => handleRefund(invoice?.id)}
                          size="small"
                          sx={{
                            alignSelf: "center",
                            p: "2px",
                            backgroundColor: "neutral.brown",
                            "&:hover": {
                              backgroundColor: "neutral.brown",
                            },
                            color: "neutral.white",
                          }}
                          label="Refund"
                        />{" "}
                      </>
                    ) : (
                      <Typography color={"text.grey2"} variant="body1_text">
                        {invoice?.total_amount}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
})} */}
              </TableBody>
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #F7F7FF",
                  }}
                  rowSpan={5}
                  colSpan={5}
                >
                  {/* <Typography color={"black"} variant="heading2">
                    Data Not Found
                  </Typography> */}

                  <Box
                    sx={{
                      width: "100%",
                      margin: "auto",
                      display: isLoading ? "none" : "",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        height: "185px",
                        marginTop: "100px",
                      }}
                    >
                      <Box sx={{ marginTop: "-80px", marginLeft: "30px" }}>
                        <img src={not_found} alt="" width={"150px"} />
                      </Box>

                      <Box sx={{ paddingRight: "20px", marginTop: "-14px" }}>
                        <Typography
                          fontSize={"22px"}
                          fontWeight={"bold"}
                          color={"neutral.navyBlue"}
                        >
                          {" "}
                          <Typography variant="span" color={"black"}>
                            {" "}
                            Data not found!
                          </Typography>{" "}
                        </Typography>
                        {/* <Typography fontSize={'24px'}  fontWeight={'bold'} >You've made it </Typography> */}
                        {/* <Typography sx={{marginTop:"8px"}}> Data not found!</Typography> */}
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Modal
        open={loadingInvoice}
        // open={true}
        //  onClose={}
        sx={{
          position: "absolute",
          top: "40%",
          left: "47%",
          border: "none !important",
          outline: "none !important",
        }}
      >
        <Box
          sx={{
            animation: `${rotateSpinner} 1s linear infinite`,
            width: "50px",
            border: "none !important",
            outline: "none !important",
          }}
        >
          <img
            src={loadingSpinner}
            alt=""
            style={{
              width: "50px",
              height: "50px",
              border: "none !important",
              outline: "none !important",
            }}
          />
        </Box>
      </Modal>
    </Grid>
  );
};

export default Invoice;
