import { Box } from '@mui/material';
import React from 'react';
import { pharmacyPageWrapper } from '../style/PharmacyStyle';
import PatientListTable from '../Components/Pharmacy/PatientListTable';

const Pharmacy = () => {
return (
  <Box sx={pharmacyPageWrapper}>
        <PatientListTable/> 
  </Box>
  )
}

export default Pharmacy;