import React, { useState } from "react";
import UseFetchCallDetails from "../../helper/UseFetchCallDetails";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { drTableRow } from "../../style/DoctorListComponent";
import {
  menuItemStyle,
  primaryBtn,
  selectField,
  tableCellStyle,
  textfieldText,
} from "../../style/AppointmentStyle";
import { primaryBtnBlue, textfieldTextBlueBorder, textfieldTextPrep } from "../../style/PatientDetailsStyle";
import { autoFocusOffWithOutBorder, closeBtn } from "../../style/PatinetFormStyle";
import { addReminders } from "../../connections/postApi";
import Loader from "../UI/Loader";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from '@mui/icons-material/Call';
import { headingStyle, tableRowLab } from "../../style/LapPageStyle";

let initState = {
  reminderable_id: null,
  reminderable_type: "Appointment",
  status: "",
  remarks: "",
  reminded_at: "",
};
const getFormattedDate = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const AppointmentCalls = ({ setCallModal, id, refetchAppointment  , titleName}) => {
  console.log(titleName) 
  const { data: callList, refetch, isLoading } = UseFetchCallDetails(id);
  const [formState, setFormState] = useState(initState);
  const handleFormChange = (e, mewValue) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
      reminderable_id: id,
      reminded_at: getFormattedDate(),
    }));
  };

  const submitReminder = () => {
    const Data = {
      reminder: formState,
    };
    addReminders(Data);
    refetch(id);
    refetchAppointment();
    setCallModal(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if date is null or undefined
  
    // Check if date has "PM" or "AM" with 24-hour format, and remove them if so
    if (dateString.includes("PM") || dateString.includes("AM")) {
      // Remove AM/PM if hour is in 24-hour format
      const [datePart, timePart, period] = dateString.split(" ");
      const [hours, minutes, seconds] = timePart.split(":");
  
      // If hour is greater than 12 but still has AM/PM, remove the period
      if (parseInt(hours, 10) >= 12) {
        dateString = `${datePart} ${timePart}`;
      }
    }
  
    const date = new Date(dateString);
  
    if (isNaN(date.getTime())) return ""; // Return empty string if the date is invalid
  
    // Extract day, month, year, hours, and minutes
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? "PM" : "AM";
  
    // Convert 24-hour time to 12-hour format
    hours = hours % 12 || 12; // `0` hours should be displayed as `12`
  
    return `${day}/${month}/${year} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
  };
  

  return (
    <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
    
    <Box></Box>
            
    
    <Box sx={{display:'flex' , alignItems:'center'}}>
    <CallIcon sx={{ color:"neutral.navyBlue" ,width:'28px' ,height:'24px' , marginRight:"8px" ,  marginTop:'-4px'}}/>
      <Typography
        variant="heading1" 
        fontWeight={600}
        gutterBottom
        sx={{...headingStyle }}

      >
     {titleName}
      </Typography>
    </Box>
      
     
    
    <IconButton sx={{ marginBottom: "2rem" }}
         onClick={() => setCallModal(false)}
    >
      <CloseIcon  sx={{ color:'red'}}size="small" color="red" />
    </IconButton>
</Box>

    <Grid alignItems={"start"} mt={3} container>

      {callList?.reminders.length > 0 && (
        <Grid item md={12}>
          <TableContainer

          // sx={{ mt: 2, maxHeight: "100vh", minHeight: "70vh" }}
          >
            <Table sx={{ overflowY: "scroll" }}>
              <TableHead>
                <TableRow >
                  <TableCell sx={{...tableCellStyle , textAlign:"center"}}>
                    <Typography variant="body1_text_bold">
                      Call Count 
                    </Typography>
                  </TableCell>
                  <TableCell sx={{...tableCellStyle , textAlign:"center"}}>
                    <Typography variant="body1_text_bold">Status</Typography>
                  </TableCell>
                  <TableCell sx={{...tableCellStyle , textAlign:"center"}}>
                    <Typography variant="body1_text_bold">Remarks</Typography>
                  </TableCell>
                  <TableCell sx={{...tableCellStyle , textAlign:"center"}}>
                    <Typography variant="body1_text_bold">Call At</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableBody>
                  {callList?.reminders?.map((patient, index) => (
                    <TableRow key={index} sx={{...tableRowLab , borderTop:'2px solid white' , borderBottom:"8px solid white"}}>
                      <TableCell sx={{ padding: "10px", pl: "16px" , borderTopLeftRadius:'20px' , borderBottomLeftRadius:"20px" , textAlign:"center" }}>
                        <Typography variant="body_Grey1">
                          {" "}
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: "8px", pl: "16px",  textAlign:"center"}}>
                        <Typography
                          variant="body_Grey1"
                          color={"neutral.black"}
                        >
                          {patient.status}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: "4px", pl: "16px" ,  textAlign:"center" }}>
                        <Typography
                          variant="body_Grey1"
                          color={"neutral.black"}
                        >
                          {patient?.remarks}
                        </Typography>{" "}
                      </TableCell>
                      <TableCell sx={{ p: "4px", pl: "16px" ,  borderTopRightRadius:'20px' , borderBottomRightRadius:"20px" ,  textAlign:"center"  }}>
                        <Typography
                          variant="body_Grey1"
                          color={"neutral.black"}
                        >
                          {/* {patient.reminded_at} */}
                         { formatDate(patient.reminded_at)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      )}
 
      <Grid mt={7} textAlign={"center"} item md={12}>
        <Typography color={"neutral.black"} variant="heading2_14">
          Add Call details
        </Typography>

        <Box         
          display="flex"
          gap={3}
          margin='auto'
          mt={3}
          minWidth={'min-content'}
           justifyContent={"space-around"}
        >

          <FormControl
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              // width: "11%",
            }}
            variant="outlined"
          >
            <Typography mb={1} variant="body1_text_bold" justifyContent={'flex-start'}>
              Status
            </Typography>
            <Select
              fullWidth
              sx={{
                ...autoFocusOffWithOutBorder,
                height: "42px",
                p: 1.4,
                "& .MuiSelect-icon": {
                  paddingTop: 0.4, // Adjust the icon's padding here
                  color: "#1f2022 !important",
                },
                lineHeight:"24px",
                minWidth:"220px",
 
                backgroundColor:"#F7F7FF",
              }}
              value={formState.status}
              onChange={handleFormChange}
              name="status"
              displayEmpty
            >
              <MenuItem value="" disabled>
                {"Nothing Selected"}
              </MenuItem>
              {[
                { value: "call_later", title: "Call Later" },
                { value: "confirmed", title: "Confirmed" },
                { value: "denied", title: "Denied" },
                { value: "not_picked", title: "Not Picked" },
              ]?.map((option) => (
                <MenuItem
                  // id={option?.value || "menu"}
                  key={option + Math.random()}
                  value={option?.value}
                  sx={{ ...menuItemStyle, textTransform: "capitalize" }}
                >
                  {option?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "20%",
            }}
            variant="outlined"
          >
            <Typography mb={1} variant="body1_text_bold">
              Remarks
            </Typography>
            <TextField
              type="text"
              fullWidth
              value={formState.remarks}
              onChange={handleFormChange}
              name="remarks"
              placeholder="Remarks"
              variant="outlined"
              sx={{
              ...autoFocusOffWithOutBorder
              }}
            />
          </FormControl>
 
          <FormControl
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "20%",
            }}
            variant="outlined"
          >
            <Typography mb={1} variant="body1_text_bold">
              Called At
            </Typography>
            <TextField
              type="text"
              value={new Date().toLocaleString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "2-digit",
                hour12: true,
              })}
              onChange={handleFormChange}
              name="reminded_at"
              disabled={true}
              variant="outlined"
              sx={{
                ...autoFocusOffWithOutBorder
                }
              }
            />
          </FormControl>
        </Box>

      </Grid>

      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "flex-end",
          justifyContent: "flex-end",
          width:'88%',
          margin:'auto',
          marginTop:'15px',
          // border:"2px solid black"
        }}
        // item
        // md={12}
        // mt={1}
      >
        
        <Button
          onClick={submitReminder}
          variant="contained"
          sx={{ ...primaryBtnBlue }}
        >
          <Typography textTransform={"capitalize"} fontSize={"1.1rem"}>
            Save
          </Typography>
        </Button>
      </Box>

    </Grid>
    </Box>
  );
};

export default AppointmentCalls;
