import React, { useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";

const PharmacyInvoice = React.forwardRef((props, ref) => {
  const formatDate = () => {
    const date = new Date();
    // Options for the date format: MM/DD/YY, h:mm AM/PM
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  const formatDateMonth = () => {
    const date = new Date();

    // Options for the date format: DD MMM, YYYY hh:mm:ss AM/PM
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  function convertNumberToWords(number) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    let result = "";

    if (number >= 100) {
      result += `${units[Math.floor(number / 100)]} Hundred `;
      number %= 100;
    }

    if (number >= 10 && number <= 19) {
      result += teens[number - 10];
    } else {
      result += tens[Math.floor(number / 10)];
      if (number % 10 > 0) {
        result += ` ${units[number % 10]}`;
      }
    }

    return result.trim();
  }

  // console.log(props?.props?.payload?.total_amount);
  // console.log( props?.props?.payload?.total_net);
  return (
    <Box
      ref={ref}
      sx={{
        padding: "20px",
        minWidth: "1000px",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          // border: "0.5px solid",
          // borderColor: "lightgray",
          p: 2,
        }}
      >
        <Box>
          <Typography>{formatDate()}</Typography>
        </Box>
        <Box>
          <Typography>CYPHER MD</Typography>
        </Box>
        <Box></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          // justifyContent: "space-between",
          // marginTop: "20px",
          border: "0.5px solid",
          borderColor: "lightgray",
          gap: 12,
          p: 2,
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight={"semi-bold"} gutterBottom>
            AGGARWAL PHARMACY
          </Typography>
          <Typography variant="subtitle1">
            B-35-922/2/1, Ferozpur Road Near MBD Mall Ludhiana
            <br />
            +91 98787-36644
          </Typography>
        </Box>

        <Box>
          <Typography variant="h6" fontWeight={"bold"}>
            {props?.props?.payload?.name} {(props?.props?.payload?.patient_uid)}
          </Typography>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            {props?.props?.payload?.mobile}&nbsp;&nbsp;
            {`${props?.props?.payload?.age || ""} / ${
              props?.props?.payload?.gender || ""
            }`}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
          border: "0.5px solid",
          borderColor: "lightgray",
          gap: 12,
          p: 2,
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={"semi-bold"} gutterBottom>
            GSTIN : 03ABRFA6867B1ZE
          </Typography>
          <Typography variant="subtitle1" mt={-1} >
            D.L. No: LDH-137840 NB LDH/137841 B
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5" fontWeight={"500"}>
            GST INVOICE
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">
            INVOICE NO: {props?.props?.payload?.id}
          </Typography>
          <Typography variant="subtitle1"  mt={-1}>Date :{formatDateMonth()}</Typography>
        </Box>
      </Box>

      <Table
        sx={{
          marginTop: "20px",
          width: "100%",
          border: "0.5px solid",
          borderColor: "lightgray",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>SN</strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong> PRODUCT NAME</strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>PACK</strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>BATCH</strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>EXP</strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>QTY</strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>MRP</strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>DISCOUNT</strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>CGST /SGST </strong>
            </TableCell>
            <TableCell
              sx={{
                border: "0.5px solid",
                borderColor: "lightgray",
                margin: "0px",
                textAlign: "center",
                padding: "4px",
              }}
            >
              <strong>AMOUNT</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.props?.payload?.medicines?.map((medicine, index) => (
            <TableRow>
              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {index + 1}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {medicine?.name}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {medicine?.pack}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {medicine?.batch}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {medicine?.exp}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {medicine?.qty}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {medicine?.mrp}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {medicine?.discount}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {`${medicine?.cgst} / ${medicine?.sgst} `}
              </TableCell>

              <TableCell
                sx={{
                  border: "0.5px solid",
                  borderColor: "lightgray",
                  margin: "0px",
                  textAlign: "center",
                  padding: "4px",
                }}
              >
                {medicine?.amount}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
          border: "0.5px solid",
          borderColor: "lightgray",
          gap: 12,
        }}
      >
        <Box width={"60%"} sx={{ p: 2 }}>
          <Typography variant="subtitle1" fontWeight={"bold"} gutterBottom>
            Terms & Conditions
          </Typography>

          <ul>
            <li>Goods sold once will not be taken back or exchanged </li>
            <li>All disputes subject to jurisdictions only</li>
            <li> Prescribed Sales Tax declaration will be given</li>
          </ul>

          <Typography variant="subtitle1" gutterBottom>
            Remarks
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Rupees : {convertNumberToWords(props?.props?.payload?.total_net)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            //  justifyContent: "space-between",
            flexDirection: "column",
            border: "0.5px solid",
            borderColor: "lightgray",
            width: "35%",
            margin: "0px",
            p: 2,
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="subtitle1" fontWeight={"bold"}>
                NET (incl DISc )
              </Typography>

              <Typography variant="subtitle1" fontWeight={"bold"}>
                {`INR ${props?.props?.payload?.total_amount}`}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Round-Off
              </Typography>

              <Typography variant="subtitle1" fontWeight={"bold"}>
                {`INR ${
                  Math.abs(
                  props?.props?.payload?.total_amount -
                  props?.props?.payload?.total_net).toFixed(2)
                }`}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "0.5px solid",
              borderColor: "lightgray",
              pt: 2,
            }}
          >
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Payable
            </Typography>

            <Typography variant="subtitle1" fontWeight={"bold"}>
              {`INR ${props?.props?.payload?.total_net}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
export default PharmacyInvoice;
