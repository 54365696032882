import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { keyframes } from "@emotion/react";

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const CustomModal = ({
  open,
  onClose,
  children,
  title,
  customStyles,
  isTitle = false,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
      
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        overflow: "auto",
        borderRadius:'20px',
      }}
    >
      <Box
        sx={{
          mt: 2,
          bgcolor: "background.paper",
          borderRadius: 3.6,
          boxShadow: 24,

          p: 2,
          // minWidth: '92%',
          "&:focus": {
            outline: "none",
          },
          // maxWidth: '92%',
          animation: `${slideIn} 0.3s ease-out`,
          ...customStyles,
        }}
      >
        {isTitle && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography fontWeight={"bold"} variant="h6">
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{color:'red'}} />
            </IconButton>
          </Box>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
