
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  Popper,
  InputAdornment,
  Modal,
  Skeleton,
} from "@mui/material";
import {
  headingStyle,
  labPageButton,
  tableCellLab,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  dateFiled,
  menuItemStyle,
  primaryBtn,
  selectField,
  textfieldText,
  textfieldTextBlueBorder,
  textfieldTextBlueBorderPharmacy,
} from "../../style/AppointmentStyle";
import SearchIcon from "@mui/icons-material/Search";
import { drTableRow } from "../../style/DrApotComponentStyle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { addBtn, primaryBtnPharmacy } from "../../style/PharmacyStyle";
import {
  setSearchPatient,
  setPatientDetailsData,
  setLoading,
} from "../../redux/slices/pharmacySlice";
import { useDispatch, useSelector } from "react-redux";
import { orangeButton, primaryBtnBlue, textfieldTextPrep } from "../../style/PatientDetailsStyle";
import UseSearchPatient from "../../helper/UseSearchPatient";
import UsePatientDetail from "../../helper/UsePatientDetail";
import useDebounce from "../../hooks/UseDebounce";
import UseGetMedicineList from "../../helper/UseGetMedicineList";
import UseMedicineByName from "../../helper/UseMedicineByName";
import UseGetMedicineDetail from "../../helper/UseGetMedicineDetail";
import {
  getMedicineByName,
  getPriceByQuantity,
  getPriceByQuantityForReturn,
} from "../../connections/getApi";
import PharmacyInvoice from "../PrintCompoents/PharmacyInvoice";
import { useReactToPrint } from "react-to-print";
import { generateBill } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import UseGetAllMedicineList from "../../helper/UseGetAllMedicineList";
import { Link } from "react-router-dom";
import calender_icon from '../../assets/calender_icon.svg';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { autoFocusOffWithOutBorder, drTableRowBlueBg } from "../../style/PatinetFormStyle";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import updownar from "../../assets/updownar.svg";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { DeleteForeverRounded } from "@mui/icons-material";
import json_icon from '../../assets/json_icon.svg';
import csv_icon from '../../assets/csv_icon.svg';
import CustomModal from "../CustomModal/CustomModal";
import PurchaseOrderDetails from "./PurchaseOrderDetails";
import EditPurchaseOrder from "./EditPurchaseOrder";
import NewPurchaseOrder from "./NewPurchaseOrder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditMedicines from "./EditMedicines";
import NewMedicines from "./NewMedicines";
import MedicinesDetails from "./MedicinesDetails";
import NewMedicineSalt from "./NewMedicineSalt";
 import medicine_salt from  '../../assets/medicine_salt.svg'
import EditMedicineSalt from "./EditMedicineSalt";
import UseFetchMedicineSaltDetails from "../../helper/UseFetchMedicineSaltDetails";
import { deleteMedicineSalt } from "../../connections/deleteApi";
import { formattedDateWithoutBg } from "../../utils/constantFunctions";

const MedicineSaltDetails = ({ onClose , id ,  refetch , page , itemPerPage }) => {
 
  
  const {data :medicineDetails , isLoading}= UseFetchMedicineSaltDetails(id);
  // console.log(medicineDetails);

   const[filterModal, setFilterModal] =useState(false);
   const[deleteModal, setDeleteModal] =useState(false);
   const[detailsModal,setDetailsModal]=useState(false);
   const[editPurchaseOrderModal,setEditPurchaseOrderModal]=useState(false);
   const[newPurchaseOrderModal,setNewPurchaseOrderModal]=useState(false);

   const handleDelete = async(id)=>{
    setDeleteModal(false);
     const res = await  deleteMedicineSalt(id);
     console.log(res);
     showToast(  res?.status?.message ||   res?.status?.status ,  res?.status?.status);
     if( res?.status?.status==='SUCCESS'){
      refetch(page,itemPerPage);
      onClose();
     }
  }
  console.log(medicineDetails);
 
  return ( 
    <>
    <Box sx={{ width: "100%", display:'flex' , height:"90vh" ,  flexDirection:'column' }}>



    {/* //modals for delete and filter */}
   
   <Modal 
    open={deleteModal}
    onClose={()=>setDeleteModal(false)}
    sx={{
    position:'absolute',
    top:'25%',
    // left:'50%',
    width:"450px",
    // height:"400px",
    margin:'auto',
    //  display: "flex",
    //  alignItems: "flex-start",
    //  justifyContent: "center",
    //  overflow: "auto",
    borderRadius:'20px',
   }}
  >
     
     <Box
        sx={{
          mt: 2,
          bgcolor: "background.paper",
          borderRadius: 3.6,
          boxShadow: 24,
          p: 4.5,         
          "&:focus": {
            outline: "none",
          },
        
        
        }}
      >

        <Box sx={{display:'flex' ,flexDirection:'column'}}>
          <Typography textAlign={'center'}>Are you sure?</Typography>
          <Typography textAlign={'center'}>You want to delete these selected purchase orders?</Typography>
          
        </Box>

        <Box onClick={()=>handleDelete(id)} sx={{display:'flex' ,flexDirection:'flex'  , gap:'25px' , width:'max-content',  margin:'auto' , marginTop:"40px"}}>
       <Button sx={{ backgroundColor:"#FF392B" , color:"white" , border:'' , width:"150px" ,  borderRadius:"5px" ,   fontSize:"12px" , textTransform:'capitalize' ,'&:hover': {
         backgroundColor:"red",
        } }}>Delete</Button>
       <Button onClick={()=>setDeleteModal(false)}  sx={{ backgroundColor:"white" , color:"black" , border:'2px solid #FF392B' , width:"150px" , borderRadius:"5px",  fontSize:"12px" , textTransform:'capitalize'  }}>Cancel</Button>
        </Box>
     
      </Box>
   </Modal> 
   
   <Modal 
    open={filterModal}
    onClose={()=>setFilterModal(false)}
    sx={{
    position:'absolute',
    top:'14%',
    // left:'50%',
    width:"340px", 
    // height:"400px",
    margin:'auto',
    //  display: "flex",
    //  alignItems: "flex-start",
    //  justifyContent: "center",
    //  overflow: "auto",
    borderRadius:'20px',
   }}
  >
     
     <Box
        sx={{
          mt: 2,
          bgcolor: "background.paper",
          borderRadius: '20px',
          boxShadow: 24,
          p: 1,         
          "&:focus": {
            outline: "none",
          },
        
        
        }} 
      >    

<Grid container spacing={2} sx={{padding:'9px'}}>
        <Grid item xs={12}>
        <Typography variant="subTitle116px" fontWeight={'600'} textAlign={'center'}  margin={'auto'}>Filters</Typography>
      </Grid>



      {/* <Grid item xs={12}>
        <Typography  variant="subTitle116px" >Suppliers</Typography>
      </Grid> */}

      {/* Filter Selection */}
      <Grid item xs={12}>
        
        <Select fullWidth defaultValue=""  placeholder="Select Filter"  sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove border
                }, 
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove border on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Ensure border remains none on focus
                },
                "& .MuiSelect-select": {
                  padding: "9px",
                  borderRadius: "15px",
                  backgroundColor: "#F7F7FF",
                },
                "&:hover": {
                  boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                }}} 
                
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span style={{ color: "text.default_grey" }}>
                        Select filter
                      </span>
                    );
                  }
                  return selected === "filter1"
                    ? "filter2"
                    : selected === "filter3"
                    ? "filter3"
                    : "select filter";
                }}
                >
          <MenuItem value="filter1">Filter 1</MenuItem>
          <MenuItem value="filter2">Filter 2</MenuItem>
          <MenuItem value="filter3">Filter 3</MenuItem>
        </Select>
      </Grid>

      {/* Purchase Date */}
      <Grid item xs={12}>
        <Typography variant="subTitle116px">Purchase Date</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={autoFocusOffWithOutBorder}
          label="From"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={autoFocusOffWithOutBorder}
          label="To"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      {/* Department Selection */}
      <Grid item xs={12}>
        <Typography variant="subTitle116px">Department</Typography>
        <Select fullWidth defaultValue=""  sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove border
            }, 
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove border on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none", // Ensure border remains none on focus
            },
            "& .MuiSelect-select": {
              padding: "9px",
              borderRadius: "15px",
              backgroundColor: "#F7F7FF",
            },
            "&:hover": {
              boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
            }
        }}>
          <MenuItem value="dept1">Department 1</MenuItem>
          <MenuItem value="dept2">Department 2</MenuItem>
          <MenuItem value="dept3">Department 3</MenuItem>
        </Select>
      </Grid>

      {/* Created At */}
      <Grid item xs={12}>
        <Typography variant="subTitle116px">Created At</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={autoFocusOffWithOutBorder}
          label="From"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={autoFocusOffWithOutBorder}
          label="To"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      {/* Buttons */}
      <Grid item xs={6}>
        <Button fullWidth variant="subTitle116px16px" sx={{...primaryBtnBlue , color:'white'}}>Apply</Button>
      </Grid>
      <Grid item xs={6}>
        <Button onClick={()=>setFilterModal(false)} fullWidth variant="subTitle116px16px" sx={{...primaryBtn , color:'white' , border:'none'}}>Clear Filters</Button>
      </Grid>
    </Grid>


       
      </Box>
   </Modal> 


    <Box sx={{overflowY :'auto' , maxHeight:"80vh" ,  '&::-webkit-scrollbar': {
    display: 'none',
    },
    '-ms-overflow-style': 'none',  
    'scrollbar-width': 'none',  }}  >
      
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
      >
        <Box></Box>
  

         <Box sx={{display:'flex', justifyContent:'center' , alignItems:'center'  , gap:"5px"}} >
         <img src={medicine_salt}  alt='' sx={{color:'neutral.navyBlue' , marginBottom:"4px" , marginRight:'14px'}} /> 
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={{...headingStyle}} 
        >
         Medicine salt Details
       </Typography>
       </Box>
        <IconButton sx={{ marginBottom: "1rem" }} onClick={onClose}>
          <CloseIcon size="small"  sx={{color:"red"}}/>
        </IconButton>
      </Box> 
     
      <Box
       mt={1}
       mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        paddingX="25px"
      >
        <Box sx={{display:'flex' , gap:'20px' ,width:"100%"}}>

          <Box sx={{display:'flex' , gap:'20px' , flexDirection:'column'}}>
          <Typography fontWeight={'bold'}>
            Name 
          </Typography>

          <Typography sx={{marginBottom:"10px"}}>
           Brands
          </Typography>
          </Box>

          <Box sx={{display:'flex' , gap:'10px' , flexDirection:'column'}}>
          <Typography fontWeight={'400'}>
           {medicineDetails?.payload?.name}
          </Typography>

          <Typography>
        
          </Typography>
          </Box>
        </Box>


       <Box mt={-3}  sx={{display:'flex' , gap:'10px', alignItems:'center' ,marginBottom:'10px', marginRight:'20px'}}>
       
       <EditRoundedIcon onClick={()=>{setEditPurchaseOrderModal(true)}}  sx={{ backgroundColor:"#FF8D1E" , p:'2px',  width:"20px", color:'white' , borderRadius:"8px" ,cursor:"pointer"}}/>
                <DeleteForeverRounded onClick={()=>{setDeleteModal(true)}} sx={{backgroundColor:"#FF392B" ,p:'2px',  width:"18px", color:"white" , borderRadius:"8px" ,cursor:"pointer"}}/>
        </Box>
      </Box> 

      <Grid item xs={12}>
       
        
      </Grid>
    </Box> 

     <Box  mt={2} sx={{ display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent:'space-around', overflowY:'auto' }}>        
      
         {medicineDetails?.payload?.medicines?.length>0 &&  medicineDetails?.payload?.medicines?.map((item, index)=>      
        <Box sx={{width:'45%' ,  marginTop:"24px"  ,  borderRadius:"20px" , padding:"14px" , boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'}} key={index}>
          
           <Typography mb={1.5} variant="heading1" color={'#0067FF'} >{item?.name}</Typography>
           <Typography  fontSize={'17px'}> {item?.company}</Typography>
                   
         
          <Box sx={{ width: "100%" }}>

           
              <Box  sx={{ textAlign: "center" , display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent:'space-around' }}>
               <Box sx={{ display :'flex',width:'45%' }} >
                 <Typography> Form type</Typography>
                 <Typography sx={{marginLeft:"20px"}}>{item?.form_type}   </Typography>
                </Box>
                
               <Box sx={{ display :'flex', width:'45%' ,}} >
                 <Typography>  Packing</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.packing}   </Typography>
                </Box>
              </Box>

              <Box  sx={{ textAlign: "center" , display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent:'space-around' }}>
               <Box sx={{ display :'flex',width:'45%' }} >
                 <Typography> Discount</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.discount}   </Typography>
                </Box>
                
               <Box sx={{ display :'flex', width:'45%' ,}} >
                 <Typography>  Stock Left</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.stock_left}   </Typography>
                </Box>
              </Box>

              <Box  sx={{ textAlign: "center" , display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent:'space-around' }}>
               <Box sx={{ display :'flex',width:'45%' }} >
                 <Typography> Batch</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.batch}   </Typography>
                </Box>
                
               <Box sx={{ display :'flex', width:'45%' ,}} >
                 <Typography> Purchased at </Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.purchased_at}   </Typography>
                </Box>
              </Box>

              <Box  sx={{ textAlign: "center" , display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent:'space-around' }}>
               <Box sx={{ display :'flex',width:'45%' }} >
                 <Typography> MFG Date</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.manufacture_date}  </Typography>
                </Box>
                
               <Box sx={{ display :'flex', width:'45%' ,}} >
                 <Typography> Delivered at</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.delivered_at}  </Typography>
                </Box>
              </Box>

              <Box  sx={{ textAlign: "center" , display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent:'space-around' }}>
               <Box sx={{ display :'flex',width:'45%' }} >
                 <Typography>Expiry</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.expiry_date}  </Typography>
                </Box>
                
               <Box sx={{ display :'flex', width:'45%' ,}} >
                 <Typography> GST</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.gst}  </Typography>
                </Box>
              </Box>

              <Box  sx={{ textAlign: "center" , display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent:'space-around' }}>
               <Box sx={{ display :'flex',width:'45%' }} >
                 <Typography> Amount</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.amount}  </Typography>
                </Box>
                
               <Box sx={{ display :'flex', width:'45%' ,}} >
                 <Typography> Units Purchased </Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.units_purchased}  </Typography>
                </Box>
              </Box>

              <Box  sx={{ textAlign: "center" , display: 'flex', flexWrap: 'wrap', width: "100%", justifyContent:'space-around' }}>
               <Box sx={{ display :'flex',width:'45%' }} >
                 <Typography> Purchase cost</Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.purchase_cost}  </Typography>
                </Box>
                
               <Box sx={{ display :'flex', width:'45%' ,}} >
                 <Typography> Auto Purchase </Typography>
                 <Typography sx={{marginLeft:"20px"}}> {item?.auto_purchase_threshold}  </Typography>
                </Box>
              </Box>

          </Box>
        </Box>
        )}
        </Box>
   

   
      
    </Box>
      {/* <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={detailsModal} setDetailsModal
        onClose={() => setDetailsModal(false)}
      >
        <MedicineSaltDetails onClose={() => setDetailsModal(false)} />
      </CustomModal> */}

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
         open={editPurchaseOrderModal}
        onClose={() => setEditPurchaseOrderModal(false)}
      >
        <EditMedicineSalt onClose={() =>  setEditPurchaseOrderModal(false)} />
      </CustomModal>  

       <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={newPurchaseOrderModal}
        onClose={() => setNewPurchaseOrderModal(false)}
      >
        <NewMedicineSalt onClose={() => setNewPurchaseOrderModal(false)} />
      </CustomModal>


    </> 
  );
};

export default MedicineSaltDetails;
             