import React from 'react';
import { useSelector } from 'react-redux';
const PrintMail = React.forwardRef((data, ref) => {

  const prescription =  useSelector((state)=> state.prescription.prescriptionData);
  console.log(data.data)

  return ( 
     <div  ref={ref} 
     style={{ 
      paddingLeft:'70px',  paddingRight:'70px' , paddingTop:"5px" , width :'1000px' , minHeight :'1460px', color :'black', display:'flex', flexDirection :'column',  justifyContent:"space-between" , fontFamily: "Outfit, sans-serif"}} >
      
      <div>
      {/* <div style={{position :'relative' , left:'0' , top:'10px'}}  >
       {prescription?.organization?.logo &&
       <img src={prescription?.organization?.logo} alt="logo" width="95"  height='' className="logo-img" />
       }
       
     
      </div>  */}

      {/* <h1 style={{textAlign :'center' , marginTop:"5px" , marginBottom :'10px', fontSize:'32px' }}> {prescription?.organization?.name} </h1> */}
      <br />
   
      <div style={{ maxWidth: '1300px', margin: 'auto',}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{minHeight:'280px'}}>
            {/* <h1 style={{fontSize :'24px' , marginBottom :'10px'}} >{prescription?.appointment?.doctor?.full_name}</h1>
            
            <p>MD DM (Immunology) </p>
            <p>Rheumatologist  & Immunology</p>
            <p>EX Consultant Apollo Hospital. LDH</p>
            <p>EX Asstt. prof DMC & H, LDH</p>
            <p>Regn No. PMC 29517</p> */}
          </div>

          <div style={{marginRight:"10px"}} >
            {/* <h1 style={{fontSize :'24px' , marginBottom :'10px'}} >{prescription?.appointment?.consultant_doctor?.full_name}</h1>
                      
            {prescription?.appointment?.consultant_doctor?.full_name ? (
              <div>
                <p>MD Medicine PDCC</p>
                <p>EULAR Fellow</p>
                <p>Physician & Rheumatologist</p>
                <p>EX Asstt. Prof DMC & H, LDH</p>
                <p>Regn No. PMC 31277</p>
              </div>
            ) : ""} */}
          </div>
        </div>
        <br/>
        <hr/>
        <br/>
        </div>
   
        <div  style={{maxWidth:'80%' ,fontFamily:'Outfit'}} dangerouslySetInnerHTML={{ __html: data.data }} />

      <div/>
      </div>
  
      {/* <div className="footer">
        <hr />
        <p className="text-center pt-3  text-[12px]">
          {prescription?.organization?.address} {prescription?.organization?.city} ({prescription?.organization?.state}) {prescription?.organization?.contact_number} {prescription?.organization?.email} | {prescription?.organization?.website} &nbsp;
          Advice given without physical checkup is Provisional, pending physical evaluation by a qualified Doctor. <b>Patient Can Buy Generic Medicine of Same Salt</b>
        </p>
      </div> */}

    
    </div>
  );
});
PrintMail.displayName = 'PrintMail';
export default PrintMail;


