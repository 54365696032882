import Cookies from "js-cookie";
import { BASE_URL } from "./connectionUrl"; 
const authStaging = Cookies.get("authStaging")
  ? JSON.parse(Cookies.get("authStaging"))
  : null;
const token = authStaging?.token || "";


export const saveOldPatientAppointment = async (appointment, id) => {
  //save old appointments
  const url = `${BASE_URL}/api/v1/patients/${id}`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(appointment),
    });

    const data = await res.json();
    return data;
  } catch (error) {
    return { error, msg: error.message };
  }
};

export const updatePatientAppointment = async (appointment, id) => {
  //save appointments
  const url = `${BASE_URL}/api/v1/appointments/${id}`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(appointment),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return { error, msg: error.message };
  }
};

export const pinPatientAppointment = async (id) => {
  // for pin patient
  const url = `${BASE_URL}/api/v1/appointments/${id}/pin`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });

    const data = await res.json();
    return data;
  } catch (error) {
    return { error, msg: error.message };
  }
};

export const updateMasterData = async (type, id, updatedData) => {
  const url = `${BASE_URL}/api/v1/master/save_spelling?type=${type}&id=${id}`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(updatedData),
    });

    const data = await res.json();
    return data;
  } catch (error) {
    return { error, msg: error.message };
  }
};

export const cancelAppointment = async (id) => {
  const url = `${
    BASE_URL
  }/api/v1/appointments/${id}/${"cancel"}`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return { error, msg: error.message };
  }
};

export const removeInvestigation = async (appointmentId, test) => {
  const url = `${BASE_URL}/api/v1/appointments/${appointmentId}/remove_investigations?test=${test}`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return { error, msg: error.message };
  }
};


export const referrerUpdate = async (id, payload) => {
  const url = `${BASE_URL}/api/v1/referrers/${id}`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return { error, msg: error.message };
  }
};

// billing  put requests

export const putRefundMoney = async (id) => {
  const url = `${BASE_URL}/api/v1/invoices/${id}/refund`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return { error, msg: error.message };
  }
};


export const putPurchaseOrder = async (payload, id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URL}/api/v1/pharmacy/purchase_orders/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.error(data?.status?.message || "something went wrong");
    }
  } catch (error) {
    console.error(error.message || "something went wrong");
  }
};

export const updateSupplier = async (payload, id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URL}/api/v1/pharmacy/suppliers/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.error(data?.status?.message || "something went wrong");
    }
  } catch (error) {
    console.error(error.message || "something went wrong");
  }
};

export const updateMedicine = async (payload, id) => {
  try {
    const response = await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URL}/api/v1/pharmacy/medicines/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();
    if (data.status.status === "SUCCESS") {
      return data;
    } else {
      console.error(data?.status?.message || "something went wrong");
    }
  } catch (error) {
    console.error(error.message || "something went wrong");
  }
};