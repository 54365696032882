import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getInvoice } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/pharmacySlice";

const UseGetInvoice = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getInvoice", id],
    queryFn: () => getInvoice(id),
    enabled: !!id,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: 0, // Keep the data fresh indefinitely
    // cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    // refetchOnReconnect: false, // Don't refetch when reconnecting
    // refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseGetInvoice;
