import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Popper,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import {
  accordionPatientTest,
  accordionSummaryStyle,
  accordionTitleStyle,
  addMoreTestStyle,
  deleteTest,
  saveBtnStyle,
  tabButton,
} from "../../style/PatientTestStyle";
import {
  dateFiled,
  menuItemStyle,
  selectField,
  textfieldText,
} from "../../style/AppointmentStyle";
import UseGetMedicalTestList from "../../helper/UseGetMedicalTestList";
import {
  getMedicalTestById,
  getProcedureMedicalTestById,
  rePrintInvoice,
} from "../../connections/getApi";
import ReportPrint from "../PrintCompoents/ReportPrint";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { postAdmission, saveAndPrintTestData } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import {
  setData,
  setPatientId,
  setPatientInvoice,
  setPrintData,
  setRenderInvoice,
} from "../../redux/slices/billingSlice";
import { putRefundMoney } from "../../connections/putApi";
import dayjs from "dayjs";
import { BorderBottom } from "@mui/icons-material";
import {
  orangeButton,
  primaryBtnBlue,
  textfieldTextBlueBorder,
} from "../../style/PatientDetailsStyle";
import AddIcon from "@mui/icons-material/Add";
import { xBtn } from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import { setLoading } from "../../redux/slices/appointmentSlice";

const initial = [{ name: "", test: "", cost: 0, discount: 0 }];
const instate = {
  Lab: false,
  "X-Ray": false,
  Procedure: false,
  Dietician: false,
  Physiotherapist: false,
  Psychologist: false,
};
const initialState = {
  Dietician: { id: "0" },
  DieticianPlan: { id: "1" },
  Physiotherapist: { id: "0" },
  PhysiotherapistPlan: { id: "1" },
  Psychologist: { id: "0" },
  PsychologistPlan: { id: "1" },
};
const PatientsTest = () => {
  const {
    billing: { data: patientData, inVoiceData, medicalTest },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { data: listData, isLoading } = UseGetMedicalTestList();
  const [department, setDepartment] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [departmentTest, setDepartmentTest] = useState({
    Lab: [],
    "X-Ray": [],
    Procedure: [],
    Dietician: [],
    Physiotherapist: [],
    Psychologist: [],
  });
  const [selectedSpecialist, setSelectedSpecialist] = useState({
    Dietician: { id: "0" },
    DieticianPlan: { id: "1" },
    Physiotherapist: { id: "0" },
    PhysiotherapistPlan: { id: "1" },
    Psychologist: { id: "0" },
    PsychologistPlan: { id: "1" },
  });

  const [labTests, setLabTests] = useState([
    { name: "", test: "", cost: 0, discount: 0 },
  ]);
  const [xRayTest, setXrayTest] = useState([
    { name: "", test: "", cost: 0, discount: 0 },
  ]);
  const [procedureTest, setProcedureTest] = useState([
    { id: Date.now(), name: "", test: "", cost: 0, discount: 0, quantity: 1 },
  ]);
  const [expanded, setExpanded] = useState({
    Lab: false,
    "X-Ray": false,
    Procedure: false,
    Dietician: false,
    Physiotherapist: false,
    Psychologist: false,
  });

  const [admission, setAdmission] = useState({
    room_no: null,
    bed_no: null,
    checkin: null,
    checkout: null,
    id: null,
    quantity: { id: "", quantity: 1 },
  });
  const [invoiceId, setInvoice] = useState("");
  const [planInvoice, setPlanInvoice] = useState({});
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalBill, setTotalBill] = useState(0);
  const [totalDiscountXray, setTotalDiscountXray] = useState(0);
  const [totalBillXray, setTotalBillXray] = useState(0);
  const [totalDiscountProcedure, setTotalDiscountProcedure] = useState(0);
  const [totalBillProcedure, setTotalBillProcedure] = useState(0);
  const testReportRef = useRef();
  const [departmentIds, setDepartmentIds] = useState([]);
  const [addTest, setAddTest] = useState(false);
  const [rePrint, setRePrint] = useState({
    Lab: false,
    "X-Ray": false,
    Procedure: false,
    Dietician: false,
    Physiotherapist: false,
    Psychologist: false,
  });
  const [activeTab, setActiveTab] = useState("Lab");

  const handleAddTest = (key) => {
    setAddTest(!addTest);
    if (key === "lab") {
      setLabTests([...labTests, { test: "", cost: 0, discount: 0 }]);
    } else if (key === "xray") {
      setXrayTest([...xRayTest, { test: "", cost: 0, discount: 0 }]);
    } else if (key === "procedure") {
      setProcedureTest([
        ...procedureTest,
        {
          id: Date.now(),
          name: "",
          test: "",
          cost: 0,
          discount: 0,
          quantity: 1,
        },
      ]);
    }
  };

  const handleDeleteTest = (index, key) => {
    if (key === "lab") {
      const newLabTests = labTests.filter((_, i) => i !== index);
      setLabTests(newLabTests);
    } else if (key === "xray") {
      const newXRayTest = xRayTest.filter((_, i) => i !== index);
      setXrayTest(newXRayTest);
    } else if (key === "procedure") {
      const newProcedure = procedureTest.filter((_, i) => i !== index);
      setProcedureTest(newProcedure);
    }
  };

  const handleSelectTest = async (index, value, key) => {
    if (key === "lab") {
      const testData = await getMedicalTestById(value?.id);

      const newLabTests = labTests.map((test, i) =>
        i === index
          ? {
              ...test,
              // test: value,
              // cost: selectedTest.cost,
              // discount: selectedTest.discount,
              ...testData,
            }
          : test
      );
      const isDuplicate = labTests.some((test) => test.id === testData.id);

      if (!isDuplicate) {
        setLabTests(newLabTests);
      } else {
        const updatedTests = labTests.filter((_, i) => i !== index);
        setLabTests(updatedTests);
      }
      // setLabTests(newLabTests);
    } else if (key === "xray") {
      const testData = await getMedicalTestById(value?.id);

      const newLabTests = xRayTest.map((test, i) =>
        i === index
          ? {
              ...test,
              // test: value,
              // cost: selectedTest.cost,
              // discount: selectedTest.discount,
              ...testData,
            }
          : test
      );
      setXrayTest(newLabTests);
    } else if (key === "procedure") {
      const type = departmentTest?.Procedure?.consumables?.includes(value)
        ? "consumable"
        : "test";

      const testData = await getProcedureMedicalTestById(value?.id, type);

      if (!testData) {
        return;
      }

      // if (
      //   type === "consumable" &&
      //   testData?.consumables_composite_fields?.length > 0
      // ) {
      //   // console.log(testData, "testData");
      //   const compositeFields = testData?.consumables_composite_fields?.map(
      //     (field) => ({
      //       ...field,
      //       parentId: value?.id, // Reference the parent consumable
      //       type,
      //     })
      //   ).filter((field) => !procedureTest.some((test) => test.id === field.id));

      //   // Insert composite fields into the procedureTest array
      //   const newTests = [
      //     ...procedureTest.slice(0, index),
      //     ...compositeFields,
      //     ...procedureTest.slice(index + 1),
      //   ];
      // //  console.log(compositeFields , 'compositeFields')
      //   // console.log(newTests , 'newTests');
      //   setProcedureTest(newTests);
      // }
      if (type === "test" && testData?.composite_fields?.length > 0) {
        // Handle composite fields for tests
        const compositeFields = testData?.composite_fields
          ?.map((field) => ({
            ...field,
            parentId: value?.id, // Reference the parent test
            type,
          }))
          .filter(
            (field) => !procedureTest.some((test) => test.id === field.id)
          );

        const newTests = [
          ...procedureTest.slice(0, index),
          ...compositeFields,
          ...procedureTest.slice(index + 1),
        ];
        setProcedureTest(newTests);
      } else {
        // For simple procedure without composite fields
        const newTests = procedureTest.map((test, i) =>
          i === index
            ? {
                ...test,
                ...testData,
                type, // Ensure type is correctly set
              }
            : { ...test }
        );
        const isDuplicate = procedureTest.some(
          (test) => test.id === testData.id
        );

        if (!isDuplicate) {
          setProcedureTest(newTests);
        } else {
          const updatedTests = procedureTest.filter((_, i) => i !== index);
          setProcedureTest(updatedTests);
        }
        //  setProcedureTest(newTests);
      }
    }
  };
  const handleSpecialistChange = (event, key) => {
    const selectedId = event.target.value;
    // setSelectedSpecialist(selectedId);
    const selectedSpecialistData = departmentTest?.[key]?.specialists?.find(
      (el) => el.id === selectedId
    );

    setSelectedSpecialist((prev) => ({
      ...prev,
      [key]: selectedSpecialistData,
    }));
  };

  const handleChangePlan = async (event, key, updater) => {
    const selectedId = event.target.value;
    // setSelectedSpecialist(selectedId);
    const selectedSpecialistData = selectedSpecialist?.[
      key
    ]?.specialist_plans?.find((el) => el.id === selectedId);
    // console.log(selectedSpecialistData);
    setSelectedSpecialist((prev) => ({
      ...prev,
      [updater]: selectedSpecialistData,
    }));
  };

  const handleSaveAndPrint = async (key, testArray) => {
    // console.log(patientData?.payload, "patientData?.payload");
    if (!patientData?.payload?.uid || !patientData?.payload?.id) {
      showToast("Please select a patient first ", "ERROR");
      return null;
    }

    if (testArray?.length === 0) {
      return null;
    }
    setButtonLoading(true);
    let res;

    if (key === "Procedure" && admission?.bed_no && !admission?.id) {
      res = await postAdmission(admission);
      showToast(`Admission ${res?.status?.status}`, res?.status?.status);
    }

    // const testIds =
    //   key === "Procedure"
    //     ? testArray?.map((el) => ({
    //         id: el?.id?.toString(),
    //         quantity: el?.quantity > 0 ? el?.quantity?.toString() : "1",
    //       }))
    //     : testArray?.map((el) => el.id);

    const mapItemsToPayload = (items) =>
      items.map((el) => ({
        id: el?.id?.toString(),
        quantity: el?.quantity > 0 ? el?.quantity?.toString() : "1",
      }));

    // console.log(mapItemsToPayload,'mapItemsToPayload');

    const testIds =
      key === "Procedure"
        ? mapItemsToPayload(testArray.filter((item) => item.type === "test"))
        : testArray.map((el) => el.id);

    const consumablesIds =
      key === "Procedure"
        ? mapItemsToPayload(
            testArray.filter((item) => item.type === "consumable")
          )
        : testArray.map((el) => el.id);

    const id = departmentIds?.find((el) => el[key]);

    const hasInvalidTestIds =
      key === "Procedure"
        ? testIds?.some((el) => !el?.id)
        : testIds?.some((el) => el === null || el === undefined);
    // console.log(testArray, "testArray");
    // console.log(hasInvalidTestIds, "hasInvalidTestIds", testIds);
    if (hasInvalidTestIds) {
      showToast("Please Select a Test", "ERROR");
      setButtonLoading(false);
      return;
    }

    const payload = {
      invoices: [
        {
          invoice_type:
            key === "Procedure"
              ? res?.payload?.id
                ? "daycare"
                : "daycare"
              : "tests",
          department_id: key === "Procedure" ? 8 : id[key],
          tests: testIds,
          consumables: consumablesIds,
        },
      ],
      dob: patientData?.payload?.dob,
      uid: patientData?.payload?.uid,
      gender: patientData?.payload?.gender,
      name: patientData?.payload?.full_name,
      mobile: patientData?.payload?.mobile,
    };
    if (res?.payload?.id || inVoiceData?.admission?.id) {
      payload["admission_id"] = res?.payload?.id || inVoiceData?.admission?.id;
    }
    if (inVoiceData?.id) {
      if (key === "Procedure" && inVoiceData?.department_name === "Daycare") {
        // If key is "procedure" and procedure is "daycare"
        payload.invoices[0]["old_invoice_id"] = inVoiceData?.id;
      } else if (inVoiceData?.department_name === key) {
        // For other keys, check department_name
        payload.invoices[0]["old_invoice_id"] = inVoiceData?.id;
      }
    }
    if (invoiceId?.length > 0 && invoiceId[0]?.id) {
      payload.invoices[0]["old_invoice_id"] = invoiceId[0]?.id;
    }
    try {
      // if (Object.keys(patientData?.payload)?.length > 5) {
      const data = await saveAndPrintTestData(payload);

      if (data?.status?.status === "SUCCESS") {
        setInvoice(data?.payload?.invoices);
        dispatch(setPrintData(data?.payload));
        setRePrint((prev) => ({
          ...prev,
          [key]: true,
        }));
        dispatch(setRenderInvoice());
        setTimeout(() => {
          handlePrint();
        }, 100);
        showToast("Generating Invoice", data?.status?.status);
        setLabTests([]);
        setXrayTest([]);
        setProcedureTest([]);
        setButtonLoading(false);
      } else if (data?.status?.status === "ERROR") {
        showToast(data?.status?.message, data?.status?.status);
        setRePrint((prev) => ({
          ...prev,
          [key]: false,
        }));
      }
      // }
      setButtonLoading(false);
    } catch (error) {
      setRePrint((prev) => ({
        ...prev,
        [key]: false,
      }));
      showToast(error?.status?.message, error?.status?.status);
      setButtonLoading(false);
    }
    setButtonLoading(false);
  };
  // console.log(planInvoice, "planInvoice");

  const handleSaveAndPrintPlans = async (key, testObj) => {
    // const testIds = testArray?.map((el) => el.id);
    dispatch(setPrintData([]));
    const id = departmentIds?.find((el) => el[key]);
    // if (typeof testObj?.id) {
    //   showToast("Please Select a Plan", "ERROR");
    //   return;
    // }
    // if(testObj?.id){
    //   return null;
    // }
    const payload = {
      invoices: [
        {
          invoice_type: "consultancy",
          department_id: id[key],
          tests: testObj?.id,
        },
      ],
      dob: patientData?.payload?.dob,
      uid: patientData?.payload?.uid,
      gender: patientData?.payload?.gender,
      name: patientData?.payload?.full_name,
      mobile: patientData?.payload?.mobile,
    };
    // if (inVoiceData?.id) {
    //   if (key === "Procedure" && inVoiceData?.department_name === "Daycare") {
    //     // If key is "procedure" and procedure is "daycare"
    //     payload.invoices[0]["old_invoice_id"] = inVoiceData?.id;
    //   } else if (inVoiceData?.department_name === key) {
    //     // For other keys, check department_name
    //     payload.invoices[0]["old_invoice_id"] = inVoiceData?.id;
    //   }
    // }
    if (planInvoice[key]?.length > 0 && planInvoice[key][0]?.id) {
      payload.invoices[0]["old_invoice_id"] = planInvoice[key][0].id;
    }

    try {
      // if (Object.keys(patientData?.payload)?.length > 5) {
      const data = await saveAndPrintTestData(payload);

      if (data?.status?.status === "SUCCESS") {
        setPlanInvoice((prev) => ({
          ...prev,
          [key]: data?.payload?.invoices,
        }));
        dispatch(setPrintData(data?.payload));
        setRePrint((prev) => ({
          ...prev,
          [key]: true,
        }));
        dispatch(setRenderInvoice());
        showToast("Generating Invoice", data?.status?.status);
        setSelectedSpecialist({
          Dietician: { id: "0" },
          DieticianPlan: { id: "1" },
          Physiotherapist: { id: "0" },
          PhysiotherapistPlan: { id: "1" },
          Psychologist: { id: "0" },
          PsychologistPlan: { id: "1" },
        });
      } else if (data?.status?.status === "ERROR") {
        showToast(data?.status?.message, data?.status?.status);
        setRePrint((prev) => ({
          ...prev,
          [key]: false,
        }));
      }
      // }
    } catch (error) {
      setRePrint((prev) => ({
        ...prev,
        [key]: false,
      }));
      showToast(error?.status?.message, error?.status?.status);
    }
  };

  const handleAccordionChange = (panel) => {
    setExpanded({
      ...expanded,
      [panel]: !expanded?.[panel],
    });
  };

  const handleRePrint = async (key, testArray) => {
    setButtonLoading(true);

    try {
      const result = await rePrintInvoice(inVoiceData?.id);
      if (result?.payload?.id) {
        showToast("Re-generating Print..", "SUCCESS");
        dispatch(setPrintData({ invoices: [result?.payload] }));
        setLabTests([]);
        setXrayTest([]);
        setProcedureTest([]);
        dispatch(setPatientInvoice([]));
        // dispatch(setData([]));

        // dispatch(setPatientId(null));

        setSelectedSpecialist({
          Dietician: { id: "0" },
          DieticianPlan: { id: "1" },
          Physiotherapist: { id: "0" },
          PhysiotherapistPlan: { id: "1" },
          Psychologist: { id: "0" },
          PsychologistPlan: { id: "1" },
        });
      } else {
        showToast("Error in Re-generating Print..", "ERROR");
      }
      setButtonLoading(false);
    } catch (error) {
      showToast(error?.status?.message, error?.status?.status);
      setButtonLoading(false);
    }

    setButtonLoading(false);
  };

  const handleRefund = async (val) => {
    setButtonLoading(true);
    try {
      const data = await putRefundMoney(inVoiceData?.id);
      if (data?.payload?.id) {
        // console.log(data, "refund");
        showToast("Refund ..Success", "SUCCESS");
        handleAccordionChange(val);
        dispatch(setPatientInvoice([]));
        // dispatch(setData([]));
        dispatch(setRenderInvoice());
        setLabTests([]);
        setXrayTest([]);
        setProcedureTest([{}]);
        // dispatch(setPatientId(null));

        setSelectedSpecialist({
          Dietician: { id: "0" },
          DieticianPlan: { id: "1" },
          Physiotherapist: { id: "0" },
          PhysiotherapistPlan: { id: "1" },
          Psychologist: { id: "0" },
          PsychologistPlan: { id: "1" },
        });
      } else {
        showToast(data?.status?.message, "ERROR");
      }
      setButtonLoading(false);
    } catch (error) {
      showToast(error?.status?.message, error?.status?.status);
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (inVoiceData && Object.keys(inVoiceData)?.length) {
      setExpanded({
        ...instate,
        [inVoiceData?.department_name]: true,
      });
      if (inVoiceData?.department_name === "Daycare") {
        setExpanded({
          ...instate,
          Procedure: true,
        });
        setActiveTab("Procedure");
      }
      const result = inVoiceData?.invoice_items?.map((item) => item.item);
      const planResult = departmentTest[
        inVoiceData?.department_name
      ]?.specialists?.map((el) => el.specialist_plans);

      if (inVoiceData?.department_name === "Lab") {
        setDepartmentTest((prev) => ({
          ...prev,
          [inVoiceData?.department_name]: result,
        }));
        setLabTests(result);
        setActiveTab("Lab");
      } else if (inVoiceData?.department_name === "X-Ray") {
        setDepartmentTest((prev) => ({
          ...prev,
          [inVoiceData?.department_name]: result,
        }));
        setXrayTest(result);
        setLabTests(initial);
        setActiveTab("X-Ray");
      } else if (
        inVoiceData?.department_name === "Procedure" ||
        inVoiceData?.department_name === "Daycare"
      ) {
        const formattedCheckin = inVoiceData?.admission
          ? dayjs(inVoiceData?.admission?.checkin).format("YYYY-MM-DDTHH:mm")
          : null;

        const formattedCheckout = inVoiceData?.admission
          ? dayjs(inVoiceData?.admission?.checkout).format("YYYY-MM-DDTHH:mm")
          : null;
        setAdmission({
          room_no: +inVoiceData?.admission?.room_no,
          bed_no: +inVoiceData?.admission?.bed_no,
          checkin: formattedCheckin,
          checkout: formattedCheckout,
          id: inVoiceData?.admission?.id,
        });
        setProcedureTest(result);
        setDepartmentTest((prev) => ({
          ...prev,
          [inVoiceData?.department_name]: result,
        }));
        setActiveTab("Procedure");
      } else {
        setSelectedSpecialist(() => ({
          ...initialState,
          [inVoiceData?.department_name]: {
            id: result[0]?.specialist_id,
            full_name: inVoiceData?.invoice_items[0]?.name,
            specialist_plans: planResult ? planResult[0] : null,
          },
          [inVoiceData?.department_name + "Plan"]: {
            id: result[0]?.plan_id,
          },
        }));

        if (inVoiceData?.department_name === "Dietician") {
          setActiveTab("Dietician");
        }
        if (inVoiceData?.department_name === "Physiotherapist")
          setActiveTab("Physiotherapist");
        if (inVoiceData?.department_name === "Psychologist")
          setActiveTab("Psychologist");
        //  console.log(inVoiceData?.department_name)
      }
    }
  }, [inVoiceData]);

  useEffect(() => {
    const discount = labTests.reduce((acc, test) => acc + test.discount, 0);
    const bill = labTests.reduce((acc, test) => acc + test.amount, 0);

    setTotalDiscount(discount);
    setTotalBill(bill - discount);
  }, [labTests]);

  useEffect(() => {
    const discount = xRayTest.reduce((acc, test) => acc + test.discount, 0);
    const bill = xRayTest.reduce((acc, test) => acc + test.amount, 0);
    // console.log(bill);
    setTotalDiscountXray(discount);
    setTotalBillXray(bill - discount);
  }, [xRayTest]);

  useEffect(() => {
    const discount = procedureTest.reduce(
      (acc, test) =>
        acc + test.discount * (test.quantity > 0 ? test.quantity : 1),
      0
    );

    const bill = procedureTest.reduce(
      (acc, test) =>
        acc + test.amount * (test.quantity > 0 ? test.quantity : 1),
      0
    );

    setTotalDiscountProcedure(discount);
    setTotalBillProcedure(bill - discount);
  }, [procedureTest]);

  useEffect(() => {
    const updatedDepartmentTest = { ...departmentTest };
    const depIds = listData?.payload?.departments?.map((el) => {
      return {
        [el.name]: el.id,
      };
    });
    const depList = listData?.payload?.departments?.map((el) => el?.name);
    setDepartment(depList);
    setDepartmentIds(depIds);

    listData?.payload?.departments.forEach((department) => {
      if (updatedDepartmentTest.hasOwnProperty(department.name)) {
        updatedDepartmentTest[department.name] = department.medical_tests;
      }
    });

    setDepartmentTest(updatedDepartmentTest);
  }, [listData?.payload?.departments, addTest]);

  useEffect(() => {
    if (medicalTest?.length > 0) {
      const labTests = medicalTest
        ?.filter((test) => test.category === "LAB")
        .map(({ name, discount, amount, id }) => ({
          name,
          discount,
          amount,
          id,
        }));

      const xRayTests = medicalTest
        ?.filter((test) => test.category === "X-Ray")
        .map(({ name, discount, amount, id }) => ({
          name,
          discount,
          amount,
          id,
        }));
      const procedureTest = medicalTest
        ?.filter((test) => test.category === "Procedure")
        .map(({ name, discount, amount }) => ({ name, discount, amount }));
      const newExpanded = {
        Lab: medicalTest.some((test) => test.category === "LAB"), // Example name for Lab test
        "X-Ray": medicalTest.some((test) => test.category === "X-Ray"), // Example name for X-Ray test
        Procedure: false, // Set to false as an example, adjust based on your data
        Dietician: false,
        Physiotherapist: false,
        Psychologist: false,
      };
      // console.log(procedureTest, "vivek");
      setLabTests(labTests);
      setXrayTest(xRayTests);
      setExpanded(newExpanded);
      setProcedureTest(procedureTest);
    }
  }, [medicalTest]);

  const handlePrint = useReactToPrint({
    content: () => testReportRef.current,
    documentTitle: "medicine invoice Print",
    onAfterPrint: () => console.log("Print job finished"),
  });

  // console.log([
  //   ...(departmentTest?.Procedure?.consumables || []),
  //   ...(departmentTest?.Procedure?.tests || []),
  // ], 'option ');
  // console.log(procedureTest, "procedureTest");
  return (
    <>
      <Grid
        container
        spacing={2}
        mb={"auto"}
        justifyContent={"center"}
        mt={0}
        sm={6}
        md={3.9}
        lg={4.6}
        sx={{
          marginRight: "-10px",
          //  border: "1px solid rgba(0, 0, 0, 0.125)",
          backgroundColor: "#FFFFFF",
          borderRadius: "30px",
          paddingTop: "15px",
        }}
      >
        {/* tabs for test  */}

        {isLoading ? (
          <Skeleton height={"75px"} width={"90%"} />
        ) : (
          <Box
            mb={1}
            mt={1}
            sx={{
              width: "90%",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {department?.includes("Lab") && (
              <Button
                onClick={() => setActiveTab("Lab")}
                sx={{
                  ...tabButton,
                  backgroundColor: activeTab === "Lab" ? "#FF8D1E" : "#FFE7DC",
                  color: activeTab === "Lab" ? "white" : "#3A3A3A",
                  boxShadow:
                    activeTab === "Lab"
                      ? "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px"
                      : "",
                }}
              >
                {" "}
                Lab
              </Button>
            )}

            {department?.includes("X-Ray") && (
              <Button
                onClick={() => setActiveTab("X-Ray")}
                sx={{
                  ...tabButton,
                  backgroundColor:
                    activeTab === "X-Ray" ? "#FF8D1E" : "#FFE7DC",
                  color: activeTab === "X-Ray" ? "white" : "#3A3A3A",
                  boxShadow:
                    activeTab === "X-Ray"
                      ? "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px"
                      : "",
                }}
              >
                X-Ray
              </Button>
            )}

            {department?.includes("Procedure") && (
              <Button
                onClick={() => setActiveTab("Procedure")}
                sx={{
                  ...tabButton,
                  backgroundColor:
                    activeTab === "Procedure" ? "#FF8D1E" : "#FFE7DC",
                  boxShadow:
                    activeTab === "Procedure"
                      ? "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px"
                      : "",
                  color: activeTab === "Procedure" ? "white" : "#3A3A3A",
                }}
              >
                Daycare
              </Button>
            )}

            {department?.includes("Dietician") && (
              <Button
                onClick={() => setActiveTab("Dietician")}
                sx={{
                  ...tabButton,
                  backgroundColor:
                    activeTab === "Dietician" ? "#FF8D1E" : "#FFE7DC",
                  boxShadow:
                    activeTab === "Dietician"
                      ? "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px"
                      : "",
                  color: activeTab === "Dietician" ? "white" : "#3A3A3A",
                }}
              >
                Dietician
              </Button>
            )}

            {department?.includes("Physiotherapist") && (
              <Button
                onClick={() => setActiveTab("Physiotherapist")}
                sx={{
                  backgroundColor:
                    activeTab === "Physiotherapist" ? "#FF8D1E" : "#FFE7DC",
                  boxShadow:
                    activeTab === "Physiotherapist"
                      ? "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px"
                      : "",
                  marginBottom: "10px",
                  color: activeTab === "Physiotherapist" ? "white" : "#3A3A3A",
                  fontFamily: "Outfit",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "#FF8D1E",
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                Physiotherapist
              </Button>
            )}

            {department?.includes("Psychologist") && (
              <Button
                onClick={() => setActiveTab("Psychologist")}
                sx={{
                  ...tabButton,
                  backgroundColor:
                    activeTab === "Psychologist" ? "#FF8D1E" : "#FFE7DC",
                  boxShadow:
                    activeTab === "Psychologist"
                      ? "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px"
                      : "",
                  color: activeTab === "Psychologist" ? "white" : "#3A3A3A",
                }}
              >
                Psychologist
              </Button>
            )}
          </Box>
        )}

        {isLoading ? (
          <Skeleton height={"200px"} width={"90%"} />
        ) : (
          <Box
            mt={1}
            sx={{
              padding: "15px",
              borderTop: "1px solid #7A7A7A80",
              width: "100%",
            }}
          >
            {/* {"----------Lab Test accordion----------"} */}
            {activeTab === "Lab" && (
              <AccordionDetails
                sx={{ backgroundColor: "neutral.white", padding: "20px 20px" }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <Grid
                    sx={{
                      paddingLeft: 0,
                    }}
                    item
                    sm={2}
                    lg={4}
                  >
                    <Typography variant="title1_medium">Test Name</Typography>
                  </Grid>
                  <Grid alignItems={"start"} item sm={4} lg={6}>
                    <Box
                      display={"flex"}
                      gap={"1px"}
                      justifyContent={"space-between"}
                      alignItems={"flex-start"}
                      width="100%"
                    >
                      <Typography p={0} variant="title1_medium">
                        Quantity
                      </Typography>
                      <Typography
                        variant="title1_medium"
                        sx={{ marginRight: "20px" }}
                      >
                        Cost
                      </Typography>
                      <Typography
                        variant="title1_medium"
                        sx={{ marginRight: "20px" }}
                      >
                        Discount
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    sx={{ "&.MuiGrid-item": { paddingLeft: "0px" } }}
                    sm={2}
                    item
                    lg={1}
                  >
                    {/* <Box
                      sx={addMoreTestStyle}
                      onClick={() => handleAddTest("lab")}
                    >
                      <Typography variant="title1_medium">+</Typography>
                    </Box> */}
                    <IconButton
                      variant="contained"
                      sx={{ ...orangeButton, marginLeft: "8px" }}
                      onClick={() => handleAddTest("lab")}
                    >
                      <AddIcon
                        sx={{
                          width: "25px",
                          color: "neutral.white",
                          margin: "0px",
                          height: "25px",
                          padding: "0px",
                        }}
                      />
                    </IconButton>
                  </Grid>

                  {labTests?.map((test, index) => (
                    <Grid
                      key={index}
                      item
                      lg={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <Grid item sm={3} lg={4}>
                        <Autocomplete
                          clearIcon={false}
                          sx={{
                            ...textfieldTextBlueBorder,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              height: "40px",
                              padding: "9px !important",
                              "& input": {
                                padding: "8px",
                                fontSize: "14px",
                              },
                            },
                          }}
                          fullWidth
                          options={departmentTest?.Lab || []} // Ensure departmentTest.Lab is an array of objects
                          getOptionLabel={(option) => option.name} // Use the 'name' property for display
                          value={
                            departmentTest?.Lab.find(
                              (testObj) => testObj.name === test.name
                            ) ||
                            departmentTest[0]?.name ||
                            null
                          }
                          onChange={
                            (event, newValue) =>
                              handleSelectTest(index, newValue, "lab") // Pass the whole object
                          }
                          PopperComponent={(props) => (
                            <Grid item lg={8}>
                              <Popper
                                {...props}
                                placement="bottom-start"
                                style={menuItemStyle}
                              />
                            </Grid>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Test"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          renderOption={(props, option) => (
                            <MenuItem
                              sx={menuItemStyle}
                              {...props}
                              key={option.id}
                            >
                              {option.name}{" "}
                              {/* Display the 'name' of each test */}
                            </MenuItem>
                          )}
                        />
                      </Grid>

                      <Grid
                        sx={{ "&.MuiGrid-item": { paddingLeft: "0px" } }}
                        item
                        sm={4}
                        lg={6}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          gap={"8px"}
                          alignItems={"center"}
                          width="100%"
                        >
                          <TextField
                            type="number"
                            value={1}
                            placeholder="quantity"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            // sx={{
                            //   ...textfieldText,
                            //   color: "red",
                            //   "& input": {
                            //     padding: "0px",
                            //   },
                            //   "& .MuiInputBase-root": {
                            //     backgroundColor: "rgba(204, 204, 204, 0.1)",
                            //     border: "1px solid rgba(204, 204, 204, 0.5)",
                            //     transition: "background-color 0.3s",
                            //   },
                            // }}
                            sx={{
                              ...textfieldTextBlueBorder,
                              //   "& .MuiOutlinedInput-root": {
                              //     borderRadius: "10px",
                              //     height: "40px",
                              //     padding: "9px !important",
                              //     "& input": {
                              //       padding: "8px",
                              //       fontSize: "14px",
                              //     },
                              //   },
                              //   "& .MuiInputBase-root": {
                              //     border: "1px solid #0067FF",
                              //   },
                            }}
                            // disabled
                          />
                          <TextField
                            type="number"
                            value={test.amount ? test?.amount : test?.cost}
                            placeholder="Cost"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            sx={{
                              ...textfieldTextBlueBorder,
                            }}
                          />
                          <TextField
                            type="number"
                            value={test.discount}
                            placeholder="Discount"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            sx={{
                              ...textfieldTextBlueBorder,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid sm={2} item lg={1}>
                        {/* <Box
                          sx={deleteTest}
                          
                        > 
                          <RestoreFromTrashRoundedIcon
                            sx={{ width: "15px", height: "15px" }}
                            fontSize="small"
                          />
                        </Box> */}
                        <IconButton
                          sx={{
                            marginBottom: "7px",
                            display:
                              test?.report_status === "processing" ||
                              test?.report_status === "done"
                                ? "none"
                                : "",
                          }}
                          onClick={() => handleDeleteTest(index, "lab")}
                        >
                          <CloseIcon
                            sx={{
                              ...xBtn,
                              width: "23px",
                              height: "23px",
                              borderRadius: "20%",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"space-between"}
                    xs={12}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Typography variant="title1_medium">Discount:</Typography>
                      <Typography variant="title1_medium">
                        {totalDiscount}
                      </Typography>
                    </Box>

                    <Box
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Typography variant="title1_medium">
                        Net Amount
                      </Typography>
                      <TextField
                        type="number"
                        value={totalBill}
                        placeholder="Net amount"
                        InputProps={{ readOnly: true }}
                        sx={{
                          ...textfieldTextBlueBorder,
                        }}
                      />
                    </Box>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "12px",
                    }}
                  >
                    <Box>
                      {inVoiceData &&
                        Object?.keys(inVoiceData)?.length > 0 &&
                        inVoiceData?.department_name === "Lab" && (
                          <Button
                            disabled={buttonLoading || labTests?.length < 1}
                            onClick={() => handleRefund("Lab")}
                            sx={{
                              ...primaryBtnBlue,
                              color: "white",
                              borderRadius: "10px",
                              py: "3px",
                              marginRight: "10px",
                              backgroundColor: "neutral.brown",
                              "&:hover": {
                                backgroundColor: "neutral.brown",
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                              },
                            }}
                          >
                            <Typography variant="small_Text">refund</Typography>
                          </Button>
                        )}

                      {inVoiceData &&
                        Object?.keys(inVoiceData)?.length > 0 &&
                        inVoiceData?.department_name === "Lab" && (
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                // fullWidth
                                // onClick={handleRePrint}
                                disabled={buttonLoading || labTests?.length < 1}
                                sx={{
                                  ...primaryBtnBlue,
                                  color: "white",
                                  borderRadius: "10px",
                                  py: "3px",
                                  backgroundColor: "neutral.main",
                                  "&:hover": {
                                    backgroundColor: "neutral.main",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                  },
                                  marginRight: "10px",
                                }}
                              >
                                <Typography variant="small_Text">
                                  Re-Print
                                </Typography>
                              </Button>
                            )}
                            content={() => testReportRef.current}
                            onBeforeGetContent={() => handleRePrint()}
                          />
                        )}
                    </Box>

                    <Box>
                      <Button
                        disabled={buttonLoading}
                        onClick={() => handleSaveAndPrint("Lab", labTests)}
                        sx={{
                          ...primaryBtnBlue,
                          color: "white",
                          borderRadius: "10px",
                          py: "3px",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <Typography variant="small_Text">
                          Save & Print
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </AccordionDetails>
            )}

            {/* {"----------x-ray Test accordion----------"} */}
            {activeTab === "X-ray" && (
              <AccordionDetails
                sx={{ backgroundColor: "neutral.white", padding: "20px 20px" }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <Grid
                    sx={{
                      paddingLeft: 0,
                    }}
                    item
                    sm={2}
                    lg={4}
                  >
                    <Typography variant="title1_medium">Test Name</Typography>
                  </Grid>
                  <Grid alignItems={"start"} item sm={4} lg={6}>
                    <Box
                      display={"flex"}
                      gap={"1px"}
                      justifyContent={"space-between"}
                      alignItems={"flex-start"}
                      width="100%"
                    >
                      <Typography p={0} variant="title1_medium">
                        quantity
                      </Typography>
                      <Typography
                        p={0}
                        variant="title1_medium"
                        sx={{ marginRight: "20px" }}
                      >
                        Cost
                      </Typography>
                      <Typography
                        p={0}
                        variant="title1_medium"
                        sx={{ marginRight: "20px" }}
                      >
                        Discount
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{ "&.MuiGrid-item": { paddingLeft: "0px" } }}
                    sm={2}
                    item
                    lg={1}
                  >
                    {/* <Box
                      sx={addMoreTestStyle}
                      onClick={() => handleAddTest("xray")}
                    >
                      <Typography variant="title1_medium">+</Typography>
                    </Box> */}
                    <IconButton
                      variant="contained"
                      sx={{ ...orangeButton, marginLeft: "8px" }}
                      onClick={() => handleAddTest("xray")}
                    >
                      <AddIcon
                        sx={{
                          width: "25px",
                          color: "neutral.white",
                          margin: "0px",
                          height: "25px",
                          padding: "0px",
                        }}
                      />
                    </IconButton>
                  </Grid>

                  {xRayTest.map((test, index) => (
                    <Grid
                      key={index}
                      item
                      lg={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <Grid item sm={3} lg={4}>
                        <Autocomplete
                          clearIcon={false}
                          sx={{
                            ...textfieldTextBlueBorder,
                            border: "1px solid #0067ff",
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              height: "40px",
                              padding: "9px !important",
                              "& input": {
                                padding: "8px",
                                fontSize: "14px",
                              },
                            },
                          }}
                          fullWidth
                          options={departmentTest?.["X-Ray"] || []} // Ensure departmentTest.Lab is an array of objects
                          getOptionLabel={(option) => option.name} // Use the 'name' property for display
                          value={
                            departmentTest?.["X-Ray"].find(
                              (testObj) => testObj.name === test.name
                            ) || null
                          }
                          onChange={
                            (event, newValue) =>
                              handleSelectTest(index, newValue, "xray") // Pass the whole object
                          }
                          PopperComponent={(props) => (
                            <Grid item lg={8}>
                              <Popper
                                {...props}
                                placement="bottom-start"
                                style={menuItemStyle}
                              />
                            </Grid>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Test"
                              variant="outlined"
                              fullWidth
                              sx={textfieldText}
                            />
                          )}
                          renderOption={(props, option) => (
                            <MenuItem
                              sx={menuItemStyle}
                              {...props}
                              key={option.id}
                            >
                              {option.name}{" "}
                            </MenuItem>
                          )}
                        />
                      </Grid>

                      <Grid
                        sx={{ "&.MuiGrid-item": { paddingLeft: "0px" } }}
                        item
                        sm={4}
                        lg={6}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          gap={"8px"}
                          alignItems={"center"}
                          width="100%"
                        >
                          <TextField
                            type="number"
                            value={1}
                            placeholder="quantity"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            sx={{
                              ...textfieldTextBlueBorder,
                            }}
                          />
                          <TextField
                            type="number"
                            value={test.amount}
                            placeholder="Cost"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            sx={{
                              ...textfieldTextBlueBorder,
                            }}
                          />
                          <TextField
                            type="number"
                            value={test.discount}
                            placeholder="Discount"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            sx={{
                              ...textfieldTextBlueBorder,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid sm={2} item lg={1}>
                        {/* <Box
                          sx={deleteTest}
                          onClick={() => handleDeleteTest(index, "xray")}
                        >
                          <RestoreFromTrashRoundedIcon
                            sx={{ width: "15px", height: "15px" }}
                            fontSize="small"
                          />
                        </Box> */}
                        <IconButton
                          sx={{ marginBottom: "7px" }}
                          onClick={() => handleDeleteTest(index, "xray")}
                        >
                          <CloseIcon
                            sx={{
                              ...xBtn,
                              width: "23px",
                              height: "23px",
                              borderRadius: "20%",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"space-between"}
                    xs={12}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Typography variant="body_Grey1">Discount:</Typography>
                      <Typography variant="body_Grey1">
                        {totalDiscountXray}
                      </Typography>
                    </Box>

                    <Box
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Typography variant="title1_medium">
                        Net Amount
                      </Typography>
                      <TextField
                        type="number"
                        value={totalBillXray}
                        placeholder="Net amount"
                        InputProps={{ readOnly: true }}
                        sx={textfieldTextBlueBorder}
                      />
                    </Box>
                  </Grid>

                  <Grid item sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: "12px",
                      }}
                    >
                      <Box>
                        {Object.keys(inVoiceData).length > 0 &&
                          inVoiceData?.department_name === "X-Ray" && (
                            <Button
                              onClick={() => handleRefund("X-Ray")}
                              sx={{
                                ...primaryBtnBlue,
                                color: "white",
                                borderRadius: "10px",
                                py: "3px",
                                marginRight: "10px",
                                backgroundColor: "neutral.brown",
                                "&:hover": {
                                  backgroundColor: "neutral.brown",
                                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                },
                              }}
                              disabled={buttonLoading || xRayTest?.length < 1}
                            >
                              <Typography variant="small_Text">
                                refund
                              </Typography>
                            </Button>
                          )}

                        {Object.keys(inVoiceData).length > 0 &&
                          inVoiceData?.department_name === "X-Ray" && (
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  disabled={
                                    buttonLoading || xRayTest?.length < 1
                                  }
                                  sx={{
                                    ...primaryBtnBlue,
                                    color: "white",
                                    borderRadius: "10px",
                                    py: "3px",
                                    backgroundColor: "neutral.main",
                                    "&:hover": {
                                      backgroundColor: "neutral.main",
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    },
                                    marginRight: "10px",
                                  }}
                                >
                                  <Typography variant="small_Text">
                                    Re-Print
                                  </Typography>
                                </Button>
                              )}
                              content={() => testReportRef.current}
                              onBeforeGetContent={() => handleRePrint()}
                            />
                          )}
                      </Box>

                      <Button
                        onClick={() => {
                          handleSaveAndPrint("X-Ray", xRayTest);
                        }}
                        disabled={departmentTest?.["X-Ray"].find(
                          (testObj) => testObj.name === test.name
                        )}
                        // disabled={buttonLoading}
                        sx={{
                          ...primaryBtnBlue,
                          color: "white",
                          borderRadius: "10px",
                          py: "3px",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                      >
                        <Typography variant="small_Text">
                          Save & Print
                        </Typography>
                      </Button>
                    </Box>
                    {/* <ReactToPrint
                      trigger={() => (
                      
                      )}
                      content={() => testReportRef.current}
                      onBeforeGetContent={() =>
                        handleSaveAndPrint("X-Ray", xRayTest)
                      }
                    /> */}
                  </Grid>
                </Grid>
              </AccordionDetails>
            )}

            {/* {"----------Procedure / day care Test accordion----------"} */}
            {activeTab === "Procedure" && (
              <AccordionDetails
                sx={{ backgroundColor: "neutral.white", padding: "20px 20px" }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item lg={2}>
                      <Typography variant="title1_medium">Room No</Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography variant="title1_medium">Bed No</Typography>
                    </Grid>
                    <Grid item lg={3.5}>
                      <Typography variant="title1_medium">Check In</Typography>
                    </Grid>
                    <Grid item lg={3.5}>
                      <Typography variant="title1_medium">Check Out</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid mb={2} item xs={12}>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item lg={2}>
                      <TextField
                        type="number"
                        value={admission?.room_no}
                        onChange={(e) => {
                          setAdmission((prev) => ({
                            ...prev,
                            room_no: +e.target.value,
                          }));
                        }}
                        placeholder="room"
                        fullWidth
                        sx={{
                          ...textfieldTextBlueBorder,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            height: "40px",
                            padding: "9px !important",
                            "& input": {
                              padding: "8px",
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <TextField
                        type="number"
                        value={admission?.bed_no}
                        onChange={(e) => {
                          setAdmission((prev) => ({
                            ...prev,
                            bed_no: +e.target.value,
                          }));
                        }}
                        placeholder="bed"
                        fullWidth
                        sx={{
                          ...textfieldTextBlueBorder,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            height: "40px",
                            padding: "9px !important",
                            "& input": {
                              padding: "8px",
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item lg={3.5}>
                      <TextField
                        type="datetime-local"
                        value={admission?.checkin}
                        onChange={(e) => {
                          setAdmission((prev) => ({
                            ...prev,
                            checkin: e.target.value,
                          }));
                        }}
                        placeholder="checkIn"
                        fullWidth
                        // sx={dateFiled}
                        sx={{
                          ...textfieldTextBlueBorder,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            height: "40px",
                            padding: "9px !important",
                            "& input": {
                              padding: "8px",
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item lg={3.5}>
                      <TextField
                        type="datetime-local"
                        value={admission?.checkout}
                        onChange={(e) => {
                          setAdmission((prev) => ({
                            ...prev,
                            checkout: e.target.value,
                          }));
                        }}
                        placeholder="check-out"
                        fullWidth
                        // sx={dateFiled}
                        sx={{
                          ...textfieldTextBlueBorder,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            height: "40px",
                            padding: "9px !important",
                            "& input": {
                              padding: "8px",
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <Grid
                    sx={{
                      paddingLeft: 0,
                    }}
                    item
                    sm={2}
                    lg={4}
                  >
                    <Typography variant="title1_medium">Test Name</Typography>
                  </Grid>
                  <Grid alignItems={"start"} item sm={4} lg={6}>
                    <Box
                      display={"flex"}
                      gap={"1px"}
                      justifyContent={"space-between"}
                      alignItems={"flex-start"}
                      width="100%"
                    >
                      <Typography p={0} variant="title1_medium">
                        Quantity
                      </Typography>
                      <Typography
                        sx={{ marginRight: "20px" }}
                        variant="title1_medium"
                      >
                        Cost
                      </Typography>
                      <Typography
                        sx={{ marginRight: "20px" }}
                        variant="title1_medium"
                      >
                        Discount
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{ "&.MuiGrid-item": { paddingLeft: "0px" } }}
                    sm={2}
                    item
                    lg={1}
                  >
                    {/* <Box
                      sx={addMoreTestStyle}
                      onClick={() => handleAddTest("procedure")}
                    >
                      <Typography variant="title1_medium">+</Typography>
                    </Box> */}
                    <IconButton
                      variant="contained"
                      sx={{ ...orangeButton, marginLeft: "8px" }}
                      onClick={() => handleAddTest("procedure")}
                    >
                      <AddIcon
                        sx={{
                          width: "25px",
                          color: "neutral.white",
                          margin: "0px",
                          height: "25px",
                          padding: "0px",
                        }}
                      />
                    </IconButton>
                  </Grid>

                  {procedureTest?.map((test, index) => {
                    // console.log(test);
                    return (
                      <Grid
                        key={index}
                        item
                        lg={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                        }}
                      >
                        <Grid item sm={3} lg={4}>
                          <Autocomplete
                            clearIcon={false}
                            sx={{
                              ...textfieldTextBlueBorder,
                              textTransform: "capitalize",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10px",
                                height: "40px",
                                padding: "9px !important",
                                "& input": {
                                  padding: "8px",
                                  fontSize: "14px",
                                },
                              },
                            }}
                            fullWidth
                            options={[
                              ...(departmentTest?.Procedure?.consumables || []),
                              ...(departmentTest?.Procedure?.tests || []),
                            ]}
                            isOptionEqualToValue={(option, value) =>
                              typeof option === "object" &&
                              typeof value === "object"
                                ? option?.id === value?.id
                                : option === value
                            }
                            getOptionLabel={(option) =>
                              typeof option === "object"
                                ? option?.name
                                : option || option?.name || null
                            }
                            groupBy={(option) =>
                              departmentTest?.Procedure?.consumables?.includes(
                                option
                              )
                                ? "Consumables"
                                : "Tests"
                            }
                            value={(() => {
                              const selectedOption = procedureTest[index];
                              if (!selectedOption) return null;
                              // console.log(
                              //   selectedOption?.consumables_composite_fields,
                              //   "selectedOption consumables_composite_fields"
                              // );
                              // console.log(
                              //   selectedOption?.composite_fields,
                              //   "selectedOption"
                              // );
                              // Check if composite fields are available
                              if (
                                Array.isArray(
                                  selectedOption?.composite_fields
                                ) &&
                                selectedOption.composite_fields.length > 0 &&
                                typeof selectedOption.composite_fields[0] ===
                                  "object"
                              ) {
                                return selectedOption.composite_fields[0];
                              }

                              // Check if consumable composite fields are available
                              if (
                                Array.isArray(
                                  selectedOption?.consumables_composite_fields
                                ) &&
                                selectedOption.consumables_composite_fields
                                  .length > 0 &&
                                typeof selectedOption
                                  .consumables_composite_fields[0] === "object"
                              ) {
                                return (
                                  selectedOption
                                    ?.consumables_composite_fields[0] ||
                                  selectedOption?.name
                                );
                              }

                              // Return selectedOption or null if no composites
                              return selectedOption || null;
                            })()}
                            onChange={(event, newValue) =>
                              handleSelectTest(index, newValue, "procedure")
                            }
                            PopperComponent={(props) => (
                              <Grid item lg={8}>
                                <Popper
                                  {...props}
                                  placement="bottom-start"
                                  style={menuItemStyle}
                                />
                              </Grid>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ textTransform: "capitalize" }}
                                placeholder="Select Test"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                            renderOption={(props, option) => (
                              <MenuItem
                                sx={{
                                  ...menuItemStyle,
                                  textTransform: "capitalize",
                                }}
                                {...props}
                                key={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            )}
                          />
                        </Grid>

                        <Grid
                          sx={{ "&.MuiGrid-item": { paddingLeft: "0px" } }}
                          item
                          sm={4}
                          lg={6}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            gap={"8px"}
                            alignItems={"center"}
                            width="100%"
                          >
                            <TextField
                              type="number"
                              value={test?.quantity || 1}
                              placeholder="quantity"
                              fullWidth
                              onChange={(e) => {
                                const newQty = +e.target.value;
                                // Update the procedureTest array
                                setProcedureTest((prev) => {
                                  // Create a copy of the previous array
                                  const updatedTests = [...prev];
                                  // Update the quantity of the test at the specific index
                                  updatedTests[index] = {
                                    ...updatedTests[index],
                                    quantity: newQty > 0 ? newQty : 1, // Ensure quantity is not less than 1
                                  };

                                  return updatedTests; // Return the updated array
                                });
                              }}
                              InputProps={{ readOnly: true }}
                              sx={{
                                ...textfieldTextBlueBorder,
                                // "& .MuiOutlinedInput-root": {
                                //   borderRadius: "10px",
                                //   height: "40px",
                                //   padding: "9px !important",
                                //   "& input": {
                                //     padding: "8px",
                                //     fontSize: "14px",
                                //   },
                                // },
                                // "& .MuiInputBase-root": {
                                //   border: "1px solid #0067FF",
                                // },
                              }}
                              // disabled
                            />

                            <TextField
                              type="number"
                              value={test.amount ? test?.amount : 0}
                              placeholder="Cost"
                              fullWidth
                              InputProps={{ readOnly: true }}
                              sx={{
                                ...textfieldTextBlueBorder,
                              }}
                            />
                            <TextField
                              type="number"
                              value={test?.discount ? test?.discount : 0}
                              placeholder="Discount"
                              fullWidth
                              InputProps={{ readOnly: true }}
                              sx={{
                                ...textfieldTextBlueBorder,
                              }}
                            />
                          </Box>
                        </Grid>

                        <Grid sm={2} item lg={1}>
                          <IconButton
                            sx={{ marginBottom: "7px" }}
                            onClick={() => handleDeleteTest(index, "procedure")}
                          >
                            <CloseIcon
                              sx={{
                                ...xBtn,
                                width: "23px",
                                height: "23px",
                                borderRadius: "20%",
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}

                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"space-between"}
                    xs={12}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Typography variant="body_Grey1">Discount:</Typography>
                      <Typography variant="body_Grey1">
                        {totalDiscountProcedure}
                      </Typography>
                    </Box>

                    <Box
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                    >
                      <Typography variant="title1_medium">
                        Net Amount
                      </Typography>
                      <TextField
                        type="number"
                        value={totalBillProcedure}
                        placeholder="Net amount"
                        InputProps={{ readOnly: true }}
                        sx={{
                          ...textfieldTextBlueBorder,
                        }}
                      />
                    </Box>
                  </Grid>

                  <Box
                    sx={{
                      width: "100%",
                      justifyContent: "space-between",
                      display: "flex",
                      marginTop: "20px",
                    }}
                  >
                    <Box>
                      {Object.keys(inVoiceData).length > 0 &&
                        (inVoiceData?.department_name === "Procedure" ||
                          inVoiceData?.department_name === "Daycare") && (
                          <Button
                            onClick={() => handleRefund("Procedure")}
                            disabled={
                              buttonLoading || procedureTest?.length < 0
                            }
                            sx={{
                              ...primaryBtnBlue,
                              color: "white",
                              borderRadius: "10px",
                              py: "3px",
                              mr: "10px",
                              backgroundColor: "neutral.brown",
                              "&:hover": {
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                backgroundColor: "neutral.brown",
                              },
                            }}
                          >
                            <Typography variant="small_Text">refund</Typography>
                          </Button>
                        )}
                      {Object.keys(inVoiceData).length > 0 &&
                        (inVoiceData?.department_name === "Procedure" ||
                          inVoiceData?.department_name === "Daycare") && (
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                // fullWidth
                                // onClick={handleRePrint}
                                disabled={
                                  buttonLoading || procedureTest?.length < 0
                                }
                                sx={{
                                  ...primaryBtnBlue,
                                  color: "white",
                                  borderRadius: "10px",
                                  py: "3px",
                                  mr: "10px",
                                  backgroundColor: "neutral.main",
                                  "&:hover": {
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    backgroundColor: "neutral.main",
                                  },
                                }}
                              >
                                <Typography variant="small_Text">
                                  Re-Print
                                </Typography>
                              </Button>
                            )}
                            content={() => testReportRef.current}
                            onBeforeGetContent={() => handleRePrint()}
                          />
                        )}
                    </Box>

                    <Box>
                      <Button
                        // disabled={departmentTest?.Procedure?.find(
                        //   (testObj) => testObj.name === test.name
                        // )}
                        disabled={buttonLoading}
                        onClick={() =>
                          handleSaveAndPrint("Procedure", procedureTest)
                        }
                        sx={{
                          ...primaryBtnBlue,
                          color: "white",
                          borderRadius: "10px",
                          py: "3px",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          fontSize: {
                            md: "10px !important",
                          },
                        }}
                      >
                        <Typography variant="small_Text">
                          Save & Print
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </AccordionDetails>
            )}

            {/* {"----------Dietician Test accordion----------"} */}
            {activeTab === "Dietician" && (
              <AccordionDetails
                sx={{ backgroundColor: "white", padding: "20px 20px" }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    width: "100%",
                  }}
                >
                  <Grid item lg={12} sm={12}>
                    {" "}
                    <Select
                      value={selectedSpecialist?.Dietician?.id || ""}
                      onChange={(e) => handleSpecialistChange(e, "Dietician")}
                      defaultValue="default"
                      fullWidth
                      // sx={selectField}
                      sx={{
                        ...textfieldTextBlueBorder,
                        borderRadius: "10px", // Apply 20px border radius to the entire Select component
                        "& .MuiSelect-select": {
                          borderRadius: "10px",
                          padding: "8px !important", // Adjust padding if needed
                        },
                      }}
                    >
                      <MenuItem disabled value="0">
                        Dietician
                      </MenuItem>
                      {departmentTest?.Dietician?.specialists?.map((el) => (
                        <MenuItem key={el.id} sx={menuItemStyle} value={el.id}>
                          {el.full_name}
                        </MenuItem>
                      ))}
                    </Select>{" "}
                  </Grid>
                  <Grid item lg={12} sm={12}>
                    <Select
                      value={selectedSpecialist?.DieticianPlan?.id || ""}
                      onChange={(e) =>
                        handleChangePlan(e, "Dietician", "DieticianPlan")
                      }
                      defaultValue="default"
                      fullWidth
                      // sx={selectField}
                      sx={{
                        ...textfieldTextBlueBorder,
                        borderRadius: "10px", // Apply 20px border radius to the entire Select component
                        "& .MuiSelect-select": {
                          borderRadius: "10px",
                          padding: "8px !important", // Adjust padding if needed
                        },
                      }}
                    >
                      <MenuItem disabled value="1">
                        Plans
                      </MenuItem>
                      {selectedSpecialist?.Dietician?.specialist_plans?.map(
                        (el) => (
                          <MenuItem
                            key={el.id}
                            sx={menuItemStyle}
                            value={el.id}
                          >
                            {el?.title}
                          </MenuItem>
                        )
                      )}
                    </Select>{" "}
                  </Grid>
                  <Grid item sm={4} lg={12}>
                    {selectedSpecialist?.DieticianPlan?.id &&
                      selectedSpecialist?.Dietician?.specialist_plans?.map(
                        (el) => (
                          <Box
                            sx={{
                              backgroundColor: "rgba(204, 204, 204, 0.1)",
                              border: "1px solid #0067FF",
                              padding: "8px 0px",
                              borderRadius: "10px",
                              display:
                                selectedSpecialist?.DieticianPlan?.id === el.id
                                  ? "flex"
                                  : "none",
                              justifyContent: "center",
                              color: "grey",
                            }}
                            key={el.id}
                            alignItems={"center"}
                            // sx={menuItemStyle}
                            value={el.id}
                          >
                            {selectedSpecialist?.DieticianPlan?.id === el.id &&
                              el?.amount}
                          </Box>
                        )
                      )}
                  </Grid>
                </Grid>

                <Box
                  mt={2}
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Box>
                    {Object.keys(inVoiceData).length > 0 &&
                      inVoiceData?.department_name === "Dietician" && (
                        <Button
                          disabled={buttonLoading}
                          onClick={() => handleRefund("Dietician")}
                          sx={{
                            ...primaryBtnBlue,
                            color: "white",
                            borderRadius: "10px",
                            py: "3px",
                            mr: "10px",
                            backgroundColor: "neutral.brown",
                            "&:hover": {
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                              backgroundColor: "neutral.brown",
                            },
                          }}
                        >
                          <Typography variant="small_Text">refund</Typography>
                        </Button>
                      )}
                    {Object.keys(inVoiceData).length > 0 &&
                      inVoiceData?.department_name === "Dietician" && (
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              // fullWidth
                              // onClick={handleRePrint}
                              disabled={buttonLoading}
                              sx={{
                                ...primaryBtnBlue,
                                color: "white",
                                borderRadius: "10px",
                                py: "3px",
                                mr: "10px",
                                backgroundColor: "neutral.main",
                                "&:hover": {
                                  backgroundColor: "neutral.main",
                                },
                              }}
                            >
                              <Typography variant="small_Text">
                                Re-Print
                              </Typography>
                            </Button>
                          )}
                          content={() => testReportRef.current}
                          onBeforeGetContent={() => handleRePrint()}
                        />
                      )}
                  </Box>

                  <Box>
                    {patientData?.payload &&
                    Object?.keys(patientData?.payload)?.length > 3 &&
                    inVoiceData?.department_name === "Dietician" &&
                    rePrint?.Dietician === false ? (
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            sx={{
                              ...primaryBtnBlue,
                              color: "white",
                              borderRadius: "10px",
                              py: "3px",
                            }}
                            disabled={buttonLoading}
                          >
                            <Typography variant="small_Text">
                              Save & Print
                            </Typography>
                          </Button>
                        )}
                        content={() => testReportRef.current}
                        onBeforeGetContent={() =>
                          handleSaveAndPrintPlans(
                            "Dietician",
                            selectedSpecialist?.DieticianPlan
                          )
                        }
                      />
                    ) : (
                      // <Button
                      //   onClick={() => showToast("Validation Fail", "ERROR")}
                      //   sx={saveBtnStyle}
                      // >
                      //   <Typography variant="small_Text">
                      //      Save & Print
                      //   </Typography>
                      // </Button>
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            disabled={buttonLoading}
                            sx={{
                              ...primaryBtnBlue,
                              color: "white",
                              borderRadius: "10px",
                              py: "3px",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography variant="small_Text">
                              Save & Print
                            </Typography>
                          </Button>
                        )}
                        content={() => testReportRef.current}
                        onBeforeGetContent={() =>
                          handleSaveAndPrintPlans(
                            "Dietician",
                            selectedSpecialist?.DieticianPlan
                          )
                        }
                      />
                    )}
                  </Box>
                </Box>
              </AccordionDetails>
            )}

            {/* {"----------Physiotherapist Test accordion----------"} */}
            {activeTab === "Physiotherapist" && (
              <AccordionDetails
                sx={{ backgroundColor: "white", padding: "20px 20px" }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <Grid item lg={4}>
                    {" "}
                    <Select
                      value={selectedSpecialist?.Physiotherapist?.id || ""}
                      onChange={(e) =>
                        handleSpecialistChange(e, "Physiotherapist")
                      }
                      defaultValue="default"
                      fullWidth
                      sx={{
                        ...textfieldTextBlueBorder,
                        borderRadius: "10px", // Apply 20px border radius to the entire Select component
                        "& .MuiSelect-select": {
                          borderRadius: "10px",
                          padding: "8px !important", // Adjust padding if needed
                        },
                      }}
                    >
                      <MenuItem disabled value="0">
                        Physiotherapist
                      </MenuItem>
                      {departmentTest?.Physiotherapist?.specialists?.map(
                        (el) => (
                          <MenuItem key={el} sx={menuItemStyle} value={el.id}>
                            {el.full_name}
                          </MenuItem>
                        )
                      )}
                    </Select>{" "}
                  </Grid>
                  <Grid item lg={4}>
                    <Select
                      value={selectedSpecialist?.PhysiotherapistPlan?.id || ""}
                      onChange={(e) =>
                        handleChangePlan(
                          e,
                          "Physiotherapist",
                          "PhysiotherapistPlan"
                        )
                      }
                      defaultValue="default"
                      fullWidth
                      sx={{
                        ...textfieldTextBlueBorder,
                        borderRadius: "10px", // Apply 20px border radius to the entire Select component
                        "& .MuiSelect-select": {
                          borderRadius: "10px",
                          padding: "8px !important", // Adjust padding if needed
                        },
                      }}
                    >
                      <MenuItem disabled value="1">
                        Plans
                      </MenuItem>
                      {selectedSpecialist?.Physiotherapist?.specialist_plans?.map(
                        (el) => (
                          <MenuItem
                            key={el.id}
                            sx={menuItemStyle}
                            value={el.id}
                          >
                            {el?.title}
                          </MenuItem>
                        )
                      )}
                    </Select>{" "}
                  </Grid>
                  <Grid item sm={4}>
                    {selectedSpecialist?.PhysiotherapistPlan?.id &&
                      selectedSpecialist?.Physiotherapist?.specialist_plans?.map(
                        (el) => (
                          <Box
                            sx={{
                              backgroundColor: "rgba(204, 204, 204, 0.1)",
                              border: "1px solid #0067FF",
                              padding: "8px 0px",
                              borderRadius: "10px",
                              display:
                                selectedSpecialist?.PhysiotherapistPlan?.id ===
                                el.id
                                  ? "flex"
                                  : "none",
                              justifyContent: "center",
                              color: "grey",
                            }}
                            key={el.id}
                            alignItems={"center"}
                            // sx={menuItemStyle}
                            value={el.id}
                          >
                            {selectedSpecialist?.PhysiotherapistPlan?.id ===
                              el.id && el?.amount}
                          </Box>
                        )
                      )}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box>
                    {Object.keys(inVoiceData).length > 0 &&
                      inVoiceData?.department_name === "Physiotherapist" && (
                        <Button
                          onClick={() => handleRefund("Physiotherapist")}
                          disabled={buttonLoading}
                          sx={{
                            ...primaryBtnBlue,
                            color: "white",
                            borderRadius: "10px",
                            py: "3px",
                            mr: "10px",
                            backgroundColor: "neutral.brown",
                            "&:hover": {
                              backgroundColor: "neutral.brown",
                            },
                          }}
                        >
                          <Typography variant="small_Text">refund</Typography>
                        </Button>
                      )}
                    {Object.keys(inVoiceData).length > 0 &&
                      inVoiceData?.department_name === "Physiotherapist" && (
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              // onClick={handleRePrint}
                              sx={{
                                ...primaryBtnBlue,
                                color: "white",
                                borderRadius: "10px",
                                py: "3px",
                                mr: "10px",
                                backgroundColor: "neutral.main",
                                "&:hover": {
                                  backgroundColor: "neutral.main",
                                },
                              }}
                              disabled={buttonLoading}
                            >
                              <Typography variant="small_Text">
                                Re-Print
                              </Typography>
                            </Button>
                          )}
                          content={() => testReportRef.current}
                          onBeforeGetContent={() => handleRePrint()}
                        />
                      )}
                  </Box>
                  <Box>
                    {patientData?.payload &&
                    Object.keys(patientData?.payload)?.length > 3 &&
                    rePrint?.Physiotherapist === false ? (
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            disabled={buttonLoading}
                            sx={{
                              ...primaryBtnBlue,
                              color: "white",
                              borderRadius: "10px",
                              py: "3px",
                            }}
                          >
                            <Typography variant="small_Text">
                              Save & Print
                            </Typography>
                          </Button>
                        )}
                        content={() => testReportRef.current}
                        onBeforeGetContent={() =>
                          handleSaveAndPrintPlans(
                            "Physiotherapist",
                            selectedSpecialist?.PhysiotherapistPlan
                          )
                        }
                      />
                    ) : (
                      // <Button
                      //   onClick={() => showToast("Validation Fail", "ERROR")}
                      //   sx={saveBtnStyle}
                      // >
                      //   <Typography variant="small_Text">
                      //      Save & Print
                      //   </Typography>
                      // </Button>
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            disabled={buttonLoading}
                            sx={{
                              ...primaryBtnBlue,
                              color: "white",
                              borderRadius: "10px",
                              py: "3px",
                            }}
                          >
                            <Typography variant="small_Text">
                              Save & Print
                            </Typography>
                          </Button>
                        )}
                        content={() => testReportRef.current}
                        onBeforeGetContent={() =>
                          handleSaveAndPrintPlans(
                            "Physiotherapist",
                            selectedSpecialist?.PhysiotherapistPlan
                          )
                        }
                      />
                    )}
                  </Box>
                </Box>
              </AccordionDetails>
            )}

            {/* {"----------Psychologist Test accordion----------"} */}
            {activeTab === "Psychologist" && (
              <AccordionDetails
                sx={{ backgroundColor: "white", padding: "20px 20px" }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <Grid item lg={4}>
                    {" "}
                    <Select
                      value={selectedSpecialist?.Psychologist?.id || ""}
                      onChange={(e) =>
                        handleSpecialistChange(e, "Psychologist")
                      }
                      defaultValue="default"
                      fullWidth
                      //  sx={selectField}
                      sx={{
                        ...textfieldTextBlueBorder,
                        borderRadius: "10px", // Apply 20px border radius to the entire Select component
                        "& .MuiSelect-select": {
                          borderRadius: "10px",
                          padding: "8px !important", // Adjust padding if needed
                        },
                      }}
                    >
                      <MenuItem disabled value="0">
                        Psychologist
                      </MenuItem>
                      {departmentTest?.Psychologist?.specialists?.map((el) => (
                        <MenuItem key={el.id} sx={menuItemStyle} value={el.id}>
                          {el?.full_name}
                        </MenuItem>
                      ))}
                    </Select>{" "}
                  </Grid>
                  <Grid item lg={4}>
                    <Select
                      value={selectedSpecialist?.PsychologistPlan?.id || ""}
                      onChange={(e) =>
                        handleChangePlan(e, "Psychologist", "PsychologistPlan")
                      }
                      defaultValue="default"
                      fullWidth
                      //  sx={selectField}
                      sx={{
                        ...textfieldTextBlueBorder,
                        borderRadius: "10px", // Apply 20px border radius to the entire Select component
                        "& .MuiSelect-select": {
                          borderRadius: "10px",
                          padding: "8px !important", // Adjust padding if needed
                        },
                      }}
                    >
                      <MenuItem disabled value="1">
                        Plans
                      </MenuItem>
                      {selectedSpecialist?.Psychologist?.specialist_plans?.map(
                        (el) => (
                          <MenuItem
                            key={el.id}
                            sx={menuItemStyle}
                            value={el.id}
                          >
                            {el?.title}
                          </MenuItem>
                        )
                      )}
                    </Select>{" "}
                  </Grid>
                  <Grid item sm={4}>
                    {selectedSpecialist?.PsychologistPlan?.id &&
                      selectedSpecialist?.Psychologist?.specialist_plans?.map(
                        (el) => (
                          <Box
                            sx={{
                              backgroundColor: "rgba(204, 204, 204, 0.1)",
                              border: "1px solid #0067FF",
                              padding: "8px 0px",
                              borderRadius: "10px",
                              display:
                                selectedSpecialist?.PsychologistPlan?.id ===
                                el.id
                                  ? "flex"
                                  : "none",
                              justifyContent: "center",
                              color: "grey",
                            }}
                            key={el.id}
                            alignItems={"center"}
                            // sx={menuItemStyle}
                            value={el.id}
                          >
                            {selectedSpecialist?.PsychologistPlan?.id ===
                              el.id && el?.amount}
                          </Box>
                        )
                      )}
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box>
                    {Object.keys(inVoiceData).length > 0 &&
                      inVoiceData?.department_name === "Psychologist" && (
                        <Button
                          disabled={buttonLoading}
                          onClick={() => handleRefund("Psychologist")}
                          sx={{
                            ...primaryBtnBlue,
                            color: "white",
                            borderRadius: "10px",
                            py: "3px",
                            mr: "10px",
                            backgroundColor: "neutral.brown",
                            "&:hover": {
                              backgroundColor: "neutral.brown",
                            },
                          }}
                        >
                          <Typography variant="small_Text">refund</Typography>
                        </Button>
                      )}
                    {Object.keys(inVoiceData).length > 0 &&
                      inVoiceData?.department_name === "Psychologist" && (
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              //  fullWidth
                              // onClick={handleRePrint}
                              disabled={buttonLoading}
                              sx={{
                                ...primaryBtnBlue,
                                color: "white",
                                borderRadius: "10px",
                                py: "3px",
                                mr: "10px",
                                backgroundColor: "neutral.main",
                                "&:hover": {
                                  backgroundColor: "neutral.main",
                                },
                              }}
                            >
                              <Typography variant="small_Text">
                                Re-Print
                              </Typography>
                            </Button>
                          )}
                          content={() => testReportRef.current}
                          onBeforeGetContent={() => handleRePrint()}
                        />
                      )}
                  </Box>
                  <Box>
                    {patientData?.payload &&
                    Object.keys(patientData?.payload)?.length > 3 &&
                    rePrint?.Psychologist === false ? (
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            disabled={buttonLoading}
                            sx={{
                              ...primaryBtnBlue,
                              color: "white",
                              borderRadius: "10px",
                              py: "3px",
                            }}
                          >
                            <Typography variant="small_Text">
                              Save & Print
                            </Typography>
                          </Button>
                        )}
                        content={() => testReportRef.current}
                        onBeforeGetContent={() =>
                          handleSaveAndPrintPlans(
                            "Psychologist",
                            selectedSpecialist?.PsychologistPlan
                          )
                        }
                      />
                    ) : (
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            disabled={buttonLoading}
                            sx={{
                              ...primaryBtnBlue,
                              color: "white",
                              borderRadius: "10px",
                              py: "3px",
                            }}
                          >
                            <Typography variant="small_Text">
                              Save & Print
                            </Typography>
                          </Button>
                        )}
                        content={() => testReportRef.current}
                        onBeforeGetContent={() =>
                          handleSaveAndPrintPlans(
                            "Psychologist",
                            selectedSpecialist?.PsychologistPlan
                          )
                        }
                      />
                    )}
                  </Box>
                </Box>
              </AccordionDetails>
            )}
          </Box>
        )}
      </Grid>

      <ReportPrint ref={testReportRef} />
    </>
  );
};

export default PatientsTest;
