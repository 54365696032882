import React, { useState } from "react";
import { Box, Typography, TextField, Grid, Button, IconButton } from "@mui/material";
import { textfieldText } from "../../style/AppointmentStyle";
import { closeBtn } from "../../style/PatinetFormStyle";
import { primaryBtn } from "../../style/AppointmentStyle";
import { headingStyle } from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import { primaryBtnBlue, textfieldTextPrep } from "../../style/PatientDetailsStyle";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { createReferrer } from "../../connections/postApi";
import { referrerUpdate } from "../../connections/putApi";


const Referrer = ({ onSave, setOpenModal, selectedReferrer , onClose}) => {
    // console.log(selectedReferrer)
    
  // const [name, location, phone, email] = selectedReferrer?.title?.split(' - ') || [];
  const [referrer, setReferrer] = useState(
   {
      name:  selectedReferrer?.value || "",
      email:   selectedReferrer?.email || '',
      phone:  selectedReferrer?.phone || '',
      address:  selectedReferrer?.address || '',
    }
  ); 
//  console.log(onSave , 'onSave')
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReferrer({ ...referrer, [name]: value });
  };

  const handleSave =  async() => {
    onSave("referrer",referrer);
    setOpenModal(false);

      const payload={
        referrer:{
          full_name: referrer?.name,
          email: referrer?.email,
          phone : referrer?.phone,
          address :referrer?.address,  
        }
      }
      if(selectedReferrer?.id){
        let res = await  referrerUpdate(selectedReferrer?.id,payload);
        // console.log(res);
      }else{
        let res =  await createReferrer(payload);
        // console.log(res);
      }
  };

  return (
    <Box container>

      <Box display="flex" justifyContent="space-between" alignItems="center">
      

<Box></Box>
        

        <Box sx={{display:'flex' , alignItems:'center'}}>
        <BorderColorIcon sx={{ color:"neutral.navyBlue" ,width:'28px' ,height:'24px' , marginRight:"8px" ,  marginTop:'-4px'}}/>
          <Typography
            variant="heading1" 
            fontWeight={600}
            gutterBottom
            sx={{...headingStyle }}
  
          >
          Add/Update  Referrer
          </Typography>
          </Box>
  


        <IconButton sx={{ marginBottom: "2rem" }}
        onClick={() => setOpenModal(false)} 
        >
          <CloseIcon  sx={{ color:'red'}}size="small" color="red" />
        </IconButton>
      </Box>
 
      <Grid container sm={12} lg={12} spacing={2.5} >
        <Grid item sm={4} xs={12}>
          <Typography>Name</Typography>
        
          <TextField
            fullWidth
            type="text"
            placeholder="Name"
            
            value={referrer.name}
            sx={{...textfieldTextPrep,  
              "& .MuiInputBase-root": {
                backgroundColor:'#F7F7FF',
                transition: "background-color 0.3s",
              },
             }}
            variant="outlined"
            name="name"
            onChange={handleChange}
          />
        </Grid>


        <Grid item sm={4} xs={12}>
          <Typography> Email</Typography>
          <TextField
            fullWidth
            type="email"
            value={referrer.email}
            placeholder="Email"
            sx={{...textfieldTextPrep,  
              "& .MuiInputBase-root": {
                backgroundColor:'#F7F7FF',
                transition: "background-color 0.3s",
              },
             }}
            variant="outlined"
            name="email"
            onChange={handleChange}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Typography>Phone</Typography>
          <TextField
            fullWidth
            type="text"
            value={referrer.phone}
            placeholder="Phone"
            sx={{...textfieldTextPrep,  
              "& .MuiInputBase-root": {
                backgroundColor:'#F7F7FF',
                transition: "background-color 0.3s",
              },
             }}
            variant="outlined"
            name="phone"
            onChange={handleChange}
            inputProps={{
              maxLength: 10, 
              pattern: "[0-9]*", 
              inputMode: "numeric", 
            }}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <Typography> Address</Typography>
          <TextField
            fullWidth
            type="text"
            placeholder="Address"
            value={referrer.address}
            sx={{...textfieldTextPrep,  
              "& .MuiInputBase-root": {
                backgroundColor:'#F7F7FF',
                transition: "background-color 0.3s",
              },
             }}
            variant="outlined"
            name="address"
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }} >
        <Grid item>
          {/* <Button
            variant="contained"
            sx={closeBtn}
            onClick={() => setOpenModal(false)}
          >
            <Typography textTransform={"capitalize"} fontSize={"1.1rem"}>Close</Typography>
          </Button> */}
        </Grid>
        
        <Grid item>
          <Button onClick={handleSave} variant="contained" sx={primaryBtnBlue}>
            <Typography textTransform={"capitalize"} fontSize={"1.1rem"}>Save</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Referrer;
 