import { useQuery } from "@tanstack/react-query";
import { useDispatch} from "react-redux";
import { selectedTreatment } from "../connections/getApi";
import { setError, setLoading} from "../redux/slices/prescriptionSlice";
 
const UseGetSelectTreatementId = (id) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ["selectedTreatment", id],
    queryFn: () => selectedTreatment(id), // Pass function reference, not invocation
    enabled: !!id, // Correct this to only enable if id exists
    onError: (error) => {
      console.error("Error in treatment:", error);
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSuccess: (data) => {
      console.log("Successfully fetched treatment list:", data);
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseGetSelectTreatementId;