export const textfieldTextLab = {
  textAlign: "center",
  "& .MuiOutlinedInput-root": {
    height: "30px",
    "& input": {
      padding: "0px",
      textAlign: "center",
      margin: 0,
      fontSize: "14px",
    },
  },
  "& .MuiInputBase-root": {
    textAlign: "center",
    backgroundColor: "#ebebeb",
    transition: "background-color 0.3s",
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "white",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
  },
};

export const accordionPatientTest = {
  backgroundColor: "neutral.main",
  p: 0,
  margin: 0,
  "&:hover": {
    color: "neutral.black",
  },
};

export const accordionSummaryStyle = {
  minHeight: "32px",
  p: "24px",
  m: 0,
  maxHeight: "32px",
 backgroundColor:'white',
  border :'0.5px solid blue',
   borderRadius:'15px',
  "&.Mui-expanded": {
    minHeight: "32px",
    p: "10px",
    m: 0,
    maxHeight: "32px",
  },
  color:"black",
  "&:hover": {
    "& .MuiTypography-root": {
      color: "neutral.black",
    },
    "& .MuiSvgIcon-root": {
      color: "neutral.black",
    },
  },
};

export const accordionDetailTitle = {
  fontSize: "14px",
  fontWeight: 600,
};
export const accordionTitleStyle = {
  color: "neutral.black",
  "&:hover": {
    color: "neutral.blue",
  },
};
export const addMoreTestStyle = {
  width: "1.5rem",
  height: "1.5rem",
  backgroundColor: "neutral.main",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  margin: "auto",
  cursor: "pointer",
};

export const deleteTest = {
  width: "1.5rem",
  height: "1.5rem",
  backgroundColor: "neutral.red",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  margin: "auto",
  cursor: "pointer",
};
export const saveBtnStyle = {
  m: 0,
  mt: 1,
  width: {
    xs: "100%",
    sm: "22%",
    md: "fit-content",
    lg: "fit-content",
  },
  backgroundColor: "neutral.blue1",
  textTransform: "capitalize",
  fontSize: "11px",
  fontFamily: "Lato, sans-serif",
  p: "9px, 15px",
  color: "white",
  borderRadius: "6px",
  "&:hover": {
    backgroundColor: "neutral.blue1",
    color: "black",
  },
};

export const tabButton ={
  marginBottom: '10px',
  borderRadius: '10px',
  fontFamily:"Outfit",
  '&:hover': { 
    backgroundColor:'neutral.main',
    color:'white',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    transition: "background-color 0.4s", 
  },
  textTransform:"capitalize",
}
