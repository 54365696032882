import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorList } from "../connections/getApi";
import {
  setDoctorOption,
  setError,
  setLoading,
} from "../redux/slices/appointmentSlice";
 

const UseFetchDoctorList = () => {
  const { doctorOptions } = useSelector((state) => state.appointment);
 
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getDoctorList"],
    queryFn: getDoctorList,
    enabled: !!doctorOptions,
    onError: (error) => {
      console.error("Error in UseFetchDoctorList:", error);
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSuccess: (data) => {
      dispatch(setDoctorOption(data));
      console.log("Successfully fetched doctor list:", data);
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseFetchDoctorList;
