import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  Skeleton,
  Button,
  Modal,
  keyframes,
} from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import MobileScreenShareRoundedIcon from "@mui/icons-material/MobileScreenShareRounded";
import { drListWrapper, drTableRow } from "../../style/DoctorListComponent";
import {
  actionIconStyle,
  tableCellStyle,
  menuItemStyle,
  selectField,
} from "../../style/AppointmentStyle";
import UseFetchAppointmentList from "../../helper/UseFetchAppointmentList";
import { useDispatch, useSelector } from "react-redux";
import { xBtn } from "../../style/LapPageStyle";
import {
  setPrescriptionData,
  setPatientDetailsData,
  setAppointmentData,
  setRenderPrescriptionTable,
} from "../../redux/slices/prescriptionSlice";
import UsePatientDetail from "../../helper/UsePatientDetail";
import UsePatientDetailPrescription from "../../helper/UsePatientDetailPrescription";
import UseFetchAppointmentDetailsPatient from "../../helper/UseFetchAppointmentDetailsPatient";
import { cancelAppointment } from "../../connections/putApi";
import { pinPatientAppointment } from "../../connections/putApi";
import { showToast } from "../../utils/toastUtil.js";
import { useLocation, useNavigate } from "react-router-dom";
import hero from "../../assets/hero.png";
import {
  primaryBtnBlue,
  selectFieldPrep,
} from "../../style/PatientDetailsStyle/index.js";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import updownar from "../../assets/updownar.svg";
import bye from "../../assets/bye.svg";
import loadingSpinner from "../../assets/loadingSpinner.svg";
import { getPatientPrescription } from "../../connections/getApi.js";

const rotateSpinner = keyframes`
0% { transform: rotate(0deg); }
10% { transform: rotate(36deg); }
20% { transform: rotate(72deg); }
30% { transform: rotate(108deg); }
40% { transform: rotate(144deg); }
50% { transform: rotate(180deg); }
60% { transform: rotate(216deg); }
70% { transform: rotate(252deg); }
80% { transform: rotate(288deg); }
90% { transform: rotate(324deg); }
100% { transform: rotate(360deg); }
`;

const DoctorDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedRow, setSelectedRow] =useState(null);
  const[startYourDay, setStartYourDay] = useState(
    localStorage.getItem("startDay") || false
  );

  // Check localStorage on component mount
  useEffect(() => {
    const startDayData = localStorage.getItem("startDay");
    if (startDayData) {
      const { value, expiry } = JSON.parse(startDayData);
      if (value === "true" && new Date().getTime() < expiry) {
        setStartYourDay(true);
      } else {
        localStorage.removeItem("startDay");
      }
    }
  }, []);

  const handleDayStart = () => {
    const expiryTime = new Date().getTime() + 10 * 60 * 60 * 1000; // 10 hours in ms
    localStorage.setItem(
      "startDay",
      JSON.stringify({
        value: "true",
        expiry: expiryTime,
      })
    );
    setStartYourDay(true);
  };

  const handleSelect = (id) => {
    //  dispatch(setPatientDetailsData({}));
    setTimeout(() => {
      setSelectedPatientId(id);
    }, 0);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const patientId = query.get("patientId");
    // console.log(patientId);
    if (patientId) {
      setSelectedPatientId(patientId);
      query.delete("patientId");
      navigate({ search: query.toString() }, { replace: true });
    }
  }, [location, navigate]);

  //  const isLoading = useSelector(
  //   (state) => state.prescription.isLoading
  // );

  const cancelPatientAppointment = async (id, e) => {
    dispatch(setRenderPrescriptionTable());
    e.stopPropagation();

    const confirmed = window.confirm(
      "Are you sure you want to cancel this appointment?"
    );
    if (confirmed) {
      const response = await cancelAppointment(id);
      if (!response.error) {
        refetch();
        setSelectedRow(null);
        showToast("appointment cancel", "SUCCESS");
      } else {
        console.error("Failed to cancel appointment:", response.msg);
        showToast("Failed to cancel appointment", "ERROR");
      }
    }
  };

  const pinAppointment = async (id, e) => {
    e.stopPropagation();
    const response = await pinPatientAppointment(id);
    if (!response.error) {
      refetch();
      // console.log(response.payload);
      showToast("SUCCESS  ", "SUCCESS");
    } else {
      console.error("Failed to pin appointment:");
    }
  };

  const handleRedirect = (patientId, e) => {
    e.stopPropagation();
    const newTabUrl = `${window.location.origin}${window.location.pathname}?patientId=${patientId}`;
    window.open(newTabUrl, "_blank");
  };

  // Fetch patient details when a patient is selected
  const { data: patientDetails, isLoading: patientDetailsLoading } =
    UsePatientDetail(selectedPatientId);
  const { data: appointmentDetails, isLoading: appointmentDataLoading } =
    UseFetchAppointmentDetailsPatient( 
      patientDetails?.payload?.appointments[0]?.id
    );

  const { data: prescriptionDetails, isLoading: prescriptionDataLoading } =
    UsePatientDetailPrescription( (patientDetails?.payload?.appointments && patientDetails?.payload?.appointments[0]?.prescription_id)  || (patientDetails?.payload?.appointments && patientDetails?.payload?.appointments[1]?.prescription_id) );

  // Dispatch data to Redux store when it changes
  useEffect(() => {
    if (patientDetails) {
      dispatch(setPatientDetailsData(patientDetails.payload));
      // dispatch(setAppointmentData({}))
    }
  }, [patientDetails, dispatch]);

  useEffect(() => {
    if (appointmentDetails) {
      dispatch(setAppointmentData(appointmentDetails));
      // dispatch(setAppointmentData({}));
      dispatch(setPrescriptionData([]));
    }
  }, [appointmentDetails, dispatch]);

  const authState = useSelector((state) => state.auth);
  // console.log(authState);

  const [allDataLoading, setAllDataLoading] = useState(false);
  // Debounce effect to stabilize allDataLoading state
  useEffect(() => {
    if (patientDetailsLoading) {
      // console.log(patientDetailsLoading);
      setAllDataLoading(true); // Set to true immediately when patientDetailsLoading starts
    } else if (
      !patientDetailsLoading &&
      !appointmentDataLoading &&
      !prescriptionDataLoading
    ) {
      // Use a timeout to debounce the false state change
      const timer = setTimeout(() => {
        setAllDataLoading(false);
        // console.log("i am loader", "all api loader");
      }, 1000);

      return () => clearTimeout(timer); // Clear timeout if dependencies change before debounce ends
    }
  }, [patientDetailsLoading, appointmentDataLoading, prescriptionDataLoading]);

  useEffect(() => {
    if (prescriptionDetails) {
      dispatch(setPrescriptionData(prescriptionDetails));
    }
  }, [prescriptionDetails, dispatch]);

  // useEffect(() => {
  //   const fetchPrescription = async () => {
  //     if (!appointmentDetails?.prescription_id) {
  //       if (patientDetailsData?.appointments?.length > 1 && !patientDetailsData?.appointments[0]?.prescription_id) {

  //         let prescriptionTruthyId = null;

  //         for (let appointment of patientDetailsData?.appointments) {
  //           if (appointment.prescription_id) {
  //             prescriptionTruthyId = appointment.prescription_id;
  //             break; // Stop at the first truthy prescription_id
  //           }
  //         }
  //         if (prescriptionTruthyId) {
  //           try {
  //             const res = await getPatientPrescription(prescriptionTruthyId);
  //             // console.log(res, "Fetched Prescription Data TO save medicine from an prev prescription");
              
  //             if (res) {
  //               dispatch(setPrescriptionData(res));
  //             }
  //           } catch (error) {
  //             console.error("Error fetching prescription:", error);
  //           }
  //         } else {
  //           console.warn("No truthy prescription ID found in appointments");
  //         }
  //       }
  //     }
  //   };
  //   fetchPrescription();
  // }, [appointmentDetails]);
  

  const { doctorOptions } = useSelector((state) => state.prescription);
  // console.log(doctorOptions, 'doctorOptions');

  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  //  const [date, setDate] = useState('2024/10/14');
  const [data, setData] = useState({
    visitDate: date,
    status: "paid",
    // visitIteq: undefined,
    doctorId: 1,
  });

  const {
    data: filterList,
    refetch,
    isError,
    isLoading,
  } = UseFetchAppointmentList(data);

  const sortedAppointments = filterList?.appointments?.sort((a, b) => {
    if (a.pinned_sequence && !b.pinned_sequence) return -1;
    if (!a.pinned_sequence && b.pinned_sequence) return 1;
    return 0;
  });

  //  console.log(filterList);
  // console.log(isError, "error");
  const patientDetailsData = useSelector(
    (state) => state.prescription.patientDetailsData
  );

  const handleDoctorChange = (event) => {
    setData((prev) => ({
      ...prev,
      doctorId: event.target.value,
    }));
  };

  useEffect(() => {
    refetch(data);
  }, [data, patientDetailsData]);

  // console.log(data, filterList);

  function getGreeting() {
    const now = new Date(); // Get the current date and time
    const hours = now.getHours(); // Get the current hour (0-23)

    if (hours < 12) {
      return "Good Morning";
    } else if (hours < 18) {
      return "Good Afternoon";
    } else if (hours < 21) {
      return "Good Evening";
    } else {
      return "Good Night ";
    }
  }

  return (
    <Grid
      sx={{
        //  border: "0.1px solid gray",
        backgroundColor: "#F7F7FF",
        // backgroundColor:'red',
        marginRight: -1,
        padding: 2,
        paddingTop: 3,
        // mt: 6,
        borderTopLeftRadius: "50px",
      }}
      container
      // spacing={1}
      justifyContent="center"
      lg={4.3}
      md={3.5}
      sm={12}
    >
      {!startYourDay ? (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "white",
              borderRadius: "20px",
              height: "185px",
              marginTop: "100px",
            }}
          >
            <Box sx={{ marginTop: "-80px" }}>
              <img src={hero} alt="" width={"250px"} />
            </Box>

            <Box sx={{ paddingRight: "30px", marginTop: "-10px" }}>
              <Typography fontSize={"24px"} fontWeight={"bold"}>
                {" "}
                {getGreeting()}{" "}
              </Typography>
              <Typography
                fontSize={"24px"}
                fontWeight={"bold"}
                color={"neutral.navyBlue"}
              >
                {" "}
                Dr. {authState?.first_name} {authState?.last_name}{" "}
              </Typography>
              <Typography sx={{ marginTop: "8px" }}>
                {" "}
                Have a nice day at work
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "35px",
            }}
          >
            <Button
              sx={{
                ...primaryBtnBlue,
                color: "white",
                width: "200px",
                fontSize: "14px",
              }}
              onClick={handleDayStart}
              className="btn draw-border"
            >
              Start your day{" "}
              <ArrowForwardOutlinedIcon sx={{ marginLeft: "5px" }} />
            </Button>
          </Box>
        </Box>
      ) : (
        <Grid item xs={12} sx={{ padding: 1 }}>
          {/* Doctor list */}

          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <FormControl variant="outlined">
              <Select
                fullWidth
                // sx={{
                //   ...selectField,
                // }}
                sx={{ ...selectFieldPrep, paddingX: "4px" }}
                value={data?.doctorId || ""}
                onChange={handleDoctorChange}
                name="doctor"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <span>{"Select Doctor"}</span>
                </MenuItem>
                {doctorOptions?.map((option) => (
                  <MenuItem
                    id={option?.value || "menu"}
                    key={option.value + Math.random()}
                    value={option.id}
                    sx={menuItemStyle}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {filterList?.appointments?.length > 0 && (
              <Typography
                letterSpacing={"0.15px"}
                variant="heading1"
                color={"neutral.navyBlue"}
                fontSize={"18px"}
              >
                {" "}
                Appointments : {filterList?.appointments?.length || 0}
              </Typography>
            )}
          </Box>

          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              sx={{ backgroundColor: "lightgray", marginTop: "10px" }}
            />
          ) : (
            <TableContainer
              // component={Paper}
              sx={{
                mt: 2,
                maxHeight: "100vh",
                minHeight: "70vh",
                backgroundColor: "#F7F7FF",
                overflowY: "auto" ,  // Enable scrolling
    "::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for webkit browsers
    },
    scrollbarWidth: "none", 
              }}
            >
              <Table sx={{ overflowY: "auto" ,  // Enable scrolling
    "::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for webkit browsers
    },
    scrollbarWidth: "none", }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableCellStyle}>
                      <Typography variant="small_Text1">Name </Typography>
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      <Typography variant="small_Text1">Patient ID</Typography>
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      <Typography variant="small_Text1">Visit Time</Typography>
                    </TableCell>

                    <TableCell sx={tableCellStyle}>
                      <Typography variant="small_Text1">Actions</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {filterList?.appointments?.length > 0 ? (
                  <TableBody>
                    {filterList?.appointments?.map((patient, index) => (
                      <TableRow
                        sx={{
                          backgroundColor:   selectedRow===patient?.patient_id?  "#0067FF40" : "white",
                          paddingY: "6px",
                          cursor: "pointer",
                       
                          //  marginBottom: '10px !important', // Force margin with !important
                          
                          borderBottom: "8px solid #F7F7FF",
                           borderTop: index === 0 ? "6px solid #F7F7FF" : "",
                          //  '&:hover': {
                          //   //  boxShadow: 'rgb(204, 219, 232) 3px 3px 6px 0px, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px',
                          //   // boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                          //    borderRadius: '20px !important',

                          //  },
                          "&:hover": {
                            transform: "scale(1.001)", // Slight scaling for the pop-up effect
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                            borderRadius: "20px !important",
                          },
                        }}
                        onClick={() => {
                          handleSelect(patient?.patient_id);
                          setSelectedRow(patient?.patient_id);
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "10px",
                            pl: "16px",
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography variant="heading2">
                            {patient?.patient_name}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <MobileScreenShareRoundedIcon
                              sx={{ height: "10px", width: "12px" }}
                            />
                            <Typography variant="body2_text">
                              {patient?.mobile}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: "10px", pl: "16px" }}>
                          <Typography variant="body_Grey1" color="text.black">
                            {patient?.uid}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ p: "10px", pl: "16px" }}>
                          <Typography color="text.black" variant="body3_text">
                            {patient?.visit_date}
                          </Typography>
                          <br />
                          <Typography color="text.black1" variant="body2_text">
                            {patient?.visit_time}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: "10px",
                            pl: "16px",
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                          }}
                        >
                          <IconButton sx={actionIconStyle}>
                            <CloseIcon
                              sx={{
                                ...xBtn,
                                borderRadius: "50%",
                                padding: "5px",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={(e) =>
                                cancelPatientAppointment(patient?.id, e)
                              }
                            />
                            <PushPinOutlinedIcon
                              disabled={isLoading}
                              sx={{
                                border: "2px solid #0067FF",
                                borderRadius: "50%",
                                padding: "2px",
                                backgroundColor:
                                  patient.pinned_sequence > 0 ? "#0067FF" : "",
                                color:
                                  patient.pinned_sequence > 0
                                    ? "white"
                                    : "#0067FF",
                                width: "22px",
                                height: "22px",
                                margin: "5px",
                              }}
                              onClick={(e) => pinAppointment(patient?.id, e)}
                            />
                            <OpenInNewOutlinedIcon
                              sx={{
                                backgroundColor: "#FF8D1E",
                                color: "white",
                                borderRadius: "50%",
                                px: "5px",
                                py: "5px",
                                width: "20px",
                                height: "20px",
                                "&:hover": {
                                  backgroundColor: "orange",
                                },
                              }}
                              onClick={(e) =>
                                handleRedirect(patient?.patient_id, e)
                              }
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableRow></TableRow>
                )}
              </Table>

              <Box
                sx={{
                  width: "100%",
                  display: filterList?.appointments?.length > 0 ? "none" : "",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "185px",
                    marginTop: "100px",
                  }}
                >
                  <Box sx={{ marginTop: "-80px", marginLeft: "-18px" }}>
                    <img src={bye} alt="" width={"250px"} />
                  </Box>

                  <Box sx={{ paddingRight: "30px", marginTop: "-14px" }}>
                    <Typography
                      fontSize={"24px"}
                      fontWeight={"bold"}
                      color={"neutral.navyBlue"}
                    >
                      All Done,{" "}
                      <Typography variant="span" color={"black"}>
                        {" "}
                        You've made it{" "}
                      </Typography>{" "}
                    </Typography>
                    {/* <Typography fontSize={'24px'}  fontWeight={'bold'} >You've made it </Typography> */}
                    <Typography sx={{ marginTop: "8px" }}>
                      {" "}
                      Good Bye !
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </TableContainer>
          )}
        </Grid>
      )}

      <Modal
        open={allDataLoading}
        //onClose={}
        sx={{
          border: "none",
          position: "absolute",
          top: "40%",
          left: "40%",
          // border:'1px solid transparent',
        }}
      >
        <Box
          sx={{
            animation: `${rotateSpinner} 1s linear infinite`,
            width: "50px",
            border: "none !important",
            outline: "none !important",
          }}
        >
          <img
            src={loadingSpinner}
            alt=""
            style={{
              width: "50px",
              height: "50px",
              border: "none !important",
              outline: "none !important",
            }}
          />
        </Box>
      </Modal>
    </Grid>
  );
};

export default DoctorDetail;
 