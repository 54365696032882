import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  appointmentTableCell,
  appointmentTableHead,
} from "../../style/FutureAppointmentStatusStyle";
import { headingStyle } from "../../style/LapPageStyle";
import rupee from '../../assets/rupee.svg';

const DailyCollectionBilling = ({ appointments = [], onClose }) => {
  const [totalAmt, setTotalAmt] = useState(0);
  useEffect(() => {
    // Calculate the total amount based on appointments
    const total = appointments.reduce((sum, appointment) => {
      return sum + (appointment.grand_total || 0); // Summing up grand_total
    }, 0);

    setTotalAmt(total);
  }, [appointments]);
  return (
    <Box sx={{ minWidth: "60%" }}>


     
<Box display="flex" justifyContent="space-between" alignItems="center">
          
          <Box></Box>
       
        <Box sx={{display:'flex' , alignItems:'center'}}>
          
          <Box sx={{marginRight:"8px" ,  marginTop:'-8px'}}>
         <img  src={rupee} alt="" sx={{ width:'28px' ,height:'24px' , marginRight:"8px" ,  marginTop:'-6px'}}/> 
         </Box>
          <Typography
            variant="heading1" 
            fontWeight={600} 
            gutterBottom
            sx={{...headingStyle }}
  
          >
         Today's Collection
          </Typography>
          </Box>
  


        <IconButton sx={{ marginBottom: "2rem" }}
        onClick={onClose}
        >
          <CloseIcon  sx={{ color:'red'}}size="small" color="red" />
        </IconButton>
      </Box>
 

      
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={"bold"} variant="h6" mb={2}>       
        </Typography>

        <Typography fontWeight={"200"}  mb={2}  sx={{backgroundColor:'#F7F7FF' , padding:'10px' , borderRadius:"12px"}}>
        <Typography fontSize={'16px'} variant="span">  Total Collections:  </Typography>    &#8377;   {" "} {totalAmt}
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={appointmentTableCell}>Sr No.</TableCell>
              <TableCell sx={appointmentTableCell}>Name</TableCell>
              <TableCell sx={appointmentTableCell}>PatientID</TableCell>
              <TableCell sx={appointmentTableCell}>Amount</TableCell>
              <TableCell sx={appointmentTableCell}>Discount</TableCell>
              <TableCell sx={appointmentTableCell}>Net</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments?.length === 0 && (
              <TableCell
                rowSpan={3}
                colSpan={7}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                No Data
              </TableCell>
            )}
            {appointments?.map((appointment, index) => {
              return (
                <TableRow key={appointment.id} sx={{backgroundColor:"#F7F7FF" , borderBottom:'8px solid white',   borderTop:'2px solid white'}}>
                  <TableCell sx={{ fontSize: "14px" , borderTopLeftRadius:'20px' , borderBottomLeftRadius:'20px' }}>{index + 1}</TableCell>
                  <TableCell sx={{ fontSize: "14px"}}>
                    {appointment?.name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px"}}>
                    {appointment?.uid}
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>
                    {appointment?.grand_total || 0}
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>
                    {appointment?.discount || 0}
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px",  borderTopRightRadius:"20px" , borderBottomRightRadius:'20px'  }}>
                    {appointment?.total || 0}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DailyCollectionBilling;
