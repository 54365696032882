import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";

const toastStyle = {
  success: {
    background:
      "linear-gradient(to right, rgb(0, 176, 155), rgb(150, 201, 61))",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  error: {
    background: "linear-gradient(to right, rgb(207, 80, 80), rgb(255, 111, 7))",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
  },
};

export const showToast = (message, status) => {
  const icon =
    status === "SUCCESS" ? (
      <CheckCircleIcon sx={{ color: "#fff" }} />
    ) : (
      <ErrorIcon sx={{ color: "#fff" }} />
    );
  const toastConfig = {
    icon: icon,
    style: toastStyle[status?.toLowerCase()],
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  if (status === "SUCCESS") {
    toast.success(message, toastConfig);
  } else if (status === "ERROR") {
    toast.error(message, toastConfig);
  }
};

// This component should be included in your app's main file (e.g., App.js)
export const ToastNotifier = () => {
  return (
    <div>
      <toast-container />
    </div>
  );
};
