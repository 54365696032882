import { Box, Typography } from "@mui/material";
import React from "react";
import logo from '../../assets/logo.png';
import ring_loader_blue from '../../assets/ring_loader_blue.gif';
import load from '../../assets/load.webp';

const MountPageLoader = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
     
      {/* <Typography mt={2} color="balck" variant="title_small">
        CYPHER MD
      </Typography> */}
      
      
      <Typography color={"#1a8cff"} variant="h4" >
        LOADING 
      </Typography>

     {/* <Box
        sx={{
          height: "20px",
          width: "20px",
          borderRadius: "50%",
          backgroundColor: "neutral.navyBlue",
          
          mt: 2,
          mr: 1.5,
          animation: `upDown 1s ease-in-out infinite`, // Animation applied here
          "@keyframes upDown": {
            "0%": {
              transform: "translateY(0)", // Start at the initial position
            },
            "50%": {
              transform: "translateY(-30px)", // Move up by 20px
            },
            "100%": {
              transform: "translateY(0)", // Return to the initial position
            },
          },
        }}
      />

     
   </Box> */}



<Box 
 sx={{marginTop:"18px"}}
  // sx={{  animation: `upDown 1s ease-in-out infinite`, // Animation applied here
  //         "@keyframes upDown": {
  //           "0%": {
  //             transform: "translateY(0)", // Start at the initial position
  //           },
  //           "50%": {
  //             transform: "translateY(-30px)", // Move up by 20px
  //           },
  //           "100%": {
  //             transform: "translateY(0)", // Return to the initial position
  //           },
  //         },

        
  //       }}

      >
         {/* <img src={ring_loader_blue} alt="" width={'20px'}/> */}
         <img src={load} width={'38px'} alt=""/>

      </Box>
 

      </Box>
  );
};

export default MountPageLoader;
