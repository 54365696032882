import Cookies from "js-cookie";
import { BASE_URL } from "./connectionUrl"; 
const authStaging = Cookies.get("authStaging")
  ? JSON.parse(Cookies.get("authStaging"))
  : null;
const token = authStaging?.token || "";

export const deleteLabReport = async (id) => {
  const url = `${BASE_URL}/api/v1/invoices/remove_item?item_id=${id}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};


export const deletePurchaseOrder = async (id) => {
  const url = `${BASE_URL}/api/v1/pharmacy/purchase_orders/${id}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteSupplier = async (id) => {
  const url = `${BASE_URL}/api/v1/pharmacy/suppliers/${id}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteMedicine = async (id) => {
  const url = `${BASE_URL}/api/v1/pharmacy/medicines/${id}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteMedicineSalt = async (id) => {
  const url = `${BASE_URL}/api/v1/pharmacy/medicine_salts/${id}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
}; 

export const deletePharmacy = async (id) => {
  const url = `${BASE_URL}/api/v1/invoices/remove_item?item_id=${id}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
};
