import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  patientData: [],
  doctorOptions: [],
  collections:[],
  patientDetailsData:{},
  pharmacyTableData:[],
// appointmentData:{},
  isLoading: false,
  isError: false,
};
   
const pharmacySlice = createSlice({
  name: "pharmacy",
  initialState,
  reducers: {
    setSearchPatient: (state, action) => {
      state.patientData = action.payload;
    },
    setPatientDetailsData: (state, action) => {
      state.patientDetailsData = action.payload;
    },
    setPharmacyTableData: (state, action) => {
      state.pharmacyTableData = action.payload;
    },
    setDoctorOption: (state, action) => {
      state.doctorOptions = action.payload;
    },
    setCollection: (state, action) => {
      state.collections = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
  },
});

export const { setSearchPatient ,  setPatientDetailsData ,setPharmacyTableData, setLoading, setError , setCollection , setDoctorOption} = pharmacySlice.actions;
export default pharmacySlice.reducer;
