import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Login from "../pages/Login";
import Appointment from "../pages/Appointment";
import Navbar from "../layout/Navbar";
import Billing from "../pages/Billing";
import Prescription from "../pages/Prescription";
import Nurse from "../pages/Nurse";
import Lab from "../pages/Lab";
import Pharmacy from "../pages/Pharmacy";
import PrivateRoute from "./PrivateRoute";
import Cookies from "js-cookie";

const ALLRoute = () => {
  const location = useLocation();

  const authStaging = Cookies.get("authStaging")
    ? JSON.parse(Cookies.get("authStaging"))
    : null;
  const isAuthenticated = authStaging?.isAuthenticated || false;

  return (
    <>
      {location.pathname !== "/" && <Navbar />}

      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated === true ? (
              <Navigate to={authStaging?.redirect || "/"} />
            ) : (
              <Login />
            )
          }
        />
         
        {/* element={isAuthenticated ? <Navigate to={authStaging.redirect || '/'} /> : <Login /> */}
        <Route element={<PrivateRoute />}>
          <Route path="/appointments" element={<Appointment />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/prescription" element={<Prescription />} />
          <Route path="/quick_check" element={<Nurse />} />
          <Route path="/lab" element={<Lab />} />
          <Route path="/pharmacy" element={<Pharmacy />} />
        </Route>

        
        {/* Catch-all route for invalid URLs */}
        <Route
          path="*"
          element={<Navigate to={isAuthenticated ? authStaging.redirect || authStaging?.redirect : "/"} />}
        />
      </Routes>
    </>
  );
};

export default ALLRoute;
