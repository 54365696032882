import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getPatientPrescription } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/prescriptionSlice";

const UsePatientDetailPrescription = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getPatientPrescription", id],
    queryFn: () => getPatientPrescription(id),
    enabled: !!id,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
  });
};

export default UsePatientDetailPrescription;
