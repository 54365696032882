import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import {
  collectBtn,
  headingStyle,
  labPageButton,
  tableCellLab,
  tableRowLab,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  dateFiled,
  menuItemStyle,
  newAppointmentSearchTextField,
  primaryBtn,
  selectField,
  textfieldText,
} from "../../style/AppointmentStyle";
import SearchIcon from "@mui/icons-material/Search";
import { drTableRow } from "../../style/DrApotComponentStyle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  addBtn,
  pharmacyPageBlueBtn,
  yellowBgBtn,
} from "../../style/PharmacyStyle";
import {
  getPriceByQuantity,
  getPriceByQuantityForReturn,
  reprintInvoice,
  refundInvoice,
} from "../../connections/getApi";
import { generateBill } from "../../connections/postApi";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/UseDebounce";
import UseGetMedicineList from "../../helper/UseGetMedicineList";
import UseMedicineByName from "../../helper/UseMedicineByName";
import UseGetMedicineDetail from "../../helper/UseGetMedicineDetail";
import UseGetInvoice from "../../helper/UseGetInvoice";
import { useReactToPrint } from "react-to-print";
import PharmacyInvoice from "../PrintCompoents/PharmacyInvoice";
import { showToast } from "../../utils/toastUtil";


// { this modal is not in use  }
const PastBillsPharmacy = ({ id, onClose }) => {
  console.log(id, "id voice");
  const [formState, setFormState] = useState({
    full_name: "",
    uid: "",
    mobile: "",
    email: "",
    dob: "",
    gender: "",
    patient_id: "",
    prescription_id: "",
    invoice_id: "",
  });

  const [medicines, setMedicines] = useState([
    {
      id: "",
      name: "",
      quantity: "",
      batchId: null,
      batch: "",
      batchArray: [],
      returned: false,
      stock: "",
      packing: "",
      unitMrp: null,
      mrp: null,
      discount: null,
      gst: null,
      amount: null,
    },
  ]);
  const [total, setTotal] = useState(0);
  const [select, setSelect] = useState(null);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [showButton, setShowButton] = useState(true);
  const [printInvoiceData, setPrintInvoiceData] = useState({});
  const [focusMedicineIndex, setFocusMedicineIndex] = useState(0);
  const dispatch = useDispatch();

  // console.log(focusMedicineIndex);
  // console.log(formState)

  const debouncedMedicineInputValue = useDebounce(
    medicines[focusMedicineIndex]?.name,
    200
  );
  const { data: invoiceDetails } = UseGetInvoice(id);
  const { data: medicinesList } = UseGetMedicineList(
    debouncedMedicineInputValue
  );
  const { data: medicineName } = UseMedicineByName(
    selectedMedicine?.name,
    selectedMedicine?.name
  );
  const { data: medicineDetail } = UseGetMedicineDetail(selectedMedicine?.id);

  console.log(invoiceDetails, "Invoice Details");
  // console.log(invoiceDetails);

  const { patientData } = useSelector((state) => state.pharmacy);
  const { patientDetailsData } = useSelector((state) => state.pharmacy);

  useEffect(() => {
    if (invoiceDetails) {
      setFormState({
        full_name: invoiceDetails.patient_info?.name || "",
        uid: invoiceDetails.patient_info?.uid || "",
        mobile: invoiceDetails.patient_info?.mobile || "",
        dob: invoiceDetails.patient_info?.dob || "",
        gender: invoiceDetails.patient_info?.gender || "",
        patient_id: invoiceDetails?.patient_id || "",
        prescription_id: invoiceDetails?.prescription_id || "",
        invoice_id: invoiceDetails?.id || "",
      });

      setMedicines(
        invoiceDetails?.medicines?.map((medicine) => ({
          id: medicine.sl_no,
          name: medicine.name || "",
          quantity: medicine.qty || "",
          batchId: medicine.medicine_batches?.id || null,
          batch: medicine.batch || "",
          batchArray: [],
          returned: false,
          stock: medicine?.stock,
          packing: medicine?.stock || "",
          unitMrp: medicine.mrp || null,
          mrp: medicine.mrp || null,
          discount: `${medicine.discount || 0}%`,
          gst: (medicine.cgst || 0) + (medicine.sgst || 0),
          amount: medicine.amount || null,
        }))
      );
    }
  }, [invoiceDetails]);

  //handle form change
  // const handleFocusMedicineName = (e) => {
  //   const { name, value } = e.target;
  //   setFormState((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const handleAddRow = () => {
    setMedicines([
      ...medicines,
      {
        name: "",
        quantity: "",
        batch: "",
        return: false,
        stock: "",
        packing: "",
        UnitMRP: null,
        MRP: null,
        GST: null,
        amount: null,
      },
    ]);
  };

  const handleMedicineChange = (index, field, value) => {
    const newMedicines = medicines.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );
    setMedicines(newMedicines);
    // console.log(index,value);
  };

  //packing and stock show after select medicine
  useEffect(() => {
    if (medicineDetail && select !== null) {
      setMedicines((prevMedicines) =>
        prevMedicines.map((medicine, idx) =>
          idx === select
            ? {
                ...medicine,
                stock: medicineDetail?.payload?.stock_left,
                packing: medicineDetail?.payload?.packing,
              }
            : medicine
        )
      );
      // console.log(packing, stock_left, '<-stock and packing');
    }
  }, [medicineDetail, select]);

  //  console.log(medicines);

  const handleQuantityChange = (index, field, value) => {
    const newMedicines = medicines?.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );

    setMedicines(newMedicines);
    getPriceByQuantity(medicines[index].id, value, medicines[index].batchId)
      .then((res) => {
        console.log(res.payload);
        const {
          selected_batch,
          discount,
          gst,
          mrp,
          batches,
          unit_price,
          grand_total,
        } = res.payload;

        // Update the medicine entry with the new price data
        const updatedMedicines = newMedicines?.map((medicine, idx) =>
          idx === index
            ? {
                ...medicine,
                batchId: selected_batch?.id,
                batch: selected_batch,
                batchArray: batches,
                stock: selected_batch?.stock_left,
                packing: batches[0]?.packing,
                unitMrp: medicine.returned ? -unit_price : unit_price,
                mrp: medicine.returned ? -mrp : mrp,
                discount: medicine.returned ? `-${discount}%` : `${discount}%`,
                gst,
                amount: medicine.returned ? -grand_total : grand_total,
              }
            : medicine
        );
        setMedicines(updatedMedicines);
      })
      .catch((error) => {
        console.error("Error fetching price:", error);
      });
  };

  // console.log(medicines);
  const handleReturnChange = (index) => {
    const newMedicines = medicines?.map((medicine, idx) =>
      idx === index ? { ...medicine, returned: !medicine.returned } : medicine
    );
    setMedicines(newMedicines);

    getPriceByQuantityForReturn(medicines[index].id, medicines[index].quantity)
      .then((res) => {
        console.log(res.payload);
        const {
          selected_batch,
          discount,
          gst,
          mrp,
          batches,
          unit_price,
          grand_total,
        } = res.payload;

        // Update the medicine entry with the new price data
        const updatedMedicines = newMedicines?.map((medicine, idx) =>
          idx === index
            ? {
                ...medicine,
                batchId: selected_batch?.id,
                batch: selected_batch,
                batchArray: batches,
                stock: selected_batch?.stock_left,
                packing: batches[0]?.packing,
                unitMrp: medicine.returned ? -unit_price : unit_price,
                mrp: medicine.returned ? -mrp : mrp,
                discount: medicine.returned ? `-${discount}%` : `${discount}%`,
                gst,
                amount: medicine.returned ? -grand_total : grand_total,
              }
            : medicine
        );

        setMedicines(updatedMedicines);
      })
      .catch((error) => {
        console.error("Error fetching price:", error);
      });
  };

  useEffect(() => {
    let totalAmount = 0;
    let validMedicines = 0;
    let returnMedicine = 0;
    let hasInvalidQuantity = false;

    medicines.forEach((medicine) => {
      // Check if the medicine has a valid name and quantity
      if (medicine.name && medicine.quantity && medicine.quantity > 0) {
        validMedicines++;

        if (medicine?.amount < 0) {
          returnMedicine += parseFloat(medicine.amount);
        } else {
          totalAmount += parseFloat(medicine.amount);
        }
      }

      // Check if there are medicines with a name but invalid quantity (null, 0, or undefined)
      if (medicine.name && (!medicine.quantity || medicine.quantity <= 0)) {
        hasInvalidQuantity = true;
      }
    });

    setSelect(validMedicines);
    setTotal(Math.abs(totalAmount + returnMedicine));

    // If any invalid quantity is found, hide the button
    setShowButton(!hasInvalidQuantity && validMedicines > 0);
  }, [medicines]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "medicine invoice Print",
    onAfterPrint: () => console.log("Print job finished"),
  });

  //handle save & print invoice
  const saveAndPrint = async () => {
    const formattedMedicines = medicines?.map((medicine) => ({
      id: medicine?.id,
      name: medicine.name,
      batch: medicine?.batch?.id,
      packing: medicine?.packing,
      quantity: medicine?.quantity,
      amount: medicine.unitMrp * medicine.quantity,
      discount: medicine.discount,
      net: medicine.amount,
    }));

    if (formState?.patient_id) {
      const payload = {
        patient: {
          full_name: formState.full_name,
          dob: formState.dob,
          gender: formState.gender,
          mobile: formState.mobile,
          email: formState.email,
          patient_id: patientDetailsData.id,
          prescription_id: patientDetailsData?.appointments?.prescription_id,
          invoice_id: patientDetailsData?.invoice_id || null,
        },
        medicines: formattedMedicines,
      };
      const res = await generateBill(payload);
      setPrintInvoiceData(res);
      console.log(res, "<-res");

      const statusMessage = res?.status?.status || "ERROR";
      const detailedMessage = res?.status?.message || "Something went wrong";
      showToast(statusMessage, detailedMessage);
      showToast(
        res?.status?.message || res?.status?.status,
        res?.status?.status
      );

      if (res) {
        setTimeout(() => {
          handlePrint();
        }, 0);
      }
      showToast(`Generating prescription PDF`, "SUCCESS");
    } else {
      const payload = {
        patient: {
          full_name: formState.full_name,
          dob: formState.dob,
          gender: formState.gender,
          mobile: formState.mobile,
          email: formState.email,
          invoice_id: patientDetailsData?.invoice_id || null,
        },
        medicines: formattedMedicines,
      };

      const res = await generateBill(payload);
      setPrintInvoiceData(res);
      console.log(res);
      if (res?.status?.status === "SUCCESS") {
        setTimeout(() => {
          handlePrint();
        }, 0);
      }
      const statusMessage = res?.status?.status || "ERROR";
      const detailedMessage = res?.status?.message || "Something went wrong";
      showToast(statusMessage, detailedMessage);
      showToast(
        res?.status?.message || res?.status?.status,
        res?.status?.status
      );
    }
  };

  //handle reprint  (invoiceId , prescriptionId)
  const rePrint = async () => {
    const res = await reprintInvoice(
      formState.invoice_id,
      formState.prescription_id
    );
    setPrintInvoiceData(res);
    console.log(res);
    if (res) {
      setTimeout(() => {
        handlePrint();
      }, 0);
    }
    const statusMessage = res?.status?.status || "ERROR";
    const detailedMessage = res?.status?.message || "Something went wrong";
    showToast(statusMessage, detailedMessage);
    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
    showToast(`Generating prescription PDF`, "SUCCESS");
  };

  // refund and cancel
  const refundAndCancel = async () => {
    const res = await refundInvoice(
      formState.invoice_id,
      formState.prescription_id
    );
    setPrintInvoiceData(res);
    console.log(res);

    const statusMessage = res?.status?.status || "ERROR";
    const detailedMessage = res?.status?.message || "Something went wrong";
    showToast(statusMessage, detailedMessage);
    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
  };
  console.log(medicines, "medicines");
  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={headingStyle}
        >
          Bill
        </Typography>
        <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
          <CloseIcon size="small" />
        </IconButton>
      </Box>

      <Grid
        container
        sx={{ justifyContent: "space-between", width: "100%", mt: "30px" }}
      >
        <Typography>Name : {formState?.full_name}</Typography>
        <Typography>Patient ID: {formState?.uid} </Typography>
        <Typography>Mobile: {formState?.mobile}</Typography>
        <Typography>Age : {formState?.age}</Typography>
        <Typography>{formState?.gender}</Typography>
      </Grid>

      <Grid item xs={12}>
        <TableContainer sx={{ mt: 2, width: "100%" }}>
          <Table sx={{ width: "100%" }}>
            <TableHead stickyHeader>
              <TableRow sx={{ ...drTableRow, textAlign: "center" }}>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                    width: "200px",
                  }}
                >
                  Name
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                    width: "150px",
                    paddingRight: "30px",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Batch
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Return
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Total Stock
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Pack
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Unit MRP
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  MRP
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Discount
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  GST
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Amount
                </TableCell>

                <TableCell
                  sx={{ p: "4px", textAlign: "center" }}
                  onClick={handleAddRow}
                >
                  <AddIcon sx={addBtn} />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {medicines.length > 0 &&
                medicines?.map((medicine, index) => (
                  <TableRow sx={tableRowLab}>
                    <TableCell sx={tableCellLab}>
                      <Typography variant="body_Grey1" color={"text.grey"}>
                        {index + 1}
                      </Typography>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Autocomplete
                        options={medicinesList?.payload?.medicine || []}
                        freeSolo
                        id={`name-${index}`}
                        inputValue={medicines[index]?.name || ""}
                        onInputChange={(e, newInputValue) => {
                          handleMedicineChange(index, "name", newInputValue);
                          setFocusMedicineIndex(index);
                        }}
                        onChange={(e, value) => {
                          if (value) {
                            handleMedicineChange(index, "name", value[0]);
                            handleMedicineChange(index, "id", value[1]);
                            setSelectedMedicine({
                              name: value[0],
                              id: value[1],
                            });
                            setFocusMedicineIndex(index);
                            setSelect(index);
                          }
                        }}
                        getOptionLabel={(option) => option[0]}
                        renderInput={(params) => (
                          <TextField
                            sx={textfieldText}
                            {...params}
                            placeholder="Medicine"
                          />
                        )}
                        renderOption={(props, option) => (
                          <MenuItem
                            sx={menuItemStyle}
                            {...props}
                            key={option[1]}
                          >
                            {option[0]}
                          </MenuItem>
                        )}
                      />
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <TextField
                        fullWidth
                        placeholder="Quantity"
                        variant="outlined"
                        sx={textfieldText}
                        onChange={(e) => {
                          handleQuantityChange(
                            index,
                            "quantity",
                            e.target.value
                          );
                        }}
                      />
                    </TableCell>

                    <TableCell sx={{ ...tableCellLab, width: "100px" }}>
                      <FormControl fullWidth variant="outlined">
                        <Select
                          sx={selectField}
                          placeholder="batch"
                          fullWidth
                          value={medicines[index]?.batchId || ""}
                          onChange={(e) => {
                            handleQuantityChange(
                              index,
                              "batchId",
                              e.target.value
                            );
                          }}
                        >
                          {medicines[index]?.batchArray?.map((batch) => (
                            <MenuItem key={batch.id} value={batch.id}>
                              {batch.batch_no}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* Render the batch stock and expiry date */}

                        <Box
                          justifyContent={"space-between"}
                          display={"flex"}
                          paddingX={"2px"}
                        >
                          <Typography
                            fontSize={"12px"}
                            color={` ${
                              medicine?.quantity >
                              medicines[index]?.batch?.stock_left
                                ? "#ed5564"
                                : "text.grey"
                            } `}
                          >
                            {medicines[index]?.batch?.stock_left}
                          </Typography>
                          <Typography fontSize={"12px"}>
                            {medicines[index]?.batch?.expiry_date}
                          </Typography>
                        </Box>
                      </FormControl>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Checkbox
                        onChange={() => handleReturnChange(index)}
                        sx={{ height: "10px", width: "10px" }}
                      />
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Typography
                        variant="body_Grey1"
                        color={` ${
                          medicine?.quantity > medicine.stock
                            ? "#ed5564"
                            : "text.grey"
                        } `}
                      >
                        {medicine.stock}
                      </Typography>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Typography variant="body_Grey1" color={"text.grey"}>
                        {medicine?.packing}
                      </Typography>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      {medicine.returned ? (
                        <Typography variant="body_Grey1" color={"#ed5564"}>
                          {" "}
                          {medicine?.mrp}{" "}
                        </Typography>
                      ) : (
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine.mrp}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      {medicine.returned ? (
                        <Typography variant="body_Grey1" color={"#ed5564"}>
                          {" "}
                          {medicine?.mrp}{" "}
                        </Typography>
                      ) : (
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine.mrp}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      {medicine.returned ? (
                        <Typography variant="body_Grey1" color={"#ed5564"}>
                          {" "}
                          {medicine.discount}{" "}
                        </Typography>
                      ) : (
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine.discount}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Typography variant="body_Grey1" color={"text.grey"}>
                        {medicine.gst}
                      </Typography>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      {medicine.returned ? (
                        <Typography variant="body_Grey1" color={"#ed5564"}>
                          {" "}
                          {medicine?.amount}{" "}
                        </Typography>
                      ) : (
                        <Typography variant="body_Grey1" color={"text.grey"}>
                          {medicine?.amount}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <EditIcon sx={{ ...addBtn, color: "white" }} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            ...tableRowLab,
            justifyContent: "space-between",
            display: "flex",
            height: "60px",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Box>
            <Typography variant="heading2_14" marginRight={"40px"}>
              {select}
            </Typography>
            <br />
            <Typography variant="heading2_14">Selected</Typography>
          </Box>
          <Box></Box>

          <Box></Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">
              Total&nbsp;&nbsp;&nbsp;{" "}
              <Typography variant="span"> {total} </Typography>{" "}
            </Typography>

            <Typography variant="h6">Round-Off</Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">{total} </Typography>

            <Typography variant="h6">{Math.floor(total / 10) * 10}</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ zIndex: "10" }}
        lg={12}
      >
        <Grid item>
          <Button variant="contained" sx={primaryBtn} onClick={onClose}>
            <Typography fontSize={"1.1rem"}>Close</Typography>
          </Button>
        </Grid>

        <Grid item sx={{ display: showButton ? "block" : "none" }}>
          <Button
            variant="contained"
            sx={{ ...pharmacyPageBlueBtn, marginRight: "5px" }}
            onClick={saveAndPrint}
          >
            <Typography fontSize={"1.1rem"}> Save & Print</Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ ...labPageButton, marginRight: "5px" }}
            onClick={rePrint}
          >
            <Typography fontSize={"1.1rem"}>Reprint</Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ ...yellowBgBtn }}
            onClick={refundAndCancel}
          >
            <Typography fontSize={"1.1rem"}>Refund & Cancel</Typography>
          </Button>
        </Grid>
      </Grid>

      <div style={{ display: "none" }}>
        <PharmacyInvoice props={printInvoiceData} ref={printRef} />
      </div>
    </Box>
  );
};

export default PastBillsPharmacy;
