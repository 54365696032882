import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const storedAuth = Cookies.get('authStaging') ? JSON.parse(Cookies.get('authStaging')) : null;

 
const initialState = {
  first_name:  storedAuth?.first_name || null,
  last_name: storedAuth?.last_name || null,
  email: storedAuth?.email || null,
  mobile: storedAuth?.mobile || null,
  user: storedAuth?.user || null,
  token: storedAuth?.token || null,
  redirect: storedAuth?.redirect || null,
  isAuthenticated: storedAuth?.isAuthenticated || false,
  isLoading: false,
  isError: false, 
  errorMessage: null,
  subDomainValue: localStorage.getItem("cyphermdSubDomain") || "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = null;
    },
    loginSuccess: (state, action) => {
      const { user, token, redirect ,  first_name, last_name, email, mobile, isAuthenticated } = action.payload;
     
      state.isAuthenticated = isAuthenticated;
      state.user = user;
      state.token = token;
      state.redirect = redirect;
      state.isLoading = false;
      state.first_name= first_name;
      state.last_name= last_name;
      state.email= email;
      state.mobile =mobile;
       Cookies.set('authStaging', JSON.stringify({
        user: user,
        token: token,
        redirect: redirect,
        isAuthenticated: true,
        first_name:first_name,
        last_name: last_name,
        email: email,
        mobile :mobile,
      }), { secure: true, sameSite: 'strict', expires: 1 });

    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.redirect = null;
      state.isError = false;
      state.errorMessage = null;
      Cookies.remove('authStaging');
    },
    setSubDomainRoot: (state, action) => {
      state.subDomainValue = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
  },
});
 
export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  setLoading,
  setError,
  setSubDomainRoot,
  subDomainValue,
} = authSlice.actions;

export default authSlice.reducer;
