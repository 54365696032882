import { Typography } from "@mui/material";
import React from "react";

const TextView = ({
  id,
  text,
  component,
  variant,
  color,
  onClick,
  style,
  children,
  textAlign,
  ...props
}) => {
  return (
    <Typography
    fontFamily={"Lato, sans-serif"}
      textAlign={textAlign}
      id={id}
      sx={{ ...style }}
      color={color ?? "text.primary"}
      onClick={onClick}
      variant={variant ?? ""}
      component={component}
      {...props}
    >
      {text}
      {children}
    </Typography>
  );
};

export default TextView;
