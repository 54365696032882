import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getAppointmentCount } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/appointmentSlice";
import { useLocation } from "react-router-dom";

const UseFetchAppointmentCount = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isEnable = (location.pathname === "/quick_check" || location.pathname === "/appointments"  || location.pathname === "/billing" );
 
  return useQuery({
    queryKey: ["getAppointmentCount"],
    queryFn: getAppointmentCount,
    enabled: isEnable,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },

    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    cacheTime: 1000 * 60 * 60, // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchOnMount: false, // Don't refetch on component mount
  });
};

export default UseFetchAppointmentCount;
