import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  Box,
  Button,
  useMediaQuery,
  TextField,
  Grid,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
// import AccountCircleIcon from "@mui/icons-material/Person";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import logo from "../assets/logo.png";
import dateTotal from "../assets/dateTotal.svg";
import TextView from "../Components/UI/TextView/TextView";
import SearchIcon from "@mui/icons-material/Search";
import { menuItemStyle } from "../style/AppointmentStyle";
import CustomModal from "../Components/CustomModal/CustomModal";
import FutureAppointmentStatus from "../Components/ModalChildren/FutureAppointmentStatus";
import PendingReport from "../Components/ModalChildren/PendingReport";
import TotalAppointment from "../Components/ModalChildren/TotalAppointment";
import UseFetchAppointmentCount from "../helper/UseFetchAppointmentCount";
import UseFetchUpcomingholidays from "../helper/UseFetchUpcomingholidays";
import { textfieldTextPrep } from "../style/PatientDetailsStyle";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../hooks/UseDebounce";
import UseSearchPatient from "../helper/UseSearchPatient";
import {
  setPrescriptionData,
  setSearchPatient,
  setPatientDetailsData,
  setAppointmentData,
} from "../redux/slices/prescriptionSlice";
import UsePatientDetailPrescription from "../helper/UsePatientDetailPrescription";
import UsePatientDetail from "../helper/UsePatientDetail";
import UseFetchAppointmentDetailsPatient from "../helper/UseFetchAppointmentDetailsPatient";
import { logout } from "../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import UseAppointmentsStats from "../helper/UseAppointmentsStats";
import {
  getAppointmentCountDetail,
  getCollectionData,
} from "../connections/getApi";
import AppointmentDetail from "../Components/ModalChildren/AppointmentDetail";
import AddIcon from "@mui/icons-material/Add";
import TotalCollection from "../Components/ModalChildren/TotalCollection";
import UseFetchCollections from "../helper/UseFetchCollections";
import NewBillPharmacy from "../Components/ModalChildren/NewBillPharmacy";
import UseGetLabTestCount from "../helper/UseGetLabTestCount";
import UseGetPatientPreviousReports from "../helper/UseGetPatientPreviousReports";
import AddNewReport from "../Components/ModalChildren/AddNewReport";
import UseGetPendingLabReport from "../helper/UseGetPendingLabReport";
// import Cookies from "js-cookie";
import DailyCollectionBilling from "../Components/ModalChildren/DailyCollectionBilling";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import rupee from "../assets/rupee.svg";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PurchaseOrder from "../Components/ModalChildren/PurchaseOrder";
import HourglassEmptySharpIcon from "@mui/icons-material/HourglassEmptySharp";
import lab_tube from "../assets/lab_tube.svg";
import pending_reports from "../assets/pending_reports.svg";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import supplier from "../assets/supplier.svg";
import medicine_salt from "../assets/medicine_salt.svg";
import pharmacy_icon from "../assets/pharmacy_icon.svg";
import medicine from "../assets/medicine.svg";
import Supplier from "../Components/ModalChildren/Supplier";
import Medicines from "../Components/ModalChildren/Medicines";
import MedicineSalt from "../Components/ModalChildren/MedicineSalt";
import PharmacyAdmin from "../Components/ModalChildren/PharmacyAdmin";

const Navbar = () => {
  const isMdUp = useMediaQuery("(min-width:700px)");
  const authState = useSelector((state) => state.auth);

  
  const [appointmentType, setAppointmentType] = useState("");
  const [path, setPath] = useState(authState?.redirect || "");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openTotalAppointment, setOpenTotalAppointment] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openCollection, setOpenCollection] = useState(false);
  const [openNewBill, setOpenNewBill] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [total, setTotal] = useState(0);
  const [openSearchDropDown, setOpenSearchDropDown] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [dailyCollection, setDailyCollection] = useState([]);
  const [openDailyCollection, setOpenDailyCollection] = useState(false);
  const [openPurchaseOrder, setOpenPurchaseOrder] = useState(false);
  const [openSuppliers, setOpenSuppliers] = useState(false);
  const [openMedicines, setOpenMedicines] = useState(false);
  const [openMedicinesSalt, setOpenMedicinesSalt] = useState(false);
  const [openPharmacy, setOpenPharmacy] = useState(false);
  const [addNeWReport, setAddNewReport] = useState({
    id: "",
    report: [],
    patient_info: {},
    refetch: false,
  });
  const [openAddNewReport, setOpenAddNewReport] = useState(false);
  const [searchData, setSearchData] = useState({
    q: "",
    page: 1,
    perPage: 10,
  });
  const[appointmentPage,setAppointmentPage] =useState(1);
  const[appointmentPerPage,setAppointmentPerPage] =useState(10);

  // hooks to get data
  const dispatch = useDispatch();

  const {
    billing: { renderInvoice },
  } = useSelector((state) => state);
  // renderAppointment } = useSelector((state) => state.appointment);
  const { renderAppointment } = useSelector((state) => state.appointment);

  // Fetch appointment stats using the custom hook
  const { data: appointmentStats , refetch: refetchAppointmentStats } = UseAppointmentsStats(); //only for prescription
  const { patientData , renderPrescriptionTable } = useSelector((state) => state.prescription);

  
  useEffect(()=>{
    refetchAppointmentStats();
  },[renderPrescriptionTable])

  const getCountQuery = UseFetchAppointmentCount(); // only for billing ,appointment ,nurse
  const getHolidaysQuery = UseFetchUpcomingholidays();
  const { data: searchResult } = UseSearchPatient(searchData);
  const { data: patientDetailsQuery } = UsePatientDetail(patientId);
  const { data: collectionArray, refetch: refetchMedicine } = 
    UseFetchCollections(selectedDate); //only for pharmacy
  const { data: appointmentDetails } =
    UseFetchAppointmentDetailsPatient(appointmentId);
  const { data: prescriptionData } =
    UsePatientDetailPrescription(prescriptionId);
  ///lab hooks
  const { data: gitlabTestCount } = UseGetLabTestCount(); //done only for lab
  const { data: getPatientPrevReport, refetch: refetchGetPatientPrevReport } =
    UseGetPatientPreviousReports(addNeWReport?.id, "", ""); //done only for lab
  // console.log(addNeWReport, "addNeWReport");

  const { data: getLabPendingReports, refetch: pendingTestRefetch } =
    UseGetPendingLabReport(1, 10); //done only for lab


  // console.log(authState);
  // handle total appointment 
  // const handleTotalAppointmentOpen = () => setOpenTotalAppointment(true);
  const handleTotalAppointmentClose = () => setOpenTotalAppointment(false);

  const handlePendingReportOpen = () => setOpenReport(true);
  const handlePendingReportClose = () => setOpenReport(false);

  //  {handle Appointment Status modal}
  const handleAppointmentStatusOpen = () => setOpen(true);
  const handleAppointmentStatusClose = () => setOpen(false);

  //  {handle total collections  Status modal}
  const handleTotalCollectionOpen = () => setOpenCollection(true);
  const handleTotalCollectionCLose = () => setOpenCollection(false);

  //  {handle total collections  Status modal}
  const handleOpenNewBillOpen = () => setOpenNewBill(true);
  const handleOpenNewBillCLose = () => setOpenNewBill(false);

  const handleOpenPurchaseOrderClose = () => setOpenPurchaseOrder(false);
  const handleOpenPurchaseOrderOpen = () => setOpenPurchaseOrder(true);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // get patient detail by id
  const selectedPatient = (id) => {
    setPatientId(id?.id);
  };

  // search query for patient by name or id
  const debouncedInputValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    appointmentCountRefetch();
  }, [renderInvoice , renderAppointment ]);

  useEffect(() => {
    if (debouncedInputValue) {
      setSearchData((prev) => ({ ...prev, q: debouncedInputValue }));
    } else {
      dispatch(setSearchPatient([]));
    }
  }, [debouncedInputValue, dispatch]);

  // change input for search
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  useEffect(() => {
    if (searchResult?.payload?.patients) {
      dispatch(setSearchPatient(searchResult?.payload?.patients));
    }
  }, [searchResult, dispatch, inputValue]);

  useEffect(() => {
    if (patientDetailsQuery?.payload) {
      dispatch(setPatientDetailsData(patientDetailsQuery.payload)); // Dispatch patient details to store
    }
  }, [patientDetailsQuery, dispatch]);

  useEffect(() => {
    if (appointmentDetails) {
      dispatch(setAppointmentData(appointmentDetails)); // Dispatch patient  appointment data to Redux store
    }
  }, [appointmentDetails, dispatch]);

  // Set the appointment ID after fetching patient details
  useEffect(() => {
    if (patientDetailsQuery?.payload?.appointments?.length > 0) {
      setAppointmentId(patientDetailsQuery.payload.appointments[0]?.id); // Assuming you need the first appointment ID
    }
  }, [patientDetailsQuery]);

  // Set the prescription ID after fetching appointment details
  useEffect(() => {
    if (appointmentDetails?.patient?.appointments?.length > 0) {
      setPrescriptionId(
        appointmentDetails.patient.appointments[0]?.prescription_id
      ); // Assuming you need the first prescription ID
    }
  }, [appointmentDetails]);

  // Dispatch prescription data to Redux store
  useEffect(() => {
    if (prescriptionData) {
      dispatch(setPrescriptionData(prescriptionData));
    }
  }, [prescriptionData, dispatch]);
  // set path from url to change case

  // useEffect(() => {
  //   setPath(window.location.pathname);
  // }, []);

  const { data: countData , refetch: appointmentCountRefetch } = getCountQuery;
  const { data: holidays } = getHolidaysQuery;
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    // window.location.reload();
  };

  /// billing logic
  const handleGetAppointmentDetail = async (value) => {
    // console.log(value,'value')// appointment type
     const res = await getAppointmentCountDetail(appointmentPage, appointmentPerPage, value);
     if (res.status?.status === "SUCCESS") {
      setAppointmentType(value);
     setAppointments(res?.payload?.appointments);
      setAppointmentType(value);
     }
  };

  const handleGetCollections = async () => {
    const data = await getCollectionData();
    setDailyCollection(data?.payload?.invoices);
    setOpenDailyCollection(true);
  };
  //pharmacy collection
  useEffect(() => {
    if (collectionArray?.payload?.invoices) {
      const grandTotalSum = collectionArray.payload.invoices.reduce(
        (acc, invoice) => {
          return acc + (invoice.grand_total || 0);
        },
        0
      );
      setTotal(grandTotalSum);
    }
  }, [collectionArray]);
  // console.log(authState)

  useEffect(() => {
    const transformedData = getPatientPrevReport?.tests?.reduce((acc, obj) => {
      const dateKey = obj.created_date;

      // If the date already exists in the accumulator, push to the array
      if (acc[dateKey]) {
        acc[dateKey].push(obj);
      } else {
        // Otherwise, initialize with an array containing the first object
        acc[dateKey] = [obj];
      }

      return acc;
    }, {});
    setAddNewReport((prev) => ({
      ...prev,
      report: transformedData,
      patient_info: getPatientPrevReport?.patient_info,
    }));
    if (getPatientPrevReport?.tests) {
      setOpenAddNewReport(true);
    }
  }, [addNeWReport.id, getPatientPrevReport, addNeWReport?.refetch]);

  

  const renderNavbar = (path) => {
    switch (path) {
      case "/appointments":
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "black",
              justifyContent: "space-between",
              width: "100%",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginLeft: "-4rem",
                alignItems: "center",
                gap: "5px",
                margin: "auto",
              }}
            >
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("all")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFE7DC",
                      width: "45px",
                      height: "45px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={dateTotal} alt="" width={"28px"} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Total Appointments
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h5"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.total || 0}
                    </TextView>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("confirmed")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#EAF2F4",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckIcon width={"28px"} sx={{ color: "#21C0A6" }} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Confirmed Appointments
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h5"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.confirmed || 0}
                    </TextView>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("cancelled")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FF392B40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CloseIcon width={"28px"} sx={{ color: "#FF392B" }} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#6c757d", marginBottom: "3px" }}
                    >
                      Cancelled Appointments
                    </TextView>
                    <TextView fontSize={"20px"} variant={"h6"} sx={{ mx: 2 }}>
                      {countData?.cancelled || 0}
                    </TextView>
                  </Box>
                </Box>

                {/* 
            <Box
              sx={{  display: "flex",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor:'#FFFFFF',
                borderRadius:'16PX',
                paddingX:"15px",
                paddingY:'15PX',   
                marginLeft:'8px',
                  }}
              onClick={handleGetCollections}
            >
            <Box sx={{ backgroundColor: '#0067FF40' , width:"42px" , height:'42px', borderRadius:"10px", display:"flex" , justifyContent:"center" , alignItems:'center'}}>
            <img src={rupee} width={'28px'} sx={{color : "#21C0A6"}} alt="rupee" />
            </Box>

        <Box  sx={{ display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          backgroundColor:'#FFFFFF', 
          }}>
          <TextView fontSize={"14px"} sx={{ mx: 2, color: "#6c757d" }}>
            Collection
          </TextView>
          <TextView fontSize={"20px"} variant={"h6"} sx={{ mx: 2 }}>
            {countData?.collections || 0}
          </TextView>
         </Box>


      </Box> */}
              </>

              {/* <Button
                onClick={handleAppointmentStatusOpen}
                variant="contained"
                color="primary"
                sx={{
                  background: "#64b5f6",
                  borderRadius: "9px",
                  padding: "8px",
                  ml: "2rem",
                  mt: "auto",
                  "&:hover": {
                    backgroundColor: "#64b349ef35f6",
                  },
                }}
              >
                <TextView variant={"p"} fontSize={"0.6rem"} color={"white"}>
                  Future Appointments
                </TextView>
              </Button> */}
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {holidays?.holidays?.length > 0 &&
                holidays.holidays.slice(0, 2).map((holiday, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      padding: "10px 10px",
                      borderRadius: "15px",
                      backgroundColor: "#FFE7DC",
                      marginRight: "20px",
                    }}
                  >
                    <TextView
                      fontSize="0.70rem"
                      sx={{ mx: 2, color: "#3A3A3A" }}
                    >
                      Holiday
                    </TextView>
                    <TextView
                      fontWeight={400}
                      fontSize="0.87rem"
                      sx={{ mx: 2 }}
                    >
                      {holiday.date} - {holiday.end_date}
                    </TextView>
                    <TextView
                      fontSize="0.87rem"
                      sx={{ mx: 2, color: "#f58633" }}
                    >
                      {holiday.details}
                    </TextView>
                  </Box>
                ))}
            </Box>

            <Box
              sx={
                (path === "/prescription" || path === "/lab") && {
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }
              }
            >
              <IconButton onClick={handleMenuOpen} color="inherit">
                <AccountCircleIcon sx={{ height: "3rem", width: "3.5rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  marginLeft: "2.5rem",
                  marginTop: "-0.5rem",
                  "& .MuiPaper-root": {
                    borderRadius: "12px",
                    // padding: "16px",
                    minWidth: "180px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "3px",
                    p: 2,
                  }}
                >
                  {path !== "/prescription" && (
                    <Box sx={{  fontFamily:"Outfit" }}>  {authState?.first_name} </Box>
                 
                  )}
                  {path !== "/prescription" && (
                    <Box
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {authState?.user}
                    </Box>
                  )}
                  {path !== "/prescription" && (
                    <Box sx={{ marginTop: "5px", fontSize: "0.87rem" }}>
                   {authState?.email}
                    </Box> 
                  )} 

                  <Button
                    //  style={{border :'2px solid black'}}
                    disableRipple
                    startIcon={
                      <LogoutOutlinedIcon
                        sx={{
                          borderRadius: "50%",
                          padding: "8px",
                          color: "neutral.navyBlue",
                          "&:hover": {
                            // backgroundColor: "neutral.navyBlue",
                            color: "white",
                          },
                        }}
                      />
                    }
                    sx={{
                      backgroundColor: "#F7F7FF",
                      marginTop: "0.5rem",
                      color: "grey",
                      "&:hover": {
                        backgroundColor: "#0067FF40",
                        color: "gray",

                        "& .MuiSvgIcon-root": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      },

                      borderRadius: "20px",
                      textTransform: "capitalize",
                      width: "100%",
                      justifyContent: "flex-start",
                      fontSize: "16px",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Box>
        );
      
      case "/billing":
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "black",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: "2rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("all")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFE7DC",
                      width: "45px",
                      height: "45px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={dateTotal} alt="" width={"28px"} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Total Appointments
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h5"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.total || 0}
                    </TextView>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("confirmed")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#EAF2F4",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckIcon width={"28px"} sx={{ color: "#21C0A6" }} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Confirmed Appointments
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h5"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.confirmed || 0}
                    </TextView>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("cancelled")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FF392B40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CloseIcon width={"28px"} sx={{ color: "#FF392B" }} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Cancelled Appointments
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h6"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.cancelled || 0}
                    </TextView>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={handleGetCollections}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0067FF40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={rupee}
                      width={"28px"}
                      sx={{ color: "#21C0A6" }}
                      alt="rupee"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Collections
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h6"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.collections || 0}
                    </TextView>
                  </Box>
                </Box>
              </>

              {/* <Button
                onClick={handleAppointmentStatusOpen}
                variant="contained"
                color="primary"
                sx={{
                  background: "#64b5f6",
                  borderRadius: "9px",
                  padding: "8px",
                  ml: "2rem",
                  mt: "auto",
                  "&:hover": {
                    backgroundColor: "#64b349ef35f6",
                  },
                }}
              >
                <TextView variant={"p"} fontSize={"0.6rem"} color={"white"}>
                  Future Appointments
                </TextView>
              </Button> */}
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {holidays?.holidays?.length > 0 &&
                holidays.holidays.slice(0, 2).map((holiday, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      padding: "10px 10px",
                      borderRadius: "15px",
                      backgroundColor: "#FFE7DC",
                    }}
                  >
                    <TextView
                      fontSize="0.70rem"
                      sx={{ mx: 2, color: "#3A3A3A" }}
                    >
                      Holiday
                    </TextView>
                    <TextView
                      fontWeight={400}
                      fontSize="0.87rem"
                      sx={{ mx: 2 }}
                    >
                      {holiday.date} - {holiday.end_date}
                    </TextView>
                    <TextView
                      fontSize="0.87rem"
                      sx={{ mx: 2, color: "#f58633" }}
                    >
                      {holiday.details}
                    </TextView>
                  </Box>
                ))}
            </Box>

            <Box
              sx={
                (path === "/prescription" || path === "/lab") && {
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }
              }
            >
              <IconButton onClick={handleMenuOpen} color="inherit">
                {/* <AccountCircleIcon sx={{ height: "2.5rem", width: "3rem" }} /> */}
                <AccountCircleIcon sx={{ height: "3rem", width: "3.5rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  marginLeft: "2.5rem",
                  marginTop: "-0.5rem",
                  "& .MuiPaper-root": {
                    borderRadius: "12px",
                    // padding: "16px",
                    minWidth: "180px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "3px",
                    p: 2,
                  }}
                >
                  {path !== "/prescription" && (
                    <Box sx={{ fontWeight: "" , fontFamily:"Outfit" }}>{authState?.first_name} </Box>
                  )}
                  {path !== "/prescription" && (
                    <Box
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {authState?.user}
                    </Box>
                  )}
                  {path !== "/prescription" && (
                    <Box sx={{ marginTop: "5px", fontSize: "0.87rem" }}>
                   {authState?.email}
                    </Box> 
                  )}

                  <Button
                    //  style={{border :'2px solid black'}}
                    disableRipple
                    startIcon={
                      <LogoutOutlinedIcon
                        sx={{
                          borderRadius: "50%",
                          padding: "8px",
                          color: "neutral.navyBlue",
                          "&:hover": {
                            // backgroundColor: "neutral.navyBlue",
                            color: "white",
                          },
                        }}
                      />
                    }
                    sx={{
                      backgroundColor: "#F7F7FF",
                      marginTop: "0.5rem",
                      color: "grey",
                      "&:hover": {
                        backgroundColor: "#0067FF40",
                        color: "gray",

                        "& .MuiSvgIcon-root": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      },

                      borderRadius: "20px",
                      textTransform: "capitalize",
                      width: "100%",
                      justifyContent: "flex-start",
                      fontSize: "16px",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Box>
        );
      
      case "/lab":
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "black",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: "2rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: "2px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "16PX",
                  paddingX: "15px",
                  paddingY: "15PX",
                  marginLeft: "8px",
                }}
                onClick={() => setOpenReport(true)}
              >
                <Box
                  sx={{
                    backgroundColor: "#0067FF40",
                    width: "45px",
                    height: "45px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={pending_reports} alt="" width={"22px"} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <TextView
                    fontWeight={400}
                    fontSize={"14px"}
                    sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                  >
                    Pending
                  </TextView>
                  <TextView
                    fontSize={"20px"}
                    fontWeight={"600"}
                    variant={"h5"}
                    sx={{ mx: 2 }}
                  >
                    {getLabPendingReports?.meta?.total_count || 0}
                  </TextView>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "16PX",
                  paddingX: "15px",
                  paddingY: "15PX",
                  marginLeft: "8px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFE7DC",
                    width: "45px",
                    height: "45px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={lab_tube} alt="" width={"28px"} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <TextView
                    fontWeight={400}
                    fontSize={"14px"}
                    sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                  >
                    Total Tests
                  </TextView>
                  <TextView
                    fontSize={"20px"}
                    fontWeight={"600"}
                    variant={"h5"}
                    sx={{ mx: 2 }}
                  >
                    {gitlabTestCount?.total_tests || 0}
                  </TextView>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "16PX",
                  paddingX: "15px",
                  paddingY: "15PX",
                  marginLeft: "8px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FEFF5C40",
                    width: "45px",
                    height: "45px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <HourglassEmptySharpIcon
                    sx={{ width: "28px", color: "#cccc00" }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <TextView
                    fontWeight={400}
                    fontSize={"14px"}
                    sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                  >
                    In Process
                  </TextView>
                  <TextView
                    fontSize={"20px"}
                    fontWeight={"600"}
                    variant={"h5"}
                    sx={{ mx: 2 }}
                  >
                    {gitlabTestCount?.pending_tests || 0}
                  </TextView>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "16PX",
                  paddingX: "15px",
                  paddingY: "15PX",
                  marginLeft: "8px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#EAF2F4",
                    width: "45px",
                    height: "45px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoneSharpIcon sx={{ width: "28px", color: "#21C0A6" }} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <TextView
                    fontWeight={400}
                    fontSize={"14px"}
                    sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                  >
                    Completed
                  </TextView>
                  <TextView
                    fontSize={"20px"}
                    fontWeight={"600"}
                    variant={"h5"}
                    sx={{ mx: 2 }}
                  >
                    {gitlabTestCount?.total_tests || 0}
                  </TextView>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "42%",
              }}
            >
              <Grid width={"90%"}>
                <Autocomplete
                  popupIcon={false}
                  open={openSearchDropDown}
                  clearIcon={false}
                  onOpen={() => setOpenSearchDropDown(true)}
                  onClose={() => {
                    dispatch(setSearchPatient([]));
                    setOpenSearchDropDown(false);
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setAddNewReport(() => ({
                        id: newValue?.id,
                        report: [],
                        refetch: !addNeWReport?.refetch,
                      }));

                      setInputValue("");
                    }
                  }}
                  filterOptions={(options) => options}
                  onInputChange={handleInputChange}
                  options={patientData || []}
                  getOptionLabel={(option) =>
                    option.uid && option.full_name
                      ? `${option.uid} - ${option.full_name}`
                      : ""
                  }
                  renderOption={(props, option) => (
                    <MenuItem
                      key={option.id + Math.random()}
                      // onClick={() => selectedPatient(option)}
                      {...props}
                      sx={{
                        ...menuItemStyle,
                        width: "100%",
                        "&:hover, &.Mui-focused": {
                          backgroundColor: "#0067FF40 !important",
                          color: "black !important",
                        },
                      }}
                    >{`${option.uid} - ${option.full_name}`}</MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="search"
                      placeholder="Search Patient"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            <SearchIcon sx={{ opacity: 0.4 }} color="grey" />

                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      sx={{
                        ...textfieldTextPrep,
                      }}
                    />
                  )}
                />
              </Grid>

              <IconButton onClick={handleMenuOpen} color="inherit">
                <AccountCircleIcon sx={{ height: "3.6rem", width: "3.2rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  marginLeft: "2.5rem",
                  marginTop: "-0.5rem",
                  "& .MuiPaper-root": {
                    borderRadius: "12px",
                    // padding: "16px",
                    minWidth: "180px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "3px",
                    p: 2,
                  }}
                >
                  <Box sx={{ fontFamily:'Outfit' }}>  {authState?.first_name} </Box>
                  <Box
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                      fontSize: "1rem",
                    }}
                  >
                    {authState?.user}
                  </Box>

                  <Box sx={{ marginTop: "5px", fontSize: "0.87rem" }}>
                  {authState?.email}
                  </Box>

                  <Button
                    //  style={{border :'2px solid black'}}
                    disableRipple
                    startIcon={
                      <LogoutOutlinedIcon
                        sx={{
                          borderRadius: "50%",
                          padding: "8px",
                          color: "neutral.navyBlue",
                          "&:hover": {
                            // backgroundColor: "neutral.navyBlue",
                            color: "white",
                          },
                        }}
                      />
                    }
                    sx={{
                      backgroundColor: "#F7F7FF",
                      marginTop: "0.5rem",
                      color: "grey",
                      "&:hover": {
                        backgroundColor: "#0067FF40",
                        color: "gray",

                        "& .MuiSvgIcon-root": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      },

                      borderRadius: "20px",
                      textTransform: "capitalize",
                      width: "100%",
                      justifyContent: "flex-start",
                      fontSize: "16px",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Box>
        );

      case "/prescription":
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "black",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: "4rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {/* <Button
                onClick={handleAppointmentStatusOpen}
                variant="contained"
                color="primary"
                sx={{
                  background: "#64b5f6",
                  borderRadius: "5px",
                  padding: "6px",
                  paddingTop: "8px",
                  ml: "0rem",
                  mt: "auto",
                  "&:hover": {
                    backgroundColor: "#64b349ef35f6",
                  },
                }}
              >
                <Typography
                  fontSize={"0.8rem"}
                  color={"white"}
                  textTransform="none"
                >
                  Future Appointments
                </Typography>
              </Button> */}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Grid width={"90%"}>
                {/* <Autocomplete
                  popupIcon={false}
                  open={openSearchDropDown}
                  clearIcon={false}
                  onOpen={() => setOpenSearchDropDown(true)}
                  onClose={() => {
                    dispatch(setSearchPatient([]));
                    setOpenSearchDropDown(false);
                  }}
                  filterOptions={(options) => options}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      selectedPatient(newValue);
                      setInputValue("");
                    }
                  }}
                  onInputChange={handleInputChange}
                  options={patientData || []}
                  getOptionLabel={(option) =>
                    option.uid && option.full_name
                      ? `${option.uid} - ${option.full_name}`
                      : ""
                  }
                  renderOption={(props, option) => (
                    <MenuItem
                      key={option.id + Math.random()}
                      // onClick={() => selectedPatient(option)}
                      {...props}
                      sx={{
                        ...menuItemStyle,
                        width: "100%",
                        "&:hover, &.Mui-focused": {
                          backgroundColor: "#f58633 !important",
                          color: "white !important",
                        },
                      }}
                    >
                      {" "}
                      {option.uid
                        ? `${option.uid} - ${option.full_name}`
                        : option.full_name}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="search"
                      placeholder="Search Patient"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            <SearchIcon sx={{ opacity: 0.4 }} color="grey" />

                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      sx={{
                        ...textfieldTextPrep,
                      }}
                    />
                  )}
                /> */}
              </Grid>

              <IconButton onClick={handleMenuOpen} color="inherit">
                <AccountCircleIcon sx={{ height: "3rem", width: "3.5rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  marginLeft: "2.5rem",
                  marginTop: "-0.5rem",
                  "& .MuiPaper-root": {
                    borderRadius: "20px",
                    paddingTop: "-16px",
                    minWidth: "260px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "3px",
                    width: "320px",
                    padding: "10px",
                    borderRadius: "20px",
                    // p: 2,
                  }}
                >
                  {path === "/prescription" && (
                    <Box ml={"8px"}>
                      <Grid
                        container
                        md={12}
                        spacing={0}
                        style={{ listStyleType: "disc" }}
                      >
                        <Grid
                          item
                          md={5.8}
                          sx={{
                            backgroundColor: "#F7F7FF",
                            paddingY: "8px",
                            borderRadius: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="small_Text"
                            sx={{ paddingLeft: "12px" }}
                          >
                            Total Slots
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "10px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="heading2_14"
                                sx={{ paddingLeft: "12px" }}
                              >
                                {appointmentStats?.appointments_total || 0}
                              </Typography>
                            </Box>
                            {/* <Box sx={{backgroundColor :'#E5FBF9' , marginRight:"8px",  paddingX:'5px', borderRadius:"20px", color:"#16D7CB" , fontSize:"12px",  fontFamily:"Outfit"}} display={'flex'} alignItems={'center'} >
                          <ArrowDropUpIcon sx={{color:"#16D7CB"  , padding:'0px'}}/>

                           {(appointmentStats?.appointments_total * 100) /appointmentStats?.appointments_total || 0}%

                          </Box> */}
                          </Box>
                        </Grid>

                        <Grid
                          tem
                          md={5.8}
                          sx={{
                            backgroundColor: "#F7F7FF",
                            paddingY: "8px",
                            borderRadius: "20px",
                            marginLeft: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="small_Text"
                            sx={{ paddingLeft: "12px" }}
                          >
                            Complimentary
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "10px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="heading2_14"
                                sx={{ paddingLeft: "12px" }}
                              >
                                {appointmentStats?.appointments_complimentary ||
                                  0}{" "}
                                /{" "}
                                {appointmentStats?.appointments_complimentary_total ||
                                  0}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                backgroundColor: "#E5FBF9",
                                marginRight: "8px",
                                paddingX: "5px",
                                borderRadius: "20px",
                                color: "#16D7CB",
                                fontSize: "12px",
                                fontFamily: "Outfit",
                              }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <ArrowDropUpIcon
                                sx={{ color: "#16D7CB", padding: "0px" }}
                              />
                              {(
                                (appointmentStats?.appointments_complimentary_total *
                                  100) /
                                  appointmentStats?.appointments_total || 0
                              ).toFixed(2)}
                              %
                            </Box>
                          </Box>
                        </Grid>

                        <Grid
                          tem
                          md={5.8}
                          sx={{
                            backgroundColor: "#F7F7FF",
                            paddingY: "8px",
                            borderRadius: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="small_Text"
                            sx={{ paddingLeft: "12px" }}
                          >
                            New Slots
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "10px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="heading2_14"
                                sx={{ paddingLeft: "12px" }}
                              >
                                {appointmentStats?.appointments_new || 0} /{" "}
                                {appointmentStats?.appointments_new_total || 0}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                backgroundColor: "#E5FBF9",
                                marginRight: "8px",
                                paddingX: "5px",
                                borderRadius: "20px",
                                color: "#16D7CB",
                                fontSize: "12px",
                                fontFamily: "Outfit",
                              }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <ArrowDropUpIcon
                                sx={{ color: "#16D7CB", padding: "0px" }}
                              />
                              {(
                                (appointmentStats?.appointments_new_total *
                                  100) /
                                  appointmentStats?.appointments_total || 0
                              ).toFixed(2)}
                              %
                            </Box>
                          </Box>
                        </Grid>

                        <Grid
                          tem
                          md={5.8}
                          sx={{
                            backgroundColor: "#F7F7FF",
                            paddingY: "8px",
                            borderRadius: "20px",
                            marginLeft: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="small_Text"
                            sx={{ paddingLeft: "8px" }}
                          >
                            Review
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "10px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="heading2_14"
                                sx={{ paddingLeft: "8px" }}
                              >
                                {appointmentStats?.appointments_review || 0} /{" "}
                                {appointmentStats?.appointments_review_total ||
                                  0}{" "}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                backgroundColor: "#E5FBF9",
                                marginRight: "8px",
                                paddingX: "5px",
                                borderRadius: "20px",
                                color: "#16D7CB",
                                fontSize: "12px",
                                fontFamily: "Outfit",
                              }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <ArrowDropUpIcon
                                sx={{ color: "#16D7CB", padding: "0px" }}
                              />
                              {(
                                (appointmentStats?.appointments_review_total *
                                  100) /
                                  appointmentStats?.appointments_total || 0
                              ).toFixed(2)}
                              %
                            </Box>
                          </Box>
                        </Grid>

                        <Grid
                          tem
                          md={5.8}
                          sx={{
                            backgroundColor: "#F7F7FF",
                            paddingY: "8px",
                            borderRadius: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="small_Text"
                            sx={{ paddingLeft: "8px" }}
                          >
                            Old Slots
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "10px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="heading2_14"
                                sx={{ paddingLeft: "8px" }}
                              >
                                {appointmentStats?.appointments_old || 0} /{" "}
                                {appointmentStats?.appointments_old_total || 0}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                backgroundColor: "#E5FBF9",
                                marginRight: "8px",
                                paddingX: "5px",
                                borderRadius: "20px",
                                color: "#16D7CB",
                                fontSize: "12px",
                                fontFamily: "Outfit",
                              }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <ArrowDropUpIcon
                                sx={{ color: "#16D7CB", padding: "0px" }}
                              />
                              {(
                                (appointmentStats?.appointments_old_total *
                                  100) /
                                  appointmentStats?.appointments_total || 0
                              ).toFixed(2)}
                              %
                            </Box>
                          </Box>
                        </Grid>

                        <Grid
                          tem
                          md={5.8}
                          sx={{
                            backgroundColor: "#F7F7FF",
                            paddingY: "8px",
                            borderRadius: "20px",
                            marginLeft: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="small_Text"
                            sx={{ paddingLeft: "8px" }}
                          >
                            Cancelled
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "10px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="heading2_14"
                                sx={{ paddingLeft: "8px" }}
                              >
                                {appointmentStats?.appointments_cancelled || 0}{" "}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                backgroundColor: "#FFE5F6",
                                marginRight: "8px",
                                paddingX: "5px",
                                borderRadius: "20px",
                                color: "#FF392B",
                                fontSize: "12px",
                                fontFamily: "Outfit",
                              }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <ArrowDropUpIcon
                                sx={{
                                  color: "#FF392B",
                                  padding: "0px",
                                  transform: "rotate(180deg)",
                                }}
                              />
                              {(
                                (appointmentStats?.appointments_cancelled *
                                  100) /
                                  appointmentStats?.appointments_total || 0
                              ).toFixed(2)}
                              %
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  <Button
                    //  style={{border :'2px solid black'}}
                    disableRipple
                    startIcon={
                      <LogoutOutlinedIcon
                        sx={{
                          borderRadius: "50%",
                          padding: "8px",
                          color: "neutral.navyBlue",
                          "&:hover": {
                            // backgroundColor: "neutral.navyBlue",
                            color: "white",
                          },
                        }}
                      />
                    }
                    sx={{
                      backgroundColor: "#F7F7FF",
                      marginTop: "0.5rem",
                      color: "grey",
                      "&:hover": {
                        backgroundColor: "#0067FF40",
                        color: "gray",

                        "& .MuiSvgIcon-root": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      },

                      borderRadius: "20px",
                      textTransform: "capitalize",
                      width: "100%",
                      justifyContent: "flex-start",
                      fontSize: "16px",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Box>
        );

      case "/quick_check":
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "black",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: "0rem",
              //  margin:'auto'
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                margin: "auto",
              }}
            >
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("all")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFE7DC",
                      width: "45px",
                      height: "45px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={dateTotal} alt="" width={"28px"} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Total Appointments
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h5"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.total || 0}
                    </TextView>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("confirmed")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#EAF2F4",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckIcon width={"28px"} sx={{ color: "#21C0A6" }} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Confirmed Appointments
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h5"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.confirmed || 0}
                    </TextView>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleGetAppointmentDetail("cancelled")}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FF392B40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CloseIcon width={"28px"} sx={{ color: "#FF392B" }} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontWeight={400}
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#6c757d", marginBottom: "3px" }}
                    >
                      Cancelled Appointments
                    </TextView>
                    <TextView fontSize={"20px"} variant={"h6"} sx={{ mx: 2 }}>
                      {countData?.cancelled || 0}
                    </TextView>
                  </Box>
                </Box>

                {/* 
            <Box
              sx={{  display: "flex",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor:'#FFFFFF',
                borderRadius:'16PX',
                paddingX:"15px",
                paddingY:'15PX',   
                marginLeft:'8px',
                  }}
              onClick={handleGetCollections}
            >
            <Box sx={{ backgroundColor: '#0067FF40' , width:"42px" , height:'42px', borderRadius:"10px", display:"flex" , justifyContent:"center" , alignItems:'center'}}>
            <img src={rupee} width={'28px'} sx={{color : "#21C0A6"}} alt="rupee" />
            </Box>

        <Box  sx={{ display: "flex",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          backgroundColor:'#FFFFFF', 
          }}>
          <TextView fontSize={"14px"} sx={{ mx: 2, color: "#6c757d" }}>
            Collection
          </TextView>
          <TextView fontSize={"20px"} variant={"h6"} sx={{ mx: 2 }}>
            {countData?.collections || 0}
          </TextView>
         </Box>


      </Box> */}
              </>

              {/* <Button
                onClick={handleAppointmentStatusOpen}
                variant="contained"
                color="primary"
                sx={{
                  background: "#64b5f6",
                  borderRadius: "9px",
                  padding: "8px",
                  ml: "2rem",
                  mt: "auto",
                  "&:hover": {
                    backgroundColor: "#64b349ef35f6",
                  },
                }}
              >
                <TextView variant={"p"} fontSize={"0.6rem"} color={"white"}>
                  Future Appointments
                </TextView>
              </Button> */}
            </Box>
            {holidays?.holidays?.length > 0 &&
              holidays.holidays.slice(0, 2).map((holiday, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    padding: "10px 10px",
                    borderRadius: "15px",
                    backgroundColor: "#FFE7DC",
                    marginRight: "20px",
                  }}
                >
                  <TextView fontSize="0.70rem" sx={{ mx: 2, color: "#3A3A3A" }}>
                    Holiday
                  </TextView>
                  <TextView fontWeight={400} fontSize="0.87rem" sx={{ mx: 2 }}>
                    {holiday.date} - {holiday.end_date}
                  </TextView>
                  <TextView fontSize="0.87rem" sx={{ mx: 2, color: "#f58633" }}>
                    {holiday.details}
                  </TextView>
                </Box>
              ))}

            <Box
              sx={
                (path === "/prescription" || path === "/lab") && {
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }
              }
            >
              <IconButton onClick={handleMenuOpen} color="inherit">
                <AccountCircleIcon sx={{ height: "3rem", width: "3.5rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  marginLeft: "2.5rem",
                  marginTop: "-0.5rem",
                  "& .MuiPaper-root": {
                    borderRadius: "12px",
                    // padding: "16px",
                    minWidth: "180px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "3px",
                    p: 2,
                  }}
                >
                  {path !== "/prescription" && (
                    <Box sx={{ fontFamily:"Outfit" }}>  {authState?.first_name} </Box>
                  )}
                  {path !== "/prescription" && (
                    <Box
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {authState?.user}
                    </Box>
                  )}
                  {path !== "/prescription" && (
                    <Box sx={{ marginTop: "5px", fontSize: "0.87rem" }}>
                     {authState?.email}
                    </Box> 
                  )}

                  <Button
                    //  style={{border :'2px solid black'}}
                    disableRipple
                    startIcon={
                      <LogoutOutlinedIcon
                        sx={{
                          borderRadius: "50%",
                          padding: "8px",
                          color: "neutral.navyBlue",
                          "&:hover": {
                            // backgroundColor: "neutral.navyBlue",
                            color: "white",
                          },
                        }}
                      />
                    }
                    sx={{
                      backgroundColor: "#F7F7FF",
                      marginTop: "0.5rem",
                      color: "grey",
                      "&:hover": {
                        backgroundColor: "#0067FF40",
                        color: "gray",

                        "& .MuiSvgIcon-root": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      },

                      borderRadius: "20px",
                      textTransform: "capitalize",
                      width: "100%",
                      justifyContent: "flex-start",
                      fontSize: "16px",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Box>
        );

      case "/pharmacy":
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "black",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: "1rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <>
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    cursor: "pointer",
                    backgroundColor: "#f58633",
                    borderRadius: "5px",
                    justifyContent: "center",
                    boxShadow:
                      "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                    py: 0.8,
                    px: 1,
                    mr: 1,
                    color: "white",
                    "&:hover": {
                      color: "black",
                      py: 1,
                      px: 1.1,
                    },
                  }}
                  onClick={handleOpenNewBillOpen}
                >
                  <AddIcon
                    sx={{
                      color: "white",
                      height: "22px",
                      "&:hover": {
                        color: "black",
                      },
                    }}
                  />
                  <TextView
                    sx={{
                      color: "white",
                      "&:hover": {
                        color: "black",
                      },
                    }}
                  >
                    New Bill
                  </TextView>
                </Box> */} 
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                    height: "50px",
                  }}
                  onClick={handleOpenNewBillOpen}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFE7DC",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddIcon
                      sx={{
                        height: "24px",
                        color: "#FF8D1E",
                        // "&:hover": {
                        //   color: "black",
                        // },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontSize={"14px"}
                      sx={{
                        mx: 2,
                        color: "#3A3A3A",
                        marginBottom: "3px",
                        fontWeight: "600",
                      }}
                    >
                      New Bill
                    </TextView>
                    <TextView
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      {/* New Bill */}
                    </TextView>
                  </Box>
                </Box>     
             
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                    height: "50px",
                  }}
                  onClick={handleOpenPurchaseOrderOpen}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0067FF40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CreditCardIcon
                      sx={{ color: "neutral.navyBlue", height: "30px" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontSize={"14px"}
                      sx={{
                        mx: 2,
                        color: "#3A3A3A",
                        marginBottom: "3px",
                        fontWeight: "600",
                      }}
                    >
                      Purchase Order
                    </TextView>
                  </Box>
                </Box> 

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                    height: "50px",
                  }}
                  onClick={() => {
                    setOpenSuppliers(true);
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0067FF40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={supplier}
                      alt=""
                      sx={{
                        height: "24px",
                        color: "#FF8D1E",
                      }} 
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontSize={"14px"}
                      sx={{
                        mx: 2,
                        color: "#3A3A3A",
                        marginBottom: "3px",
                        fontWeight: "600",
                      }}
                    >
                      Suppliers
                    </TextView>
                    <TextView
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                    </TextView>
                  </Box>
                </Box> 

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                    height: "50px",
                  }}
                  onClick={() => {
                    setOpenMedicines(true);
                  }}
                >

                  <Box
                    sx={{
                      backgroundColor: "#0067FF40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={medicine}
                      alt=""
                      sx={{
                        height: "24px",
                        color: "#FF8D1E",
                        // "&:hover": {
                        //   color: "black",
                        // },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontSize={"14px"}
                      sx={{
                        mx: 2,
                        color: "#3A3A3A",
                        marginBottom: "3px",
                        fontWeight: "600",
                      }}
                    >
                      Medicines
                    </TextView>
                    <TextView
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                     
                    </TextView>
                  </Box>
                </Box>   

              {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                    height: "50px",
                  }}
                  onClick={() => {
                    setOpenMedicinesSalt(true);
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0067FF40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={medicine_salt}
                      alt=""
                      sx={{
                        height: "24px",
                        color: "#FF8D1E",
                        // "&:hover": {
                        //   color: "black",
                        // },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontSize={"14px"}
                      sx={{
                        mx: 2,
                        color: "#3A3A3A",
                        marginBottom: "3px",
                        fontWeight: "600",
                      }}
                    >
                      Medicine Salt
                    </TextView>
                    <TextView
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                     
                    </TextView>
                  </Box>
                </Box>  */}

                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                    height: "50px",
                  }}
                  onClick={() => setOpenPharmacy(true)}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0067FF40",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={pharmacy_icon}
                      alt=""
                      sx={{
                        height: "24px",
                        color: "#FF8D1E",
                        // "&:hover": {
                        //   color: "black",
                        // },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontSize={"14px"}
                      sx={{
                        mx: 2,
                        color: "#3A3A3A",
                        marginBottom: "3px",
                        fontWeight: "600",
                      }}
                    >
                      Pharmacy
                    </TextView>
                    <TextView
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                 
                    </TextView>
                  </Box>
                </Box> */}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16PX",
                    paddingX: "15px",
                    paddingY: "15PX",
                    marginLeft: "8px",
                    height: "50px",
                  }}
                  onClick={() => {
                    refetchMedicine();
                    handleTotalCollectionOpen();
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#EAF2F4",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={rupee}
                      width={"28px"}
                      sx={{ color: "#21C0A6" }}
                      alt="rupee"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TextView
                      fontSize={"14px"}
                      sx={{ mx: 2, color: "#3A3A3A", marginBottom: "3px" }}
                    >
                      Collections
                    </TextView>
                    <TextView
                      fontSize={"20px"}
                      fontWeight={"600"}
                      variant={"h6"}
                      sx={{ mx: 2 }}
                    >
                      {countData?.collections || 0}
                    </TextView>
                  </Box>
                </Box>
              </>
            </Box>

            {/*         
            {holidays?.holidays?.length > 0 && (
  holidays.holidays.slice(0, 2).map((holiday, index) => (
    <Box
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        padding: "10px 10px",
        borderRadius: "15px",
        backgroundColor: "#FFE7DC",
      }}
    >
      <TextView
        fontSize="0.70rem"
        sx={{ mx: 2, color: "#3A3A3A" }}
      >
        Holiday
      </TextView>
      <TextView fontWeight={400} fontSize="0.87rem" sx={{ mx: 2 }}>
        {holiday.date} - {holiday.end_date}
      </TextView>
      <TextView
        fontSize="0.87rem"
        sx={{ mx: 2, color: "#f58633" }}
      >
        {holiday.details}
      </TextView>
    </Box>
  ))
)}

             */}
            <Box
              sx={
                (path === "/prescription" || path === "/lab") && {
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                  borderRadius:'15px',
                }
              }
            >
              <IconButton onClick={handleMenuOpen} color="inherit">
                <AccountCircleIcon sx={{ height: "3.2rem", width: "3.6rem" }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  marginLeft: "2.5rem",
                  marginTop: "-0.5rem",
                  "& .MuiPaper-root": {
                    borderRadius: "12px",
                    // padding: "16px",
                    minWidth: "180px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "3px",
                    p: 2,
                  }}
                >
                  {path !== "/prescription" && (
                    <Box sx={{fontFamily:"Outfit" }}>   {authState?.first_name} </Box>
                  )}

                  {path !== "/prescription" && (
                    <Box
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {authState?.user}
                    </Box>
                  )}

                   {path !== "/prescription" && (
                    <Box sx={{ marginTop: "5px", fontSize: "0.87rem" }}>
                    {authState?.email}
                    </Box>
                  )}

                  <Button
                    //  style={{border :'2px solid black'}}
                    disableRipple
                    startIcon={
                      <LogoutOutlinedIcon
                        sx={{
                          borderRadius: "50%",
                          padding: "8px",
                          color: "neutral.navyBlue",
                          "&:hover": {
                            // backgroundColor: "neutral.navyBlue",
                            color: "white",
                          },
                        }}
                      />
                    }
                    sx={{
                      backgroundColor: "#F7F7FF",
                      marginTop: "0.5rem",
                      color: "grey",
                      "&:hover": {
                        backgroundColor: "#0067FF40",
                        color: "gray",

                        "& .MuiSvgIcon-root": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      },

                      borderRadius: "20px",
                      textTransform: "capitalize",
                      width: "100%",
                      justifyContent: "flex-start",
                      fontSize: "16px",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Box>
        );
      
      default:
        return null;
    }
  };

  


  return (
    <>
      <AppBar
        sx={{ background: "#F1F1FF", padding: "8px 0px", boxShadow: "none" }}
        position="fixed"
      >
        <Toolbar>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "10rem", marginLeft: "1.5rem" }}
          />
          {isMdUp ? (
            renderNavbar(path)
          ) : (
            <>
              <IconButton
                edge="start"
                color="black"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{ marginLeft: "auto" }}
              >
                <MenuIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>

      <CustomModal
        title={"future"}
        open={open}
        onClose={handleAppointmentStatusClose}
      >
        <FutureAppointmentStatus onClose={handleAppointmentStatusClose} />
      </CustomModal>
      <CustomModal
        customStyles={{ minWidth: "80%" }}
        open={openTotalAppointment}
        onClose={handleTotalAppointmentClose}
      >
        <TotalAppointment onClose={handleTotalAppointmentClose} />
      </CustomModal>

      <CustomModal
        customStyles={{ minWidth: "80%" }}
        open={openReport}
        onClose={handlePendingReportClose}
      >
        <PendingReport
          // reports={getLabPendingReports}
          onClose={handlePendingReportClose}
          // pendingTestRefetch={pendingTestRefetch}
        />
      </CustomModal>
      <CustomModal
        open={openAddNewReport}
        onClose={() => setOpenAddNewReport(false)}
      >
        <AddNewReport
          patientData={addNeWReport?.patient_info}
          onClose={() => setOpenAddNewReport(false)}
          report={addNeWReport?.report}
          refetchGetPatientPrevReport={refetchGetPatientPrevReport}
          handlePreview={refetchGetPatientPrevReport}
        />
      </CustomModal>
      <CustomModal
        customStyles={{ border: "none", minWidth: "50%" }}
        open={appointmentType === "all"}
        onClose={() => setAppointmentType(null)}
        propAppointmentType={appointmentType}
      >
        <AppointmentDetail      
          onClose={() => setAppointmentType(null)}
          appointments={appointments}
          propAppointmentType={appointmentType}
        />
      </CustomModal>
      <CustomModal
        customStyles={{ border: "none", minWidth: "50%" }}
        open={appointmentType === "confirmed"}
        onClose={() => setAppointmentType(null)}
        propAppointmentType={appointmentType}
      >
        <AppointmentDetail
          onClose={() => setAppointmentType(null)}
          appointments={appointments}
          propAppointmentType={appointmentType}
        />
      </CustomModal>
      <CustomModal
        customStyles={{ border: "none", minWidth: "50%" }}
        open={appointmentType === "cancelled"}
        onClose={() => setAppointmentType(null)}
        // appointmentType={appointmentType}
        propAppointmentType={appointmentType}
      >
        <AppointmentDetail
          onClose={() => setAppointmentType(null)}
          propAppointmentType={appointmentType}
          appointments={appointments}
        />
      </CustomModal>
      <CustomModal
        customStyles={{ border: "none", minWidth: "50%" }}
        open={openDailyCollection}
        onClose={() => setOpenDailyCollection(false)}
      >
        <DailyCollectionBilling
          onClose={() => setOpenDailyCollection(false)}
          appointments={dailyCollection}
        />
      </CustomModal>

      {/* /pharmacy modals */}
      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={openCollection}
        onClose={() => handleTotalCollectionCLose(null)}
      >
        <TotalCollection onClose={() => handleTotalCollectionCLose(null)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={openNewBill}
        onClose={() => handleOpenNewBillCLose(null)}
      >
        <NewBillPharmacy onClose={() => handleOpenNewBillCLose(null)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={openPurchaseOrder}
        onClose={() => handleOpenPurchaseOrderClose(null)}
      >
        <PurchaseOrder onClose={() => handleOpenPurchaseOrderClose(null)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={openSuppliers}
        onClose={() => setOpenSuppliers(false)}
      >
        <Supplier onClose={() => setOpenSuppliers(false)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%"  , maxWidth: "90%" }}
        open={openMedicines}
        onClose={() => setOpenMedicines(false)}
      >
        <Medicines onClose={() => setOpenMedicines(false)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={openMedicinesSalt}
        onClose={() => setOpenMedicinesSalt(false)}
      >
        <MedicineSalt onClose={() => setOpenMedicinesSalt(false)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={openPharmacy}
        onClose={() => setOpenPharmacy(false)}
      >
        <PharmacyAdmin onClose={() => setOpenPharmacy(false)} />
      </CustomModal>
    </>
  );
};

export default Navbar;
