import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { drTableRow } from "../../style/DrApotComponentStyle";
import { dateFiled, primaryBtn } from "../../style/AppointmentStyle";
import {
  collectBtn,
  headingStyle,
  tableCellLab,
  tableRowLab,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from "../CustomModal/CustomModal";
import PastBillsPharmacy from "./PastBillsPharmacy";
import UseFetchCollections from "../../helper/UseFetchCollections";
import PastBillPharmacyByInvoice from "./PastBillPharmacyByInvoice";
import Loader from "../UI/Loader";
import rupee from '../../assets/rupee.svg';
import  not_found from '../../assets/not_found.svg';
import { primaryBtnBlue } from "../../style/PatientDetailsStyle";

const TotalCollection = ({ onClose }) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [openBill, setOpenBill] = useState(false);
  const [openBillByInvoice, setOpenBillByInvoice] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  //get collection data
  const {
    data: collectionArray,
    refetch,
    isLoading,
  } = UseFetchCollections(selectedDate);

  // Handle date change
  const handleDateChange = (event) => {
    setTotal(0);
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (collectionArray?.payload?.invoices) {
      const grandTotalSum = collectionArray.payload.invoices.reduce(
        (acc, invoice) => {
          return acc + (invoice.grand_total || 0);
        },
        0
      );
      setTotal(grandTotalSum);
    }
  }, [collectionArray]);

  //handle open bill at pharmacy
  // const handleOpenBillOpen = (id) => {
  //   setSelectedInvoiceId(id);
  //   setOpenBill(true);
  // };

  // useEffect(() => {
  //   refetch(); // Fetch new data whenever selectedDate changes
  // }, [selectedDate, refetch]);

  const handleOpenBillClose = () => setOpenBill(false);

  //handle open bill at pharmacy
  const handleOpenBillByInvoice = (id) => {
    setSelectedInvoiceId(id);
    setOpenBillByInvoice(true);
    // setTimeout(() => {
    //   onClose();
    // }, 100);
  };

  const handleOpenBillByInvoiceClose = () => setOpenBillByInvoice(false);

  return (
    <Box sx={{ width: "100%" }}>


          
<Box display="flex" justifyContent="space-between" alignItems="center">
          
          <Box></Box>
       
        <Box sx={{display:'flex' , alignItems:'center'}}>
          
          <Box sx={{marginRight:"8px" ,  marginTop:'-8px'}}>
         <img  src={rupee} alt="" sx={{ width:'28px' ,height:'24px' , marginRight:"8px" ,  marginTop:'-6px'}}/> 
         </Box>
          <Typography
            variant="heading1" 
            fontWeight={600} 
            gutterBottom
            sx={{...headingStyle }}
  
          >
         Today's Collection
          </Typography>
          </Box>
  


        <IconButton sx={{ marginBottom: "2rem" }}
         onClick={onClose}
        >
          <CloseIcon  sx={{ color:'red'}}size="small" color="red" />
        </IconButton>
      </Box>
 
    

      <Box display="flex" justifyContent="space-between" width="100%">
      <FormControl variant="outlined" sx={{ width: "160px" }}>
          <TextField
            defaultValue={new Date().toISOString().split("T")[0]}
            max={new Date().toISOString().split("T")[0]}
            type="date"
            sx={{ marginTop: "2px",
              "& .MuiOutlinedInput-root": {
                height: "40px",
                borderRadius:'15px',
                fontSize: "14px",
                "& input": {
                  padding: "8px",
                  fontSize: "14px",
                },
                backgroundColor:"#F7F7FF",
                transition: "background-color 0.3s",
              },
              "& fieldset": {
                border: "none", // Removes the default border
              },
              "& .MuiInputBase-root": {
                backgroundColor: "#F7F7FF",
                transition: "background-color 0.3s",
              } , cursor:'pointer' ,
           
            }} 
            value={selectedDate}
            onChange={handleDateChange}
            name="Date"
          />
        </FormControl>

        {/* <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={{ fontSize: "1.5rem", m: 2, p: 0 }}
        >
         Today's Collection: {' '}   {`INR: ${total}`}
        </Typography>    */}

        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={"bold"} variant="h6" mb={2}>       
        </Typography>

        <Typography fontWeight={"200"}  mb={2}  sx={{backgroundColor:'#F7F7FF' , padding:'10px' , borderRadius:"12px"}}>
        <Typography fontSize={'16px'} variant="span">  Today's Collections:  </Typography>    &#8377;   {" "} {total}
        </Typography>
      </Box>



      </Box>

      

      <Grid container sm={12} md={12} marginBottom={"auto"} lg={12}>
        <Grid item xs={12}>
          <TableContainer sx={{ mt: 2, width: "100%" }}>

          {collectionArray?.payload?.invoices?.length > 0 && !isLoading ?(
        <Table sx={{ width: "100%" }}>
          <TableHead stickyHeader>
            <TableRow sx={{ textAlign: "center" }}>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  p: "6px",
                  textAlign: "center",
                }}
              >
                Sr No.
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  p: "6px",
                  textAlign: "center",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  p: "6px",
                  textAlign: "center",
                }}
              >
                Patient ID
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  p: "6px",
                  textAlign: "center",
                }}
              >
                Invoice Id
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  p: "6px",
                  textAlign: "center",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  p: "6px",
                  textAlign: "center",
                }}
              >
                Time
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  p: "6px",
                  textAlign: "center",
                }}
              >
                Modify
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {collectionArray?.payload?.invoices?.map((invoice, index) => (
              <TableRow key={index} sx={{...tableRowLab , borderBottom:'8px solid white' , borderTop: index===0? "2px solid white" :' '}} >
                <TableCell sx={{...tableCellLab , paddingY:"8px" , borderTopLeftRadius:"20px" , borderBottomLeftRadius:"20px"}}>
                  <Typography
                    variant="body_Grey1"
                    color={
                      invoice?.grand_total < 0
                        ? "neutral.textDanger"
                        : "text.black"
                    }
                  >
                    {index + 1}
                  </Typography>
                </TableCell>

                <TableCell sx={tableCellLab}>
                  <Typography
                    color={
                      invoice?.grand_total < 0
                        ? "neutral.textDanger"
                        : "text.black"
                    }
                    variant="heading2"
                  >
                    {" "}
                    {invoice.name}
                  </Typography>
                  {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <MobileScreenShareRoundedIcon sx={{ height: "12px", width: "12px" }} />
                <Typography variant="body2_text">
                  {" "}
                  {"1234567890"}
                </Typography>
              </Box> */}
                </TableCell>

                <TableCell sx={tableCellLab}>
                  <Typography
                    color={
                      invoice?.grand_total < 0
                        ? "neutral.textDanger"
                        : "text.black"
                    }
                    variant="body_Grey1"
                  >
                    {invoice?.patient_id ? invoice?.patient_id : "-"}
                  </Typography>
                </TableCell>

                <TableCell sx={tableCellLab}>
                  <Typography
                    color={
                      invoice?.grand_total < 0
                        ? "neutral.textDanger"
                        : "text.black"
                    }
                    variant="body_Grey1"
                  >
                    {invoice?.id}
                  </Typography>
                </TableCell>

                <TableCell sx={tableCellLab}>
                  <Typography
                    color={
                      invoice?.grand_total < 0
                        ? "neutral.textDanger"
                        : "text.black"
                    }
                    variant="body_Grey1"
                  >
                    {" "}
                    INR {invoice.grand_total}
                  </Typography>
                </TableCell>

                <TableCell sx={tableCellLab}>
                  <Typography
                    color={
                      invoice?.grand_total < 0
                        ? "neutral.textDanger"
                        : "text.black"
                    }
                    variant="body_Grey1"
                  >
                    {invoice?.generated_time}
                  </Typography>
                </TableCell>

                <TableCell sx={{...tableCellLab , paddingY:"8px" , borderTopRightRadius:"20px" , borderBottomRightRadius:"20px"}}>
                  {invoice?.patient_id ? (
                    <Button
                      sx={{
                        ...primaryBtnBlue,
                        color:'white',
                        paddingY: "2px",
                        paddingX: "4px",
                        fontSize:'15px',
                      }}
                      onClick={() => {
                        handleOpenBillByInvoice(invoice?.id);
                      }}
                    >
                      View
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        ...primaryBtnBlue,
                        color:'white',
                        paddingY: "2px",
                        paddingX: "4px",
                        fontSize:'15px',
                      }}
                      onClick={() => {
                        handleOpenBillByInvoice(invoice?.id);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

          ):(

<Box sx={{display:'flex' , justifyContent:"center" , flexDirection:'column' , width:'30%',  margin:'auto'}}>
 
   <Box sx={{margin:"auto" , width:'40%'}}>
    <img src={not_found} alt=""  width={'100%'} sx={{ margin:'auto'}}/>
    </Box>
    <Typography align="center" fontSize={'17px'} color={"crimson"}>   collections not found for selected date</Typography>
</Box>

)}
          </TableContainer>
        </Grid>
      </Grid>

     
      <CustomModal
        customStyles={{ minWidth: "80%" }}
        open={openBill}
        onClose={handleOpenBillClose}
      >
        <PastBillsPharmacy
          id={selectedInvoiceId}
          onClose={handleOpenBillClose}
        />
      </CustomModal>

      <CustomModal
        customStyles={{ minWidth: "80%" }}
        open={openBillByInvoice}
        onClose={handleOpenBillByInvoiceClose}
      >
        <PastBillPharmacyByInvoice
          id={selectedInvoiceId}
          setOpenBillByInvoice={setOpenBillByInvoice}
          onClose={handleOpenBillByInvoiceClose}
          refetch={refetch}
          showBtn={selectedDate !== new Date().toISOString().split("T")[0]}
        />
      </CustomModal>
    </Box>
  );
};

export default TotalCollection;
