import React from "react";
import { Box } from "@mui/material";
import PatientsInfo from "../Components/Billing/PatientsInfo";
import PatientsTest from "../Components/Billing/PatientsTest";
import Invoice from "../Components/Billing/Invoice";
import { billingWrapper } from "../style/PatientsInfoStyle";
const Billing = () => {
  return (
    <Box sx={billingWrapper}>
      <PatientsInfo />
      <PatientsTest />
      <Invoice />
    </Box>
  );
};

export default Billing;
        