export const slotWrapper = {
  margin: 0,
  padding: "15px 1rem",
  marginBottom: "auto",
  backgroundColor:"#FFFFFF",
  border: "1px solid rgba(0, 0, 0, 0.125)",
  borderRadius:"20px",
};

export const slotBoxWrapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 2,
  marginTop: "1rem", 
};

export const slotButton = {
  width: "100%",
  marginBottom: 1,
  textAlign: "center",
  display: "block",
  borderRadius: "1rem",
  backgroundColor: "#F7F7FF",
  border: "1px solid #e4e4e4",
  color: "black",
  fontSize: "12px",
  fontWeight: 400,
  p: "5px",
};
