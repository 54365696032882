import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  patientData: [],
  appointmentData:{},
  patientDetailsData:{},
  doctorOptions: [],
  isLoading: false,
  isError: false,
}; 
   
const nurseSlice = createSlice({
  name: "nurse",
  initialState,
  reducers: {
    setSearchPatient: (state, action) => {
      state.patientData = action.payload;
    },
    setPatientDetailsData: (state, action) => {
      state.patientDetailsData = action.payload;
    },
    setAppointmentData: (state, action) => {
      state.appointmentData = action.payload;
    },
    setDoctorOption: (state, action) => {
      state.doctorOptions = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
  },
});

export const { setSearchPatient , setLoading, setError, setPatientDetailsData , setAppointmentData , setDoctorOption} = nurseSlice.actions;
export default nurseSlice.reducer;
