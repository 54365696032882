import React from "react";
import { Box } from "@mui/material";
import PatientForm from "../Components/Nurse/PatientForm";
import PatientReport from "../Components/Nurse/PatientReport";
import DrApot from "../Components/Nurse/DrApot";
import { Wrapper } from "../style/PatinetFormStyle";

const Nurse = () => {
  return (
    <Box sx={Wrapper}>
      <PatientForm />
      <PatientReport />
      <DrApot />
    </Box>
  );
};

export default Nurse;
