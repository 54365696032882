import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Box,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MobileScreenShareRoundedIcon from "@mui/icons-material/MobileScreenShareRounded";
import { drListWrapper, drTableRow } from "../../style/DoctorListComponent";
import {
  menuItemStyle,
  selectField,
  tableCellStyle,
} from "../../style/AppointmentStyle";
import { tableRowLab } from "../../style/LapPageStyle";
import CustomModal from "../CustomModal/CustomModal";
import PrePatientBillPharmacy from "../ModalChildren/PrePatientBillPharmacy";
import { useDispatch, useSelector } from "react-redux";
import UseFetchDoctorList from "../../helper/UseFetchDoctorList";
import {
  setDoctorOption,
  setPharmacyTableData,
} from "../../redux/slices/pharmacySlice";
import { showToast } from "../../utils/toastUtil";
import UsePharmacyIndex from "../../helper/UsePharmacyIndex";
import updownar from "../../assets/updownar.svg";
import not_found from '../../assets/not_found.svg';

const PatientListTable = () => {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const [openBill, setOpenBill] = useState(false);

  const dispatch = useDispatch();
  const { data: drQuery } = UseFetchDoctorList();
  const { data: pharmacyTable  , isLoading } = UsePharmacyIndex();

  dispatch(setPharmacyTableData(pharmacyTable));      

  useEffect(() => {
    dispatch(setDoctorOption(drQuery?.doctors));
    setDoctors(drQuery?.doctors);
  }, [drQuery, dispatch]);

  // const { doctorOptions } = useSelector((state) => state.pharmacy);
  const [doctors, setDoctors] = useState(drQuery?.doctors);
  const [doctorId, setDoctorId] = useState(drQuery?.doctors[0]?.id || 1);

  useEffect(() => {
    dispatch(setPharmacyTableData(pharmacyTable));
    // Find the selected doctor and update patients with their appointments
    const selectedDoctor = pharmacyTable?.payload?.doctors?.find(
      (doc) => doc.id === doctorId
    );
    // console.log(selectedDoctor?.appointments);
    if (selectedDoctor) {
      setPatients(selectedDoctor?.appointments || []); // Update with appointments
    }
  }, [doctorId, pharmacyTable, dispatch]);

  const handleDoctorChange = (event) => {
    setDoctorId(event.target.value);
  };
  // const { data: filterList, refetch, isError } = (doctorId);

  //handle open bill at pharmacy
  const handleOpenBillOpen = (id) => {
    setPatient(id);
    setOpenBill(true);
  };

  const handleOpenBillClose = () => setOpenBill(false);

  return (
    <>
      <Grid
        sx={{ ...drListWrapper, marginX: "auto", width: "90%" , backgroundColor:'#F7F7FF' , borderRadius:'50px' }}
        container
        justifyContent="center"
        lg={10}
        md={12}
        sm={12}
      >
        {/* Doctor list */}
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            // justifyContent={"space-between"}
            gap={3}
            p={2.2}
            sx={{backgroundColor:"#F7F7FF" , }}
          >
            <FormControl variant="outlined">
              <Select
                fullWidth
                sx={{
                  ...selectField,
                }}
                value={doctorId || ""}
                onChange={handleDoctorChange}
                name="doctor"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>{"Select Doctor"}</em>
                </MenuItem>
                {doctors?.map((option) => (
                  <MenuItem
                    id={option?.id || "menu"}
                    key={option.id + Math.random()}
                    value={option.id}
                    sx={menuItemStyle}
                  >
                    {option.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack direction={"row"}>
              <Typography variant="heading2_16" color={"neutral.navyBlue"}>
                Appointments :
              </Typography>
              <Typography
                letterSpacing={"0.15px"}
                variant="heading2_16"
                color={"neutral.navyBlue"}
              > {' '}
                {patients?.length}
              </Typography>
            </Stack>
          </Box>

          <TableContainer
            // component={Paper}
            sx={{ mt: 0,  maxHeight: "100vh", minHeight: "70vh" }}
          >
            <Table sx={{ overflowY: "scroll",  }}> 
              <TableHead>
                <TableRow  sx={{width:'100%'}}>
                  <TableCell sx={{width:'33%' , textAlign:'center'}} >
                    <Typography  fontSize={'16px'}>Name </Typography>
                  </TableCell>

                  <TableCell  sx={{width:'33%' ,  textAlign:'center'}}>
                    <Typography >
                  Mobile
                    </Typography>
                  </TableCell>

                   <TableCell  sx={{width:'33%',  textAlign:'center'}}>
                    <Typography>
                     Patient ID 
                    </Typography>
                  </TableCell> 
                </TableRow> 
              </TableHead>

              {patients?.length > 0 ? (
                <TableBody> 
                  {patients?.map((patient, index) => ( 
                    <TableRow
                      key={index}
                      // sx={{ ...tableRowLab, cursor: "pointer" }}
                      sx={{
                        backgroundColor: 'white',
                        paddingY: '6px',
                        cursor: 'pointer',
                      
                       //  marginBottom: '10px !important', // Force margin with !important
                       borderBottom: '8px solid #F7F7FF',
                       borderTop: index===0?  '6px solid #F7F7FF' :'', 
                        '&:hover': {
                          boxShadow: 'rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset',
                          borderRadius: '20px !important',
                        },  
                      }}
                      onClick={() => {
                        handleOpenBillOpen(patient?.prescription_id);
                      }}
                    >
                      <TableCell sx={{ padding: "4px", paddingY:"11px" , width:'33%' , textAlign:'center' , borderTopLeftRadius:"20px" , borderBottomLeftRadius :"20px" ,
                       
                      }}>
                        <Typography variant={'heading_light'}  color={'CypherMD_Text.black'}>
                          {" "}
                          {patient?.name}
                        </Typography>
                        {/* <Box sx={{ display: "flex", alignItems: "center"  ,width:'max-content'}}>
                          <MobileScreenShareRoundedIcon
                            sx={{ height: "12px", width: "12px" }}
                          />
                          <Typography variant="body2_text">
                            {" "}
                            {patient?.mobile}
                          </Typography>
                        </Box> */}
                      </TableCell>
  
                      <TableCell sx={{ padding: "4px"   ,width:'33%' , textAlign:'center' }}>
                        <Typography variant={'heading_light'}>
                          {patient?.mobile}
                        </Typography>
                      </TableCell>


                      <TableCell sx={{ padding: "4px",width:'33%', textAlign:'center' ,  borderTopRightRadius:"20px" , borderBottomRightRadius :"20px"}}>
                        <Typography variant={'heading_light'}>
                          {patient?.patient_id}
                        </Typography>
                      </TableCell>

                      {/* <TableCell sx={{ padding: "4px", pl: "16px" }}>
                        <Typography
                          letterSpacing={"0.15px"}
                          variant="heading2_14"
                          color={"neutral.main"}
                        >
                          {patient?.visit_time}
                        </Typography>{" "}
                        <br />
                        <Typography
                          color={"text.grey"}
                          variant="body3_text"
                          fontWeight={"600"}
                        >
                           {patient?.visit_time} 
                        </Typography>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableRow sx={{ borderBottom:"2px solid #F7F7FF"}}>
                  <TableCell
                    sx={{
                      textAlign: "center",
                    }}
                    rowSpan={5}
                    colSpan={5}
                  >
                    {/* <Typography color={"black"} variant="heading2">
                      Data Not Found
                    </Typography> */}
 
                 <Box sx={{width:'40%' , margin:"auto",  display: patients?.length>0? 'none' :'',}}>
        <Box sx={{width:'100%', display:'flex', alignItems:'center', flexDirection:'row',  justifyContent:'space-between' ,  backgroundColor :'white' , borderRadius:'20px' ,  height:'185px', marginTop:'100px'}}>
        
          <Box sx={{marginTop:'-80px' , marginLeft:'30px'}}>
          <img src={not_found} alt='' width={'180px'} />
          </Box> 
          
        <Box sx={{paddingRight:'90px', marginTop:'-14px'}}>
          
          <Typography fontSize={'24px'} fontWeight={'bold'} color={'neutral.navyBlue'}>All Done, <Typography variant="span" color={'black'}></Typography> </Typography>
          {/* <Typography fontSize={'24px'}  fontWeight={'bold'} >You've made it </Typography> */}
          <Typography sx={{marginTop:"8px"}}> Data not found!</Typography>
        </Box>

        </Box>  
                </Box>


                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={openBill}
        onClose={handleOpenBillClose}
      >
        <PrePatientBillPharmacy id={patient} onClose={handleOpenBillClose} />
      </CustomModal>
    </>
  );
};

export default PatientListTable;
