import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import InProcess from "./InProcess";
import Pending from "./Pending";
import Completed from "./Completed";
import UseGetLaPatientData from "../../helper/UseGetLaPatientData";

const TabComponent = () => {
  const [value, setValue] = useState(0);
  const [testDate, setDateTest] = useState("");
  const { data: getLapPatient, isLoading } = UseGetLaPatientData(testDate);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "90vw",
        display: "flex",
        flexDirection: "column",
        backgroundColor:'#F7F7FF',
        margin:'auto',
        borderRadius:'20px'
      }}
    >
      <Tabs
    
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{
          backgroundColor:'#F7F7FF',
          borderRadius:'20px',
          "& .MuiTabs-indicator": {
            backgroundColor: "neutral.navyBlue",
          },
        }}
       
      >
        <Tab
          label="IN Process"
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            textTransform:'capitalize',
            color: "neutral.navyBlue",
            mt: 2,
            "&.Mui-selected": {
              color: "neutral.navyBlue",
            },
          }}
        />
        <Tab
          label="Pending"
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            textTransform:'capitalize',
            color: "neutral.navyBlue",
            mt: 2,
            "&.Mui-selected": {
              color: "neutral.navyBlue",
            },
          }}
        />
        <Tab
          label="Completed"
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            color: "neutral.navyBlue",
            textTransform:'capitalize',
            mt: 2,
            "&.Mui-selected": {
              // backgroundColor:'white',
              // borderRadius:'15px',
              // border:'1px solid #0067FF',
              color: "neutral.navyBlue",
            },
          }}
        />
      </Tabs>

      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {value === 0 && (
          <InProcess
            isLoading={isLoading}
            testDate={testDate}
            data={getLapPatient?.inprocess}
          />
        )}
        {value === 1 && (
          <Pending
            isLoading={isLoading}
            testDate={testDate}
            data={getLapPatient?.pending_tests}
          />
        )}
        {value === 2 && (
          <Completed
            setDateTest={setDateTest}
            data={getLapPatient?.completed_tests}
            testDate={testDate}
          />
        )}
      </Box>
    </Box>
  );
};

export default TabComponent;
