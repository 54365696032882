import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  Paper,
  Popper,
  Skeleton,
} from "@mui/material";
import {
  headingStyle,
  labPageButton,
  tableCellLab,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  menuItemStyle,
  primaryBtn,
  selectField,
  textfieldText,
  textfieldTextBlueBorderPharmacy,
} from "../../style/AppointmentStyle";
import { drTableRow } from "../../style/DrApotComponentStyle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { addBtn, borderInset, pharmacyPageBlueBtn } from "../../style/PharmacyStyle";
import PrePatientPharmacyMedicine from "../../helper/PrePatientPharmacyMedicine";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/UseDebounce";
import UseGetInvoice from "../../helper/UseGetInvoice";
import UseGetMedicineList from "../../helper/UseGetMedicineList";
import UseMedicineByName from "../../helper/UseMedicineByName";
import UseGetMedicineDetail from "../../helper/UseGetMedicineDetail";
import {
  getMedicineByName,
  getPriceByQuantity,
  getPriceByQuantityForReturn,
} from "../../connections/getApi";
import { useReactToPrint } from "react-to-print";
import { generateBill } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import { Link } from "react-router-dom";
import UseGetAllMedicineList from "../../helper/UseGetAllMedicineList";
import PharmacyInvoice from "../PrintCompoents/PharmacyInvoice";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { drTableRowBlueBg } from "../../style/PatinetFormStyle";
import { orangeButton, primaryBtnBlue } from "../../style/PatientDetailsStyle";
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';

const PrePatientBillPharmacy = ({ onClose, id }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMedicines, setFilteredMedicines] = useState([]);
  const [openMedicineList, setOpenMedicines] = useState(false);
  const [select, setSelect] = useState(0);
  const [total, setTotal] = useState(0);
  const [formState, setFormState] = useState({
    name: "",
    uid: "",
    mobile: "",
    age: "",
    gender: "",
    patient_id: "",
    prescription_id: "",
  });
  const [medicines, setMedicines] = useState([
    {
      id: "",
      name: "",
      salt: [],
      quantity: "",
      batchId: null,
      batch: "",
      batchArray: [],
      returned: false,
      stock: "",
      packing: "",
      unitMrp: null,
      mrp: null,
      discount: null,
      gst: null,
      amount: null,
    },
  ]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [showButton, setShowButton] = useState(true);
  const [printInvoiceData, setPrintInvoiceData] = useState({});
 
  const dispatch = useDispatch();

  const { data: patient , isLoading } = PrePatientPharmacyMedicine(id);
  const { data: medicineList } = UseGetAllMedicineList();

  useEffect(() => {
    // Simulating fetching all data on component mount and storing it in local storage or state.
    if (
      medicineList?.payload?.medicines &&
      medicineList?.payload?.medicines?.length > 0
    ) {
      localStorage.setItem(
        "allMedicines",
        JSON.stringify(medicineList?.payload?.medicines)
      );
    }
  }, [medicineList]);

  // const { data: medicineName } = UseMedicineByName(
  //   selectedMedicine?.name,
  //   selectedMedicine?.name
  // );

  const handleSearch = (event) => {
    // console.log(event.target.value);
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    const storedMedicines =
      JSON.parse(localStorage.getItem("allMedicines")) || [];

    // Filter medicines based on search query
    const filteredMedicineList = storedMedicines.filter((medicine) =>
      medicine[1].toLowerCase().startsWith(query)
    );

    // Limit the results to top 10 and sort
    const limitedResults = filteredMedicineList.slice(0, 10);

    setFilteredMedicines(limitedResults);
  };

  const { data: medicineDetail } = UseGetMedicineDetail(selectedMedicine?.id);

  useEffect(() => {
    if (patient) {
      setFormState({
        name: patient?.patient?.name,
        uid: patient?.patient?.uid,
        mobile: patient?.patient?.mobile,
        age: patient?.patient?.age,
        gender: patient?.patient?.gender,
        patient_id: patient?.id,
        prescription_id: "",
      });

      setMedicines(
        patient?.medicines?.map((medicine) => ({
          id: medicine?.id,
          name: medicine?.name || "",
          quantity: medicine?.quantity || "",
          batchId: medicine?.batch || null,
          salt: medicine?.salt,
          batch: medicine?.batch || "",
          batchArray: [],
          returned: false,
          stock: medicine?.stock_left,
          packing: medicine?.packing || "",
          unitMrp: medicine?.unit_mrp || null,
          mrp: medicine?.mrp || null,
          discount: `${medicine?.discount || 0}%`,
          gst: (medicine?.cgst || 0) + (medicine?.sgst || 0),
          amount: medicine?.amount || null,
        }))
      );
    }
  }, [patient]);

  const handleAddRow = () => {
    setMedicines([
      ...medicines,
      {
        id: "",
        name: "",
        salt: [],
        quantity: "",
        batchId: null,
        batch: "",
        batchArray: [],
        returned: false,
        stock: "",
        packing: "",
        unitMrp: null,
        mrp: null,
        discount: null,
        gst: null,
        amount: null,
      },
    ]);
  };

  // handle key down with  enter  logic
  const handleChange = (index, field, value) => {
    const newMedicines = medicines.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );
    setMedicines(newMedicines);
  };

  const updateNameAndId = (index, value) => {
    const newMedicines = [...medicines];
    newMedicines[index] = {
      ...newMedicines[index],
      id: value[0],
      name: value[1],
    };
    setMedicines(newMedicines);
  };

  // console.log(medicines);

  // const handleKeyDown = (e, index, field) => {
  //   if (
  //     e.key === "Enter" &&
  //     field === "name" &&
  //     medicines[index].salt?.length === 0
  //   ) {
  //     e.preventDefault();

  //     if (!medicines[index]?.name) {
  //       setTimeout(() => document.getElementById(`submit`).focus(), 0);
  //       return;
  //     }

  //     const currentInput = medicines[index]?.name?.toLowerCase() || "";
  //     const topOption = filteredMedicines.find((medicine) =>
  //       (medicine[1] || "").toLowerCase().startsWith(currentInput)
  //     );

  //     if (topOption) {
  //       setTimeout(() => {
  //         updateNameAndId(index, topOption);
  //         handlePresence(index, topOption);
  //         setSelectedMedicine({ name: topOption[1], id: topOption[0] });
  //         document.getElementById(`quantity-${index}`).focus();
  //       }, 100); // Delay to ensure the input value is fully captured
  //     }
  //   }

  //   // for enter move to salt medicine table
  //   if (
  //     e.key === "Enter" &&
  //     field === "name" &&
  //     medicines[index].salt?.length > 0
  //   ) {
  //     e.preventDefault();

  //     if (!medicines[index]?.name) {
  //       // mean empty string
  //       setTimeout(() => document.getElementById(`submit`).focus(), 0);
  //       return;
  //     }

  //     let saltIndex = 0;
  //     const saltCount = medicines[index].salt.length;

  //     // Function to focus on next salt or move to quantity after salts
  //     const selectNextSalt = () => {
  //       if (saltIndex < saltCount) {
  //         const saltId = `selectSalt-${index}-${saltIndex}`;
  //         // console.log(saltId);

  //         if (saltId) {
  //           console.log(saltId);

  //           setTimeout(() => document.getElementById(saltId).focus(), 100);
  //           saltIndex++;
  //         }

  //         // Listen for another Enter key press for moving to the next salt
  //         document.getElementById(saltId).addEventListener("keydown", (ev) => {
  //           if (ev.key === "Enter") {
  //             ev.preventDefault();
  //             selectNextSalt(); // Call again to move to the next salt or quantity
  //           }
  //         });
  //       } else {
  //         // All salts processed, move to the quantity field
  //         setTimeout(
  //           () => document.getElementById(`quantity-${index}`).focus(),
  //           0
  //         );
  //       }
  //     };

  //     selectNextSalt();
  //   } else if (e.key === "Enter" && field === "quantity") {
  //     setTimeout(() => document.getElementById(`return-${index}`).focus(), 0);
  //   } else if (e.key === "Enter" && field === "return") {
  //     handleAddRow();
  //     setTimeout(() => document.getElementById(`name-${index + 1}`).focus(), 0);
  //   }
  // };
  const handleKeyDown = (e, index, field) => {
    if (e.key === "Enter" && field === "name") {
      e.preventDefault();

      // If no salts are available, move focus to quantity
      if (medicines[index].salt?.length === 0) {
        if (!medicines[index]?.name) {
          setTimeout(() => document.getElementById(`submit`).focus(), 0);
          return;
        }

        const currentInput = medicines[index]?.name?.toLowerCase() || "";
        console.log(currentInput , 'currentInput')
        const topOption = filteredMedicines.find((medicine) =>
          (medicine[1] || "").toLowerCase().startsWith(currentInput)
        );

        if (topOption) {
          setTimeout(() => {
            updateNameAndId(index, topOption);
            handlePresence(index, topOption);
            setSelectedMedicine({ name: topOption[1], id: topOption[0] });
            document.getElementById(`quantity-${index}`).focus();
          }, 100);
        }
      }

      // If salts are available, move focus to salt table
      if (medicines[index].salt?.length > 0) {
        e.preventDefault();

        if (!medicines[index]?.name) {
          setTimeout(() => document.getElementById(`submit`).focus(), 0);
          return;
        }

        let saltIndex = 0;
        const saltCount = medicines[index].salt.length;

        // Function to focus on the next salt or move to quantity
        const selectNextSalt = () => {
          if (saltIndex < saltCount) {
            const saltId = `selectSalt-${index}-${saltIndex}`;
            const saltElement = document.getElementById(saltId);
            if (saltElement) {
              // console.log(saltElement);

      
              
          
                  
              setTimeout(() => {
                saltElement.focus();
                saltIndex++;

                // just to preserve medi name 
               // Preserve medicine name in state
                const newMedicines = medicines.map((medicine, idx) =>
                  idx === index ? { ...medicine, name: medicines[index]?.name } : medicine
                );
                setMedicines(newMedicines);
                  
                saltElement.addEventListener("keydown", (ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    selectNextSalt(); // Move to next salt or quantity
                  }
                });
              }, 100); // Slight delay to ensure rendering
            }
          } else {
            // Move focus to quantity field after last salt
            setTimeout(
              () => document.getElementById(`quantity-${index}`).focus(),
              0
            );
          }
        };

        // Start focusing on the first salt
        selectNextSalt();
      }
    }
               
    // Handle navigation to the quantity field
    if (e.key === "Enter" && field === "quantity") {
      setTimeout(() => document.getElementById(`return-${index}`).focus(), 0);
    }

    // Handle adding a new row and focusing on the next medicine name
    if (e.key === "Enter" && field === "return") {
      handleAddRow();
      setTimeout(() => document.getElementById(`name-${index + 1}`).focus(), 0);
    }
  };
    
  const handlePresence = async (index, value) => {
    const medicineNames = medicines
      ?.slice(0, medicines?.length > 1 ? medicines?.length - 1 : 0)
      .map((med) => med.name)
      .join(",");
    // encodeURIComponent(medicineNames)
    // console.log(medicineNames);
    try {
      const res = await getMedicineByName(
        encodeURIComponent(medicineNames),
        value[1]
      );

      //  console.log(res.payload?.presence);
      if (res?.payload?.presence) {
        const updatedMedicines = medicines.map((medicine, i) =>
          i === index
            ? { ...medicine, id: "", name: "", description: "", remark: "" }
            : medicine
        );

        // Update the state with the cleared fields
        setMedicines(updatedMedicines);

        console.log(updatedMedicines, "Updated medicines list");
        showToast("Medicine already present in the list", "ERROR");
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(value, " call api to check ");
    setFilteredMedicines([]);
  };

  const handleMedicineChange = (index, field, value) => {
    const newMedicines = medicines.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );
    setMedicines(newMedicines);
  };

  //packing and stock show after select medicine
  useEffect(() => {
    if (medicineDetail && select !== null) {
      setMedicines((prevMedicines) =>
        prevMedicines.map((medicine, idx) =>
          idx === select
            ? {
                ...medicine,
                stock: medicineDetail?.payload?.stock_left,
                packing: medicineDetail?.payload?.packing,
              }
            : medicine
        )
      );
      // console.log(packing, stock_left, '<-stock and packing');
    }
  }, [medicineDetail, select]);

  // console.log(formState, "formState");
  // console.log(patient, 'patient')

  const handleQuantityChange = (index, field, value) => {
    const newMedicines = medicines?.map((medicine, idx) =>
      idx === index ? { ...medicine, [field]: value } : medicine
    );

    setMedicines(newMedicines);
    getPriceByQuantity(medicines[index].id, value, medicines[index].batchId)
      .then((res) => {
        console.log(res.payload);
        const {
          selected_batch,
          discount,
          gst,
          mrp,
          batches,
          unit_price,
          grand_total,
        } = res.payload;

        // Update the medicine entry with the new price data
        const updatedMedicines = newMedicines?.map((medicine, idx) =>
          idx === index
            ? {
                ...medicine,
                batchId: selected_batch?.id,
                batch: selected_batch,
                batchArray: batches,
                stock: selected_batch?.stock_left,
                packing: batches[0]?.packing,
                unitMrp: medicine.returned ? -unit_price : unit_price,
                mrp: medicine.returned ? -mrp : mrp,
                discount: medicine.returned ? `-${discount}%` : `${discount}%`,
                gst,
                amount: medicine.returned ? -grand_total : grand_total,
              }
            : medicine
        );
        setMedicines(updatedMedicines);
      })
      .catch((error) => {
        console.error("Error fetching price:", error);
      });
  };

  // console.log(medicines);
  const handleReturnChange = (index) => {
    const newMedicines = medicines?.map((medicine, idx) =>
      idx === index ? { ...medicine, returned: !medicine.returned } : medicine
    );
    setMedicines(newMedicines);

    getPriceByQuantityForReturn(medicines[index].id, medicines[index].quantity)
      .then((res) => {
        console.log(res.payload);
        const {
          selected_batch,
          discount,
          gst,
          mrp,
          batches,
          unit_price,
          grand_total,
        } = res.payload;

        // Update the medicine entry with the new price data
        const updatedMedicines = newMedicines?.map((medicine, idx) =>
          idx === index
            ? {
                ...medicine,
                batchId: selected_batch?.id,
                batch: selected_batch,
                batchArray: batches,
                stock: selected_batch?.stock_left,
                packing: batches[0]?.packing,
                unitMrp: medicine.returned ? -unit_price : unit_price,
                mrp: medicine.returned ? -mrp : mrp,
                discount: medicine.returned ? `-${discount}%` : `${discount}%`,
                gst,
                amount: medicine.returned ? -grand_total : grand_total,
              }
            : medicine
        );

        setMedicines(updatedMedicines);
      })
      .catch((error) => {
        console.error("Error fetching price:", error);
      });
  };

  const handleSelectMedicine = (index, salt) => {
    setMedicines((prevMedicines) =>
      prevMedicines.map((medicine, i) =>
        i === index
          ? {
              ...medicine,
              id: salt?.id,
              name: salt?.name,
              stock: salt?.stock_left,
              expiry_date: salt?.expiry_date,
              salt: [],
            }
          : medicine
      )
    );
    setSelectedMedicine({ name: salt?.name, id: salt?.id });
  };

  useEffect(() => {
    let totalAmount = 0;
    let validMedicines = 0;
    let returnMedicine = 0;
    // let hasInvalidQuantity = false;

    medicines?.forEach((medicine) => {
      // Check if the medicine has a valid name and quantity
      if (medicine.name && medicine.quantity && medicine.quantity > 0) {
        validMedicines++;

        if (medicine?.amount < 0) {
          returnMedicine += parseFloat(medicine.amount);
        } else {
          totalAmount += parseFloat(medicine.amount);
        }
      }

      // Check if there are medicines with a name but invalid quantity (null, 0, or undefined)
      if (medicine.name && (!medicine.quantity || medicine.quantity <= 0)) {
        // hasInvalidQuantity = true;
      }
    });

    setSelect(validMedicines);
    setTotal(Math.abs(totalAmount + returnMedicine));

    // If any invalid quantity is found, hide the button
    setShowButton(validMedicines > 0);
  }, [medicines]);

  // const handlePresence = async (index, value) => {
  //   const medicineNames = medicines
  //     ?.slice(0, medicines?.length > 1 ? medicines?.length - 1 : 0)
  //     .map((med) => med.name)
  //     .join(",");
  //   // encodeURIComponent(medicineNames)
  //   // console.log(medicineNames);
  //   try {
  //     const res = await getMedicineByName(
  //       encodeURIComponent(medicineNames),
  //       value[1]
  //     );

  //     //  console.log(res.payload?.presence);
  //     if (res?.payload?.presence) {
  //       const updatedMedicines = medicines.map((medicine, i) =>
  //         i === index
  //           ? { ...medicine, id: "", name: "", description: "", remark: "" }
  //           : medicine
  //       );

  //       // Update the state with the cleared fields
  //       setMedicines(updatedMedicines);

  //       console.log(updatedMedicines, "Updated medicines list");
  //       showToast("Medicine already present in the list", "ERROR");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   // console.log(value, " call api to check ");
  //   setFilteredMedicines([]);
  // };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "medicine invoice Print",
    onAfterPrint: () => console.log("Print job finished"),
  });

  //handle save & print invoice
  const saveAndPrint = async () => {
    const formattedMedicines = medicines?.map((medicine) => ({
      id: medicine?.id,
      name: medicine.name,
      batch: medicine?.batch?.id,
      packing: medicine?.packing,
      quantity: medicine?.quantity,
      amount: medicine.unitMrp * medicine.quantity,
      discount: medicine.discount,
      net: medicine.amount,
    }));

    const payload = {
      patient: {
        full_name: formState.name,
        gender: formState.gender?.toLowerCase(),
        mobile: formState.mobile,
        email: formState.email,
      },
      patient_id: formState?.patient_id ||null ,
      prescription_id:  formState?.prescription_id || null,
      // invoice_id: formState?.invoice_id || null,
      medicines: formattedMedicines,
    };

    const res = await generateBill(payload);
    setPrintInvoiceData(res);
    // console.log(res, "<-res");

    const statusMessage = res?.status?.status || "ERROR";
    const detailedMessage = res?.status?.message || "Something went wrong";
    showToast(statusMessage, detailedMessage);
    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
    if (res?.status?.status === "SUCCESS") {
      setTimeout(() => {
        handlePrint();
        showToast(`Generating prescription PDF`, "SUCCESS");
      }, 0);
    }
  };

  const handleRemoveRow = (index) => {
    const newMedicines = medicines.filter((_, idx) => idx !== index);
    setMedicines(newMedicines);
  };

  
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "90vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "80vh",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
        
        <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
      >
        <Box></Box>
  

         <Box sx={{display:'flex', justifyContent:'center' , alignItems:'center' }} >
        <ReceiptLongIcon sx={{color:'neutral.navyBlue' , marginBottom:"4px" , marginRight:'8px'}} /> 
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={headingStyle}
        >
        Bill
       </Typography>
       </Box>
        <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
          <CloseIcon size="small"  sx={{color:"red"}}/>
        </IconButton>
      </Box> 



     {isLoading? (
          <Skeleton  
            variant="rectangular"
            width="100%"
             height={30}
             />
        ):(
                               
          <Grid
            container
            sx={{ justifyContent: "space-between", width: "100%", mt: "30px" }}
          >
            <Typography>Name: {patient?.patient?.name} </Typography>
            <Typography>Patient ID: {patient?.patient?.uid} </Typography>
            <Typography>Mobile: {patient?.patient?.mobile}</Typography>
            <Typography>
              Age : {patient?.patient?.age === "0" ? patient?.patient?.age : 1}
            </Typography>
            <Typography>{patient?.patient?.gender}</Typography>
          </Grid>

   )}

          <Grid item xs={12}>
            <TableContainer sx={{ mt: 2, width: "100%" }}>
              <Table sx={{ width: "100%" }}>
                <TableHead stickyHeader>
                <TableRow sx={{ ...drTableRowBlueBg, textAlign: "center" ,  }}>
                <TableCell
                variant="body1_text_bold" 
                  sx={{
                    borderTopLeftRadius:"20px",
                  color: 'neutral.white',
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                  //  paddingX:"4px",
                   textAlign: "center",
                   width: "200px",
                 }}
                >
                  Name
                </TableCell>

                <TableCell  
                 
                  variant="body1_text_bold" 
                  sx={{
                    width: "150px",
                    paddingRight: "30px",
                  color: 'neutral.white',
                    p: "8px",
                    textAlign: "center",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Batch
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Return
                </TableCell>

                <TableCell
                variant="body1_text_bold" 
                sx={{
                color: 'neutral.white',
                  p: "8px",
                  textAlign: "center",
                }}
                >
                  Total Stock
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Pack
                </TableCell>

                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Unit MRP
                </TableCell>

                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  MRP
                </TableCell>

                <TableCell
                variant="body1_text_bold" 
                sx={{
                color: 'neutral.white',
                  p: "8px",
                  textAlign: "center",
                }}
                >
                  Discount
                </TableCell>
                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  GST
                </TableCell>

                <TableCell
                 variant="body1_text_bold" 
                 sx={{
                 color: 'neutral.white',
                   p: "8px",
                   textAlign: "center",
                 }}
                >
                  Amount
                </TableCell>

                <TableCell
                  sx={{ p: "4px", textAlign: "center" , borderTopRightRadius:"20px"}}
                  
                >
                  <IconButton  onClick={handleAddRow} sx={{...orangeButton , marginLeft:"7px"  ,marginY:'2px'}}>
                  <AddIcon sx={{color:'neutral.white',
           width:'30px',
           height:'32px' , padding:'0px'}} />
                  </IconButton>
                </TableCell> 
              </TableRow>
                </TableHead>

            {isLoading ? (     
        <Skeleton  
          variant="rectangular"
          width="10000%"
           height={300}
          
          sx={{backgroundColor: 'lightgray'  , marginTop:'10px'}}
          />)  : 
                 (<TableBody>
                  {medicines?.length > 0 &&
                    medicines?.map((medicine, index) => (
                      <TableRow key={index} sx={{...tableRowLab, borderBottom:'7px solid white' , borderTop: index===0? 'p4x solid white' :" " ,  pointerEvents:
                        medicine?.id === null ? "none" : "auto",
                      opacity: medicine?.id === null ? 0.6 : 1,
                      }}>
                    
                        <TableCell sx={tableCellLab}>
                          <Typography variant="body_Grey1" color={"text.grey"}>
                            {index + 1}
                          </Typography>
                        </TableCell>

                        <TableCell sx={tableCellLab}>
                          <Autocomplete
                            options={filteredMedicines}
                            freeSolo
                            clearOnEscape={false}
                            // clearIcon={null}
                           
                            open={openMedicineList === index}
                            onOpen={() => setOpenMedicines(index)}
                            onClose={() => setOpenMedicines(false)}
                            id={`name-${index}`}
                            inputValue={
                              medicine?.name !== null ? medicine?.name : ""
                            }
                            onInputChange={(e, value) => {
                              handleChange(index, "name", value, e);
                            }}
                            onChange={(e, value) => {
                              // console.log(value);
                              if (value) {
                                handleChange(index, "id", value[0]);
                                handlePresence(index, value);
                                setTimeout(() => {
                                  document
                                    .getElementById(`quantity-${index}`)
                                    .focus();
                                }, 0);
                              }
                            }}
                            sx={{...textfieldTextBlueBorderPharmacy,
                                border: medicine?.id === null ?  "1px solid red" : "none",
                                borderRadius: medicine?.id === null ? "5px":  "0" , // Added proper border-radius styling
                                opacity: medicine?.id === null ? 0.9 : 1,
                          
                            }}
                            PopperComponent={(props) => (
                              <Grid item lg={8}>
                                <Popper
                                  {...props}
                                  placement="bottom-start"
                                  style={menuItemStyle}
                                  modifiers={[
                                    {
                                      name: "preventOverflow",
                                      options: {
                                        boundary: "block",
                                      },
                                    },
                                  ]}
                                >
                                  <div
                                    style={{
                                      maxHeight: 300,
                                      overflowY: "auto",
                                      scrollbarWidth: "none",
                                      maxWidth: "340px",
                                      minWidth: "330px",
                                    }}
                                  >
                                    {props.children}
                                  </div>
                                </Popper>
                              </Grid>
                            )}
                            getOptionLabel={(option) => option[1]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onChange={handleSearch}
                                value={searchTerm}
                                placeholder="Medicine"
                                onKeyDown={(e) =>
                                  handleKeyDown(e, index, "name")
                                }
                              />
                            )}
                            renderOption={(props, option, { index }) => (
                              <MenuItem
                                sx={{
                                  ...menuItemStyle,
                                  "&:hover": {
                                    backgroundColor: "#f58633 !important",
                                    color: "white !important",
                                  },
                                  ...(index === 0 && {
                                    backgroundColor: "#f58633 !important",
                                    color: "white !important",
                                  }),
                                }}
                                {...props}
                                key={option[0]}
                              >
                                {option[1]}
                              </MenuItem>
                            )}
                          />
                          
                          {medicine?.salt?.length > 0 && (
                            
                            <TableContainer
                           
                              sx={{
                                ...borderInset,
                                marginTop: "5px",
                                maxHeight: "200px",
                                width: "380px", 
                                overflowY: "auto",
                                "&::-webkit-scrollbar": {
                                  display: "none",
                                },
                                /* Hide scrollbar for Firefox */ 
                                scrollbarWidth: "none",
                                /* Hide scrollbar for IE, Edge */
                                msOverflowStyle: "none", 
                                border: "1px solid rgba(0, 0, 0, 0.2)",
                                // padding:'10px',
                              
                              }}
                              // style={{
                              //   padding:'4px',
                              //     boxShadow: "inset rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, inset rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                              //   // border :'0.2px solid gray' ,
                              //    borderRadius:"2px" ,
                                
                              // }}
                              
                            >
                              <Table component={Paper} sx={{ width: "380px",
                              }}>
                                <TableHead>
                                  <TableRow  sx={{backgroundColor:"#0067FF" }}>
                                    <TableCell sx={{ padding: "6px" , borderTopLeftRadius:'10px' , borderBottomLeftRadius:'10px' }}>
                                      <Typography color={'white'} textAlign={"center"}>
                                        Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "6px" }}>
                                      <Typography color={'white'} textAlign={"center"}>
                                        Stock 
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "6px" ,  borderTopRightRadius:'10px' , borderBottomRIghtRadius:'10px' }}>
                                      <Typography color={'white'} textAlign={"center"}>
                                        Exp
                                      </Typography>
                                    </TableCell>
                                   
                                  </TableRow>
                                </TableHead>
                                <TableBody>

                                  {medicine?.salt?.map((item, idx) => (

                                    <TableRow key={idx} sx={{  }}>

                                      <TableCell sx={{  width:'35%' , alignItems:'center'}}>
                                 
                                         {/* <Box sx={{display:'flex' ,  justifyContent:'center' , alignItems:'center' , margin:'0px' , padding:'0px',width:'100%' }}> */}
                                          <Brightness1OutlinedIcon 
                                          variant="subTitle1"
                                          id={`selectSalt-${index}-${idx}`}
                                          // color={"neutral.brown"}
                                          sx={{
                                            // padding:'5px',
                                            marginBottom:'-8px',
                                            outline: "none", 
                                            "&:focus": {
                                             fontWeight:"800",
                                              color:"#0067FF"
                                            },
                                            color:"gray",
                                          }}
                                          tabIndex={idx}
                                          onClick={() =>
                                            handleSelectMedicine(index, item)
                                          }
                                          onKeyDown={(e) => {
                                            if (e.key === "Tab") {
                                              // Call handleSelectMedicine when Tab is pressed
                                              handleSelectMedicine(index, item);
                                            }
                                          }}
                                            />

                                        <Typography variant="subTitle1">
                                          {item?.name}
                                        </Typography>
                                        {/* </Box> */}
                                      </TableCell>

                                      <TableCell sx={{ padding: "2px" , textAlign:'center' }}>
                                        <Typography variant="subTitle1">
                                          {item?.stock_left}
                                        </Typography>
                                      </TableCell>
                                      <TableCell sx={{ padding: "2px", textAlign:'center'  }}>
                                        <Typography variant="subTitle1">
                                          {item?.expiry_date}
                                        </Typography>
                                      </TableCell>


                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                           
                          )}
                        </TableCell>

                        <TableCell sx={{ ...tableCellLab }}>
                          <TextField
                            id={`quantity-${index}`}
                            fullWidth
                            placeholder="Quantity"
                            type="number"
                            variant="outlined"
                            sx={textfieldTextBlueBorderPharmacy}
                            onKeyDown={(e) =>
                              handleKeyDown(e, index, "quantity")
                            }
                            onChange={(e) => {
                              handleChange(index, "quantity", e.target.value);
                              handleQuantityChange(
                                index,
                                "quantity",
                                e.target.value
                              );
                            }}
                          />
                        </TableCell> 

                        <TableCell
                          sx={{
                            ...tableCellLab,
                            width: "100px",
                            pointerEvents:
                              medicine?.id === null ? "none" : "auto",
                            opacity: medicine?.id === null ? 0.6 : 1,
                          }}
                        >
                          <FormControl fullWidth variant="outlined">
                            <Select
                              sx={{...textfieldTextBlueBorderPharmacy , 
                                borderRadius:'10px',
                                "& .MuiSelect-select": {
                                  padding: "9px", // Custom padding for select dropdown
                                },
                              }}
                              placeholder="batch"
                              fullWidth
                              value={medicines[index]?.batchId || ""}
                              onChange={(e) => {
                                handleQuantityChange(
                                  index,
                                  "batchId",
                                  e.target.value
                                );
                              }}
                            >
                              {medicines[index]?.batchArray?.map((batch) => (
                                <MenuItem key={batch.id} value={batch.id}>
                                  {batch.batch_no}
                                </MenuItem>
                              ))}
                            </Select>
                            {/* Render the batch stock and expiry date */}

                            <Box
                              justifyContent={"space-between"}
                              display={"flex"}
                              paddingX={"2px"}
                            >
                              <Typography
                                fontSize={"12px"}
                                color={` ${
                                  medicine?.quantity >
                                  medicines[index]?.batch?.stock_left
                                    ? "#ed5564"
                                    : "text.grey"
                                } `}
                              >
                                {medicines[index]?.batch?.stock_left}
                              </Typography>
                              <Typography fontSize={"12px"}>
                                {medicines[index]?.batch?.expiry_date}
                              </Typography>
                            </Box>
                          </FormControl>
                        </TableCell>

                        <TableCell sx={{ ...tableCellLab }}>
                          <Checkbox
                            id={`return-${index}`}
                            onKeyDown={(e) => handleKeyDown(e, index, "return")}
                            onChange={() => handleReturnChange(index)}
                            sx={{ height: "10px", width: "10px" }}
                          />
                        </TableCell>

                        <TableCell sx={tableCellLab}>
                          <Typography
                            variant="body_Grey1"
                            color={` ${
                              medicine?.quantity > medicine.stock
                                ? "#ed5564"
                                : "text.grey"
                            } `}
                          >
                            {medicine?.stock}
                          </Typography>
                        </TableCell>

                        <TableCell sx={tableCellLab}>
                          <Typography variant="body_Grey1" color={"text.grey"}>
                            {medicine?.packing}
                          </Typography>
                        </TableCell>

                        <TableCell sx={tableCellLab}>
                          {medicine?.returned ? (
                            <Typography variant="body_Grey1" color={"#ed5564"}>
                              {" "}
                              {medicine?.mrp}{" "}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body_Grey1"
                              color={"text.grey"}
                            >
                              {medicine.mrp}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell sx={tableCellLab}>
                          {medicine.returned ? (
                            <Typography variant="body_Grey1" color={"#ed5564"}>
                              {" "}
                              {medicine?.mrp}{" "}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body_Grey1"
                              color={"text.grey"}
                            >
                              {medicine?.mrp}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell sx={tableCellLab}>
                          {medicine.returned ? (
                            <Typography variant="body_Grey1" color={"#ed5564"}>
                              {" "}
                              {medicine?.discount}{" "}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body_Grey1"
                              color={"text.grey"}
                            >
                              {medicine?.discount}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell sx={tableCellLab}>
                          <Typography variant="body_Grey1" color={"text.grey"}>
                            {medicine.gst}
                          </Typography>
                        </TableCell>

                        <TableCell sx={tableCellLab}>
                          {medicine.returned ? (
                            <Typography variant="body_Grey1" color={"#ed5564"}>
                              {" "}
                              {medicine?.amount}{" "}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body_Grey1"
                              color={"text.grey"}
                            >
                              {medicine?.amount}
                            </Typography>
                          )}
                        </TableCell>

                       
                          <TableCell sx={{ ...tableCellLab }}>
                          <IconButton sx={{ marginLeft:'8px', marginBottom:"7px",}}
                      onClick={() => handleRemoveRow(index)}
                     >
                <CloseIcon sx={{ ...xBtn, width:'26px', height:'26px', borderRadius:'20%' }}/>
                    </IconButton> 
                          </TableCell>
                     
                      </TableRow>
                    ))}
                </TableBody> 
                 )
                  }      
              </Table>
            </TableContainer>
            <Box
          sx={{
            ...tableRowLab,
            borderBottomLeftRadius:"20px",
            borderBottomRightRadius:"20px", 
            borderTopLeftRadius:"0px",
            borderTopRightRadius:"0px",
            justifyContent: "space-between",
            display: "flex",
            height: "60px",
            alignItems: "center",
            padding: "3px",   
            paddingX:"40px"
          }}
        >
          <Box sx={{ justifyContent: "space-between",
            display: "flex",
            height: "60px",
            alignItems: "center"}} columnGap={'10px'}>
              <Typography variant="h6">Selected : </Typography>
            <Typography variant="h6">
              {select}
            </Typography>
          </Box>
{/*           
          <Box></Box>

          <Box></Box> */}

          <Box  sx={{ justifyContent: "space-between",
            display: "flex",
            height: "60px",
            alignItems: "center"}} columnGap={'10px'}>
            <Typography variant="h6">Total:</Typography>
          
            <Typography variant="h6" >{isNaN(total) ? 0 : total}</Typography>
          </Box>
        </Box>
          </Grid>
        </Box>

        <Box
          container
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ zIndex: "10" }}            
          lg={12}
          marginBottom={'5px'}
        >
          <Grid item>
          
          </Grid>
 
          <Grid item>
           

            <Button
              onClick={saveAndPrint}
               className="btn draw-border"
              variant="contained"
               id="submit"
              sx={{
               ...primaryBtnBlue,
               width:'195px',
               display: showButton ? "flex" : "none",
             
               alignItems: 'center',
               justifyContent: 'center',
              }}
              disabled={isLoading}
            >
              <Typography
                textTransform={"capitalize"}
                color={"neutral.white"}
                variant="title1_medium"
              >
                Save and Print
              </Typography> 
            </Button>
          </Grid>
        </Box>
      </Box>
      <div style={{ display: "none" }}>
        <PharmacyInvoice props={printInvoiceData} ref={printRef} />
      </div>
    </>
  );
};
export default PrePatientBillPharmacy;

//     const newTotal = cart.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
//     setTotal(newTotal.toFixed(2));
//   };
//   useEffect(() => {
//     const calculateTotal = () => {
//       const newTotal = cart.reduce((acc, item) => acc + (!item.returned ? parseFloat(item.amount || 0) : 0), 0);
//       setTotal(newTotal.toFixed(2));
//     };
//     calculateTotal();
//   }, [cart]);
//   const handleReturnChange = (index) => {
//     setCart(prevCart => {
//       const updatedCart = prevCart.map((item, i) => {
//         if (i === index) {
//           return { ...item, returned: !item.returned };
//         }
//         return item;
//       });

//       const newTotal = updatedCart.reduce((acc, item) => {
//         return acc + (!item.returned ? parseFloat(item.amount || 0) : 0);
//       }, 0);

//       setTotal(newTotal.toFixed(2));
//       return updatedCart;
//     });
//   };

//   const handleSelectMedicine = (index, selectedMedicineName) => {
//     const selectedMedicine = medicines.find(med => med.name === selectedMedicineName);

//     if (selectedMedicine) {
//       const sortedBatches = selectedMedicine.batch.sort((a, b) => new Date(a.bExp) - new Date(b.bExp));

//       setCart(prevCart => prevCart.map((item, i) =>
//         i === index ? {
//           ...item,
//           name: selectedMedicine.name,
//           totalStock: selectedMedicine.totalStock,
//           pack: selectedMedicine.pack,
//           mrp: selectedMedicine.mrp,
//           discount: selectedMedicine.discount,
//           gst: selectedMedicine.gst,
//           amount: selectedMedicine.amount,
//           batch: sortedBatches,
//           selectedBatch: sortedBatches[0].bName, // Automatically select the first batch
//           batchStock: sortedBatches[0].bStock,
//           batchExpiry: sortedBatches[0].bExp
//         } : item
//       ));
//     }
//   };

//   return (
//     <Box sx={{ width: "100%" }}>

//        <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography
//           variant="heading_Large"
//           fontWeight={700}
//           gutterBottom
//           sx={headingStyle}
//         >
//         Bill
//         </Typography>
//         <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
//           <CloseIcon size="small" />
//         </IconButton>
//       </Box>
//       <Grid
//         container
//         sx={{ justifyContent: "space-between", width: "100%", mt: "30px" }}
//       >
//         <Typography>Name: John wick</Typography>
//         <Typography>Patient ID: 4567890 </Typography>
//         <Typography>Mobile: 1234567890</Typography>
//         <Typography>Age :22</Typography>
//         <Typography>Male</Typography>
//       </Grid>

// <Grid item xs={12}>
//           <TableContainer sx={{ mt: 2, width: "100%" }}>
//             <Table sx={{ width: "100%" }}>
//               <TableHead stickyHeader>
//                 <TableRow sx={{...drTableRow , textAlign :'center'  }}>
//                   <TableCell sx={{ fontSize: "14px",  fontWeight: "600", p: "8px", textAlign:'center' }}   >
//                   ID
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600", p: "8px", textAlign:'center' , width :"200px" }}
//                   >
//                     Name
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600", p: "8px",  textAlign:'center' , width :"150px", paddingRight :"30px" }}
//                   >
//                   Quantity
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600",  p: "8px" ,textAlign:'center'  }}
//                   >
//                     Batch
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600", p: "8px" , textAlign:'center' }}
//                   >
//                     Return
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600",  p: "8px"  , textAlign:'center'}}
//                   >

//                     Stock
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600",  p: "8px"  , textAlign:'center'}}
//                   >
//                     Pack
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600",  p: "8px"  , textAlign:'center'}}
//                   >
//                    Unit MRP
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600",  p: "8px"  , textAlign:'center'}}
//                   >
//                    MRP
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600", p: "8px"  , textAlign:'center'}}
//                   >
//                    Discount
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600", p: "8px"  , textAlign:'center'}}
//                   >
//                    GST
//                   </TableCell>
//                   <TableCell
//                     sx={{ fontSize: "14px", fontWeight: "600", p: "8px"  , textAlign:'center'}}
//                   >
//                    Amount
//                   </TableCell>

//                   <TableCell
//                     sx={{ p: "4px" , textAlign:'center' }}
//                     onClick={handleAddRow}
//                   >
//                   <AddIcon  sx={addBtn}/>
//                   </TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {cart.map((medi,index)=>(

//               <TableRow  sx={tableRowLab}>

//                 <TableCell sx={tableCellLab}>
//                   <Typography variant="body_Grey1" color={"text.grey"}>
//                     {index + 1}
//                   </Typography>
//                 </TableCell>

//                 <TableCell sx={tableCellLab}>
//                   {medi.name ? (
//                   <Autocomplete
//                     options={medics.map(medic => medic.name)}
//                     freeSolo
//                     value={medi.name}
//                     onChange={(e, value) => handleMedicineChange(index, value)}
//                     renderInput={(params) => (
//                       <TextField sx={textfieldText} {...params} placeholder="Medicine" />
//                     )}
//                   />)
//                   :
//                   (
//                     <>
//                     <TextField  fullWidth
//                     // placeholder={medi.nameSalt}
//                     value={medi.nameSalt}
//                     variant="outlined"
//                     sx={textfieldText}
//                     // onChange={(e) => handleMedicSelect(index, e.target.value)}
//                      />
//                   <TableContainer sx={{ marginTop:"5px"}}>
//                   <Table component={Paper} sx={{width:'250px'}}>
//                     <TableHead>
//                       <TableRow>
//                          <TableCell sx={{padding :'2px'}}> <Typography variant="heading2_14"> Name</Typography> </TableCell>
//                          <TableCell  sx={{padding :'2px'}}> <Typography variant="heading2_14"> Stock Left</Typography></TableCell>
//                          <TableCell  sx={{padding :'2px'}}> <Typography variant="heading2_14"> Expiry Date</Typography></TableCell>
//                          <TableCell  sx={{padding :'2px'}}> </TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>

//                         {medicineOptions.map((item,index)=>(
//                          <TableRow key={index} sx={{...tableRowLab }}>
//                           <TableCell sx={{padding :'2px'}}> <Typography variant="subTitle1"> {item.brandName}</Typography></TableCell>
//                           <TableCell sx={{padding :'2px'}}>  <Typography variant="subTitle1"> {item.stockLeft}</Typography></TableCell>
//                           <TableCell sx={{padding :'2px'}}>  <Typography variant="subTitle1"> {item.expiryDate}</Typography> </TableCell>
//                           <TableCell sx={{padding :'2px' , cursor:"pointer"}}> <Typography variant="subTitle1" color={'neutral.brown'} onClick={handleSelectMedicine}>   Select </Typography></TableCell>
//                          </TableRow>
//                         ))}

//                     </TableBody>
//                   </Table>
//                   </TableContainer>
//                   </>
//                   )
//                 }
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                 <TextField
//                   fullWidth
//                   placeholder="Quantity"
//                   variant="outlined"
//                   sx={textfieldText}
//                   onChange={(e) => handleQuantityChange(index, e.target.value)}
//                 />
//                 </TableCell>

//               <TableCell sx={{ ...tableCellLab, width: '100px' }}>
//                 <FormControl fullWidth variant="outlined">
//                   <Select
//                     fullWidth
//                     sx={{ ...selectField }}
//                     value={''}
//                     name="Batch"
//                     displayEmpty
//                     renderValue={(selected) => {
//                       if (!selected) {
//                         return <span style={{ color: "text.default_grey" }}> </span>;
//                       }
//                       return selected;
//                     }}
//                               >
//                   {medi.batch && medi.batch.length > 0 ? (
//                     medi.batch.map((item) => (
//                       <MenuItem key={item.bName} value={item.bName}>
//                         {item.bName}
//                       </MenuItem>
//                     ))
//                     ) : (
//                       <MenuItem disabled>No Batch Available</MenuItem>
//                     )}
//                   </Select>
//                 </FormControl>
//                   {/* <Box justifyContent={'space-between'}  display={'flex'} paddingX={'2px'} >
//                     <Typography fontSize={'12px'}>20</Typography>
//                     <Typography fontSize={'12px'}>Aug, 2024</Typography>
//                   </Box> */}
//                 </TableCell>

//                 <TableCell sx={tableCellLab} >
//                  <Checkbox  onChange={() => handleReturnChange(index)} sx={{height :'10px' , width :'10px'}}/>
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                    <Typography variant="body_Grey1" color={"text.grey"}>
//                     {medi.totalStock}
//                   </Typography>
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                 <Typography variant="body_Grey1" color={"text.grey"}>
//                     {medi.pack}
//                   </Typography>
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                 {medi.returned ?   (
//                   <Typography variant="body_Grey1" color={"#ed5564"}> -{medi.mrp}  </Typography>
//                 ):(
//                   <Typography  variant="body_Grey1" color={"text.grey"}>
//                   {medi.mrp}
//                   </Typography>
//                 )
//               }
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                 {medi.returned ?   (
//                   <Typography variant="body_Grey1" color={"#ed5564"}> -{medi.mrp}  </Typography>
//                 ):(
//                   <Typography  variant="body_Grey1" color={"text.grey"}>
//                   {medi.mrp}
//                   </Typography>
//                 )
//               }
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                 {medi.returned ?   (
//                   <Typography variant="body_Grey1" color={"#ed5564"}> -{medi.discount}  </Typography>
//                 ):(
//                   <Typography  variant="body_Grey1" color={"text.grey"}>
//                   {medi.discount}
//                   </Typography>
//                 )
//               }
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                   <Typography variant="body_Grey1" color={"text.grey"}>
//                     {medi.gst}
//                   </Typography>
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                 {medi.returned ?   (
//                   <Typography variant="body_Grey1" color={"#ed5564"}> -{medi.amount}  </Typography>
//                 ):(
//                   <Typography  variant="body_Grey1" color={"text.grey"}>
//                   {medi.amount}
//                   </Typography>
//                 )
//               }
//                 </TableCell>
//                 <TableCell sx={tableCellLab}>
//                   <EditIcon sx={{...addBtn , color: "white"}}/>
//                 </TableCell>

//                 </TableRow>
//                )) }
//               </TableBody>
//          </Table>
//         </TableContainer>
//         <Box sx={{...tableRowLab,  justifyContent :'space-between' , display:"flex"  , height :'60px', alignItems:"center" , padding :"10px"}}>
//            <Box>

//            <Typography variant="heading2_14" marginRight={'40px'}>{select}</Typography>
//             <br/>
//             <Typography variant="heading2_14">Selected</Typography>
//            </Box>
//            <Box>
//            </Box>
//            <Box>
//            </Box>
//            <Box>
//              <Typography variant="h5">Total</Typography>
//            </Box>
//            <Box>
//              <Typography>{total}</Typography>
//            </Box>
//         </Box>
//         </Grid>
//         <Grid
//         container
//         display={"flex"}
//         justifyContent={"space-between"}
//         sx={{zIndex :"10" }}
//         lg={12}
//       >
//         <Grid item>
//           <Button variant="contained" sx={primaryBtn} onClick={onClose}>
//             <Typography fontSize={"1.1rem"}>Close</Typography>
//           </Button>
//         </Grid>
//         <Grid item>
//           <Button variant="contained" sx={pharmacyPageBlueBtn} >
//             <Typography fontSize={"1.1rem"} fontWeight={'600'}> Save & Print</Typography>
//           </Button>
//         </Grid>
//       </Grid>
//      </Box>
//   )
// }
// export default PrePatientBillPharmacy;
