// import React from "react";
// import { Navigate, Outlet, useLocation } from "react-router-dom";
// import Cookies from "js-cookie";

// const PrivateRoute = () => {
//   const authStaging = Cookies.get('authStaging') ? JSON.parse(Cookies.get('authStaging')) : null;
//   const isAuthenticated = authStaging?.isAuthenticated || false;
//   const redirect = authStaging?.redirect;

//   const location = useLocation();

//   // Redirect to login if not authenticated
//   if (!isAuthenticated) {
//     return <Navigate to="/" />;
//   }

//   // Redirect to the proper route if user tries to access a route they're not authorized for
//   const allowedRoutes = [redirect]; // Add more allowed routes based on your application's logic
//   if (!allowedRoutes.includes(location.pathname)) {
//     return <Navigate to={redirect} />;
//   }

//   return <Outlet />;
// };

// export default PrivateRoute;

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = () => {
  const authStaging = Cookies.get("authStaging")
    ? JSON.parse(Cookies.get("authStaging"))
    : null;
  const isAuthenticated = authStaging?.isAuthenticated || false;
  const redirect = authStaging?.redirect || "/";  // Fallback to a default route if redirect is not set

  const location = useLocation();

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // Only allow access to the stored redirect route
  const allowedRoutes = [redirect];
  if (!allowedRoutes.includes(location.pathname)) {
    return <Navigate to={redirect} />;
  }

  // If user is authenticated and on the allowed route, allow access
  return <Outlet />;
};

export default PrivateRoute;
