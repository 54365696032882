import dayjs from "dayjs";


export const generateCSV = (data) => {
    const header = [
      "Order ID",
      "Supplier Name",
      "Supplier Phone",
      "Supplier GST No",
      "Supplier Address",
      "Supplier Email",
      "Bill Type",
      "Bill Number",
      "Purchase Date",
      "Grand Total",
      "Item ID",
      "HSN Code",
      "Batch",
      "Expiry Date",
      "Quantity",
      "CGST",
      "SGST",
      "Rate",
      "MRP",
      "Amount",
      "Discount",
    ];
  
    const rows = data.flatMap((order) => {
      return order.purchase_items.map((item) => {
        return [
          order.id,
          order.supplier.name,
          order.supplier.phone,
          order.supplier.gst_no,
          order.supplier.address,
          order.supplier.email,
          order.bill_type,
          order.bill_number,
          order.purchase_date,
          order.grand_total,
          item.id,
          item.hsn_code,
          item.batch,
          item.expiry_date,
          item.quantity,
          item.cgst,
          item.sgst,
          item.rate,
          item.mrp,
          item.amount,
          item.discount,
        ];
      });
    });
  
    const csvContent = [header, ...rows].map((e) => e.join(",")).join("\n");
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    // Create a link and trigger a download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "purchase_orders.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  export const handleShowJson = (suppliers) => {
    const jsonWindow = window.open("", "_blank");
    jsonWindow.document.write(
      "<pre>" + JSON.stringify(suppliers, null, 2) + "</pre>"
    );
    jsonWindow.document.title = "Suppliers JSON Data";
  };

  export const handleDownloadCsvMedicine = (suppliers) => {
    const csvHeaders = [
      Object.keys(suppliers[0]).map(
        (key) => key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")
      ),
    ];
    const csvRows = suppliers.map((supplier) => Object.values(supplier));
  
    const csvContent = [csvHeaders, ...csvRows]
      .map((e) => e.join(","))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exportCSV.csv";
    link.click();
  };

  export const handleDownloadCsvSupplier = (suppliers) => {
    const csvHeaders = [
      Object.keys(suppliers[0]).map(
        (key) => key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")
      ),
    ];
    const csvRows = suppliers.map((supplier) => Object.values(supplier));
  
    const csvContent = [csvHeaders, ...csvRows]
      .map((e) => e.join(","))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exportCSV.csv";
    link.click();
  };

   export const formattedDate =(date) => {
    if (!date) return null;

    const parsedDate = dayjs(date, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("MMMM DD, YYYY");
    const isExpiringSoon = parsedDate.isBefore(dayjs().add(2, "month"));
    const style = isExpiringSoon
      ? {
          // backgroundColor: "red",
          color: "red",
          padding: "5px 10px",
          borderRadius: "4px",
        }
      : {};

    return <span style={style}>{formattedDate}</span>;
  };

  export const formattedDateWithoutBg =(date) => {
    if (!date) return null;

    const parsedDate = dayjs(date, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("MMMM DD, YYYY");
    const isExpiringSoon = parsedDate.isBefore(dayjs().add(2, "month"));
   

    return <span >{formattedDate}</span>;
  };

