import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button, 
  TextField,
  IconButton,
} from "@mui/material";
import {
  headingStyle,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import { primaryBtnBlue } from "../../style/PatientDetailsStyle";
import { autoFocusOffWithOutBorder } from "../../style/PatinetFormStyle";
import supplier  from '../../assets/supplier.svg';
import { updateSupplier } from "../../connections/putApi";
import { showToast } from "../../utils/toastUtil";
import { generateSupplier } from "../../connections/postApi";

const NewSupplier = ({ onClose }) => {
       
 
  const [formState, setFormState]=useState({
        name: "",
        phone: "",
        gst_no: "",
        address: "",
        email : "",   
  })
  const [errors, setErrors] = useState({}); 
 

  const handleFormChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Clear error when the user starts typing
    }));
  };
 
  const validateForm = () => {
    const newErrors = {};
    
    // Validate Name, GST No, Address
    Object.entries(formState).forEach(([key, value]) => {
      if (!value || typeof value !== 'string' || !value.trim()) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      }
    });
  
    // Phone Number Validation
    if (!/^\d{10}$/.test(formState.phone)) {
      newErrors.phone = "Phone number must be exactly 10 digits.";
    }
  
    // Email Validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
  
    return newErrors;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
      supplier: { 
        name: formState.name,
        phone: formState.phone,
        gst_no: formState.gst_no,
        address: formState.address,
        email: formState.email,
      },
    };
 
    try {
      const result = await generateSupplier(payload);
      if (result?.status?.status === "SUCCESS") {
        showToast("Supplier Updated Successfully", "SUCCESS");
      }
    } catch (error) {
      showToast(error.message, "ERROR");
    }
  };

  return (   
    <Box sx={{ width: "100%", display:'flex' , height:"90vh" ,  flexDirection:'column' , justifyContent:"space-between" }}>
    <Box sx={{overflowY :'auto' , maxHeight:"80vh" ,  '&::-webkit-scrollbar': {
    display: 'none',
    },
    '-ms-overflow-style': 'none',  
    'scrollbar-width': 'none',  }}  >
       
       <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
      >
        <Box></Box>

         <Box sx={{display:'flex', justifyContent:'center' , alignItems:'center'  , gap:'9px'}} >
        <img src={supplier}  alt='' sx={{color:'neutral.navyBlue' , marginBottom:"4px" , marginRight:'8px'}} /> 
        <Typography
          variant="heading_Large"
          fontWeight={700}
          gutterBottom
          sx={headingStyle}
        >
      New   Suppliers
       </Typography>
       </Box>
        <IconButton sx={{ marginBottom: "0rem" }} onClick={onClose}>
          <CloseIcon size="small"  sx={{color:"red"}}/>
        </IconButton>
      </Box> 

      
<Grid 
  container
  mt={2}
  spacing={2}
  width="100%" // Ensures the grid container takes full width
  marginBottom="5px"
  paddingX="20px"
  mb={2}
> 
  <Grid item md={1} mt={1}>  <Typography variant="heading_light"> Name</Typography> </Grid>    
  <Grid item md={11}> 
    <TextField value={formState?.name} fullWidth placeholder="Name"  name="name"    onChange={(e) => handleFormChange('name', e.target.value)} sx={autoFocusOffWithOutBorder}  
       error={!!errors['name']}
       helperText={errors['name']}
       /> {/* Ensure TextField takes full width inside the grid */}
  </Grid> 

  <Grid item md={1} mt={1}>  <Typography variant="heading_light"> Phone</Typography> </Grid>    
  <Grid item md={11}>  
    <TextField value={formState?.phone}   name="phone"  onChange={(e) => handleFormChange('phone', e.target.value)} fullWidth placeholder="Phone" sx={autoFocusOffWithOutBorder} 
     inputProps={{
      maxLength: 10, // Limit input to 10 characters
      inputMode: 'numeric', // Ensure numeric input on mobile devices
      pattern: '[0-9]*', // Allow only numeric values
    }}
    error={!!errors['phone']}
     helperText={errors['phone']} 
     /> {/* Ensure TextField takes full width inside the grid */}
  </Grid> 

  <Grid item md={1} mt={1}>  <Typography variant="heading_light"> GST No</Typography> </Grid>    
  <Grid item md={11}> 
    <TextField  value={formState?.gst_no}  name="gst_no"  onChange={(e) => handleFormChange('gst_no', e.target.value)} fullWidth placeholder="GST No" sx={autoFocusOffWithOutBorder} 
        error={!!errors['gst_no']}
        helperText={errors['gst_no']}/> {/* Ensure TextField takes full width inside the grid */}
  </Grid> 

  <Grid item md={1} mt={1}>  <Typography variant="heading_light"> Address</Typography> </Grid>    
  <Grid item md={11}> 
    <TextField value={formState?.address}  name="address"  onChange={(e) => handleFormChange('address', e.target.value)}  fullWidth placeholder="Address" sx={autoFocusOffWithOutBorder}
     error={!!errors['address']}
     helperText={errors['address']}
    /> {/* Ensure TextField takes full width inside the grid */}
  </Grid> 

  <Grid item md={1} mt={1}>   <Typography variant="heading_light"> Email</Typography></Grid>    
  <Grid item md={11}> 
    <TextField value={formState?.email}   name="email"  onChange={(e) => handleFormChange('email', e.target.value)}  fullWidth placeholder="Email" sx={autoFocusOffWithOutBorder}
       error={!!errors['email']}
       helperText={errors['email']}
       /> {/* Ensure TextField takes full width inside the grid */}
  </Grid> 
</Grid>  

       
      <Box 
        
        display={"flex"}   
        justifyContent={"space-between"}
        sx={{ zIndex: "10" }}
        lg={12}
        marginBottom={'5px'}
        paddingX={'20px'}
      >       
        <Grid item>
        
        </Grid>


        <Grid item  sx={{display:'flex' , gap:'20px' , alignItems:'center' , justifyContent:'center' }} >
          
          <Button onClick={handleSubmit} sx={{...primaryBtnBlue , color:"white"}} > Create  Supplier</Button>
        </Grid>

      </Box>

      
    </Box>

     

   </Box>
  );
};

export default NewSupplier;
